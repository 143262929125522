import { dia, util } from '@clientio/rappid';
import { TICKET_DEFAULT_COLOR, TICKET_HEIGHT, TICKET_WIDTH } from './constant';

class WorkflowPopup extends dia.Element {
  constructor(...args) {
    super(...args);
    this.resize(TICKET_WIDTH, TICKET_HEIGHT);
  }

  defaults() {
    return {
      ...super.defaults,
      type: 'WorkflowPopup',
      attrs: {
        body: {
          fill: TICKET_DEFAULT_COLOR,
          width: TICKET_WIDTH,
          height: TICKET_HEIGHT,
          rx: 8
        },
        foreignObject: {
          width: TICKET_WIDTH - 10,
          height: TICKET_HEIGHT - 10,
          x: 5,
          y: 5
        }
      }
    };
  }

  preinitialize(...args) {
    this.markup = util.svg`
      <rect @selector="body" />
    `;
  }
}

const createWorkflowPopup = (config, graph, part, y) => {
  const popup = new WorkflowPopup(config);
  part.embed(popup);
  popup.addTo(graph);
  return popup;
};
export { createWorkflowPopup, WorkflowPopup };
