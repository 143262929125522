import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { MenuType } from '../../../organisms/spaces/SpaceDetail';

interface Props {
  type: MenuType;
  handleClickMenu: (menuType: MenuType) => void;
}

interface SpaceMenu {
  type: MenuType;
  text: string;
}

const spaceMenuList: SpaceMenu[] = [
  {
    type: 'project',
    text: 'プロジェクト'
  },
  {
    type: 'member',
    text: 'メンバー管理'
  },
  {
    type: 'settings',
    text: '設定'
  }
];
const SpaceSidebar = (props: Props) => {
  const { type, handleClickMenu } = props;

  return (
    <div className="w-167 h-full">
      <List component="nav" aria-label="mailbox folders">
        {spaceMenuList.map((spaceMenu, index) => (
          <ListItem
            button
            selected={type === spaceMenu.type}
            key={`space_menu_${index}`}
            onClick={() => handleClickMenu(spaceMenu.type)}>
            <ListItemText primary={spaceMenu.text} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default SpaceSidebar;
