import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.scss';
import WorkflowPage from './pages/Workflow/Workflow';
import Root from './Root';
import reportWebVitals from './reportWebVitals';
import { ApolloClientProvider } from './context/ApolloClientProvider';
import LoginPage from './pages/Login/Login';
import RegisterPage from './pages/Register/Register';
import ForgotPasswordPage from './pages/ForgotPassword/ForgotPassword';
import NewRegisterPage from './pages/NewRegister/NewRegister';
import SpacePage from './pages/Space';
import ProjectDetailPage from './pages/Space/Project';
import WorkflowTaskPage from './pages/Workflow/Task';
import WorkflowTicketPage from './pages/Workflow/Ticket';
import SpaceCreatePage from './pages/Space/create';
import PageNotFound from './pages/PageNotFound';
import WorkflowTicketV2Page from './pages/Workflow/TicketV2';
import TagManager, { TagManagerArgs } from 'react-gtm-module';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: 'workflows/:workflowId/diagram/view',
        element: <WorkflowPage />
      },
      {
        path: 'workflows/:workflowId/list/view',
        element: <WorkflowPage />
      },
      {
        path: 'workflows/:workflowId/task',
        element: <WorkflowTaskPage />
      },
      {
        path: 'workflows/:workflowId/ticket',
        element: <WorkflowTicketPage />
      },
      {
        path: 'workflows/:workflowId/ticket-v2',
        element: <WorkflowTicketV2Page />
      },
      {
        path: 'workflows/:workflowId/ticket/:ticketId',
        element: <WorkflowPage />
      },
      {
        path: 'workflows/:workflowId/process/:processId',
        element: <WorkflowPage />
      },
      {
        path: 'spaces/create',
        element: <SpaceCreatePage />
      },
      {
        path: 'spaces/:spaceId',
        element: <SpacePage />
      },
      {
        path: 'projects/:projectId',
        element: <ProjectDetailPage />
      }
    ]
  },
  {
    path: 'login',
    element: <LoginPage />
  },
  {
    path: 'new-register',
    element: <NewRegisterPage />
  },
  {
    path: 'register',
    element: <RegisterPage />
  },
  {
    path: 'forgot-password',
    element: <ForgotPasswordPage />
  },
  {
    path: '*',
    element: <PageNotFound />
  }
]);

const tagManagerArgs: TagManagerArgs = {
  gtmId: process.env.REACT_APP_GA || ''
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ApolloClientProvider>
    <RouterProvider router={router} />
  </ApolloClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
