import { File } from '@/__generated__/graphql';
import { GET_PROJECTS } from '@/api/graphql';
import { Project, ProjectOrder, SortType } from '@/graphql';
import { useQuery } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  TextField
} from '@mui/material';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';

interface Props {
  transferFile: (proj: Project) => Promise<void>
  handleClose: () => void;
}

const FileTransferDialog = (props: Props) => {
  const { transferFile, handleClose } = props;
  const [query, setQuery] = useState<string>();
  const [selectedProject, setSelectedProject] = useState<Project>();

  const { data } = useQuery(GET_PROJECTS, {
    variables: {
      filter: { page: 1, limit: 999999, orderBy: ProjectOrder.UpdatedAt, sortType: SortType.Desc }
    }
  });

  const list = (data?.projects.nodes || []).filter((proj: Project) => query ? proj.name.includes(query) : true)

  const handleCloseDialog = () => {
    handleClose();
  };
  const handleSubmit = async () => {
    if (selectedProject) {
      await transferFile(selectedProject)
    }
    handleClose();
  }

  return (
    <Dialog
      open
      fullWidth={true}
      hideBackdrop={false}
      PaperProps={{
        sx: { width: 500, maxHeight: 600 }
      }}>
      <DialogTitle className="font-size-20 font-weight-500">ファイルを移動</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCloseDialog}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Box sx={{ mt: 2 }}>
          <TextField fullWidth value={query} onChange={(e) => setQuery(e.target.value)} />
          <List>
            {list.map((proj: Project) => {
              return (
                <ListItemButton
                  key={`project_${proj.id}`}
                  selected={proj.id === selectedProject?.id}
                  onClick={() => {
                    setSelectedProject(proj);
                  }}>
                  <ListItemText>{proj.name}</ListItemText>
                </ListItemButton>
              );
            })}
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>
          キャンセル
        </Button>
        <Button variant="contained" disabled={!selectedProject} onClick={handleSubmit}>
          ファイルを移動
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileTransferDialog;
