import React from 'react';
import Box from '@mui/material/Box';
import { Project } from '@/__generated__/graphql';
import SpaceProjectListHeader from '@/components/organisms/spaces/SpaceProjectListHeader';
import SpaceProjectListTable from '@/components/organisms/spaces/SpaceProjectListTable';

interface Props {
  projectList: Project[];
  setShowModal: (type: string) => void;
  currentPage: number;
  setCurrentPage: (type: number) => void;
  handleDeleteProject: () => void;
  showAddProjectDialog: () => void;
  setSelectedProject: (type: Project) => void;
  totalCount: number;
}

const SpaceProjectList = (props: Props) => {
  const {
    projectList,
    setSelectedProject,
    setShowModal,
    showAddProjectDialog,
    handleDeleteProject,
    currentPage,
    setCurrentPage,
    totalCount
  } = props;

  return (
    <Box className="h-full text-left">
      <SpaceProjectListHeader handleAddProject={() => setShowModal('AddProjectModal')} />
      <SpaceProjectListTable
        projects={projectList}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setSelectedProject={setSelectedProject}
        showAddProjectDialog={showAddProjectDialog}
        handleDeleteProject={handleDeleteProject}
        totalCount={totalCount}
      />
    </Box>
  );
};

export default SpaceProjectList;
