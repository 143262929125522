import React, { ChangeEvent } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { File } from '@/__generated__/graphql';
import Pagination from '@mui/material/Pagination';
import WorkFlowItem from '@/components/organisms/spaces/SpaceWorkflowListTable/WorkFlowItem';

interface Props {
  files: File[];
  showFileNameChangeDialog: (file: File) => void;
  setSelectFileForTransfer: (file: File) => void;
  cronFile: (file: File) => void;
  showFileInfoDialog: (file: File) => void;
  deleteFile: (file: File) => void;
  handleDeleteFile: () => void;
  currentPage: number;
  setCurrentPage: (type: number) => void;
  totalCount: number;
}

const SpaceWorkflowListTable = (props: Props) => {
  const {
    files,
    showFileNameChangeDialog,
    setSelectFileForTransfer,
    cronFile,
    showFileInfoDialog,
    deleteFile,
    handleDeleteFile,
    currentPage,
    setCurrentPage,
    totalCount
  } = props;

  const itemsPerPage = 20;
  const totalPages = Math.ceil(totalCount / itemsPerPage);

  const handleChangePage = (event: ChangeEvent<unknown>, newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: '#F5F5F5' }}>
              <TableCell sx={{ padding: '0px', width: 50 }}></TableCell>
              <TableCell sx={{ padding: '0px' }}>ワークフローファイル名</TableCell>
              <TableCell sx={{ paddingLeft: '16px' }}>更新日</TableCell>
              <TableCell sx={{ paddingLeft: '16px' }}>作成日</TableCell>
              <TableCell sx={{ paddingLeft: '16px' }}>作成者</TableCell>
              <TableCell sx={{ padding: '3px', width: 50 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files?.map((file, index) => (
              <WorkFlowItem
                file={file}
                index={index}
                key={`space_project_workflow_item_${index}`}
                showFileNameChangeDialog={showFileNameChangeDialog}
                setSelectFileForTransfer={setSelectFileForTransfer}
                cronFile={cronFile}
                showFileInfoDialog={showFileInfoDialog}
                deleteFile={deleteFile}
                handleDeleteFile={handleDeleteFile}
              />
            ))}
          </TableBody>
        </Table>
        <Stack spacing={2} sx={{ alignItems: 'center', mt: 2, mb: 2 }}>
          <Pagination
            color="primary"
            count={totalPages}
            page={currentPage}
            onChange={handleChangePage}
          />
        </Stack>
      </TableContainer>
    </>
  );
};

export default SpaceWorkflowListTable;
