import React, { useState } from 'react';
import { Avatar, Box } from '@mui/material';
import { GNodePropertyFile } from '@/__generated__/graphql';
import Typography from '@mui/material/Typography';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { getFileType } from '@/utils/files';

interface Props {
  propertyFile: GNodePropertyFile;
  setShowModal: (fileID: string) => void;
  setShowFullScreen: Function;
}

const NodePropertyFileItem = (props: Props) => {
  const { propertyFile, setShowModal, setShowFullScreen } = props;
  const [hovered, setHovered] = useState(false);

  const handleAvatarClick = () => {
    setShowFullScreen(true);
  };

  const checkFileType = (propertyFile: GNodePropertyFile) => {
    switch (getFileType(propertyFile.fileName)) {
      case 'image':
        return (
          <img
            src={`${propertyFile.file}?w=161&fit=crop&auto=format`}
            alt="file"
            style={{ maxWidth: '100%', maxHeight: 68 }}
          />
        );
      case 'docx':
        return <img src="/icons/doc.svg" alt="docx" />;
      case 'pdf':
        return <img src="/icons/pdf.svg" alt="pdf" />;
      case 'ppt':
        return <img src="/icons/ppt.svg" alt="ppt" />;
      case 'xls':
        return <img src="/icons/xls.svg" alt="xls" />;
      case 'txt':
        return <img src="/icons/txt.svg" alt="txt" />;
    }
  };

  const handleDownload = async () => {
    const response = await fetch(propertyFile.file);
    const blob = await response.blob();
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = propertyFile.fileName;
    link.click();
  };

  return (
    <Box sx={{ height: '100%' }}>
      <Box
        sx={{
          position: 'relative'
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}>
        <Box
          onClick={handleAvatarClick}
          sx={{
            width: 115,
            height: 68,
            cursor: 'pointer'
          }}>
          <Avatar
            sx={{
              width: 115,
              height: 68,
              backgroundColor: '#F5F5F5'
            }}
            variant="rounded">
            {checkFileType(propertyFile)}
          </Avatar>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: 115,
              height: 68,
              backgroundColor: hovered ? '#0000008F' : '#F5F5F5',
              opacity: hovered ? 1 : 0
            }}></Box>
        </Box>

        <Typography
          sx={{
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
            textOverflow: 'ellipsis',
            width: 115,
            whiteSpace: 'wrap',
            wordBreak: 'break-word',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          variant="body2">
          {propertyFile.fileName}
        </Typography>

        {hovered && (
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              top: 19,
              right: 23
            }}>
            <Box
              sx={{
                width: 30,
                height: 30,
                backgroundColor: 'white',
                marginRight: '8px',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <IconButton>
                <FileDownloadOutlinedIcon sx={{ width: 20 }} onClick={handleDownload} />
              </IconButton>
            </Box>
            <Box
              sx={{
                width: 30,
                height: 30,
                backgroundColor: 'white',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <IconButton>
                <DeleteIcon sx={{ width: 20 }} onClick={() => setShowModal(propertyFile.id)} />
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default NodePropertyFileItem;
