import React, { SetStateAction } from 'react';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { FilterValueType } from './../../../organisms/workflows/TicketList';
import CheckIcon from '@mui/icons-material/Check';
import remove from 'lodash/remove';
import { GNodeProperty, Kind, NodePropertyStatusEnum, User } from '@/__generated__/graphql';
import { TICKET_PROPERTY_PRIORITY } from '@/types/tickets';
import { getColorKind, getLabelKind } from '@/utils/tickets';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface Props {
  tickets: GNodeProperty[];
  excludeViews: string[];
  kinds: Kind[];
  setExcludeViews: (excludeViews: SetStateAction<string[]>) => void;
  filterValue: FilterValueType;
  setFilterValue: (excludeViews: SetStateAction<FilterValueType>) => void;
  handleAddTicket: () => void;
  selectedTicket: (ticketId: string) => void;
  users: User[];
  isSidebarShowing: boolean;
}

const views = [
  {
    label: '種類',
    name: 'type'
  },
  {
    label: '関連プロセス',
    name: 'task'
  },
  {
    label: '優先順位',
    name: 'priority'
  },
  {
    label: '担当者',
    name: 'assignee'
  },
  {
    label: '作成日',
    name: 'created_at'
  }
];

const TicketListHeader = (props: Props) => {
  const {
    tickets,
    excludeViews,
    kinds,
    setExcludeViews,
    filterValue,
    setFilterValue,
    handleAddTicket,
    selectedTicket,
    users,
    isSidebarShowing
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [displayAnchorEl, setDisplayAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [searchString, setSearchString] = React.useState('');
  const [focus, setFocus] = React.useState(false);
  const open = Boolean(anchorEl);
  const openDisplay = Boolean(displayAnchorEl);
  const handleMenuItemClick = (index: number, ticketId: string) => {
    setSelectedIndex(index);
    selectedTicket(ticketId);
    setFocus(false);
  };

  const getTicketsByString = (text: string): GNodeProperty[] => {
    return tickets.filter((ticket) => ticket.name.includes(text));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDisplayMenu = () => {
    setDisplayAnchorEl(null);
  };

  const handleChange = (name: string) => {
    setExcludeViews((prevState) => {
      const newState = [...prevState];
      if (newState.includes(name)) {
        remove(newState, (view) => view === name);
      } else {
        newState.push(name);
      }
      return newState;
    });
    handleCloseDisplayMenu();
  };

  return (
    <Box className="canvas-header" style={{ left: !isSidebarShowing ? 0 : 160 }}>
      <Box>
        <TextField
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            )
          }}
          placeholder="チケットを検索"
          value={searchString}
          sx={{
            '& .MuiOutlinedInput-root': {
              height: 40
            }
          }}
          onFocus={(e) => {
            if (e.currentTarget === e.target) {
              setFocus(true);
            }
          }}
          onBlur={(e) => {
            e.preventDefault();
            if (e.currentTarget === e.target) {
              if (!e.relatedTarget?.classList.contains('MuiMenuItem-root')) {
                setFocus(false);
              }
            }
          }}
          onChange={(e) => {
            setSearchString(e.target.value);
          }}
        />
        {focus && (
          <Box
            sx={{
              position: 'absolute',
              backgroundColor: 'white',
              boxShadow:
                '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
              zIndex: 1100
            }}>
            {getTicketsByString(searchString).map((ticket, i) => (
              <MenuItem
                key={`project_menu_item_${i}`}
                selected={i === selectedIndex}
                onClick={(e) => {
                  e.preventDefault();
                  handleMenuItemClick(i, ticket.id);
                }}
                sx={{ minWidth: 300 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <Box
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      width: 200,
                      textOverflow: 'ellipsis'
                    }}>
                    {ticket.name}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1
                    }}>
                    <Box
                      sx={{
                        display: 'inline-flex',
                        backgroundColor: getColorKind(ticket),
                        width: '16px',
                        height: '16px'
                      }}
                    />
                    <span>{getLabelKind(ticket)}</span>
                  </Box>
                </Box>
              </MenuItem>
            ))}
            {getTicketsByString(searchString).length === 0 && (
              <MenuItem sx={{ minWidth: 300 }}>チケットが見つかりません。</MenuItem>
            )}
          </Box>
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
        <FormControl>
          <Button
            id="customized-button"
            sx={{
              height: 40,
              borderColor: '#0000003B',
              color: '#00000099',
              fontSize: '14px',
              padding: '9px 12px'
            }}
            size="small"
            aria-controls={open ? 'customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            disableElevation
            onClick={(e) => setAnchorEl(e.currentTarget)}
            variant="outlined"
            endIcon={<KeyboardArrowDownIcon />}>
            表示項目
          </Button>
          <Menu
            id="project-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'lock-button',
              role: 'listbox'
            }}>
            <Box sx={{ p: 1 }}>
              <Box sx={{ m: 1 }}>
                <label className="font-size-14 font-weight-400">チケット</label>
                <Box sx={{ display: 'flex', ml: 1 }}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={filterValue.status}
                    name="radio-buttons-group"
                    onChange={(e) => {
                      setFilterValue((prev) => ({
                        ...prev,
                        status: e.target.value as NodePropertyStatusEnum | 'all'
                      }));
                    }}>
                    <FormControlLabel value="all" control={<Radio />} label="すべて" />
                    <Box sx={{ display: 'flex' }}>
                      <FormControlLabel
                        value={NodePropertyStatusEnum.InProgress}
                        control={<Radio />}
                        label="未完了"
                      />
                      <FormControlLabel
                        value={NodePropertyStatusEnum.Complete}
                        control={<Radio />}
                        label="解決済み"
                      />
                    </Box>
                  </RadioGroup>
                </Box>
              </Box>
              <Box sx={{ m: 1 }}>
                <label className="font-size-14 font-weight-400">種類</label>
                <Box sx={{ display: 'flex', ml: 1 }}>
                  {kinds &&
                    kinds.map((kind, index) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filterValue.kind && filterValue.kind.includes(kind.id)}
                            onClick={() => {
                              let kindIds = filterValue.kind || [];
                              if (filterValue.kind && filterValue.kind.includes(kind.id)) {
                                kindIds.splice(kindIds.indexOf(kind.id), 1);
                              } else {
                                kindIds.push(kind.id);
                              }
                              setFilterValue((prev) => ({
                                ...prev,
                                kind: kindIds
                              }));
                            }}
                          />
                        }
                        labelPlacement="end"
                        label={kind.text}
                        key={`kind_type_${index}`}
                      />
                    ))}
                </Box>
              </Box>
              <Box sx={{ m: 1 }}>
                <label className="font-size-14 font-weight-400">担当者</label>
                <Box sx={{ display: 'flex' }}>
                  <Autocomplete
                    id="tags-standard"
                    sx={{
                      width: '100%',
                      height: '52px'
                    }}
                    options={users ?? []}
                    value={filterValue.selectedUser}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, selectedUser) => {
                      setFilterValue((prev) => ({
                        ...prev,
                        selectedUser: selectedUser as User | undefined
                      }));
                    }}
                    componentsProps={{
                      paper: {
                        sx: {
                          width: '321px'
                        }
                      }
                    }}
                    renderOption={(props, option, { selected }) => (
                      <Box
                        component="li"
                        {...props}
                        key={`user_key_${option.id}`}
                        sx={{
                          '& > img': { mr: 2, flexShrink: 0 },
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          maxWidth: '100%',
                          maxLines: 1,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}>
                        <Avatar
                          sx={{ width: 24, height: 24 }}
                          variant="circular"
                          src={option?.icon as string}
                        />
                        {option?.firstName + ' ' + option?.lastName} {option?.email}
                      </Box>
                    )}
                    renderInput={(params) => <TextField {...params} sx={{ height: '30px' }} />}
                  />
                </Box>
              </Box>
              <Box sx={{ m: 1 }}>
                <label className="font-size-14 font-weight-400">作成者</label>
                <Box sx={{ display: 'flex', ml: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterValue.isAuthor}
                        onClick={() => {
                          setFilterValue((prev) => ({
                            ...prev,
                            isAuthor: !filterValue.isAuthor
                          }));
                        }}
                      />
                    }
                    labelPlacement="end"
                    label="自分"
                  />
                </Box>
              </Box>
              <Box sx={{ m: 1 }}>
                <label className="font-size-14 font-weight-400">優先順位</label>
                <Box sx={{ display: 'flex', ml: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterValue.low}
                        onClick={() => {
                          setFilterValue((prev) => ({
                            ...prev,
                            low: !filterValue.low
                          }));
                        }}
                      />
                    }
                    labelPlacement="end"
                    label={TICKET_PROPERTY_PRIORITY.LOW}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterValue.middle}
                        onClick={() => {
                          setFilterValue((prev) => ({
                            ...prev,
                            middle: !filterValue.middle
                          }));
                        }}
                      />
                    }
                    labelPlacement="end"
                    label={TICKET_PROPERTY_PRIORITY.MEDIUM}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterValue.high}
                        onClick={() => {
                          setFilterValue((prev) => ({
                            ...prev,
                            high: !filterValue.high
                          }));
                        }}
                      />
                    }
                    labelPlacement="end"
                    label={TICKET_PROPERTY_PRIORITY.HIGH}
                  />
                </Box>
              </Box>
            </Box>
          </Menu>
        </FormControl>
        <FormControl sx={{ minWidth: 120 }}>
          <Button
            id="customized-button"
            sx={{
              height: 40,
              borderColor: '#0000003B',
              color: '#00000099',
              fontSize: '14px',
              padding: '9px 12px'
            }}
            size="small"
            aria-controls={openDisplay ? 'customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openDisplay ? 'true' : undefined}
            disableElevation
            onClick={(e) => setDisplayAnchorEl(e.currentTarget)}
            variant="outlined"
            endIcon={<KeyboardArrowDownIcon />}>
            テーブル表示項目
          </Button>
          <Menu
            id="project-menu"
            anchorEl={displayAnchorEl}
            open={openDisplay}
            onClose={handleCloseDisplayMenu}
            MenuListProps={{
              'aria-labelledby': 'lock-button',
              role: 'listbox'
            }}>
            {views.map((view) => {
              return (
                <MenuItem
                  key={view.name}
                  onClick={() => handleChange(view.name)}
                  disableRipple
                  sx={{ width: 200, gap: 2 }}>
                  {!excludeViews.includes(view.name) ? (
                    <CheckIcon />
                  ) : (
                    <Box style={{ width: 24, height: 24 }}></Box>
                  )}
                  <label>{view.label}</label>
                </MenuItem>
              );
            })}
          </Menu>
        </FormControl>
        <FormControl sx={{ minWidth: 120 }}>
          <Button
            type="button"
            onClick={() => handleAddTicket()}
            className="w-162"
            variant="contained"
            color="primary"
            sx={{ backgroundColor: '#2196F3', boxShadow: 'none' }}
            size="medium">
            <span className="font-weight-700 font-size-14">チケットを追加</span>
          </Button>
        </FormControl>
      </Box>
    </Box>
  );
};
export default TicketListHeader;
