import React, { ChangeEvent } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

interface Props {
  id?: string;
  label?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  type?: string;
  size?: 'medium' | 'small';
  errors?: string[];
}

const InputTextFieldWithLabel = (props: Props) => {
  const { id, label, value, onChange, placeholder, className, type, size, errors, ...other } =
    props;
  return (
    <>
      {label && <label className="font-size-14 font-weight-500">{label}</label>}
      <Box sx={{ mt: 1 }}>
        <TextField
          id={id}
          value={value}
          placeholder={placeholder}
          className={className}
          onChange={onChange}
          type={type}
          size={size}
          variant="outlined"
          {...other}
        />
        {errors && (
          <div className="text-red">
            {errors.map((error, i) => (
              <div key={i}>{error}</div>
            ))}
          </div>
        )}
      </Box>
    </>
  );
};

export default InputTextFieldWithLabel;
