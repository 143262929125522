import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useMutation } from '@apollo/client';
import { UPDATE_COLUMN } from '../../../../api/graphql';
import { Column, ColumnTypeEnum } from '@/__generated__/graphql';

interface Props {
  lane: Column;
  fileId: string;
  index: number;
  handleClose: () => void;
  updateState: Function;
  refetch: Function;
}

const types = [
  {
    id: ColumnTypeEnum.Task,
    label: 'アクター'
  },
  {
    id: ColumnTypeEnum.System,
    label: 'システム'
  }
];

const colors = [
  {
    id: '#FEEBEE',
    label: 'レッド'
  },
  {
    id: '#E8F5E9',
    label: 'グリーン'
  },
  {
    id: '#E3F2FD',
    label: 'ブルー'
  }
];

const AddLaneDialog = (props: Props) => {
  const { lane, handleClose, updateState, refetch } = props;
  const [laneName, setLaneName] = useState('');
  const [laneColor, setLaneColor] = useState(colors[0].id);
  const [updateColumn] = useMutation(UPDATE_COLUMN);

  const isEditing = lane?.index !== undefined;

  useEffect(() => {
    lane?.name ? setLaneName(lane.name) : setLaneName('');
    lane?.color ? setLaneColor(lane.color) : setLaneColor(colors[0].id);
  }, [lane]);

  const handleAddLane = async () => {
    updateColumn({
      variables: {
        in: {
          fileId: props.fileId,
          index: props.index,
          name: laneName,
          type: ColumnTypeEnum.Task,
          color: laneColor
        }
      },
      onCompleted: () => {
        refetch();
        const updated = {
          index: props.index,
          name: laneName,
          type: ColumnTypeEnum.Task,
          color: laneColor
        };
        if (isEditing) {
          updateState((prevData: any) => {
            const newData = [...prevData];
            newData[lane.index] = {
              ...newData[lane.index],
              ...updated
            };
            return newData;
          });
        } else {
          updateState((prevData: any) => {
            const newData = [...prevData];
            newData.splice(newData.length - 1, 0, updated);
            return newData;
          });
        }
      }
    });
    handleClose();
  };

  return (
    <Dialog
      open={Boolean(lane)}
      onClose={handleClose}
      PaperProps={{
        sx: { width: 500 }
      }}>
      <DialogTitle className="font-size-20 font-weight-500">
        レーンを{isEditing ? '編集' : '追加'}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <FormControl sx={{ m: 1, minWidth: 400 }}>
          <TextField
            required
            fullWidth
            id="outlined-required"
            label="レーン名"
            value={laneName}
            className="w-full"
            onChange={(e) => setLaneName(e.target.value)}
          />
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 400 }}>
          <InputLabel id="select-lane-type-label">色</InputLabel>
          <Select
            labelId="select-lane-type"
            value={laneColor}
            label="色"
            onChange={(event) => {
              setLaneColor(event.target.value);
            }}>
            {colors.map((color) => {
              return (
                <MenuItem key={color.id} value={color.id}>
                  <em>{color.label}</em>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          type="button"
          onClick={() => handleClose()}
          variant="text"
          color="primary"
          size="medium"
          className="w-88">
          <span className="font-weight-700 font-size-14">キャンセル</span>
        </Button>
        <Button
          type="submit"
          onClick={() => handleAddLane()}
          className="w-162"
          variant="contained"
          color="primary"
          disabled={laneName === ''}
          size="medium"
          sx={{ boxShadow: 'none' }}>
          <span className="font-weight-700 font-size-14">
            レーンを{isEditing ? '保存' : '追加'}
          </span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddLaneDialog;
