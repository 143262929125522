import { useRefCallback } from '../../../../hooks/useRefCallback';
import { useEffect } from 'react';
import { isNodeElement, isTicketElement } from '../utils';
import { SIDE_WIDTH } from '../elements/constant';
import { useMutation } from '@apollo/client';
import { CREATE_NODE_RELATION, DELETE_NODE_RELATION, UPDATE_NODE } from '../../../../api/graphql';

export const useElementPointerUpDown = ({
  fileId,
  paper,
  lanes,
  nodes,
  setNodes,
  setRelations,
  setActiveNode,
  setActiveTicket,
  offsetX,
  offsetY,
  groups
}) => {
  const [updateNode] = useMutation(UPDATE_NODE);
  const [deleteNodeRelation] = useMutation(DELETE_NODE_RELATION);
  const [createNodeRelation] = useMutation(CREATE_NODE_RELATION);
  let activeElement = null;

  const createARelation = (fromId, toId) => {
    if (fromId === toId) {
      return;
    }
    const newRelation = {
      fromId,
      toId
    };
    createNodeRelation({
      variables: {
        in: {
          fileId,
          ...newRelation
        }
      },
      onCompleted: (data) => {
        setRelations((prevData) => {
          const newData = [...prevData];
          newData.push({
            id: data.createNodeRelation,
            ...newRelation
          });
          return newData;
        });
      }
    });
  };

  const onPointerDownRef = useRefCallback((elementView, evt) => {
    // If pointer down element is a part element, then the parent should its self
    if (isNodeElement(elementView.model) || isTicketElement(elementView.model)) {
      let parentElement = elementView.model;
      // If pointer down element is a ticket element, then the parent should it's parent part element
      if (isTicketElement(elementView.model)) {
        parentElement = parentElement.getParentCell();
      }
      if (!parentElement) return;
      // Add the shadow when moving a part
      parentElement.attr('body/filter', 'drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5))');
      // Store the clicked element data for movement
      activeElement = {
        ...parentElement.getBBox(),
        id: parentElement.prop('pid'),
        row: parentElement.prop('row'),
        column: parentElement.prop('column')
      };
    }
  });
  const onPointerUpRef = useRefCallback((elementView, evt) => {
    // If pointer up element is a part element, then the parent should its self
    if (isNodeElement(elementView.model) || isTicketElement(elementView.model)) {
      let parentElement = elementView.model;
      // If pointer up element is a ticket element, then the parent should it's parent part element
      if (isTicketElement(elementView.model)) {
        parentElement = parentElement.getParentCell();
      }
      if (!parentElement) return;
      // Remove the shadow after moving a part
      parentElement.attr('body/filter', 'none');
      if (activeElement === null) return;
      const { id, column, row, x, y } = activeElement;
      const { x: centerX, y: centerY } = parentElement.getBBox().center();
      const newColumn = offsetX.current.findIndex((item) => item > centerX) - 1;
      const newRow = offsetY.current.findIndex((item) => item > centerY) - 1;
      let isMoved = column !== newColumn || row !== newRow;
      if (isTicketElement(elementView.model)) {
        isMoved = false;
      }

      // If a part doesn't change the column and row, just need to reset its position
      // If a part changed to different place, need to modify the source data and re-render graph
      if (!isMoved || lanes[newColumn].type === 'comment') {
        parentElement.position(x + (groups.length > 0 ? SIDE_WIDTH : 0), y, {
          deep: true,
          parentRelative: true
        });
      } else {
        // TODO: Remove the part if confirming the existing behavior is acceptable with PM
        // Remove the moved part's links
        // setRelations((prevData) => {
        //   const newData = [...prevData];
        //   const removedRelations = remove(
        //     newData,
        //     (relation) => relation.fromId === id || relation.toId === id
        //   );
        //   removedRelations.forEach((relation) => {
        //     deleteNodeRelation({
        //       variables: { id: relation.id }
        //     });
        //   });
        //   return newData;
        // });

        setNodes((prevData) => {
          const newData = [...prevData];
          const sourceNode = newData.find((node) => node.id === id);
          if (!sourceNode) {
            return newData;
          }
          const targetNode = newData.find(
            (node) => node.rowIndex === newRow && node.columnIndex === newColumn
          );
          updateNode({
            variables: {
              in: { id, rowIndex: newRow, columnIndex: newColumn }
            }
          });
          // If the part is moved to a place which already has another part,
          // row plus 1 to all the parts below this one
          if (targetNode) {
            newData.forEach((node) => {
              if (node.columnIndex === newColumn && node.rowIndex >= newRow) {
                node.rowIndex += 1;
                updateNode({
                  variables: {
                    in: { id: node.id, rowIndex: node.rowIndex, columnIndex: newColumn }
                  }
                });
              }
            });
          }

          // Change the moved node's row and column
          sourceNode.rowIndex = newRow;
          sourceNode.columnIndex = newColumn;
          return newData;
        });

        // TODO: Remove the part if confirming the existing behavior is acceptable with PM
        // Create new links after moving one part between other two parts:
        // 1. from previous to current
        // 2. from current to next
        // const prevNodes = sortBy(
        //   nodes.filter((node) => {
        //     return node.columnIndex === newColumn && node.rowIndex < newRow;
        //   }),
        //   'rowIndex'
        // );
        // const nextNodes = sortBy(
        //   nodes.filter((node) => {
        //     return node.columnIndex === newColumn && node.rowIndex >= newRow;
        //   }),
        //   'rowIndex'
        // );
        // const currentNodeId = id;
        // if (prevNodes?.length && nextNodes?.length) {
        //   const { id: prevNodeId } = prevNodes[prevNodes.length - 1];
        //   const { id: nextNodeId } = nextNodes[0];
        //   createARelation(prevNodeId, currentNodeId);
        //   createARelation(currentNodeId, nextNodeId);
        // }
      }
    }
  });

  useEffect(() => {
    if (paper) {
      paper.on('element:pointerdown', (elementView, evt) =>
        onPointerDownRef.current(elementView, evt)
      );
      paper.on('element:pointerup', (elementView, evt) => onPointerUpRef.current(elementView, evt));
      paper.on('blank:pointerup', () => {
        activeElement = null;
      });
    }
  }, [paper]);
};
