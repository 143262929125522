import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import InputPasswordField from '../../molecules/form/InputPasswordField';
import InputTextField from '../../molecules/form/InputTextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useFormik } from 'formik';
import { postSignUp } from '../../../foundations/validationSchemas/auth';
import { FormControlLabel, FormLabel, Link } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

export interface postSignUpInput {
  token: string;
  password: string;
  first_name: string;
  last_name: string;
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

interface Props {
  handleSignUp: (input: postSignUpInput) => void;
}

const RegisterForm = ({ handleSignUp }: Props) => {
  const [token, setToken] = useState('');
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      password: '',
      password_confirm: '',
      is_agree: false
    },
    validationSchema: postSignUp,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      await handleSubmit({
        token: token,
        first_name: values.first_name,
        last_name: values.last_name,
        password: values.password
      });
    }
  });
  let query = useQuery();

  const handleSubmit = (values: postSignUpInput) => {
    return handleSignUp(values);
  };

  const handleInvalidToken = () => {
    // ToDo Dialogを表示
    navigate('/login');
  };

  useEffect(() => {
    const token = query.get('token');
    if (!token || token === '') {
      handleInvalidToken();
    }
    if (token) {
      setToken(token);
    }

    // ToDo tokenの有効確認API
  }, [query]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box sx={{ p: 2, mt: 2 }}>
        <p className="text-center font-weight-700 font-size-24">アカウント登録</p>
        <Box sx={{ mt: 2 }}>
          <InputTextField
            id="first_name"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            placeholder="姓"
            className="w-full"
            type="text"
            errors={formik.errors.first_name ? [formik.errors.first_name] : []}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <InputTextField
            id="last_name"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            placeholder="名"
            className="w-full"
            type="text"
            errors={formik.errors.last_name ? [formik.errors.last_name] : []}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <InputPasswordField
            id="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            placeholder="パスワード"
            className="w-full"
            errors={formik.errors.password ? [formik.errors.password] : []}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <span className="font-size-12 font-weight-400">
            ８文字以上、半角英数字で入力してください
          </span>
        </Box>
        <Box sx={{ mt: 2 }}>
          <InputPasswordField
            id="password_confirm"
            value={formik.values.password_confirm}
            onChange={formik.handleChange}
            placeholder="パスワード確認用"
            className="w-full"
            errors={formik.errors.password_confirm ? [formik.errors.password_confirm] : []}
          />
        </Box>
        <Box sx={{ mt: 2, ml: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.is_agree}
                onChange={() => formik.setFieldValue('is_agree', !formik.values.is_agree)}
              />
            }
            label={
              <FormLabel>
                <Link href="https://acatise.com/terms" target="_blank" underline="none">
                  利用規約
                </Link>
                に同意
              </FormLabel>
            }
          />
          {formik.errors.is_agree && (
            <div className="text-red">
              {[formik.errors.is_agree].map((error, i) => (
                <div key={i}>{error}</div>
              ))}
            </div>
          )}
        </Box>

        <Box sx={{ mt: 2 }}>
          <Button type="submit" className="w-full" variant="contained" color="primary" size="large">
            新規登録
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default RegisterForm;
