import { dia, util } from '@clientio/rappid';
import {
  BIG_HEIGHT,
  BIG_WIDTH,
  LANE_HEADER_HEIGHT,
  ROW_HEIGHT,
  SIDE_WIDTH,
  STROKE_GREY_COLOR,
  TEXT_COLOR
} from './constant';

class WorkflowDivider extends dia.Element {
  constructor(...args) {
    super(...args);
    this.resize(BIG_WIDTH, ROW_HEIGHT);
  }

  defaults() {
    return {
      ...super.defaults,
      type: 'WorkflowDivider',
      attrs: {
        border: {
          stroke: STROKE_GREY_COLOR,
          strokeWidth: 1,
          d: `M 0 -${LANE_HEADER_HEIGHT} V ${BIG_HEIGHT}`
        },
        body: {
          event: 'element:show:menu',
          fill: 'transparent',
          x: 0,
          y: 0,
          width: SIDE_WIDTH,
          height: 144
        },
        divider: {
          stroke: STROKE_GREY_COLOR,
          strokeWidth: 1,
          d: `M 0 calc(h) H calc(w)`
        },
        label: {
          event: 'element:show:menu',
          fill: TEXT_COLOR,
          fontSize: 10,
          textVerticalAnchor: 'middle',
          x: SIDE_WIDTH / 2 - 2,
          y: 'calc(h / 2)'
        }
      },
      row: 0,
      rowIndex: 0
    };
  }

  preinitialize(...args) {
    this.markup = util.svg`
        <rect @selector="body" class="divider-body" />
        <text @selector="label" />
        <path @selector="divider" />
        <path @selector="border" />
    `;
  }

  getActions() {
    return [
      {
        action: 'add-row',
        content: '下に行を挿入'
      },
      {
        action: 'remove-row',
        content: '行を削除'
      }
    ];
  }
}

const createWorkflowDivider = (config) => {
  return new WorkflowDivider(config);
};
export { createWorkflowDivider, WorkflowDivider };
