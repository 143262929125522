import { useRefCallback } from '../../../../hooks/useRefCallback';
import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_NODE_RELATION } from '../../../../api/graphql';
import { isNodeElement } from '../utils';

export const useLinkChange = ({ graph, relations, setRelations, fileId, refetch }) => {
  const [createNodeRelation] = useMutation(CREATE_NODE_RELATION);

  const onLinkChangeRef = useRefCallback((link) => {
    const source = link.get('source');
    const target = link.get('target');
    if (source.id && target.id) {
      const sourceNode = link.getSourceElement();
      const targetNode = link.getTargetElement();
      console.log(relations);
      if (
        isNodeElement(sourceNode) &&
        isNodeElement(targetNode) &&
        sourceNode.id !== targetNode.id
      ) {
        const { anchor: sourceAnchor } = link.get('source');
        const { anchor: targetAnchor } = link.get('target');
        const fromId = sourceNode.prop('pid');
        const toId = targetNode.prop('pid');
        // Avoid creating arrow on a same node
        const isSameNode = fromId === toId;
        // Avoid creat same arrow as an existing arrow
        const isExistingLink = relations.find(
          (relation) => relation.fromId === fromId && relation.toId === toId
        );
        if (isSameNode || isExistingLink) {
          link.remove();
          return;
        }
        createNodeRelation({
          variables: {
            in: {
              fileId,
              fromId,
              toId
            }
          },
          onCompleted: (data) => {
            setRelations((prevData) => {
              const newData = [...prevData];
              newData.push({
                id: data.createNodeRelation,
                fromId,
                toId,
                out: sourceAnchor.name,
                in: targetAnchor.name
              });
              return newData;
            });
            refetch();
          }
        });
      } else {
        link.remove();
      }
    }
  });

  useEffect(() => {
    if (graph) {
      graph.on('change:source change:target', (link) => onLinkChangeRef.current(link));
    }
  }, [graph]);
};
