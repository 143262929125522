import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  ALERT_TYPE,
  HIDE,
  HIDE_ADD_ROW_LOCALSTORAGE_KEY
} from '../../../../components/workflow/Canvas/elements/constant';
import { Checkbox, FormControlLabel } from '@mui/material';

interface Props {
  isOpen: boolean;
  type: string;
  handleClose: () => void;
  callback: Function;
}

const TITLE = {
  [ALERT_TYPE.REMOVE_PART]: 'プロセスを削除します',
  [ALERT_TYPE.REMOVE_COLUMN]: 'レーンを削除します',
  [ALERT_TYPE.REMOVE_ROW]: '行を削除します',
  [ALERT_TYPE.ADD_ROW]: 'アローがリセットされます。'
};

const CONTENT = {
  [ALERT_TYPE.REMOVE_PART]:
    'プロセスを削除すると、そのプロセスにつながるアローが全て削除されます。 よろしいですか？',
  [ALERT_TYPE.REMOVE_COLUMN]:
    'レーンを削除すると、同じレーンのコンテンツが全て削除されます。 よろしいですか？',
  [ALERT_TYPE.REMOVE_ROW]:
    '行を削除すると、同じ行のコンテンツが全て削除されます。 よろしいですか？',
  [ALERT_TYPE.ADD_ROW]: '行を追加すると、その間にあるアローは削除されます。 よろしいですか？'
};

const AlertDialog = (props: Props) => {
  const { isOpen, type, handleClose, callback } = props;
  const [isHideDialog, setIsHideDialog] = useState(false);
  const onSubmit = () => {
    callback();
    handleClose();
    if (type === ALERT_TYPE.ADD_ROW) {
      if (isHideDialog)
        window.localStorage.setItem(HIDE_ADD_ROW_LOCALSTORAGE_KEY, JSON.stringify(HIDE));
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{TITLE[type]}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{CONTENT[type]}</DialogContentText>
        {type === ALERT_TYPE.ADD_ROW ? (
          <FormControlLabel
            sx={{ marginLeft: '0px' }}
            control={
              <Checkbox
                defaultChecked={isHideDialog}
                onChange={(e) => setIsHideDialog(e.target.checked)}
              />
            }
            labelPlacement="end"
            label="次回以降、アラートを表示しない"
          />
        ) : (
          <></>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          onClick={() => handleClose()}
          variant="text"
          color="primary"
          size="medium"
          className="w-88">
          <span className="font-weight-700 font-size-14">キャンセル</span>
        </Button>
        <Button
          type="submit"
          className="w-162"
          variant="contained"
          color="primary"
          size="medium"
          onClick={onSubmit}
          sx={{ boxShadow: 'none' }}>
          <span className="font-weight-700 font-size-14">
            {type === ALERT_TYPE.ADD_ROW ? '追加' : '削除'}
          </span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
