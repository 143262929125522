import RegisterForm, { postSignUpInput } from '../../components/organisms/register/';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { signUp } from '../../api/apis';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

const RegisterPage = () => {
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = React.useState(false);
  const handleSignUp = async (values: postSignUpInput) => {
    try {
      // ToDo SignUpAPIを呼び出す
      const res = await signUp(values);
      console.log(res);
      navigate('/');
    } catch (e) {
      console.log(e);
      setOpenAlert(true);
    }
  };

  const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
    navigate('/login');
  };

  return (
    <div className="mx-auto max-w-522">
      <RegisterForm handleSignUp={handleSignUp} />
      <Snackbar
        open={openAlert}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        key={'top' + 'right'}>
        <Alert
          onClose={handleCloseAlert}
          severity="error"
          sx={{
            width: '100%',
            fontSize: '16px',
            fontWeight: '500'
          }}>
          招待メールの有効期限が切れています。お手数ですが、再度アカウント登録をお願いします。
        </Alert>
      </Snackbar>
    </div>
  );
};

export default RegisterPage;
