import * as React from 'react';
import { Link as RouterLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import TopBar from '../topbar';
import FileNameChangeDialog from '@/components/organisms/modal/FileNameChangeDialog';
import AddIcon from '@mui/icons-material/Add';
import { useMutation, useQuery } from '@apollo/client';
import {
  COPY_FILE,
  CREATE_FILE,
  DELETE_FILE,
  GET_FILE,
  GET_FILES,
  GET_PROJECT,
  UPDATE_FILE
} from '@/api/graphql';
import { File } from '@/__generated__/graphql';
import { RootContext } from '@/context/RootProvider';
import FileCloneDialog from '@/components/organisms/modal/FileCloneDialog';
import { APPBAR_HEIGHT, BAR_ITEM_HEIGHT } from '@/components/workflow/Canvas/elements/constant';
import { FileOrder, SortType } from '@/graphql';

const drawerWidth = 160;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  overflowX: 'hidden',
  backgroundColor: '#fafafa',
  color: theme.palette.secondary.light,
  top: APPBAR_HEIGHT
});

const closedMixin = (theme: Theme): CSSObject => ({
  overflowX: 'hidden',
  width: 0,
  [theme.breakpoints.up('sm')]: {
    width: 0
  },
  backgroundColor: '#fafafa',
  color: theme.palette.secondary.light,
  top: APPBAR_HEIGHT
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
);

export default function Sidebar() {
  const navigate = useNavigate();
  const rootContext = React.useContext(RootContext);
  const [showModal, setShowModal] = React.useState(false);
  const [showInfoModal, setInfoShowModal] = React.useState(false);
  const open = rootContext?.isSidebarShowing;
  let { workflowId } = useParams();
  const fileId = workflowId as string;
  const location = useLocation();
  const { loading, error, data } = useQuery(GET_FILE, {
    variables: { id: fileId }
  });
  const projectId = data?.file.projectId;

  const { data: projectData } = useQuery(GET_PROJECT, {
    skip: !projectId,
    variables: {
      id: projectId
    }
  });

  const { data: filesData } = useQuery(GET_FILES, {
    skip: !projectId,
    variables: { filter: { projectId, page: 1, limit: 20, orderBy: FileOrder.UpdatedAt, sortType: SortType.Desc } }
  });

  const [createFile] = useMutation(CREATE_FILE, {
    refetchQueries: [GET_FILES]
  });

  const [copyFile] = useMutation(COPY_FILE, {
    refetchQueries: [GET_FILES]
  });

  const [updateFile] = useMutation(UPDATE_FILE, {
    refetchQueries: [GET_FILES]
  });

  const [deleteFile] = useMutation(DELETE_FILE, {
    refetchQueries: [GET_FILES]
  });

  const sidebarItems: [File] = filesData?.files.nodes;

  const handleFileNameChange = (fileName: string) => {
    createFile({
      variables: {
        in: {
          name: fileName,
          projectId
        }
      },
      onCompleted: (data) => {
        setShowModal(false);
        navigate(`/workflows/${data.createFile}/diagram/view`);
      }
    });
  };

  const handleRemoveFile = async (id: string) => {
    await deleteFile({
      variables: {
        id: data?.file.id
      },
      onCompleted: () => {
        navigate(`/projects/${projectId}`);
      }
    });
  };
  const handleFileCloneChange = async (fileName: string, description: string) => {
    await copyFile({
      variables: {
        id: data?.file.id
      },
      onCompleted: async (data) => {
        await updateFile({
          variables: {
            in: {
              name: fileName,
              id: data.copyFile,
              description: description
            }
          }
        });
      }
    });
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <TopBar
        spaceId={projectData?.project.spaceId}
        projectId={data?.file.projectId}
        name={data?.file.name}
        projectName={projectData?.project.name}
        setInfoShowModal={setInfoShowModal}
      />
      <Drawer variant="permanent" open={open}>
        <Divider />
        {sidebarItems ? (
          <List
            sx={{
              maxHeight: 'calc(100vh - 120px)',
              overflowX: 'hidden',
              overflowY: 'auto'
            }}>
            {sidebarItems.map(({ name, id }, index) => {
              const isActive = location.pathname === `/workflows/${id}/diagram/view`;
              return (
                <ListItem
                  dense={true}
                  button
                  key={id}
                  disablePadding
                  sx={{
                    display: 'block',
                    backgroundColor: isActive ? '#2196F314' : ''
                  }}
                  component={RouterLink}
                  to={`workflows/${id}/diagram/view`}>
                  <ListItemButton
                    sx={{
                      minHeight: BAR_ITEM_HEIGHT,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5
                    }}>
                    {open ? (
                      <ListItemText primary={name} color="#000" />
                    ) : (
                      <ListItemText primary={index + 1} sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />
                    )}
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        ) : null}
        <Button
          onClick={() => setShowModal(true)}
          sx={{
            justifyContent: 'center',
            textAlign: 'center',
            padding: 0,
            height: 30
          }}>
          <AddIcon />
          新規追加
        </Button>
      </Drawer>
      {showModal && (
        <FileNameChangeDialog
          isOpen={true}
          handleClose={() => setShowModal(false)}
          handleFileNameChange={handleFileNameChange}
        />
      )}
      {showInfoModal && (
        <FileCloneDialog
          isOpen={true}
          file={data?.file}
          handleClose={() => setInfoShowModal(false)}
          handleRemove={handleRemoveFile}
          handleFileCloneChange={handleFileCloneChange}
        />
      )}
    </Box>
  );
}
