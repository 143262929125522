import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import React, { PropsWithChildren } from 'react';
import { auth } from 'src/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

export const ApolloClientProvider: React.FC<PropsWithChildren> = (props) => {
  const [user, loading] = useAuthState(auth, {
    onUserChanged: async (user) => {}
  });

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_BACKEND_URL}/gql/query`
  });
  const uploadLink = createUploadLink({
    uri: `${process.env.REACT_APP_BACKEND_URL}/gql/query`
  });

  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    // return the headers to the context so httpLink can read them

    const token = await auth.currentUser?.getIdToken();
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`
      }
    };
  });

  const client = new ApolloClient({
    // uri: 'http://localhost:4000/',
    link: authLink.concat(uploadLink),
    cache: new InMemoryCache()
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
