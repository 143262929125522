import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  JSON: { input: any; output: any; }
  /** upload type */
  Upload: { input: any; output: any; }
};

export type AiAutoCorrectFileInput = {
  /** 修正プロジェクトID */
  fileId: Scalars['ID']['input'];
  /** 修正クエリ */
  modification: Scalars['String']['input'];
  /** ファイル名前 */
  name: Scalars['String']['input'];
};

export type AiGenerateFileInput = {
  /** ワークフロー説明 */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ファイル名前 */
  name: Scalars['String']['input'];
  /** プロジェクトID */
  projectId: Scalars['ID']['input'];
  /** AI生成クエリ */
  query: Scalars['String']['input'];
};

export type AiGenerateInput = {
  /** スタートノードColumnIndex */
  columnIndex: Scalars['Int']['input'];
  /** ファイルID */
  fileId: Scalars['ID']['input'];
  /** AI生成ファイル */
  image?: InputMaybe<Scalars['Upload']['input']>;
  /** AI生成クエリ */
  query: Scalars['String']['input'];
  /** スタートノードRowIndex */
  rowIndex: Scalars['Int']['input'];
};

export type AiSummarizeFileInput = {
  /** サマリプロジェクトID */
  fileId: Scalars['ID']['input'];
  /** ファイル名前 */
  name: Scalars['String']['input'];
  /** サマリしてほしい数 */
  summarizeNumber: Scalars['Int']['input'];
};

export type AiWithImageGenerateFileInput = {
  /** ワークフロー説明 */
  description?: InputMaybe<Scalars['String']['input']>;
  /** AI生成ファイル */
  image: Scalars['Upload']['input'];
  /** ファイル名前 */
  name: Scalars['String']['input'];
  /** プロジェクトID */
  projectId: Scalars['ID']['input'];
  /** AI生成クエリ */
  query: Scalars['String']['input'];
};

export type Column = Node & {
  __typename?: 'Column';
  /** カラム色 */
  color?: Maybe<Scalars['String']['output']>;
  /** ファイルID */
  fileId: Scalars['ID']['output'];
  /** カラムID */
  id: Scalars['ID']['output'];
  /** カラム番号 */
  index: Scalars['Int']['output'];
  /** カラム名前 */
  name?: Maybe<Scalars['String']['output']>;
  /** カラムタイプ */
  type?: Maybe<ColumnTypeEnum>;
};

export type ColumnInput = {
  /** ファイルID */
  fileId: Scalars['ID']['input'];
  /** ターゲット番号 */
  index: Scalars['Int']['input'];
  /** 上下に入れるか */
  insertType: ColumnInputType;
  /** カラム名前 */
  name?: InputMaybe<Scalars['String']['input']>;
  /** カラムタイプ */
  type?: InputMaybe<ColumnTypeEnum>;
};

export enum ColumnInputType {
  Left = 'left',
  Right = 'right'
}

export type ColumnList = {
  __typename?: 'ColumnList';
  columns: Array<Column>;
};

export enum ColumnTypeEnum {
  System = 'system',
  Task = 'task'
}

export type ColumnsFilter = {
  /** ファイルID */
  fileId: Scalars['ID']['input'];
};

export type DeleteColumnInput = {
  /** ファイルID */
  fileId: Scalars['ID']['input'];
  /** ターゲット番号 */
  index: Scalars['Int']['input'];
};

export type DeleteGNodePropertyCommentInput = {
  /** コメントID */
  id: Scalars['ID']['input'];
};

export type DeleteGNodePropertyFileInput = {
  /** 詳細のファイルID */
  id: Scalars['ID']['input'];
};

export type DeleteRowInput = {
  /** ファイルID */
  fileId: Scalars['ID']['input'];
  /** ターゲット行番号 */
  index: Scalars['Int']['input'];
};

export type ExitSpaceInput = {
  /** SpaceID */
  spaceId: Scalars['ID']['input'];
  /** スペースから外すユーザーID */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type File = Node & {
  __typename?: 'File';
  /** ワークフロー作成日 */
  createdAt: Scalars['String']['output'];
  /** ワークフロー作成者 */
  creator: User;
  /** ワークフロー作成者 */
  creatorId: Scalars['ID']['output'];
  /** ワークフロー説明 */
  description: Scalars['String']['output'];
  /** ファイルID */
  id: Scalars['ID']['output'];
  /** ファイル名 */
  name: Scalars['String']['output'];
  /** 所属ProjectID */
  projectId: Scalars['ID']['output'];
  /** ワークフロー作成者 */
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  /** ワークフロー最終変更日時 */
  updatedAt: Scalars['String']['output'];
};

export type FileInput = {
  /** ワークフロー説明 */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ファイル名前 */
  name: Scalars['String']['input'];
  /** プロジェクトID */
  projectId: Scalars['ID']['input'];
  /** ワークフローのサムネイル */
  thumbnail?: InputMaybe<Scalars['Upload']['input']>;
};

/** ワークスペースのリスト */
export type FileList = List & {
  __typename?: 'FileList';
  /** ワークスペースの一覧 */
  nodes: Array<File>;
  /** ページ情報 */
  pageInfo: PageInfo;
};

export enum FileOrder {
  UpdatedAt = 'updatedAt'
}

export type FilesFilter = {
  /** リミット */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** 順番 */
  orderBy?: InputMaybe<FileOrder>;
  /** ページ */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** projectID */
  projectId?: InputMaybe<Scalars['ID']['input']>;
  /** 順番タイプ */
  sortType?: InputMaybe<SortType>;
};

export type GNode = Node & {
  __typename?: 'GNode';
  /** column番号 */
  columnIndex: Scalars['Int']['output'];
  /** ノード作成日 */
  createdAt: Scalars['String']['output'];
  /** ファイルID */
  fileId: Scalars['ID']['output'];
  /** ノードID */
  id: Scalars['ID']['output'];
  /** ノード名前 */
  name: Scalars['String']['output'];
  /** ノードのproperty */
  property: GNodeProperty;
  /** row番号 */
  rowIndex: Scalars['Int']['output'];
  /** ノードのタスク */
  tasks?: Maybe<Array<GNodeProperty>>;
  /** ノードのチケット */
  tickets?: Maybe<Array<GNodeProperty>>;
  /** ノードタイプ */
  type: NodeTypeEnum;
  /** ノード更新日 */
  updatedAt: Scalars['String']['output'];
};

export type GNodeFromToInput = {
  /** チケットノード番号 */
  childNodeId: Scalars['ID']['input'];
  /** タスクノード番号 */
  parentNodeId: Scalars['ID']['input'];
};

export type GNodeInput = {
  /** column番号 */
  columnIndex: Scalars['Int']['input'];
  /** ファイルID */
  fileId: Scalars['ID']['input'];
  /** row番号 */
  rowIndex: Scalars['Int']['input'];
  /** column番号 */
  type: NodeTypeEnum;
};

export type GNodeList = {
  __typename?: 'GNodeList';
  /** ノードの一覧 */
  nodes: Array<GNode>;
  /** ノードの関係一覧 */
  relations: Array<GNodeRelation>;
};

export type GNodeProperty = Node & {
  __typename?: 'GNodeProperty';
  /** ノードのアクティビティ */
  activities?: Maybe<Array<GNodePropertyActivity>>;
  /** ノードのコメント */
  comments?: Maybe<Array<GNodePropertyComment>>;
  /** 作成者 */
  creator?: Maybe<User>;
  /** ノード説明 */
  description: Scalars['String']['output'];
  /** ノードのファイル */
  files?: Maybe<Array<GNodePropertyFile>>;
  /** ノードID */
  id: Scalars['ID']['output'];
  /** ノードのKinds */
  kinds?: Maybe<Array<Kind>>;
  /** ノードメディア */
  media?: Maybe<NodePropertyMediaEnum>;
  /** ノードメディアのラベル */
  mediaLabel?: Maybe<Scalars['String']['output']>;
  /** ノード説明 */
  name: Scalars['String']['output'];
  /** ファイルID */
  nodeId: Scalars['ID']['output'];
  /** ノード優先度 */
  priority?: Maybe<NodePropertyPriorityEnum>;
  /** ノードStatus */
  status?: Maybe<NodePropertyStatusEnum>;
  /** ノードStatus */
  timeUnit: TimeUnitEnum;
  /** ノードStatus */
  timeValue: Scalars['Int']['output'];
  /** ノードタイプ */
  type: NodeTypeEnum;
  /** 作成者ID */
  userId?: Maybe<Scalars['ID']['output']>;
  /** ノードにアサインしたユーザー一覧 */
  users?: Maybe<Array<User>>;
};

export type GNodePropertyActivity = Node & {
  __typename?: 'GNodePropertyActivity';
  /** アクティビティID */
  id: Scalars['ID']['output'];
  /** ノードプロパティID */
  nodePropertyId: Scalars['ID']['output'];
  /** アクティビティ説明文 */
  text: Scalars['String']['output'];
};

export type GNodePropertyComment = Node & {
  __typename?: 'GNodePropertyComment';
  /** ワークフロー作成日 */
  createdAt: Scalars['String']['output'];
  /** コメント作成者 */
  creator: User;
  /** コメント作成者 */
  creatorId: Scalars['ID']['output'];
  /** コメントID */
  id: Scalars['ID']['output'];
  /** ノードプロパティID */
  nodePropertyId: Scalars['ID']['output'];
  /** アクティビティ説明文 */
  text: Scalars['String']['output'];
};

export type GNodePropertyCommentInput = {
  /** メンションするユーザー */
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** ノードID */
  nodeId: Scalars['ID']['input'];
  /** アクティビティ説明文 */
  text: Scalars['String']['input'];
};

export type GNodePropertyFile = Node & {
  __typename?: 'GNodePropertyFile';
  /** 詳細のファイルURL */
  file: Scalars['String']['output'];
  /** 詳細のファイルネーム */
  fileName: Scalars['String']['output'];
  /** 詳細のファイルタイプ */
  fileType: Scalars['String']['output'];
  /** コメントID */
  id: Scalars['ID']['output'];
  /** ノードプロパティID */
  nodePropertyId: Scalars['ID']['output'];
};

export type GNodePropertyFileInput = {
  /** 詳細のファイル */
  file: Scalars['Upload']['input'];
  /** 詳細のファイルID */
  nodeId: Scalars['ID']['input'];
};

export type GNodeRelation = Node & {
  __typename?: 'GNodeRelation';
  /** 開始ノード */
  fromId: Scalars['ID']['output'];
  /** ノード関係ID */
  id: Scalars['ID']['output'];
  /** ノードRelationのproperty */
  property: GNodeRelationProperty;
  /** 終了ノード */
  toId: Scalars['ID']['output'];
  /** ノードタイプ */
  type: NodeRelationPropertyTypeEnum;
};

export type GNodeRelationInput = {
  /** ファイルID */
  fileId: Scalars['ID']['input'];
  /** 開始ノード */
  fromId: Scalars['ID']['input'];
  /** 終了ノード */
  toId: Scalars['ID']['input'];
};

export type GNodeRelationProperty = Node & {
  __typename?: 'GNodeRelationProperty';
  /** ノードRelationタイプ */
  description: Scalars['String']['output'];
  /** ノードID */
  id: Scalars['ID']['output'];
  /** ノードRelationタイプ */
  media: NodeRelationPropertyMediaEnum;
  /** ファイルID */
  nodeRelationId: Scalars['ID']['output'];
  /** ノードRelationポジション */
  positions: Scalars['JSON']['output'];
  /** ノードRelationタイプ */
  type: NodeRelationPropertyTypeEnum;
};

export type GNodesFilter = {
  /** ファイルID */
  fileId: Scalars['ID']['input'];
  /** ノード名検索 */
  query?: InputMaybe<Scalars['String']['input']>;
  /** ノードタイプ */
  types?: InputMaybe<Array<NodeTypeEnum>>;
};

export type GNodesInput = {
  /** ファイルID */
  fileId: Scalars['ID']['input'];
  /** 関連 */
  relations?: InputMaybe<Array<RelationInput>>;
};

export type Group = Node & {
  __typename?: 'Group';
  /** ファイルID */
  fileId: Scalars['ID']['output'];
  /** グループID */
  id: Scalars['ID']['output'];
  /** グループ名 */
  name?: Maybe<Scalars['String']['output']>;
  /** グループロルIndexes */
  rowIndexes: Array<Scalars['Int']['output']>;
};

export type GroupFilter = {
  /** ファイルID */
  fileId: Scalars['ID']['input'];
};

export type GroupInput = {
  /** ファイルID */
  fileId: Scalars['ID']['input'];
  /** グループ名 */
  name?: InputMaybe<Scalars['String']['input']>;
  /** グループロルIndexes */
  rowIndexes: Array<Scalars['Int']['input']>;
};

export type InviteSpaceInput = {
  /** 招待メール一覧 */
  emails: Array<Scalars['String']['input']>;
  /** 招待SpaceID */
  spaceId: Scalars['ID']['input'];
};

export type JsonGenerateFileInput = {
  /** 生成ノードコンテンツ */
  content: Array<LlmNode>;
  /** ワークフロー説明 */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ファイル名前 */
  name: Scalars['String']['input'];
  /** プロジェクトID */
  projectId: Scalars['ID']['input'];
};

export type JsonGenerateInput = {
  /** スタートノードColumnIndex */
  columnIndex: Scalars['Int']['input'];
  /** 生成ノードコンテンツ */
  content: Array<LlmNode>;
  /** ファイルID */
  fileId: Scalars['ID']['input'];
  /** スタートノードRowIndex */
  rowIndex: Scalars['Int']['input'];
};

export type JsonGenerateV2FileInput = {
  /** 生成ノードコンテンツ */
  content: V2LlmContent;
  /** ワークフロー説明 */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ファイル名前 */
  name: Scalars['String']['input'];
  /** プロジェクトID */
  projectId: Scalars['ID']['input'];
};

export type JsonGenerateV2Input = {
  /** スタートノードColumnIndex */
  columnIndex: Scalars['Int']['input'];
  /** 生成ノードコンテンツ */
  content: V2LlmContent;
  /** ファイルID */
  fileId: Scalars['ID']['input'];
  /** スタートノードRowIndex */
  rowIndex: Scalars['Int']['input'];
};

export type Kind = Node & {
  __typename?: 'Kind';
  /** 所属ファイルID */
  fileId: Scalars['ID']['output'];
  /** ID */
  id: Scalars['ID']['output'];
  /** ユーザーメール */
  text: Scalars['String']['output'];
};

export type KindFilter = {
  /** 所属ファイルID */
  fileId: Scalars['ID']['input'];
};

export type KindInput = {
  /** ファイルID */
  fileId: Scalars['ID']['input'];
  /** グループ名 */
  text: Scalars['String']['input'];
};

export type LlmNode = {
  /** 生成ノードのアクション */
  action: Scalars['String']['input'];
  /** 生成ノードのActor */
  actor: Scalars['String']['input'];
  /** 生成ノードの関係 */
  decision?: InputMaybe<Array<LlmNodeRelation>>;
  /** 生成ノードの説明 */
  detail: Scalars['String']['input'];
  /** 生成ノードの番号 */
  order: Scalars['Int']['input'];
};

export type LlmNodeRelation = {
  /** 生成ノード関係のラベル */
  option: Scalars['String']['input'];
  /** 生成ノード関係のターゲット */
  resultStep: Scalars['Int']['input'];
};

/** ページネーションされたリスト */
export type List = {
  /** リストの要素 */
  nodes: Array<Node>;
  /** ページネーションの情報 */
  pageInfo: PageInfo;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** LLM-JSON: AI生成用 */
  AIGenerateNodes: Scalars['Boolean']['output'];
  /** ノード詳細にユーザーをアサインする */
  assignKindToNodeProperty: Scalars['Boolean']['output'];
  /** Ticketをノードに付与する */
  assignNode: Scalars['Boolean']['output'];
  /** ノード詳細にユーザーをアサインする */
  assignUserToNodeProperty: Scalars['Boolean']['output'];
  /** ファイル削除 */
  copyFile: Scalars['ID']['output'];
  /** ファイル作成 */
  createFile: Scalars['ID']['output'];
  /** AIによる修正 */
  createFileAIAutoCorrect: Scalars['ID']['output'];
  /** AI生成かつファイル作成 */
  createFileAIGenerate: Scalars['ID']['output'];
  /** AIによるサマリファイル作成 */
  createFileAISummarize: Scalars['ID']['output'];
  /** AI生成（ファイル添付）かつファイル作成 */
  createFileAIWithImageGenerate: Scalars['ID']['output'];
  /** LLM-JSON生成かつファイル作成 */
  createFileJSONGenerate: Scalars['ID']['output'];
  /** V2 LLM-JSON生成かつファイル作成 */
  createFileV2JSONGenerate: Scalars['ID']['output'];
  /** 行グループを作成 */
  createGroup: Scalars['ID']['output'];
  /** ファイルにkindを作成 */
  createKind: Scalars['ID']['output'];
  /** ノード作成 */
  createNode: Scalars['ID']['output'];
  /** ノード詳細にコメントする */
  createNodePropertyComment: Scalars['ID']['output'];
  /** ノード詳細にコメントする */
  createNodePropertyFile: Scalars['ID']['output'];
  /** ノードの関係作成 */
  createNodeRelation: Scalars['ID']['output'];
  /** ノードs作成 */
  createNodes: Scalars['Boolean']['output'];
  /** プロジェクト作成 */
  createProject: Scalars['ID']['output'];
  /** ワークスペース作成 */
  createSpace: Scalars['ID']['output'];
  /** カラムを削除 */
  deleteColumn: Scalars['Boolean']['output'];
  /** ファイル削除 */
  deleteFile: Scalars['ID']['output'];
  /** 行グループを削除 */
  deleteGroup: Scalars['ID']['output'];
  /** ファイルにkindを削除 */
  deleteKind: Scalars['ID']['output'];
  /** ノード詳細からユーザーを外す */
  deleteKindToNodeProperty: Scalars['Boolean']['output'];
  /** ノード削除 */
  deleteNode: Scalars['ID']['output'];
  /** ノード詳細にコメント削除する */
  deleteNodePropertyComment: Scalars['ID']['output'];
  /** ノード詳細にコメント削除する */
  deleteNodePropertyFile: Scalars['ID']['output'];
  /** ノードの関係削除 */
  deleteNodeRelation: Scalars['ID']['output'];
  /** プロジェクト削除 */
  deleteProject: Scalars['ID']['output'];
  /** 行を削除する */
  deleteRow: Scalars['Boolean']['output'];
  /** ワークスペース削除 */
  deleteSpace: Scalars['ID']['output'];
  /** ノード詳細からユーザーを外す */
  deleteUserToNodeProperty: Scalars['Boolean']['output'];
  /** Ticketをノードから外す */
  detachNode: Scalars['Boolean']['output'];
  /** ワークスペースから脱退する */
  exitFromSpace: Scalars['Boolean']['output'];
  /** カラムを挿入する */
  insertColumn: Scalars['Boolean']['output'];
  /** 行を挿入する */
  insertRow: Scalars['Boolean']['output'];
  /** ワークスペースに招待する */
  inviteToSpace: Scalars['Boolean']['output'];
  /** LLM-JSON: AI生成用 */
  jsonGenerateNodes: Scalars['Boolean']['output'];
  /** カラムを更新する */
  updateColumn: Scalars['Boolean']['output'];
  /** ファイル更新 */
  updateFile: Scalars['ID']['output'];
  /** 行グループを更新 */
  updateGroup: Scalars['ID']['output'];
  /** ファイルにkindを更新 */
  updateKind: Scalars['ID']['output'];
  /** ノード更新 */
  updateNode: Scalars['ID']['output'];
  /** ノードの詳細更新 */
  updateNodeProperty: Scalars['ID']['output'];
  /** ノード詳細にコメント更新する */
  updateNodePropertyComment: Scalars['ID']['output'];
  /** ノード詳細にコメント更新する */
  updateNodePropertyFile: Scalars['ID']['output'];
  /** ノードの関係更新 */
  updateNodeRelation: Scalars['ID']['output'];
  /** ノードの関係の詳細更新 */
  updateNodeRelationProperty: Scalars['ID']['output'];
  /** プロジェクト更新 */
  updateProject: Scalars['ID']['output'];
  /** 行を挿入する */
  updateRow: Scalars['Boolean']['output'];
  /** ワークスペース情報更新 */
  updateSpace: Scalars['ID']['output'];
  /** ユーザー情報をアップデートする */
  updateUser: Scalars['Boolean']['output'];
};


export type MutationAiGenerateNodesArgs = {
  in: AiGenerateInput;
};


export type MutationAssignKindToNodePropertyArgs = {
  in: NodePropertyKindInput;
};


export type MutationAssignNodeArgs = {
  in: GNodeFromToInput;
};


export type MutationAssignUserToNodePropertyArgs = {
  in: NodePropertyUserInput;
};


export type MutationCopyFileArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCreateFileArgs = {
  in: FileInput;
};


export type MutationCreateFileAiAutoCorrectArgs = {
  in: AiAutoCorrectFileInput;
};


export type MutationCreateFileAiGenerateArgs = {
  in: AiGenerateFileInput;
};


export type MutationCreateFileAiSummarizeArgs = {
  in: AiSummarizeFileInput;
};


export type MutationCreateFileAiWithImageGenerateArgs = {
  in: AiWithImageGenerateFileInput;
};


export type MutationCreateFileJsonGenerateArgs = {
  in: JsonGenerateFileInput;
};


export type MutationCreateFileV2JsonGenerateArgs = {
  in: JsonGenerateV2FileInput;
};


export type MutationCreateGroupArgs = {
  in: GroupInput;
};


export type MutationCreateKindArgs = {
  in: KindInput;
};


export type MutationCreateNodeArgs = {
  in: GNodeInput;
};


export type MutationCreateNodePropertyCommentArgs = {
  in: GNodePropertyCommentInput;
};


export type MutationCreateNodePropertyFileArgs = {
  in: GNodePropertyFileInput;
};


export type MutationCreateNodeRelationArgs = {
  in: GNodeRelationInput;
};


export type MutationCreateNodesArgs = {
  in: GNodesInput;
};


export type MutationCreateProjectArgs = {
  in: ProjectInput;
};


export type MutationCreateSpaceArgs = {
  in: SpaceInput;
};


export type MutationDeleteColumnArgs = {
  in: DeleteColumnInput;
};


export type MutationDeleteFileArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteKindArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteKindToNodePropertyArgs = {
  in: NodePropertyKindInput;
};


export type MutationDeleteNodeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteNodePropertyCommentArgs = {
  in: DeleteGNodePropertyCommentInput;
};


export type MutationDeleteNodePropertyFileArgs = {
  in: DeleteGNodePropertyFileInput;
};


export type MutationDeleteNodeRelationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteProjectArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteRowArgs = {
  in: DeleteRowInput;
};


export type MutationDeleteSpaceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUserToNodePropertyArgs = {
  in: NodePropertyUserInput;
};


export type MutationDetachNodeArgs = {
  in: GNodeFromToInput;
};


export type MutationExitFromSpaceArgs = {
  in: ExitSpaceInput;
};


export type MutationInsertColumnArgs = {
  in: ColumnInput;
};


export type MutationInsertRowArgs = {
  in: RowInput;
};


export type MutationInviteToSpaceArgs = {
  in: InviteSpaceInput;
};


export type MutationJsonGenerateNodesArgs = {
  in: JsonGenerateInput;
};


export type MutationUpdateColumnArgs = {
  in: UpdateColumnInput;
};


export type MutationUpdateFileArgs = {
  in: UpdateFileInput;
};


export type MutationUpdateGroupArgs = {
  in: UpdateGroupInput;
};


export type MutationUpdateKindArgs = {
  in: UpdateKindInput;
};


export type MutationUpdateNodeArgs = {
  in: UpdateGNodeInput;
};


export type MutationUpdateNodePropertyArgs = {
  in: UpdateGNodePropertyInput;
};


export type MutationUpdateNodePropertyCommentArgs = {
  in: UpdateGNodePropertyCommentInput;
};


export type MutationUpdateNodePropertyFileArgs = {
  in: UpdateGNodePropertyFileInput;
};


export type MutationUpdateNodeRelationArgs = {
  in: UpdateGNodeRelationInput;
};


export type MutationUpdateNodeRelationPropertyArgs = {
  in: UpdateGNodeRelationPropertyInput;
};


export type MutationUpdateProjectArgs = {
  in: UpdateProjectInput;
};


export type MutationUpdateRowArgs = {
  in: UpdateRowInput;
};


export type MutationUpdateSpaceArgs = {
  in: UpdateSpaceInput;
};


export type MutationUpdateUserArgs = {
  in?: InputMaybe<UpdateUserInput>;
};

export type Node = {
  /**
   * システム固有のID
   * 数字
   */
  id: Scalars['ID']['output'];
};

export type NodePropertyKindInput = {
  /** ユーザーID */
  kindId: Scalars['ID']['input'];
  /** ユーザーID */
  nodePropertyId: Scalars['ID']['input'];
};

export enum NodePropertyMediaEnum {
  Document = 'document',
  Email = 'email',
  Excel = 'excel',
  Fax = 'fax',
  System = 'system',
  Tel = 'tel'
}

export enum NodePropertyPriorityEnum {
  High = 'high',
  Low = 'low',
  Medium = 'medium'
}

export enum NodePropertyStatusEnum {
  Complete = 'complete',
  InProgress = 'in_progress'
}

export type NodePropertyUserInput = {
  /** ユーザーID */
  nodePropertyId: Scalars['ID']['input'];
  /** ユーザーID */
  userId: Scalars['ID']['input'];
};

export enum NodeRelationPropertyMediaEnum {
  Document = 'document',
  Email = 'email',
  Excel = 'excel',
  Fax = 'fax',
  System = 'system',
  Tel = 'tel'
}

export enum NodeRelationPropertyTypeEnum {
  ArrowBothLine = 'arrow_both_line',
  ArrowDottedBothLine = 'arrow_dotted_both_line',
  ArrowDottedLine = 'arrow_dotted_line',
  ArrowLine = 'arrow_line'
}

export enum NodeTypeEnum {
  Condition = 'condition',
  Database = 'database',
  End = 'end',
  Start = 'start',
  Task = 'task',
  Ticket = 'ticket'
}

/** ページネーション */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** 検索ヒット数 */
  count: Scalars['Int']['output'];
  /** 次のページがあるかどうか */
  hasNext: Scalars['Boolean']['output'];
  /** 前のページがあるかどうか */
  hasPrevious: Scalars['Boolean']['output'];
  /** ページ数 */
  totalPage: Scalars['Int']['output'];
};

export type Position = {
  /** column番号 */
  columnIndex: Scalars['Int']['input'];
  /** row番号 */
  rowIndex: Scalars['Int']['input'];
  /** column番号 */
  type?: InputMaybe<NodeTypeEnum>;
};

export type Project = Node & {
  __typename?: 'Project';
  /** Project作成日 */
  createdAt: Scalars['String']['output'];
  /** Project詳細 */
  description: Scalars['String']['output'];
  /** Projectに属するfilesの個数 */
  filesCount: Scalars['Int']['output'];
  /** ProjectID */
  id: Scalars['ID']['output'];
  /** Project名前 */
  name: Scalars['String']['output'];
  /** Project所属WorkspaceID */
  spaceId: Scalars['ID']['output'];
  /** Project最終変更日時 */
  updatedAt: Scalars['String']['output'];
};

export type ProjectInput = {
  /** Project詳細 */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Project名前 */
  name: Scalars['String']['input'];
  /** Project所属WorkspaceID */
  spaceId: Scalars['ID']['input'];
};

/** ワークスペースのリスト */
export type ProjectList = List & {
  __typename?: 'ProjectList';
  /** ワークスペースの一覧 */
  nodes: Array<Project>;
  /** ページ情報 */
  pageInfo: PageInfo;
};

export enum ProjectOrder {
  UpdatedAt = 'updatedAt'
}

export type ProjectsFilter = {
  /** リミット */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** 順番 */
  orderBy?: InputMaybe<ProjectOrder>;
  /** ページ */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** 順番タイプ */
  sortType?: InputMaybe<SortType>;
  /** WorkspaceID */
  spaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type Query = {
  __typename?: 'Query';
  /** ファイルカラムリスト */
  columns: ColumnList;
  /** ファイル */
  file: File;
  /** ファイルリスト */
  files: FileList;
  /** ファイルロルグループリスト */
  groups: Array<Group>;
  /** ファイルのkindリスト */
  kinds: Array<Kind>;
  /** ファイルノード */
  node: GNode;
  /** ファイルノード詳細 */
  nodeProperty: GNodeProperty;
  /** ファイルノード関係詳細 */
  nodeRelationProperty: GNodeRelationProperty;
  /** ファイルノードリスト */
  nodes: GNodeList;
  /** プロジェクト */
  project: Project;
  /** プロジェクトリスト */
  projects: ProjectList;
  /** ファイルロルリスト */
  rows: Array<Row>;
  /** ワークスペース */
  space: Space;
  /** ワークスペースリスト */
  spaces: SpaceList;
  /** ユーザー */
  user: User;
  /** ワークスペースユーザー一覧 */
  users: Array<User>;
};


export type QueryColumnsArgs = {
  filter?: InputMaybe<ColumnsFilter>;
};


export type QueryFileArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFilesArgs = {
  filter?: InputMaybe<FilesFilter>;
};


export type QueryGroupsArgs = {
  filter?: InputMaybe<GroupFilter>;
};


export type QueryKindsArgs = {
  filter?: InputMaybe<KindFilter>;
};


export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryNodePropertyArgs = {
  nodeId: Scalars['ID']['input'];
};


export type QueryNodeRelationPropertyArgs = {
  nodeRelationId: Scalars['ID']['input'];
};


export type QueryNodesArgs = {
  filter: GNodesFilter;
};


export type QueryProjectArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProjectsArgs = {
  filter?: InputMaybe<ProjectsFilter>;
};


export type QueryRowsArgs = {
  filter: RowsFilter;
};


export type QuerySpaceArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySpacesArgs = {
  filter?: InputMaybe<SpacesFilter>;
};


export type QueryUsersArgs = {
  filter?: UsersFilter;
};

export type RelationInput = {
  /** 子ノード */
  childNode: Position;
  /** 親ノード */
  parentNode: Position;
};

export type Row = Node & {
  __typename?: 'Row';
  /** 行詳細 */
  description: Scalars['String']['output'];
  /** ファイルID */
  fileId: Scalars['ID']['output'];
  /** 行ID */
  id: Scalars['ID']['output'];
  /** 行番号 */
  index: Scalars['Int']['output'];
};

export type RowInput = {
  /** 行詳細 */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ファイルID */
  fileId: Scalars['ID']['input'];
  /** ターゲット行番号 */
  index: Scalars['Int']['input'];
  /** 上下に入れるか */
  insertType: RowInputType;
};

export enum RowInputType {
  Bottom = 'bottom',
  Down = 'down',
  Top = 'top',
  Up = 'up'
}

export type RowsFilter = {
  /** ファイルID */
  fileId: Scalars['ID']['input'];
};

export enum SortType {
  Asc = 'asc',
  Desc = 'desc'
}

export type Space = Node & {
  __typename?: 'Space';
  /** スペースアイコン */
  icon?: Maybe<Scalars['String']['output']>;
  /** WorkspaceID */
  id: Scalars['ID']['output'];
  /** Workspace名 */
  name: Scalars['String']['output'];
  /** Projectに属するfilesの個数 */
  projectsCount: Scalars['Int']['output'];
};

export type SpaceInput = {
  /** ユーザーアイコン */
  icon?: InputMaybe<Scalars['Upload']['input']>;
  /** Workspaceメール */
  name: Scalars['String']['input'];
};

/** ワークスペースのリスト */
export type SpaceList = List & {
  __typename?: 'SpaceList';
  /** ワークスペースの一覧 */
  nodes: Array<Space>;
  /** ページ情報 */
  pageInfo: PageInfo;
};

export type SpacesFilter = {
  /** リミット */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** ページ */
  page?: InputMaybe<Scalars['Int']['input']>;
};

export enum TimeUnitEnum {
  Days = 'days',
  Hours = 'hours',
  Minutes = 'minutes',
  Seconds = 'seconds'
}

export type UpdateColumnInput = {
  /** カラム色 */
  color?: InputMaybe<Scalars['String']['input']>;
  /** ファイルID */
  fileId: Scalars['ID']['input'];
  /** ターゲット番号 */
  index: Scalars['Int']['input'];
  /** カラム名前 */
  name?: InputMaybe<Scalars['String']['input']>;
  /** カラムタイプ */
  type?: InputMaybe<ColumnTypeEnum>;
};

export type UpdateFileInput = {
  /** ワークフロー説明 */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ファイルID */
  id: Scalars['ID']['input'];
  /** ファイル名前 */
  name?: InputMaybe<Scalars['String']['input']>;
  /** プロジェクトID */
  projectId?: InputMaybe<Scalars['ID']['input']>;
  /** ワークフローのサムネイル */
  thumbnail?: InputMaybe<Scalars['Upload']['input']>;
};

export type UpdateGNodeInput = {
  /** column番号 */
  columnIndex: Scalars['Int']['input'];
  /** ノードID */
  id: Scalars['ID']['input'];
  /** row番号 */
  rowIndex: Scalars['Int']['input'];
  /** column番号 */
  type?: InputMaybe<NodeTypeEnum>;
};

export type UpdateGNodePropertyCommentInput = {
  /** コメントID */
  id: Scalars['ID']['input'];
  /** メンションするユーザー */
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** アクティビティ説明文 */
  text: Scalars['String']['input'];
};

export type UpdateGNodePropertyFileInput = {
  /** 詳細のファイル */
  file: Scalars['Upload']['input'];
  /** 詳細のファイルID */
  id: Scalars['ID']['input'];
};

export type UpdateGNodePropertyInput = {
  /** ノード説明 */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ノードメディア */
  media?: InputMaybe<NodePropertyMediaEnum>;
  /** ノードメディアのラベル */
  mediaLabel?: InputMaybe<Scalars['String']['input']>;
  /** ノード説明 */
  name?: InputMaybe<Scalars['String']['input']>;
  /** ファイルID */
  nodeId: Scalars['ID']['input'];
  /** ノード優先度 */
  priority?: InputMaybe<NodePropertyPriorityEnum>;
  /** ノードStatus */
  status?: InputMaybe<NodePropertyStatusEnum>;
  /** ノードStatus */
  timeUnit?: InputMaybe<TimeUnitEnum>;
  /** ノードStatus */
  timeValue?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateGNodeRelationInput = {
  /** 開始ノード */
  fromId: Scalars['ID']['input'];
  /** ノード関係ID */
  id: Scalars['ID']['input'];
  /** 終了ノード */
  toId: Scalars['ID']['input'];
};

export type UpdateGNodeRelationPropertyInput = {
  /** ノードRelationタイプ */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ノードRelationタイプ */
  media?: InputMaybe<NodeRelationPropertyMediaEnum>;
  /** ファイルID */
  nodeRelationId: Scalars['ID']['input'];
  /** ノードRelationポジション */
  positions?: InputMaybe<Scalars['JSON']['input']>;
  /** ノードRelationタイプ */
  type?: InputMaybe<NodeRelationPropertyTypeEnum>;
};

export type UpdateGroupInput = {
  /** グループID */
  id: Scalars['ID']['input'];
  /** グループ名 */
  name?: InputMaybe<Scalars['String']['input']>;
  /** グループロルIndexes */
  rowIndexes: Array<Scalars['Int']['input']>;
};

export type UpdateKindInput = {
  /** グループID */
  id: Scalars['ID']['input'];
  /** グループ名 */
  text: Scalars['String']['input'];
};

export type UpdateProjectInput = {
  /** Project詳細 */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ProjectID */
  id: Scalars['ID']['input'];
  /** Project名前 */
  name: Scalars['String']['input'];
};

export type UpdateRowInput = {
  /** 行詳細 */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ファイルID */
  fileId: Scalars['ID']['input'];
  /** ターゲット行番号 */
  index: Scalars['Int']['input'];
};

export type UpdateSpaceInput = {
  /** ユーザーアイコン */
  icon?: InputMaybe<Scalars['Upload']['input']>;
  /** WorkspaceID */
  id: Scalars['ID']['input'];
  /** Workspaceメール */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  /** メールアドレス変更 */
  email?: InputMaybe<Scalars['String']['input']>;
  /** ユーザー姓 */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** ユーザーアイコン */
  icon?: InputMaybe<Scalars['Upload']['input']>;
  /** ユーザー名 */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** 通知設定 */
  userSettingNotification?: InputMaybe<UpdateUserSettingNotificationInput>;
};

export type UpdateUserSettingNotificationInput = {
  /** メンション通知するかどうか */
  isMention?: InputMaybe<Scalars['Boolean']['input']>;
};

export type User = Node & {
  __typename?: 'User';
  /** ユーザーメール */
  email: Scalars['String']['output'];
  /** ユーザー姓 */
  firstName: Scalars['String']['output'];
  /** ユーザーアイコン */
  icon?: Maybe<Scalars['String']['output']>;
  /** システム固有のID */
  id: Scalars['ID']['output'];
  /** ユーザー名 */
  lastName: Scalars['String']['output'];
  /** ユーザー姓名 */
  name: Scalars['String']['output'];
  /** ユーザー通知設定 */
  settingNotification?: Maybe<UserSettingNotification>;
};

export type UserSettingNotification = Node & {
  __typename?: 'UserSettingNotification';
  /** システム固有のID */
  id: Scalars['ID']['output'];
  /** メンション通知するかどうか */
  isMention?: Maybe<Scalars['Boolean']['output']>;
};

export type UsersFilter = {
  /** 所属ファイルID */
  fileId?: InputMaybe<Scalars['ID']['input']>;
  /** リミット */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** ページ */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** 所属スペースID */
  spaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type V2LlmContent = {
  /** 生成ノード関係性 */
  edges: Array<InputMaybe<V2LlmEdge>>;
  /** 生成ノード */
  processes: Array<InputMaybe<V2LlmProcess>>;
};

export type V2LlmEdge = {
  /** ラベル */
  condition: Scalars['String']['input'];
  /** 親ノード */
  from: Scalars['Int']['input'];
  /** 子ノード */
  to: Scalars['Int']['input'];
};

export type V2LlmProcess = {
  /** アクション */
  action: Scalars['String']['input'];
  /** アクター */
  actor: Scalars['String']['input'];
  /** ラベル */
  detail: Scalars['String']['input'];
  /** オーダー */
  order: Scalars['Int']['input'];
};

export type NodesQueryVariables = Exact<{
  filter: GNodesFilter;
}>;


export type NodesQuery = { __typename?: 'Query', nodes: { __typename?: 'GNodeList', nodes: Array<{ __typename?: 'GNode', id: string, fileId: string, rowIndex: number, columnIndex: number, type: NodeTypeEnum, name: string, createdAt: string, updatedAt: string, property: { __typename?: 'GNodeProperty', id: string, nodeId: string, type: NodeTypeEnum, name: string, description: string, priority?: NodePropertyPriorityEnum | null, media?: NodePropertyMediaEnum | null, status?: NodePropertyStatusEnum | null, timeValue: number, timeUnit: TimeUnitEnum, kinds?: Array<{ __typename?: 'Kind', id: string, fileId: string, text: string }> | null, files?: Array<{ __typename?: 'GNodePropertyFile', id: string, nodePropertyId: string, fileName: string, fileType: string, file: string }> | null, comments?: Array<{ __typename?: 'GNodePropertyComment', id: string, nodePropertyId: string, text: string, creatorId: string, createdAt: string, creator: { __typename?: 'User', id: string, email: string, firstName: string, lastName: string, name: string, icon?: string | null } }> | null, activities?: Array<{ __typename?: 'GNodePropertyActivity', id: string, nodePropertyId: string, text: string }> | null, users?: Array<{ __typename?: 'User', id: string, email: string, firstName: string, lastName: string, name: string, icon?: string | null }> | null }, tasks?: Array<{ __typename?: 'GNodeProperty', id: string, nodeId: string, type: NodeTypeEnum, name: string, description: string, priority?: NodePropertyPriorityEnum | null, media?: NodePropertyMediaEnum | null, status?: NodePropertyStatusEnum | null, timeValue: number, timeUnit: TimeUnitEnum }> | null }> } };

export type TicketItemFragment = { __typename?: 'GNode', id: string, fileId: string, rowIndex: number, columnIndex: number, type: NodeTypeEnum, name: string, createdAt: string, updatedAt: string, property: { __typename?: 'GNodeProperty', id: string, nodeId: string, type: NodeTypeEnum, name: string, description: string, priority?: NodePropertyPriorityEnum | null, media?: NodePropertyMediaEnum | null, status?: NodePropertyStatusEnum | null, timeValue: number, timeUnit: TimeUnitEnum, kinds?: Array<{ __typename?: 'Kind', id: string, fileId: string, text: string }> | null, files?: Array<{ __typename?: 'GNodePropertyFile', id: string, nodePropertyId: string, fileName: string, fileType: string, file: string }> | null, comments?: Array<{ __typename?: 'GNodePropertyComment', id: string, nodePropertyId: string, text: string, creatorId: string, createdAt: string, creator: { __typename?: 'User', id: string, email: string, firstName: string, lastName: string, name: string, icon?: string | null } }> | null, activities?: Array<{ __typename?: 'GNodePropertyActivity', id: string, nodePropertyId: string, text: string }> | null, users?: Array<{ __typename?: 'User', id: string, email: string, firstName: string, lastName: string, name: string, icon?: string | null }> | null }, tasks?: Array<{ __typename?: 'GNodeProperty', id: string, nodeId: string, type: NodeTypeEnum, name: string, description: string, priority?: NodePropertyPriorityEnum | null, media?: NodePropertyMediaEnum | null, status?: NodePropertyStatusEnum | null, timeValue: number, timeUnit: TimeUnitEnum }> | null };

export type PropertyFragment = { __typename?: 'GNodeProperty', id: string, nodeId: string, type: NodeTypeEnum, name: string, description: string, priority?: NodePropertyPriorityEnum | null, media?: NodePropertyMediaEnum | null, status?: NodePropertyStatusEnum | null, timeValue: number, timeUnit: TimeUnitEnum, kinds?: Array<{ __typename?: 'Kind', id: string, fileId: string, text: string }> | null, files?: Array<{ __typename?: 'GNodePropertyFile', id: string, nodePropertyId: string, fileName: string, fileType: string, file: string }> | null, comments?: Array<{ __typename?: 'GNodePropertyComment', id: string, nodePropertyId: string, text: string, creatorId: string, createdAt: string, creator: { __typename?: 'User', id: string, email: string, firstName: string, lastName: string, name: string, icon?: string | null } }> | null, activities?: Array<{ __typename?: 'GNodePropertyActivity', id: string, nodePropertyId: string, text: string }> | null, users?: Array<{ __typename?: 'User', id: string, email: string, firstName: string, lastName: string, name: string, icon?: string | null }> | null };

export type KindFragment = { __typename?: 'Kind', id: string, fileId: string, text: string };

export type CreatorFragment = { __typename?: 'User', id: string, email: string, firstName: string, lastName: string, name: string, icon?: string | null };

export type UpdateNodePropertyMutationVariables = Exact<{
  in: UpdateGNodePropertyInput;
}>;


export type UpdateNodePropertyMutation = { __typename?: 'Mutation', updateNodeProperty: string };

export type CreateNodePropertyCommentMutationVariables = Exact<{
  in: GNodePropertyCommentInput;
}>;


export type CreateNodePropertyCommentMutation = { __typename?: 'Mutation', createNodePropertyComment: string };

export type CreateNodeMutationVariables = Exact<{
  in: GNodeInput;
}>;


export type CreateNodeMutation = { __typename?: 'Mutation', createNode: string };

export type DeleteNodeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteNodeMutation = { __typename?: 'Mutation', deleteNode: string };

export type AssignNodeMutationVariables = Exact<{
  in: GNodeFromToInput;
}>;


export type AssignNodeMutation = { __typename?: 'Mutation', assignNode: boolean };

export type DetachNodeMutationVariables = Exact<{
  in: GNodeFromToInput;
}>;


export type DetachNodeMutation = { __typename?: 'Mutation', detachNode: boolean };

export const KindFragmentDoc = gql`
    fragment Kind on Kind {
  id
  fileId
  text
}
    `;
export const CreatorFragmentDoc = gql`
    fragment Creator on User {
  id
  email
  firstName
  lastName
  name
  icon
}
    `;
export const PropertyFragmentDoc = gql`
    fragment Property on GNodeProperty {
  id
  nodeId
  type
  name
  description
  priority
  media
  status
  timeValue
  timeUnit
  kinds {
    ...Kind
  }
  files {
    id
    nodePropertyId
    fileName
    fileType
    file
  }
  comments {
    id
    nodePropertyId
    text
    creatorId
    createdAt
    creator {
      ...Creator
    }
  }
  activities {
    id
    nodePropertyId
    text
  }
  users {
    ...Creator
  }
}
    ${KindFragmentDoc}
${CreatorFragmentDoc}`;
export const TicketItemFragmentDoc = gql`
    fragment TicketItem on GNode {
  id
  fileId
  rowIndex
  columnIndex
  type
  name
  createdAt
  updatedAt
  property {
    ...Property
  }
  tasks {
    id
    nodeId
    type
    name
    description
    priority
    media
    status
    timeValue
    timeUnit
  }
}
    ${PropertyFragmentDoc}`;
export const NodesDocument = gql`
    query Nodes($filter: GNodesFilter!) {
  nodes(filter: $filter) {
    nodes {
      ...TicketItem
    }
  }
}
    ${TicketItemFragmentDoc}`;

/**
 * __useNodesQuery__
 *
 * To run a query within a React component, call `useNodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useNodesQuery(baseOptions: Apollo.QueryHookOptions<NodesQuery, NodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NodesQuery, NodesQueryVariables>(NodesDocument, options);
      }
export function useNodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NodesQuery, NodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NodesQuery, NodesQueryVariables>(NodesDocument, options);
        }
export function useNodesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NodesQuery, NodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NodesQuery, NodesQueryVariables>(NodesDocument, options);
        }
export type NodesQueryHookResult = ReturnType<typeof useNodesQuery>;
export type NodesLazyQueryHookResult = ReturnType<typeof useNodesLazyQuery>;
export type NodesSuspenseQueryHookResult = ReturnType<typeof useNodesSuspenseQuery>;
export type NodesQueryResult = Apollo.QueryResult<NodesQuery, NodesQueryVariables>;
export const UpdateNodePropertyDocument = gql`
    mutation UpdateNodeProperty($in: UpdateGNodePropertyInput!) {
  updateNodeProperty(in: $in)
}
    `;
export type UpdateNodePropertyMutationFn = Apollo.MutationFunction<UpdateNodePropertyMutation, UpdateNodePropertyMutationVariables>;

/**
 * __useUpdateNodePropertyMutation__
 *
 * To run a mutation, you first call `useUpdateNodePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNodePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNodePropertyMutation, { data, loading, error }] = useUpdateNodePropertyMutation({
 *   variables: {
 *      in: // value for 'in'
 *   },
 * });
 */
export function useUpdateNodePropertyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNodePropertyMutation, UpdateNodePropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNodePropertyMutation, UpdateNodePropertyMutationVariables>(UpdateNodePropertyDocument, options);
      }
export type UpdateNodePropertyMutationHookResult = ReturnType<typeof useUpdateNodePropertyMutation>;
export type UpdateNodePropertyMutationResult = Apollo.MutationResult<UpdateNodePropertyMutation>;
export type UpdateNodePropertyMutationOptions = Apollo.BaseMutationOptions<UpdateNodePropertyMutation, UpdateNodePropertyMutationVariables>;
export const CreateNodePropertyCommentDocument = gql`
    mutation CreateNodePropertyComment($in: GNodePropertyCommentInput!) {
  createNodePropertyComment(in: $in)
}
    `;
export type CreateNodePropertyCommentMutationFn = Apollo.MutationFunction<CreateNodePropertyCommentMutation, CreateNodePropertyCommentMutationVariables>;

/**
 * __useCreateNodePropertyCommentMutation__
 *
 * To run a mutation, you first call `useCreateNodePropertyCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNodePropertyCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNodePropertyCommentMutation, { data, loading, error }] = useCreateNodePropertyCommentMutation({
 *   variables: {
 *      in: // value for 'in'
 *   },
 * });
 */
export function useCreateNodePropertyCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateNodePropertyCommentMutation, CreateNodePropertyCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNodePropertyCommentMutation, CreateNodePropertyCommentMutationVariables>(CreateNodePropertyCommentDocument, options);
      }
export type CreateNodePropertyCommentMutationHookResult = ReturnType<typeof useCreateNodePropertyCommentMutation>;
export type CreateNodePropertyCommentMutationResult = Apollo.MutationResult<CreateNodePropertyCommentMutation>;
export type CreateNodePropertyCommentMutationOptions = Apollo.BaseMutationOptions<CreateNodePropertyCommentMutation, CreateNodePropertyCommentMutationVariables>;
export const CreateNodeDocument = gql`
    mutation CreateNode($in: GNodeInput!) {
  createNode(in: $in)
}
    `;
export type CreateNodeMutationFn = Apollo.MutationFunction<CreateNodeMutation, CreateNodeMutationVariables>;

/**
 * __useCreateNodeMutation__
 *
 * To run a mutation, you first call `useCreateNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNodeMutation, { data, loading, error }] = useCreateNodeMutation({
 *   variables: {
 *      in: // value for 'in'
 *   },
 * });
 */
export function useCreateNodeMutation(baseOptions?: Apollo.MutationHookOptions<CreateNodeMutation, CreateNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNodeMutation, CreateNodeMutationVariables>(CreateNodeDocument, options);
      }
export type CreateNodeMutationHookResult = ReturnType<typeof useCreateNodeMutation>;
export type CreateNodeMutationResult = Apollo.MutationResult<CreateNodeMutation>;
export type CreateNodeMutationOptions = Apollo.BaseMutationOptions<CreateNodeMutation, CreateNodeMutationVariables>;
export const DeleteNodeDocument = gql`
    mutation DeleteNode($id: ID!) {
  deleteNode(id: $id)
}
    `;
export type DeleteNodeMutationFn = Apollo.MutationFunction<DeleteNodeMutation, DeleteNodeMutationVariables>;

/**
 * __useDeleteNodeMutation__
 *
 * To run a mutation, you first call `useDeleteNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNodeMutation, { data, loading, error }] = useDeleteNodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNodeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNodeMutation, DeleteNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNodeMutation, DeleteNodeMutationVariables>(DeleteNodeDocument, options);
      }
export type DeleteNodeMutationHookResult = ReturnType<typeof useDeleteNodeMutation>;
export type DeleteNodeMutationResult = Apollo.MutationResult<DeleteNodeMutation>;
export type DeleteNodeMutationOptions = Apollo.BaseMutationOptions<DeleteNodeMutation, DeleteNodeMutationVariables>;
export const AssignNodeDocument = gql`
    mutation AssignNode($in: GNodeFromToInput!) {
  assignNode(in: $in)
}
    `;
export type AssignNodeMutationFn = Apollo.MutationFunction<AssignNodeMutation, AssignNodeMutationVariables>;

/**
 * __useAssignNodeMutation__
 *
 * To run a mutation, you first call `useAssignNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignNodeMutation, { data, loading, error }] = useAssignNodeMutation({
 *   variables: {
 *      in: // value for 'in'
 *   },
 * });
 */
export function useAssignNodeMutation(baseOptions?: Apollo.MutationHookOptions<AssignNodeMutation, AssignNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignNodeMutation, AssignNodeMutationVariables>(AssignNodeDocument, options);
      }
export type AssignNodeMutationHookResult = ReturnType<typeof useAssignNodeMutation>;
export type AssignNodeMutationResult = Apollo.MutationResult<AssignNodeMutation>;
export type AssignNodeMutationOptions = Apollo.BaseMutationOptions<AssignNodeMutation, AssignNodeMutationVariables>;
export const DetachNodeDocument = gql`
    mutation DetachNode($in: GNodeFromToInput!) {
  detachNode(in: $in)
}
    `;
export type DetachNodeMutationFn = Apollo.MutationFunction<DetachNodeMutation, DetachNodeMutationVariables>;

/**
 * __useDetachNodeMutation__
 *
 * To run a mutation, you first call `useDetachNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachNodeMutation, { data, loading, error }] = useDetachNodeMutation({
 *   variables: {
 *      in: // value for 'in'
 *   },
 * });
 */
export function useDetachNodeMutation(baseOptions?: Apollo.MutationHookOptions<DetachNodeMutation, DetachNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DetachNodeMutation, DetachNodeMutationVariables>(DetachNodeDocument, options);
      }
export type DetachNodeMutationHookResult = ReturnType<typeof useDetachNodeMutation>;
export type DetachNodeMutationResult = Apollo.MutationResult<DetachNodeMutation>;
export type DetachNodeMutationOptions = Apollo.BaseMutationOptions<DetachNodeMutation, DetachNodeMutationVariables>;