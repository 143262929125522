import React from 'react';
import { Box, Button } from '@mui/material';

interface Props {
  handleShowInviteDialog: () => void;
}

const SpaceMemberListHeader = (props: Props) => {
  const { handleShowInviteDialog } = props;
  return (
    <div
      className="text-left flex justify-content-between align-content-center"
      style={{ height: 36, marginBottom: 20 }}>
      <Box>
        <Box className="flex font-size-20 font-weight-700" sx={{ alignItems: 'center' }}>
          メンバー一覧
        </Box>
      </Box>
      <div>
        <Button
          type="button"
          onClick={handleShowInviteDialog}
          variant="contained"
          color="primary"
          size="medium"
          sx={{ boxShadow: 'none' }}>
          <span className="font-weight-600 font-size-14">メンバーを招待</span>
        </Button>
      </div>
    </div>
  );
};
export default SpaceMemberListHeader;
