import ForgotPasswordForm from '../../components/organisms/forgot-password/';
import React from 'react';

const ForgotPasswordPage = () => {
  return (
    <div className="mx-auto max-w-522">
      <ForgotPasswordForm />
    </div>
  );
};

export default ForgotPasswordPage;
