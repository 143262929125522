import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import { useMutation } from '@apollo/client';
import { CREATE_PROJECT, GET_PROJECTS } from './../../../../api/graphql';
import { useParams } from 'react-router-dom';
import { Project } from '@/__generated__/graphql';

interface Item {
  id: string;
  label: string;
}

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleUpdateProject?: (name: string, description: string) => void;
  project?: Project;
}

const AddProjectDialog = (props: Props) => {
  const { isOpen, handleClose, project, handleUpdateProject } = props;
  const { spaceId } = useParams();
  const [projectName, setProjectName] = useState(project ? project.name : '');
  const [value, selectValue] = React.useState('');
  const [lists, setLists] = useState<Item[]>([
    {
      id: '1',
      label: '「マイスペース」のメンバーが編集可'
    }
  ]);
  const [projectDescription, setProjectDescription] = useState(project ? project.description : '');

  const [createProject] = useMutation(CREATE_PROJECT, {
    refetchQueries: [GET_PROJECTS]
  });
  const handleAddProject = async () => {
    if (!spaceId) return;
    await createProject({
      variables: {
        in: {
          name: projectName,
          spaceId: spaceId,
          description: projectDescription
        }
      },
      onCompleted: () => {
        handleClose();
      }
    });
  };

  const handleUpdate = () => {
    if (handleUpdateProject) {
      handleUpdateProject(projectName, projectDescription);
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    selectValue(event.target.value);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth={true}>
      <DialogTitle className="font-size-20 font-weight-500">
        {project ? 'プロジェクトを編集' : 'プロジェクトを新規作成'}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          id="project_name"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
          placeholder="プロジェクト名"
          className="w-full"
          type="text"
          size="small"
        />
        <Box sx={{ mt: 1 }}>
          <TextField
            id="project_description"
            value={projectDescription}
            onChange={(e) => setProjectDescription(e.target.value)}
            placeholder="説明（任意）"
            className="w-full"
            type="text"
            size="medium"
            multiline
            rows={4}
          />
        </Box>
      </DialogContent>
      <Divider />
      <DialogContent>
        <div className="flex justify-content-between">
          <div className="w-258"></div>
          <div className="flex w-258 justify-content-between">
            <Button
              type="button"
              onClick={handleClose}
              variant="text"
              color="primary"
              size="medium"
              className="w-88">
              <span className="font-weight-700 font-size-14">キャンセル</span>
            </Button>
            <Button
              type="button"
              onClick={() => (project ? handleUpdate() : handleAddProject())}
              disabled={projectName === ''}
              className="w-162"
              variant="contained"
              color="primary"
              size="medium"
              sx={{ boxShadow: 'none' }}>
              <span className="font-weight-700 font-size-14">
                {project ? '変更を保存' : 'プロジェクトを作成'}
              </span>
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddProjectDialog;
