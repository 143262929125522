import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EditorState, ContentState, Modifier } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, { defaultSuggestionsFilter } from '@draft-js-plugins/mention';
import editorStyles from './MentionEditor.module.css';
import { MentionData as PluginMentionData } from '@draft-js-plugins/mention';
import { User } from '@/__generated__/graphql';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import '@draft-js-plugins/mention/lib/plugin.css';

interface Prop {
  users: User[];
  handleAddComment: (comment: string, ids: string[]) => void;
  isUpdateComment?: boolean;
  handleCloseComment?: () => void;
  setEditRows?: (rows: number) => void;
  isTicketComment?: boolean;
  comment: string;
}

const MentionEditor = ({
  users,
  handleAddComment,
  isUpdateComment,
  handleCloseComment,
  setEditRows,
  comment,
  isTicketComment
}: Prop) => {
  const ref = useRef<Editor>(null);
  const editorRef = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);
  const [editorState, setEditorState] = useState(() => {
    return EditorState.createEmpty();
  });
  const [mentionComment, setMentionComment] = useState('');
  const [isFocus, setIsFocus] = useState(false);
  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin();
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);
  const [allSuggestions, setAllSuggestions] = useState<PluginMentionData[]>([]);
  const [suggestions, setSuggestions] = useState<PluginMentionData[]>([]);
  useEffect(() => {
    if (users) {
      const greyImageUrl = window.location.origin + '/images/avatar.png';
      const userMentions: PluginMentionData[] = users
        .filter((obj) => obj.name.trim() !== '')
        .map((obj) => ({
          name: obj.name,
          link: `link-for-${obj.id}`,
          avatar: obj.icon || greyImageUrl,
          id: obj.id.toString()
        }));

      setSuggestions(userMentions);
      setAllSuggestions(userMentions);
    }
  }, [users]);

  useEffect(() => {
    if (isUpdateComment) {
      setIsFocus(true);

      setEditorState(convertTextBackToEditorState(comment));
    }
  }, [isUpdateComment]);

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(
    ({ value }: { value: string }) => {
      const filteredSuggestions = defaultSuggestionsFilter(value, allSuggestions);
      setSuggestions(filteredSuggestions);
    },
    [suggestions, allSuggestions]
  );

  const handleFocus = () => {
    setIsFocus(true);
  };

  const extractMentionIds = (text: string) => {
    const idPattern = /@\{name:[^}]+\}\{id:(\d+)\}/g;
    let match;
    const ids = [];

    while ((match = idPattern.exec(text)) !== null) {
      ids.push(match[1]);
    }

    return ids;
  };

  const handleSubmitComment = () => {
    if (mentionComment.trim() === "") {
      return
    }

    const ids = extractMentionIds(mentionComment);
    handleAddComment(mentionComment, ids);
    setEditorState(EditorState.createEmpty());
  };

  const handleChange = useCallback((newEditorState: any) => {
    setEditorState(newEditorState);
    const contentHeight = editorRef.current?.clientHeight;
    if (contentHeight) {
      if (setEditRows) {
        setEditRows(contentHeight);
      }
    }
    const contentState = newEditorState.getCurrentContent();
    const blocks = contentState.getBlockMap();
    let updatedText = '';
    blocks.forEach((block: any) => {
      let text = block.getText();
      let blockStart = 0;
      block.findEntityRanges(
        (character: any) => {
          const entityKey = character.getEntity();
          return entityKey !== null && contentState.getEntity(entityKey).getType() === 'mention';
        },
        (start: number, end: number) => {
          const entityKey = block.getEntityAt(start);
          if (entityKey) {
            const data = contentState.getEntity(entityKey).getData();
            const { name, id } = data.mention;
            const mentionString = `@{name:${name}}{id:${id}}`;
            const mentionStart = start + blockStart;
            const mentionEnd = end + blockStart;
            text = text.substring(0, mentionStart) + mentionString + text.substring(mentionEnd);
            blockStart += mentionString.length - (end - start);
          }
        }
      );
      updatedText += text + '\n';
    });
    setMentionComment(updatedText);
  }, []);

  function convertTextBackToEditorState(textWithMentions: string) {
    const contentState = ContentState.createFromText('');
    let newContentState = contentState;
    const regex = /@\{name:([^}]+)\}\{id:([^}]+)\}/g;
    let lastIndex = 0;
    let match;

    while ((match = regex.exec(textWithMentions)) !== null) {
      const [fullMatch, name, id] = match;
      const start = match.index;
      const end = regex.lastIndex;

      const textBeforeMention = textWithMentions.slice(lastIndex, start);
      newContentState = Modifier.insertText(
        newContentState,
        newContentState.getSelectionAfter(),
        textBeforeMention
      );

      newContentState = newContentState.createEntity('mention', 'SEGMENTED', {
        mention: { name, id }
      });
      const entityKey = newContentState.getLastCreatedEntityKey();

      newContentState = Modifier.insertText(
        newContentState,
        newContentState.getSelectionAfter(),
        `${name}`,
        null,
        entityKey
      );

      lastIndex = end;
    }

    const remainingText = textWithMentions.slice(lastIndex);
    newContentState = Modifier.insertText(
      newContentState,
      newContentState.getSelectionAfter(),
      remainingText
    );

    return EditorState.createWithContent(newContentState);
  }

  return (
    <Box
      sx={{
        textAlign: 'left'
      }}>
      <Box
        sx={{
          border: 1,
          height: isFocus ? 'auto' : 40,
          borderRadius: '3px',
          borderColor: '#c3c3c3',
          backgroundColor: '#ffffff'
        }}
        ref={editorRef}>
        <Box
          className={editorStyles.editor}
          style={{ minHeight: isFocus ? 90 : 30 }}
          onClick={() => {
            // ref.current!.focus();
            handleFocus();
          }}>
          {/*Alt＋Enterでメッセージを送信できます*/}
          <Editor
            editorKey={'editor'}
            editorState={editorState}
            onChange={handleChange}
            plugins={plugins}
            ref={ref}
          />
        </Box>

        {isFocus && (
          <Box sx={{ height: 52, position: 'relative' }}>
            {isUpdateComment ? (
              <Button
                variant="outlined"
                onClick={handleCloseComment}
                style={{
                  position: 'static',
                  marginLeft: '51%',
                  marginTop: '10px',
                  borderColor: '#00000080',
                  color: '#00000099',
                  fontWeight: '700'
                }}>
                キャンセル
              </Button>
            ) : (
              <></>
            )}
            <Button
              variant="contained"
              onClick={handleSubmitComment}
              color="primary"
              style={{
                position: 'absolute',
                marginTop: '10px',
                right: '5px',
                boxShadow: 'none'
              }}>
              {isUpdateComment ? '変更を保存' : 'コメント'}
            </Button>
          </Box>
        )}
      </Box>
      {isFocus && (
        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
        />
      )}
    </Box>
  );
};
export default MentionEditor;
