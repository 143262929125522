import React, { useEffect, useReducer, useState } from 'react';
import {
  Autocomplete,
  Avatar,
  Box,
  Chip,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  ListItemAvatar,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';
import Button from '@mui/material/Button';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LinkIcon from '@mui/icons-material/Link';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import LastPageIcon from '@mui/icons-material/LastPage';
import CheckIcon from '@mui/icons-material/Check';
import { grey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { formatWithDayHourMinute } from '@/utils/dates';
import {
  PropertyFragment,
  TicketItemFragment,
  GNode,
  GNodeProperty,
  NodePropertyPriorityEnum,
  NodePropertyStatusEnum,
  UpdateGNodePropertyInput
} from '@/graphql';
import { TICKET_PRIORITY_COLOR } from '@/types/tickets';

type Props = {
  nodes: TicketItemFragment[];
  node: TicketItemFragment;
  setTickets: Function;
  nextNode: () => void;
  prevNode: () => void;
  searchNodes: (query: string) => void;
  assignNode: (parentNodeId: string, childNodeId: string) => void;
  detachNode: (parentNodeId: string, childNodeId: string) => void;
  deleteNode: (nodeId: string) => void;
  updateNodeProperty: (property: UpdateGNodePropertyInput) => void;
  createCommentForProperty: (nodeId: string, text: string) => void;
};

type AttributeState = Pick<
  GNodeProperty,
  'name' | 'nodeId' | 'priority' | 'type' | 'users' | 'description' | 'media'
>;

type AttributeConfig = {
  key: keyof AttributeState;
  label: string;
  options?: {
    key: string;
    label: string;
    icon?: string;
    contact?: string;
  }[];
}[];

const attributeConfig: AttributeConfig = [
  {
    key: 'type',
    label: '種別',
    options: [
      {
        key: 'task',
        label: '課題'
      },
      {
        key: 'confirm',
        label: '確認'
      }
    ]
  },
  {
    key: 'priority',
    label: '優先順位',
    options: [
      {
        key: NodePropertyPriorityEnum.High,
        label: '高'
      },
      {
        key: NodePropertyPriorityEnum.Medium,
        label: '中'
      },
      {
        key: NodePropertyPriorityEnum.Low,
        label: '低'
      }
    ]
  }
];

type Assignee = {
  key: string;
  label: string;
  icon: string;
  contact: string;
};

const attributeReducer = (state: AttributeState, value: Partial<AttributeState>) => {
  return {
    ...state,
    ...value
  };
};

const TextFieldTitle = styled(TextField)({
  '.MuiInputBase-formControl:before, .MuiInputBase-formControl:after, .MuiInputBase-formControl:hover:before':
    {
      border: 'none !important'
    },
  '& input': {
    color: '#202123',
    fontWeight: 'bold',
    fontSize: '16px'
  }
});

const getChipTypeColor = (type: string) => {
  switch (type) {
    case NodePropertyStatusEnum.Complete:
      return 'yellow';
    case NodePropertyStatusEnum.InProgress:
      return 'pink';
    case NodePropertyPriorityEnum.High:
      return TICKET_PRIORITY_COLOR.High;
    case NodePropertyPriorityEnum.Medium:
      return TICKET_PRIORITY_COLOR.Medium;
    case NodePropertyPriorityEnum.Low:
      return TICKET_PRIORITY_COLOR.Low;
    default:
      break;
  }
};

export const TicketSideEditor = ({
  nodes,
  node,
  searchNodes,
  setTickets,
  nextNode,
  prevNode,
  assignNode,
  detachNode,
  deleteNode,
  updateNodeProperty,
  createCommentForProperty
}: Props) => {
  const [comment, setComment] = useState('');
  const informationConfig = [
    {
      key: 'nodeId',
      label: 'ID',
      value: node.property.nodeId
    }
  ];
  let nodeIndex = nodes.findIndex((item) => item.property.nodeId === node.property.nodeId);
  const [state, attributeUpdate] = useReducer(attributeReducer, node.property);

  const [searchText, setSearchText] = useState<string>('');
  // 紐づくプロセス検索周り
  const [searchEnable, setSearchEnable] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);
  const [assigneeValue, setAssigneeValue] = useState<Assignee | null>(null);
  const [assigneeInputValue, setAssigneeInputValue] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // TODO: need an assignees API
  const assignees: [] = [];
  //     [
  //   {
  //     key: 'Dev1',
  //     label: 'Mizuno Sugu',
  //     icon: '/images/avatar.png',
  //     contact: 'dev1@gmail.com'
  //   },
  //   {
  //     key: 'Dev2',
  //     label: 'Saito Yusuke',
  //     icon: '/images/avatar.png',
  //     contact: 'dev2@gmail.com'
  //   },
  //   {
  //     key: 'Dev3',
  //     label: 'Mizuno Sugu',
  //     icon: '/images/avatar.png',
  //     contact: 'dev2@gmail.com'
  //   },
  //   {
  //     key: 'Dev4',
  //     label: 'Saito Yusuke',
  //     icon: '/images/avatar.png',
  //     contact: 'dev1@gmail.com'
  //   }
  // ];

  // useEffect(() => {
  //   if (loading) return;
  //   if (data && data.nodes) {
  //     setAllNodes(data.nodes.nodes);
  //     filterTasks(data.nodes.nodes);
  //   }
  // }, [data]);

  // useEffect(() => {
  //   if (fetchedTaskNodes) {
  //     filterTasks(allNodes);
  //   }
  // }, [node.tasks]);

  useEffect(() => {
    const variables: UpdateGNodePropertyInput = {
      nodeId: state.nodeId
    };
    if (state.name) {
      variables.name = state.name;
    }
    if (state.priority) {
      variables.priority = state.priority;
    }
    if (state.description) {
      variables.description = state.description;
    }
    updateNodeProperty(variables);
    // TODO: fix any type
    setTickets((prevState: any[]) => {
      const newState = [...prevState];
      const currenNode = newState[nodeIndex];
      if (state.name) {
        currenNode.name = state.name;
      }
      newState[nodeIndex] = {
        ...newState[nodeIndex],
        ...state
      };
      return newState;
    });
  }, [state.name, state.description, state.priority]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTicketComplete = (node: PropertyFragment) => {
    let status = NodePropertyStatusEnum.Complete;
    if (node.status === NodePropertyStatusEnum.Complete) {
      status = NodePropertyStatusEnum.InProgress;
    }

    updateNodeProperty({
      nodeId: state.nodeId,
      status: status
    });
    handleClose();
  };

  const handleAddComment = async () => {
    createCommentForProperty(state.nodeId, comment);
    setComment('');
  };
  const handleRemove = async () => {
    deleteNode(state.nodeId);
    handleClose();
    setTickets((prevState: any[]) => {
      const newState = [...prevState];
      newState.splice(nodeIndex, 1);
      return newState;
    });
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    searchNodes(query);
    setSearchText(query);
  };
  const handleItemClick = (taskNode: GNode) => {
    assignNode(taskNode.id, node.property.nodeId);
    setSearchEnable(false);
  };

  const handleTaskDelete = (taskProperty: GNodeProperty) => {
    detachNode(taskProperty.nodeId, node.property.nodeId);
    setSearchEnable(false);
  };

  return (
    <Box sx={{ width: 518, fontSize: 12, height: 'calc(100vh - 55px)', overflowY: 'scroll' }}>
      <Toolbar
        sx={{
          backgroundColor: grey[100],
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          pl: 2,
          pr: 2
        }}
        variant="dense">
        <Button
          startIcon={<CheckIcon />}
          variant="outlined"
          color="inherit"
          size="medium"
          onClick={() => handleTicketComplete(node.property)}>
          {node.property.status === NodePropertyStatusEnum.Complete ? '未完了にする' : '完了にする'}
        </Button>
        <IconButton size="small" color="inherit" onClick={nextNode}>
          <KeyboardArrowDownIcon />
        </IconButton>
        <IconButton size="small" color="inherit" onClick={prevNode}>
          <KeyboardArrowUpIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <IconButton size="small" color="inherit">
            <LinkIcon />
          </IconButton>
          <IconButton id="remove-button" size="small" color="inherit" onClick={handleClick}>
            <MoreHorizIcon />
          </IconButton>
          <Menu
            id="remove-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            MenuListProps={{
              'aria-labelledby': 'remove-button'
            }}>
            <MenuItem
              sx={{
                width: 145,
                height: 36,
                color: 'red',
                fontWeight: 300,
                fontSize: 16
              }}
              onClick={handleRemove}>
              削除
            </MenuItem>
          </Menu>
          <IconButton
            size="small"
            color="inherit"
            onClick={() => {
              // setActiveNode(null);
            }}>
            <LastPageIcon />
          </IconButton>
        </Box>
      </Toolbar>
      <Stack
        spacing={3}
        sx={{ p: 2, color: '#00000099', pb: 4 }}
        divider={
          <Divider
            orientation="horizontal"
            sx={{ borderStyle: 'dashed', borderColor: '#0000001F' }}
            flexItem
          />
        }>
        <Grid>
          <Grid item xs={12}>
            <TextFieldTitle
              fullWidth
              variant="standard"
              placeholder="チケット"
              value={state.name}
              onChange={({ target: { value } }) => {
                attributeUpdate({ name: value });
              }}
            />
          </Grid>

          {informationConfig.map((item) => (
            <Grid container spacing={1} alignItems="center" key={item.key} sx={{ mt: 1 }}>
              <Grid item xs={2}>
                <Typography variant="body2">{item.label}</Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body1" sx={{ color: '#202123', fontWeight: 'normal' }}>
                  {item.value}
                </Typography>
              </Grid>
            </Grid>
          ))}

          {attributeConfig.map((item) => (
            <Grid container spacing={1} alignItems="center" key={item.key} sx={{ mt: 1 }}>
              <Grid item xs={2}>
                <Typography variant="body2">{item.label}</Typography>
              </Grid>
              <Grid item xs={10}>
                {item.options ? (
                  <FormControl variant="standard" size="small">
                    <Select
                      sx={{
                        border: 'none',
                        width: '140px',
                        height: '28px',
                        padding: '4px 8px'
                      }}
                      value={state[item.key]}
                      onChange={({ target: { value } }) => {
                        attributeUpdate({
                          [item.key]: value
                        });
                      }}>
                      {item.options.map((optionItem) => (
                        <MenuItem value={optionItem.key} key={optionItem.key}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Chip
                              label={optionItem.label}
                              size="small"
                              variant="filled"
                              sx={{
                                color: 'white',
                                fontSize: 13,
                                fontWeight: 400,
                                padding: '3px 4px',
                                backgroundColor: getChipTypeColor(optionItem.key)
                              }}
                            />
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Typography
                    variant="body1"
                    sx={{ color: '#202123', fontWeight: 'bold' }}></Typography>
                )}
              </Grid>
            </Grid>
          ))}

          <Grid container spacing={1} alignItems="center" sx={{ mt: 1 }}>
            <Grid item xs={2}>
              <Typography variant="body2">担当者</Typography>
            </Grid>
            <Grid item xs={10}>
              <FormControl variant="standard" size="small">
                <Autocomplete
                  disablePortal
                  id="tags-standard"
                  sx={{
                    backgroundColor: '',
                    border: 'none',
                    width: '200px',
                    height: '29px'
                  }}
                  options={assignees}
                  getOptionLabel={(option) => option.label}
                  defaultValue={assigneeValue}
                  value={assigneeValue}
                  onChange={(event: any, newValue: Assignee | null) => {
                    newValue ? setAssigneeValue(newValue) : setAssigneeValue(assigneeValue);
                  }}
                  inputValue={assigneeInputValue}
                  onInputChange={(event, newInputValue) => {
                    setAssigneeInputValue(newInputValue);
                  }}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: '321px'
                      }
                    }
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{
                        '& > img': { mr: 2, flexShrink: 0 },
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        maxWidth: '100%',
                        maxLines: 1,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                      {...props}>
                      <Avatar
                        sx={{ width: 24, height: 24 }}
                        variant="circular"
                        src={option?.icon}
                      />
                      {option?.label} {option?.contact}
                    </Box>
                  )}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        variant="standard"
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                {assigneeValue && (
                                  <Avatar
                                    sx={{ width: 24, height: 24 }}
                                    variant="circular"
                                    src={assigneeValue?.icon}
                                  />
                                )}
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          )
                        }}
                      />
                    );
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Stack spacing={2}>
          <Typography variant="body2">説明</Typography>
          <TextField
            multiline
            fullWidth
            variant="standard"
            minRows={3}
            placeholder="このチケットの内容を記載します"
            value={state.description}
            onChange={({ target: { value } }) => {
              attributeUpdate({
                description: value
              });
            }}
          />
        </Stack>
        <Stack spacing={1}>
          <Box>
            <Typography variant="body2">関連プロセス</Typography>
          </Box>
          <List sx={{ width: '100%' }} dense={true}>
            {node.tasks &&
              node.tasks.map((task, index) => (
                <ListItem
                  key={`task_list_item_${index}`}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleTaskDelete(task)}>
                      <ClearOutlinedIcon />
                    </IconButton>
                  }>
                  <ListItemAvatar sx={{ paddingTop: '5px' }}>
                    <ArticleOutlinedIcon />
                  </ListItemAvatar>
                  <ListItemText primary={task.name} secondary={null} />
                </ListItem>
              ))}
          </List>
          <Box>
            {searchEnable && (
              <>
                <TextField
                  sx={{
                    width: '100%',
                    maxHeight: '40px'
                  }}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>
                  }}
                  placeholder="チケット名を入力"
                  value={searchText}
                  onChange={handleSearch}
                  onFocus={(e) => {
                    if (e.currentTarget === e.target) {
                      setFocus(true);
                    }
                  }}
                  onBlur={(e) => {
                    e.preventDefault();
                    if (e.currentTarget === e.target) {
                      if (!e.relatedTarget?.classList.contains('MuiMenuItem-root')) {
                        setFocus(false);
                      }
                    }
                  }}
                />
                {focus && (
                  <Box sx={{ position: 'relative' }}>
                    <Box
                      sx={{
                        backgroundColor: 'white',
                        position: 'absolute',
                        zIndex: 1,
                        width: '100%',
                        boxShadow:
                          '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
                      }}>
                      {nodes.map((task, index) => (
                        <MenuItem
                          key={index}
                          sx={{
                            width: '100%'
                          }}
                          onClick={() => handleItemClick(task)}>
                          <Box sx={{ width: '100%' }}>
                            <Typography>{task.name || 'プロセス名'}</Typography>
                          </Box>
                        </MenuItem>
                      ))}
                      {nodes.length === 0 && (
                        <MenuItem sx={{ minWidth: 300 }}>プロセスが見つかりません。</MenuItem>
                      )}
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Box>
          <Box>
            <Button
              startIcon={<AddIcon />}
              variant="outlined"
              color="inherit"
              size="small"
              onClick={() => setSearchEnable(true)}
              sx={{ width: '40%' }}>
              紐づけるプロセスを追加
            </Button>
          </Box>
        </Stack>

        <Stack
          sx={{ backgroundColor: '#F9F8F8', p: 2, pb: 4 }}
          spacing={3}
          divider={
            <Divider
              orientation="horizontal"
              sx={{ borderStyle: 'dashed', borderColor: '#0000001F' }}
              flexItem
            />
          }>
          <Stack spacing={2}>
            {/*<Typography variant="body2">アクティビティ</Typography>*/}
            {node.property.activities?.map((activity) => (
              <Stack spacing={2} direction="row" alignItems="center">
                <Avatar sx={{ width: 28, height: 28 }}>K</Avatar>
                <Typography variant="body2">
                  {activity.text}
                  {/*<strong>水野 直</strong> がこのタスクを作成 2023/07/18 13:89*/}
                </Typography>
              </Stack>
            ))}
          </Stack>

          <Stack spacing={2}>
            <Typography variant="body2">コメント</Typography>
            {node.property.comments?.map((comment, index) => (
              <Stack
                spacing={2}
                direction="row"
                alignItems="center"
                key={index}
                sx={{ width: '100%' }}>
                <Avatar
                  sx={{
                    width: 28,
                    height: 28
                  }}>
                  {comment.creator ? <>{comment.creator.icon}</> : ''}
                </Avatar>
                <Box>
                  <Typography variant="body2">
                    {comment.creator && <span>{comment.creator.name}</span>}
                    <span style={{ marginLeft: '8px' }}>
                      {formatWithDayHourMinute(comment.createdAt)}
                    </span>
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#202123', fontSize: 14 }}>
                    {comment.text}
                  </Typography>
                </Box>
              </Stack>
            ))}
          </Stack>
          <Stack spacing={2}>
            <TextField
              multiline
              fullWidth
              variant="standard"
              minRows={3}
              value={comment}
              placeholder="コメントを追加"
              onChange={({ target: { value } }) => setComment(value)}
            />
            <Button variant="contained" onClick={() => handleAddComment()} sx={{ width: 200 }}>
              コメントを追加
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
