import React, { ChangeEvent } from 'react';
import TextField from '@mui/material/TextField';

interface Props {
  id?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  type?: string;
  size?: 'medium' | 'small';
  errors?: string[];
  margin?: 'dense' | 'normal' | 'none';
  label?: React.ReactNode;
}

const InputTextField = (props: Props) => {
  const {
    id,
    value,
    onChange,
    placeholder,
    className,
    type,
    size,
    errors,
    margin,
    label,
    ...other
  } = props;
  return (
    <>
      <TextField
        id={id}
        value={value}
        margin={margin}
        label={label}
        placeholder={placeholder}
        className={className}
        onChange={onChange}
        type={type}
        size={size}
        {...other}
      />
      {errors && (
        <div className="text-red">
          {errors.map((error, i) => (
            <div key={i}>{error}</div>
          ))}
        </div>
      )}
    </>
  );
};

export default InputTextField;
