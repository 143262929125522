import { Box, Button, InputAdornment, MenuItem, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState } from 'react';
import { GNode, GNodeProperty } from '@/__generated__/graphql';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getIconByTicketType } from '@/utils/tickets';
import { getIconByTaskType } from '@/utils/tasks';

interface Prop {
  nodes: GNode[];
  tickets: GNodeProperty[];
  selectAction: Function;
}

const NodeSearchBar = ({ nodes, tickets, selectAction }: Prop) => {
  const [searchString, setSearchString] = useState('');
  const [focus, setFocus] = useState(false);
  const [limit, setLimit] = useState(4);
  const [isShowAllItem, setIsShowAllItem] = useState(false);

  const showMoreTasks = () => {
    setLimit(getNodeByString(searchString).length);
    setIsShowAllItem(true);
  };

  const getNodeByString = (text: string): GNode[] => {
    return nodes.filter((node) => node.name.includes(text));
  };

  const getTicketByString = (text: string): GNodeProperty[] => {
    return tickets.filter((node) => node.name.includes(text));
  };

  const handleMenuItemClick = (node: GNodeProperty | GNode) => {
    setFocus(false);
    setSearchString(node.name);
    selectAction(node);
  };

  const renderContent = () => {
    const nodeCount = getNodeByString(searchString).length;
    if (nodeCount > 0) {
      if (nodeCount > 5 && !isShowAllItem) {
        return (
          <Button type="button" sx={{ width: '100%' }} onClick={showMoreTasks} variant="text">
            <KeyboardArrowDownIcon />
            <span className="font-weight-400 font-size-14">もっと表示</span>
          </Button>
        );
      }
      return <></>;
    } else {
      return <MenuItem sx={{ minWidth: 527 }}>タスクが見つかりません。</MenuItem>;
    }
  };

  return (
    <Box>
      <TextField
        id="node-search-input"
        variant="outlined"
        size="small"
        sx={{
          width: 258,
          height: 40,
          fontSize: '16px',
          fontWeight: '400',
          '& .MuiOutlinedInput-root': {
            height: 40
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="medium" />
            </InputAdornment>
          )
        }}
        placeholder="プロセス・チケットを検索"
        value={searchString}
        onFocus={(e) => {
          if (e.currentTarget === e.target) {
            setFocus(true);
          }
        }}
        onBlur={(e) => {
          e.preventDefault();
          if (e.currentTarget === e.target) {
            if (
              !e.relatedTarget?.classList.contains('MuiMenuItem-root') &&
              !e.relatedTarget?.classList.contains('MuiButtonBase-root')
            ) {
              setFocus(false);
            }
          }
        }}
        onChange={(e) => {
          setSearchString(e.target.value);
        }}
      />
      {focus && (
        <Box
          sx={{
            position: 'absolute',
            backgroundColor: 'white',
            maxHeight: '85vh',
            overflow: 'auto',
            boxShadow:
              '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
          }}>
          <Box
            className="font-size-12 font-weight-400"
            sx={{
              padding: '6px 12px',
              background: '#F5F5F5',
              width: '100%',
              display: 'block'
            }}>
            プロセス
          </Box>
          {getNodeByString(searchString)
            .slice(0, limit)
            .map((task, i) => (
              <MenuItem
                key={`project_menu_item_${i}`}
                onClick={(e) => {
                  e.preventDefault();
                  handleMenuItemClick(task);
                }}
                sx={{ minWidth: 527 }}>
                <Box sx={{ display: 'flex', gap: '8px', width: '100%' }}>
                  <div style={{ paddingTop: '2.5px' }}>{getIconByTaskType(task.type)}</div>
                  <Box
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      width: 444,
                      textOverflow: 'ellipsis'
                    }}>
                    {task.name}
                  </Box>
                </Box>
              </MenuItem>
            ))}
          {renderContent()}
          <Box
            className="font-size-12 font-weight-400"
            sx={{
              padding: '6px 12px',
              background: '#F5F5F5',
              width: '100%',
              display: 'block'
            }}>
            チケット
          </Box>
          {getTicketByString(searchString).map((ticket, i) => (
            <MenuItem
              key={`project_menu_item_${i}`}
              onClick={(e) => {
                e.preventDefault();
                handleMenuItemClick(ticket);
              }}
              sx={{ minWidth: 527 }}>
              <Box sx={{ display: 'flex', gap: '8px', width: '100%' }}>
                {ticket.kinds && ticket.kinds.length > 0 && (
                  <div style={{ paddingTop: '2.5px' }}>
                    {getIconByTicketType(ticket.kinds[0].text)}
                  </div>
                )}
                <Box
                  sx={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    width: 444,
                    textOverflow: 'ellipsis'
                  }}>
                  {ticket.name}
                </Box>
              </Box>
            </MenuItem>
          ))}
          {getTicketByString(searchString).length === 0 && (
            <MenuItem sx={{ minWidth: 527 }}>チケットが見つかりません。</MenuItem>
          )}
        </Box>
      )}
    </Box>
  );
};

export default NodeSearchBar;
