import { TICKET_PROPERTY_KIND, TICKET_PROPERTY_KIND_COLOR } from '@/types/tickets';

const initLane = (lane) => {
  return {
    ...lane,
    name: lane.name || 'アクター',
    color: lane.color || '#FEEBEE',
    type: lane.type || 'task'
  };
};

const initNode = (node, index) => {
  let name = node.name || 'プロセス名';
  if (index === 0) {
    name = node.name || '◎︎ プロセス始点';
  }

  return {
    ...node,
    name: name
  };
};

const initRelation = (relation) => {
  let newRelation = {};
  Object.keys(relation).map((k) => {
    if (k === 'property') {
      let property = { ...relation[k] };
      if (property?.positions) {
        try {
          property.positions = JSON.parse(property.positions);
        } catch (e) {}
      } else {
        property.positions = {};
      }
      newRelation[k] = property;
    } else {
      newRelation[k] = relation[k];
    }
  });

  return {
    ...newRelation
  };
};

export const formatRaw = (rootRaw) => {
  const commentLane = {
    id: 'commentLane',
    name: '説明',
    type: 'comment',
    color: '#FFF',
    freeze: true
  };

  const { columns, nodes: rootNodes, groups, rows, kinds, users } = rootRaw;
  const { columns: lanes } = columns;
  const { nodes, relations } = rootNodes;
  let tickets = [];
  nodes.forEach((node, index) => {
    if (node.tickets.length) {
      node.tickets.map((ticket) => {
        let customTicket = { ...ticket };
        if (customTicket.kinds.length > 0) {
          if (ticket.kinds[0].text === TICKET_PROPERTY_KIND.Confirm)
            customTicket.color = TICKET_PROPERTY_KIND_COLOR.Confirm;
          else if (ticket.kinds[0].text === TICKET_PROPERTY_KIND.Problem)
            customTicket.color = TICKET_PROPERTY_KIND_COLOR.Problem;
        } else customTicket.color = ticket.color || '#FFF59D';
        customTicket.parentNodeId = node.id;
        customTicket.name = ticket.name || '';
        customTicket.status = ticket.status;
        customTicket.tasks = ticket.tasks;
        tickets.push(customTicket);
      });
    }
  });

  return {
    lanes: [...lanes.map((lane) => initLane(lane)), commentLane],
    nodes: [...nodes.map((node, index) => initNode(node, index))],
    // Filtered the incorrect data if fromId and toId are the same node
    relations: [
      ...relations
        .filter((relation) => relation.fromId !== relation.toId)
        .map((relation) => initRelation(relation))
    ],
    tickets: [...tickets],
    groups: [...groups],
    rows: [...rows],
    kinds: [...kinds],
    users: [...users]
  };
};

export const formatRawTicketData = (rootRaw) => {
  const { nodes: rootNodes } = rootRaw;
  const { nodes } = rootNodes;
  let tickets = [];
  nodes.forEach((ticketNode) => {
    let customTicket = { ...ticketNode.property };
    if (customTicket.kinds.length > 0) {
      if (customTicket.kinds[0].text === TICKET_PROPERTY_KIND.Confirm)
        customTicket.color = TICKET_PROPERTY_KIND_COLOR.Confirm;
      else if (customTicket.kinds[0].text === TICKET_PROPERTY_KIND.Problem)
        customTicket.color = TICKET_PROPERTY_KIND_COLOR.Problem;
    } else customTicket.color = customTicket.color || '#FFF59D';
    customTicket.parentNodeId = ticketNode.id;
    customTicket.name = customTicket.name || '';
    customTicket.tasks = ticketNode.tasks;
    tickets.push(customTicket);
  });

  return {
    tickets: [...tickets]
  };
};
