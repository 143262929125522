import React, { SetStateAction } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Menu,
  MenuItem,
  TextField
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';
import remove from 'lodash/remove';
import { TICKET_PROPERTY_PRIORITY } from '@/types/tickets';
import { TicketItemFragment, NodePropertyStatusEnum } from '@/graphql';
import { FilterValueType } from '@/components/templates/WorkflowsTicketList';

interface Props {
  tickets: TicketItemFragment[];
  excludeViews: string[];
  setExcludeViews: (excludeViews: SetStateAction<string[]>) => void;
  filterValue: FilterValueType;
  setFilterValue: (excludeViews: SetStateAction<FilterValueType>) => void;
  handleAddTicket: () => void;
}

const views = [
  {
    label: '種類',
    name: 'type'
  },
  {
    label: 'プロセス',
    name: 'task'
  },
  {
    label: '優先順位',
    name: 'priority'
  },
  {
    label: '担当者',
    name: 'assignee'
  },
  {
    label: '作成日',
    name: 'created_at'
  }
];

const TicketListHeader = (props: Props) => {
  const { tickets, excludeViews, setExcludeViews, filterValue, setFilterValue, handleAddTicket } =
    props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [displayAnchorEl, setDisplayAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [searchString, setSearchString] = React.useState('');
  const [focus, setFocus] = React.useState(false);
  const open = Boolean(anchorEl);
  const openDisplay = Boolean(displayAnchorEl);

  const ticketFilterStatus = [
    {
      label: '全てのチケット',
      type: 'all'
    },
    {
      label: '未完了のチケット',
      type: NodePropertyStatusEnum.InProgress
    },
    {
      label: '解決したチケット',
      type: NodePropertyStatusEnum.Complete
    }
  ];

  const handleMenuItemClick = (index: number) => {
    setSelectedIndex(index);
    setFocus(false);
  };

  const getTicketsByString = (text: string): TicketItemFragment[] => {
    return tickets.filter((ticket) => ticket.name.includes(text));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDisplayMenu = () => {
    setDisplayAnchorEl(null);
  };

  const handleChange = (name: string) => {
    setExcludeViews((prevState) => {
      const newState = [...prevState];
      if (newState.includes(name)) {
        remove(newState, (view) => view === name);
      } else {
        newState.push(name);
      }
      return newState;
    });
    handleCloseDisplayMenu();
  };

  const getChipTypeColor = (type: string) => {
    if (type === NodePropertyStatusEnum.Complete) return 'yellow';
    return 'pink';
  };

  return (
    <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
      <Box>
        <TextField
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            )
          }}
          placeholder="チケットを検索"
          value={searchString}
          onFocus={(e) => {
            if (e.currentTarget === e.target) {
              setFocus(true);
            }
          }}
          onBlur={(e) => {
            e.preventDefault();
            if (e.currentTarget === e.target) {
              if (!e.relatedTarget?.classList.contains('MuiMenuItem-root')) {
                setFocus(false);
              }
            }
          }}
          onChange={(e) => {
            setSearchString(e.target.value);
          }}
        />
        {focus && (
          <Box
            sx={{
              position: 'absolute',
              backgroundColor: 'white',
              boxShadow:
                '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
              zIndex: 1100
            }}>
            {getTicketsByString(searchString).map((ticket, i) => (
              <MenuItem
                key={`project_menu_item_${i}`}
                selected={i === selectedIndex}
                onClick={(e) => {
                  e.preventDefault();
                  handleMenuItemClick(i);
                }}
                sx={{ minWidth: 300 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <Box
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      width: 200,
                      textOverflow: 'ellipsis'
                    }}>
                    {ticket.name}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1
                    }}>
                    <Box
                      sx={{
                        display: 'inline-flex',
                        backgroundColor: getChipTypeColor(ticket.type),
                        width: '16px',
                        height: '16px'
                      }}
                    />
                    <span>{ticket.type}</span>
                  </Box>
                </Box>
              </MenuItem>
            ))}
            {getTicketsByString(searchString).length === 0 && (
              <MenuItem sx={{ minWidth: 300 }}>チケットが見つかりません。</MenuItem>
            )}
          </Box>
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
        <FormControl sx={{ minWidth: 120 }}>
          <TextField
            id="filter-select-status"
            select
            value={filterValue.status === undefined ? 'all' : filterValue.status}
            size="small"
            variant="outlined"
            onChange={(e) => {
              let status: NodePropertyStatusEnum | undefined = undefined;
              if (e.target.value === NodePropertyStatusEnum.InProgress) {
                status = NodePropertyStatusEnum.InProgress;
              } else if (e.target.value === NodePropertyStatusEnum.Complete) {
                status = NodePropertyStatusEnum.Complete;
              }
              setFilterValue((prev) => ({
                ...prev,
                status: status
              }));
            }}>
            {ticketFilterStatus.map((filter) => (
              <MenuItem key={filter.type} value={filter.type}>
                {filter.label}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl sx={{ minWidth: 120 }}>
          <Button
            id="ticket-list-display"
            variant="outlined"
            disableElevation
            color="secondary"
            size="large"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            endIcon={<ArrowDropDownIcon />}>
            表示項目
          </Button>
          <Menu
            id="project-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'lock-button',
              role: 'listbox'
            }}>
            <Box sx={{ p: 1 }}>
              <Box sx={{ m: 1 }}>
                <label className="font-size-14 font-weight-400">種類</label>
                <Box sx={{ display: 'flex', ml: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterValue.confirm}
                        onClick={() => {
                          setFilterValue((prev) => ({
                            ...prev,
                            confirm: !filterValue.confirm
                          }));
                        }}
                      />
                    }
                    labelPlacement="end"
                    label="確認"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterValue.ticket}
                        onClick={() => {
                          setFilterValue((prev) => ({
                            ...prev,
                            ticket: !filterValue.ticket
                          }));
                        }}
                      />
                    }
                    labelPlacement="end"
                    label="課題"
                  />
                </Box>
              </Box>
              <Box sx={{ m: 1 }}>
                <label className="font-size-14 font-weight-400">担当者</label>
                <Box sx={{ display: 'flex', ml: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterValue.isAssignee}
                        onClick={() => {
                          setFilterValue((prev) => ({
                            ...prev,
                            isAssignee: !filterValue.isAssignee
                          }));
                        }}
                      />
                    }
                    labelPlacement="end"
                    label="自分"
                  />
                </Box>
              </Box>
              <Box sx={{ m: 1 }}>
                <label className="font-size-14 font-weight-400">作成者</label>
                <Box sx={{ display: 'flex', ml: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterValue.isAuthor}
                        onClick={() => {
                          setFilterValue((prev) => ({
                            ...prev,
                            isAuthor: !filterValue.isAuthor
                          }));
                        }}
                      />
                    }
                    labelPlacement="end"
                    label="自分"
                  />
                </Box>
              </Box>
              <Box sx={{ m: 1 }}>
                <label className="font-size-14 font-weight-400">優先順位</label>
                <Box sx={{ display: 'flex', ml: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterValue.low}
                        onClick={() => {
                          setFilterValue((prev) => ({
                            ...prev,
                            low: !filterValue.low
                          }));
                        }}
                      />
                    }
                    labelPlacement="end"
                    label={TICKET_PROPERTY_PRIORITY.LOW}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterValue.middle}
                        onClick={() => {
                          setFilterValue((prev) => ({
                            ...prev,
                            middle: !filterValue.middle
                          }));
                        }}
                      />
                    }
                    labelPlacement="end"
                    label={TICKET_PROPERTY_PRIORITY.MEDIUM}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterValue.high}
                        onClick={() => {
                          setFilterValue((prev) => ({
                            ...prev,
                            high: !filterValue.high
                          }));
                        }}
                      />
                    }
                    labelPlacement="end"
                    label={TICKET_PROPERTY_PRIORITY.HIGH}
                  />
                </Box>
              </Box>
            </Box>
          </Menu>
        </FormControl>
        <FormControl sx={{ minWidth: 120 }}>
          <Button
            id="ticket-list-filter"
            variant="outlined"
            disableElevation
            color="secondary"
            size="large"
            onClick={(e) => setDisplayAnchorEl(e.currentTarget)}
            endIcon={<ArrowDropDownIcon />}>
            テーブル表示項目
          </Button>
          <Menu
            id="project-menu"
            anchorEl={displayAnchorEl}
            open={openDisplay}
            onClose={handleCloseDisplayMenu}
            MenuListProps={{
              'aria-labelledby': 'lock-button',
              role: 'listbox'
            }}>
            {views.map((view) => {
              return (
                <MenuItem
                  key={view.name}
                  onClick={() => handleChange(view.name)}
                  disableRipple
                  sx={{ width: 200, gap: 2 }}>
                  {!excludeViews.includes(view.name) ? <CheckIcon color="secondary" /> : null}
                  <label>{view.label}</label>
                </MenuItem>
              );
            })}
          </Menu>
        </FormControl>
        <FormControl sx={{ minWidth: 120 }}>
          <Button
            type="button"
            onClick={() => handleAddTicket()}
            className="w-162"
            variant="contained"
            color="primary"
            size="medium">
            <span className="font-weight-700 font-size-14">チケットを追加</span>
          </Button>
        </FormControl>
      </Box>
    </Box>
  );
};
export default TicketListHeader;
