import { dia, util } from '@clientio/rappid';
import { ADD_BUTTON_COLOR, PART_HEIGHT, PART_WIDTH, TEXT_COLOR } from './constant';

class WorkflowAddButton extends dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: 'WorkflowAddButton',
      attrs: {
        body: {
          event: 'element:show:menu',
          fill: 'transparent',
          stroke: ADD_BUTTON_COLOR,
          strokeWidth: 2,
          strokeDasharray: '5,5',
          width: 'calc(w)',
          height: 'calc(h)',
          rx: 8
        },
        label: {
          event: 'element:show:menu',
          text: '+',
          fill: TEXT_COLOR,
          fontSize: 24,
          textVerticalAnchor: 'middle',
          x: 'calc(w / 2 - 5)',
          y: 'calc(h / 2)'
        }
      },
      columnIndex: 0
    };
  }

  preinitialize(...args) {
    this.markup = util.svg`
            <rect @selector="body" />
            <text @selector="label" />
        `;
  }

  getActions() {
    return [
      {
        action: 'add-normal',
        content: 'プロセス'
      },
      {
        action: 'add-condition',
        content: '条件分岐'
      }
      // {
      //   action: 'add-start',
      //   content: '始点'
      // },
      // {
      //   action: 'add-end',
      //   content: '終点'
      // }
    ];
  }

  constructor(...args) {
    super(...args);
    this.resize(PART_WIDTH, PART_HEIGHT);
  }
}

const createWorkflowAddButton = (config, graph) => {
  const button = new WorkflowAddButton(config);
  button.addTo(graph);
  return button;
};
export { createWorkflowAddButton, WorkflowAddButton };
