import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

interface Props {
  isOpen: boolean;
  handleRetry: () => void;
  handleSubmit: () => void;
  handleClose: () => void;
  count: number;
}

const WorkflowAIDialog = (props: Props) => {
  const { isOpen, handleRetry, handleSubmit, handleClose, count } = props;

  const handleCloseDialog = () => {
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseDialog}
      fullWidth={true}
      PaperProps={{
        sx: { width: 630, height: 90, position: 'fixed', bottom: '0px' }
      }}>
      <DialogContent sx={{ alignSelf: 'center', padding: '8px 24px' }}>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ color: '#000000DE', fontSize: '14px' }}>
          {/*{count}件のプロセスが追加されました。この内容でよければ確定を押してください。*/}
          AIにてプロセスを生成しました。
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ alignSelf: 'center' }}>
        <Button
          startIcon={<CloseIcon />}
          type="button"
          onClick={handleCloseDialog}
          variant="text"
          size="medium"
          sx={{ color: '#00000099' }}
          className="w-88">
          <span className="font-weight-700 font-size-14">取り消し</span>
        </Button>

        {/*<Button*/}
        {/*  startIcon={<ReplayIcon />}*/}
        {/*  type="button"*/}
        {/*  className="w-162"*/}
        {/*  size="medium"*/}
        {/*  variant="outlined"*/}
        {/*  sx={{ color: '#00000099', borderColor: '#00000080' }}*/}
        {/*  onClick={handleRetry}>*/}
        {/*  <span className="font-weight-700 font-size-14">再実行</span>*/}
        {/*</Button>*/}
        <Button
          startIcon={<CheckIcon />}
          type="submit"
          className="w-162"
          variant="contained"
          color="primary"
          size="medium"
          onClick={handleSubmit}
          sx={{ boxShadow: 'none' }}>
          <span className="font-weight-700 font-size-14">確定</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WorkflowAIDialog;
