import React, { ChangeEvent } from 'react';
import TextField from '@mui/material/TextField';

interface Props {
  id?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  errors?: string[];
}

const InputEmailField = (props: Props) => {
  const { id, value, onChange, placeholder, className, errors, ...other } = props;
  return (
    <>
      <TextField
        id={id}
        type="email"
        value={value}
        placeholder={placeholder}
        className={className}
        onChange={onChange}
        {...other}
      />
      {errors && (
        <div className="text-red">
          {errors.map((error, i) => (
            <div key={i}>{error}</div>
          ))}
        </div>
      )}
    </>
  );
};

export default InputEmailField;
