import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Avatar, Chip, IconButton } from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Box from '@mui/material/Box';
import { TICKET_PRIORITY_COLOR, TICKET_PROPERTY_PRIORITY } from '@/types/tickets';
import {
  GNodeProperty,
  PropertyFragment,
  NodesQuery,
  NodePropertyPriorityEnum,
  NodePropertyStatusEnum
} from '@/graphql';

interface Props {
  tickets?: NodesQuery['nodes']['nodes'];
  excludeViews: string[];
  setSelectedTicketId: (id: string) => void;
  onCheckComplete: (nodeId: string, status: NodePropertyStatusEnum) => void;
}

const getChipColor = (ticket: PropertyFragment) => {
  if (!ticket.priority) return undefined;
  if (ticket.priority === NodePropertyPriorityEnum.High) return TICKET_PRIORITY_COLOR.High;
  if (ticket.priority === NodePropertyPriorityEnum.Medium) return TICKET_PRIORITY_COLOR.Medium;
  if (ticket.priority === NodePropertyPriorityEnum.Low) return TICKET_PRIORITY_COLOR.Low;
  return 'primary';
};

const getChipTypeColor = (type: string) => {
  // if (type === TICKET_TYPE_STATUS.CONFIRM) return 'yellow';
  // ToDo ticketの種類: 確認 or 課題
  return 'pink';
};

const getLabelType = (ticket: PropertyFragment) => {
  // ToDo ticketの種類: 確認 or 課題
  return '確認';
};

const getLabelPriority = (ticket: PropertyFragment) => {
  if (ticket.priority === NodePropertyPriorityEnum.High) return TICKET_PROPERTY_PRIORITY.HIGH;
  if (ticket.priority === NodePropertyPriorityEnum.Medium) return TICKET_PROPERTY_PRIORITY.MEDIUM;
  if (ticket.priority === NodePropertyPriorityEnum.Low) return TICKET_PROPERTY_PRIORITY.LOW;
  return ticket.priority;
};

const isShowCheckIcon = (ticket: PropertyFragment) => {
  return ticket.status === NodePropertyStatusEnum.Complete;
};

const TicketTable = ({ tickets, excludeViews, setSelectedTicketId, onCheckComplete }: Props) => {
  const isShowTypeArea = !excludeViews.includes('type');
  const isShowTaskArea = !excludeViews.includes('task');
  const isShowPriorityArea = !excludeViews.includes('priority');
  const isShowAssigneeArea = !excludeViews.includes('assignee');
  const isShowCreatedAtArea = !excludeViews.includes('created_at');

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ backgroundColor: '#F5F5F5' }}>
            <TableCell></TableCell>
            <TableCell>ID</TableCell>
            <TableCell>チケット内容</TableCell>
            {isShowTypeArea && <TableCell>種類</TableCell>}
            {isShowTaskArea && <TableCell>プロセス</TableCell>}
            {isShowPriorityArea && <TableCell>優先順位</TableCell>}
            {isShowAssigneeArea && <TableCell>担当者</TableCell>}
            {isShowCreatedAtArea && <TableCell>作成日</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {tickets?.map((ticket, index) => (
            <TableRow
              key={`ticket_item_${index}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              onClick={() => {
                setSelectedTicketId(ticket.id);
              }}>
              <TableCell component="th" scope="row">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isShowCheckIcon(ticket.property)) {
                      onCheckComplete(ticket.property.nodeId, NodePropertyStatusEnum.InProgress);
                    } else {
                      onCheckComplete(ticket.property.nodeId, NodePropertyStatusEnum.Complete);
                    }
                  }}>
                  {isShowCheckIcon(ticket.property) ? (
                    <CheckCircleIcon sx={{ color: '#4CAF50' }} />
                  ) : (
                    <CheckCircleOutlineIcon />
                  )}
                </IconButton>
              </TableCell>
              <TableCell component="th" scope="row">
                {ticket.id}
              </TableCell>
              <TableCell>{ticket.name}</TableCell>
              {isShowTypeArea && (
                <TableCell>
                  <Chip
                    label={getLabelType(ticket.property)}
                    size="small"
                    variant="filled"
                    sx={{
                      color: 'white',
                      fontSize: 13,
                      fontWeight: 400,
                      padding: '3px 4px',
                      backgroundColor: getChipTypeColor(ticket.property.type)
                    }}
                  />
                </TableCell>
              )}
              {isShowTaskArea && (
                <TableCell>
                  {ticket.tasks && ticket.tasks.length > 0 ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <ListAltIcon />
                      {ticket.tasks[0].name}
                    </Box>
                  ) : (
                    ''
                  )}
                </TableCell>
              )}
              {isShowPriorityArea && (
                <TableCell>
                  <Chip
                    label={getLabelPriority(ticket.property)}
                    size="small"
                    variant="filled"
                    sx={{
                      color: 'white',
                      fontSize: 13,
                      fontWeight: 400,
                      padding: '3px 4px',
                      backgroundColor: getChipColor(ticket.property as GNodeProperty)
                    }}
                  />
                </TableCell>
              )}
              {isShowAssigneeArea && (
                <TableCell sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Avatar variant="circular"></Avatar>
                  {ticket.property.users &&
                    (ticket.property.users[0]?.name || ticket.property.users[0]?.email)}
                </TableCell>
              )}
              {isShowCreatedAtArea && <TableCell>{ticket.createdAt}</TableCell>}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TicketTable;
