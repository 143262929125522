import LoginForm, { postLoginInput } from '../../components/organisms/login/';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { auth } from 'src/firebase';
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { activityLogin } from '@/api/apis';

const LoginPage = () => {
  const navigate = useNavigate();
  const [signInWithEmailAndPassword, user, loading, error] = useSignInWithEmailAndPassword(auth);

  const handleLogin = (values: postLoginInput) => {
    signInWithEmailAndPassword(values.email, values.password).then(async (userCredential) => {
      if (userCredential) {
        const token = await auth.currentUser?.getIdToken();
        if (token) {
          await activityLogin({ emails: [values.email] }, token);
        }
        navigate('/');
      }
    });
  };
  return (
    <div className="mx-auto max-w-522">
      <LoginForm
        handleLogin={handleLogin}
        error={error?.message ? 'メールアドレスまたはパスワードが正しくありません。' : undefined}
      />
    </div>
  );
};

export default LoginPage;
