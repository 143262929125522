import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  TextField
} from '@mui/material';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useMutation } from '@apollo/client';
import { CREATE_FILE_AI_AUTO_CORRECT, GET_FILES } from '@/api/graphql';
import GenerateWorkflowAIDialog from '../GenerateWorkflowAIDialog';
import { useNavigate } from 'react-router-dom';
import ErrorAIGenerateDialog from '../ErrorDialog';
import { FileQuery } from '@/__generated__/graphql';

interface Props {
  fileId: number;
  file: FileQuery['file'] | undefined;
  nodesCount: number;
  handleClose: () => void;
}

const AutoCorrectDialog = ({ fileId, file, nodesCount, handleClose }: Props) => {
  const [fileName, setFileName] = useState(file ? `${file.name} (自動修正)` : '');
  const [query, setQuery] = useState('');
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [progress, setProgress] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [createAIAutoCorrect] = useMutation(CREATE_FILE_AI_AUTO_CORRECT);
  const navigate = useNavigate()

  const handleSubmit = async () => {
    setProgress(0);
    setIsSubmitted(true);

    const progressIncrement = 25;
    const interval = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress + progressIncrement >= 75 ? 75 : prevProgress + progressIncrement
      );
    }, 3000);

    try {
      var progress = new Promise(function (resolve, reject) {
        setTimeout(function () {
          createAIAutoCorrect({
            variables: {
              in: {
                name: fileName,
                fileId,
                modification: query
              }
            },
            refetchQueries: [GET_FILES],
            onCompleted: async (data) => {
              resolve(data.createFileAIAutoCorrect);
            },
            onError: async (err) => {
              console.error(err)
              reject(err)
            }
          });
        }, 1000);
      });

      await progress.then(
        function (generateFileId) {
          clearInterval(interval);
          setProgress(100);
          navigate(`/workflows/${generateFileId}/diagram/view?isModify=${true}&nodeCount=${nodesCount}`);
        },
        function () {
          clearInterval(interval);
          setErrorMessage(true)
        }
      );
    } catch (error) {
      clearInterval(interval);
    } finally {
      setIsSubmitted(false);
    }
  };

  if (errorMessage) {
    return (
      <ErrorAIGenerateDialog
        handleClose={handleClose}
        handleBack={() => setErrorMessage(false)}
      />
    )
  }

  return (
    <>
      {!isSubmitted ? (
        <Dialog open onClose={handleClose} hideBackdrop={false} fullWidth={true}>
          <DialogTitle className="font-size-20 font-weight-500">プロセスを自動修正</DialogTitle>
          <Divider />
          <DialogContent>
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ margin: 0, mb: '20px' }} component="p">
                現状のプロセスを自動修正して、新しいワークフローファイルを作成します。
              </Typography>
              <Box>
                <Typography
                  component="p"
                  sx={{ p: 2, bgcolor: '#F5F5F5' }}>{`現状のプロセス数：${nodesCount}`}</Typography>
              </Box>
            </Box>
            <Box sx={{ mt: '20px' }}>
              <Typography sx={{ margin: 0, mb: 1 }} component="p">
                ファイル名
              </Typography>
              <TextField
                required
                id="outlined-required"
                label="ファイル名"
                value={fileName}
                className="w-full"
                onChange={(e) => setFileName(e.target.value)}
              />
            </Box>
            <Box sx={{ mt: '20px' }}>
              <Typography sx={{ margin: 0, mb: 1 }} component="p">
              プロンプト
              </Typography>
              <TextField
                required
                multiline
                minRows={4}
                id="outlined-required"
                className="w-full"
                placeholder='牛丼屋の業務フローです。入店して、券売機で券を買って、店員に渡して、商品が届く、会計して退出'
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value)
                }}
              />
            </Box>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              type="button"
              onClick={() => handleClose()}
              variant="text"
              color="primary"
              size="medium"
              className="w-88">
              <span className="font-weight-700 font-size-14">キャンセル</span>
            </Button>
            <Button
              type="submit"
              onClick={() => handleSubmit()}
              className="w-162"
              variant="contained"
              color="primary"
              size="medium">
              <span className="font-weight-700 font-size-14">実行</span>
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <GenerateWorkflowAIDialog isOpen progress={progress} handleClose={() => {}} />
      )}
    </>
  );
};

export default AutoCorrectDialog;
