import { useRefCallback } from '../../../../hooks/useRefCallback';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isInGroup, isTargetRowLabel } from '../utils';
import { useMutation } from '@apollo/client';
import {
  ASSIGN_KIND_TO_NODE_PROPERTY,
  ASSIGN_NODE,
  CREATE_NODE,
  UPDATE_NODE_PROPERTY
} from '../../../../api/graphql';
import { TICKET_DEFAULT_COLOR } from '@/components/workflow/Canvas/elements/constant';

export const useElementPointerClick = ({
  paper,
  fileId,
  nodes,
  tickets,
  groups,
  setTickets,
  selectedRows,
  setSelectedRows,
  setActiveNode,
  setActiveTicket,
  setAddTicket,
  setActiveLane,
  activeNode,
  activeTicket,
  lastActiveNodeName,
  setLastActiveNodeName,
  lastActiveNodeMediaLabel,
  setLastActiveNodeMediaLabel,
  lastActiveNodeDesc,
  setLastActiveNodeDesc,
  kinds,
  refetch,
  refetchNodeProperty
}) => {
  const [createNode] = useMutation(CREATE_NODE);
  const [assignNode] = useMutation(ASSIGN_NODE);
  const [assignKindToNodeProperty] = useMutation(ASSIGN_KIND_TO_NODE_PROPERTY);
  const [updateNodeProperty] = useMutation(UPDATE_NODE_PROPERTY);
  let isClicked = false;

  // TODO: the root reason is it doesn't trigger the blue event when switching to another node
  // looks like the components is not re-rendered.
  const handleUpdateLastNodeName = (activeNode) => {
    if (
      activeNode &&
      (lastActiveNodeName !== null ||
        lastActiveNodeDesc !== null ||
        lastActiveNodeMediaLabel !== null)
    ) {
      const variables = {
        in: {
          nodeId: activeNode.nodeId ? activeNode.nodeId : activeNode.property.nodeId
        }
      };
      if (lastActiveNodeName !== null) {
        variables.in.name = lastActiveNodeName;
        setLastActiveNodeName(null);
      }
      if (lastActiveNodeDesc !== null) {
        variables.in.description = lastActiveNodeDesc;
        setLastActiveNodeDesc(null);
      }
      if (lastActiveNodeMediaLabel !== null) {
        variables.in.mediaLabel = lastActiveNodeMediaLabel;
        setLastActiveNodeMediaLabel(null);
      }
      updateNodeProperty({
        variables,
        onCompleted: () => {
          refetch();
        }
      });
    }
  };
  const onClickRef = useRefCallback(async (elementView, evt) => {
    if (isClicked) {
      isClicked = false;
      return;
    }
    isClicked = true;
    const currentElement = elementView.model;
    const { type } = currentElement.attributes;
    switch (type) {
      case 'WorkflowPart':
        const clickedNode = nodes.find((node) => node.id === currentElement.prop('pid'));
        if (clickedNode) {
          handleUpdateLastNodeName(activeNode ? activeNode : activeTicket);
          setActiveNode(clickedNode);
          setActiveTicket(null);
        }
        setSelectedRows([]);
        break;
      case 'WorkflowTicket':
        if (elementView.model.prop('isGhost')) {
          let newNodeId = uuidv4();
          const parentNodeId = elementView.model.prop('parentNodeId');
          const parentNode = nodes.find((node) => node.id === parentNodeId);
          if (!parentNode) return;
          // Since the response time takes time, here create the ticket in state with a temp id, but update it to real id after the response
          const tempId = uuidv4();
          setTickets((prevState) => {
            const newState = [...prevState];
            newState.push({
              nodeId: tempId,
              parentNodeId,
              name: '',
              color: TICKET_DEFAULT_COLOR
            });
            return newState;
          });
          const nodeResponse = await createNode({
            variables: {
              in: {
                fileId,
                rowIndex: parentNode.rowIndex,
                columnIndex: parentNode.columnIndex,
                type: 'ticket'
              }
            },
            onCompleted: async (data) => {
              const ticketNode = await refetchNodeProperty({
                nodeId: data.createNode
              });

              if (ticketNode && ticketNode.data.nodeProperty) {
                await assignKindToNodeProperty({
                  variables: {
                    in: { kindId: kinds[0].id, nodePropertyId: ticketNode.data.nodeProperty.id }
                  }
                });
              }
            }
          });
          newNodeId = nodeResponse.data.createNode;

          await assignNode({
            variables: {
              in: { parentNodeId, childNodeId: newNodeId }
            }
          });
          setTickets((prevState) => {
            const newState = [...prevState];
            const tempTicket = newState.find((ticket) => ticket.nodeId === tempId);
            if (tempTicket) {
              tempTicket.nodeId = newNodeId;
            }
            setAddTicket(newNodeId);
            setActiveNode(null);
            return newState;
          });
          await refetch('ticket');
        } else {
          handleUpdateLastNodeName(activeNode ? activeNode : activeTicket);
          const currentActiveTicket = tickets.find(
            (ticket) => ticket.nodeId === currentElement.prop('pid')
          );
          setActiveTicket(currentActiveTicket);
          setActiveNode(null);
        }
        setSelectedRows([]);
        break;
      case 'WorkflowLane':
        setSelectedRows([]);
        break;
      case 'WorkflowDivider':
        if (isTargetRowLabel(evt)) {
          const row = elementView.model.prop('row');
          // If row is already in a group then it can't be selected
          if (isInGroup(row, groups)) {
            return;
          }
          setSelectedRows([]);
          // -------「選択できるのは一行のみとする」関連で修正（後で確認）------
          // const index = selectedRows.indexOf(row);
          // let newState = [...selectedRows];
          // if (index === -1) {
          //   newState.push(row);
          // } else {
          //   newState.splice(index, 1);
          // }
          // if (!isNumberConsecutive(newState)) {
          //   newState = [];
          // }
          //--------------------------------------------------------
          if (activeNode) {
            handleUpdateLastNodeName(activeNode);
          }
          if (activeTicket) {
            handleUpdateLastNodeName(activeTicket);
          }
        }
      case 'WorkflowComment':
      default:
        if (activeNode) {
          handleUpdateLastNodeName(activeNode);
        }
        if (activeTicket) {
          handleUpdateLastNodeName(activeTicket);
        }
        setActiveNode(null);
        setActiveTicket(null);
    }
  });

  const onDbClickRef = useRefCallback(async (elementView, evt) => {
    const currentElement = elementView.model;
    const { type } = currentElement.attributes;
    switch (type) {
      case 'WorkflowDivider':
        if (isTargetRowLabel(evt)) {
          const row = elementView.model.prop('row');
          // If row is already in a group then it can't be selected
          if (isInGroup(row, groups)) {
            return;
          }
          const index = selectedRows.indexOf(row);
          if (index === -1) {
            setSelectedRows([row]);
          } else {
            setSelectedRows([]);
          }
          if (activeNode) {
            handleUpdateLastNodeName(activeNode);
          }
          if (activeTicket) {
            handleUpdateLastNodeName(activeTicket);
          }
        }
    }
  });
  useEffect(() => {
    if (paper) {
      paper.on('element:pointerclick', (elementView, evt) => onClickRef.current(elementView, evt));

      paper.on('element:pointerdblclick', (elementView, evt) =>
        onDbClickRef.current(elementView, evt)
      );

      paper.on('lane:plus:pointerclick', (_, evt) => {
        evt.stopPropagation();
        setActiveLane({});
      });

      // Open the side modal to edit source node after click the label on a arrow
      paper.on('link:pointerclick', (elementView, evt) => {
        evt.stopPropagation();
        const currentLink = elementView.model;
        const sourceNodeId = currentLink.prop('sourceNodeId');
        const sourceNode = nodes.find((node) => node.id === sourceNodeId);
        sourceNode && setActiveNode(sourceNode);
      });
    }
  }, [paper]);
};
