import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { File } from '@/__generated__/graphql';
import { Avatar, Menu, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useNavigate } from 'react-router-dom';
import { differenceTimeString, format } from '@/utils/dates';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

type WorkFlowMenuType = 'change' | 'cron' | 'info' | 'copy' | 'delete' | 'transfer';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />;
});

function clipboardToCopy(text: string): Promise<boolean> {
  return new Promise((resolve, error) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(
        function () {
          resolve(true);
        },
        function () {
          error();
        }
      );
    } else {
      // ToDo navigator.clipboardが利用できない場合
      error();
    }
  });
}

interface WorkFlowMenu {
  value: number;
  label: string;
  type: WorkFlowMenuType;
}

const menus: WorkFlowMenu[] = [
  {
    value: 1,
    label: '編集',
    type: 'change'
  },
  {
    value: 2,
    label: '複製',
    type: 'cron'
  },
  {
    value: 6,
    label: '移動',
    type: 'transfer'
  },
  {
    value: 3,
    label: 'ワークフロー情報',
    type: 'info'
  },
  {
    value: 4,
    label: 'リンクをコピー',
    type: 'copy'
  },
  {
    value: 5,
    label: '削除',
    type: 'delete'
  }
];

interface Props {
  file: File;
  index: number;
  showFileNameChangeDialog: (file: File) => void;
  setSelectFileForTransfer: (file: File) => void;
  cronFile: (file: File) => void;
  showFileInfoDialog: (file: File) => void;
  deleteFile: (file: File) => void;
  handleDeleteFile: () => void;
}

const WorkFlowItem = (props: Props) => {
  const navigate = useNavigate();
  const {
    file,
    index,
    showFileNameChangeDialog,
    setSelectFileForTransfer,
    cronFile,
    showFileInfoDialog,
    deleteFile,
    handleDeleteFile
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const open = Boolean(anchorEl);
  const [openAlert, setOpenAlert] = useState({
    type: '',
    isOpen: false,
    nodeId: ''
  });

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const copyFileLink = () => {
    const domainName = window.location.host;
    const url = `${domainName}/workflows/${file.id}/diagram/view`;
    clipboardToCopy(url).then((r) => {
      console.log(r);
      setOpenAlert && setOpenAlert({ type: 'CopyClipboard', isOpen: true, nodeId: '' });
    });
  };
  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    menuIndex: number,
    type: WorkFlowMenuType
  ) => {
    setSelectedIndex(menuIndex);
    setAnchorEl(null);
    switch (type) {
      case 'change':
        showFileNameChangeDialog(file);
        break;
      case 'cron':
        cronFile(file);
        break;
      case 'transfer':
        setSelectFileForTransfer(file)
        break
      case 'info':
        showFileInfoDialog(file);
        break;
      case 'copy':
        copyFileLink();
        break;
      case 'delete':
        deleteFile(file);
        handleDeleteFile();
        break;
      default:
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = () => {
    navigate(`/workflows/${file.id}/diagram/view`);
  };

  const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert({ type: '', isOpen: false, nodeId: '' });
  };

  return (
    <>
      <TableRow
        key={`file_item_${index}`}
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
          padding: '0px',
          cursor: 'pointer'
        }}
        onClick={open ? handleClose : handleClick}>
        <TableCell component="th" scope="row" sx={{ paddingLeft: '0px', width: 50 }}></TableCell>
        <TableCell
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            paddingLeft: '0px'
          }}>
          {file.name}
        </TableCell>
        <TableCell
          sx={{
            fontSize: '14px',
            fontWeight: 400
          }}>
          {differenceTimeString(file.updatedAt)}前
        </TableCell>
        <TableCell
          sx={{
            fontSize: '14px',
            fontWeight: 400
          }}>
          {format(new Date(file.createdAt), 'yyyy/MM/dd')}
        </TableCell>
        <TableCell component="th" scope="row" aria-disabled={true} sx={{ paddingLeft: '0px' }}>
          <Box
            component="li"
            sx={{
              '& > img': { mr: 2, flexShrink: 0 },
              ...(file.name === ' ' && {
                color: 'grey'
              }),
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              maxWidth: '100%',
              maxLines: 1,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: '14px',
              fontWeight: 400
            }}>
            <Avatar
              sx={{ width: 32, height: 32 }}
              variant="circular"
              src={file.creator.icon ?? '/images/grey.png'}
            />
            {file.creator.name}
          </Box>
        </TableCell>
        <TableCell component="th" scope="row">
          <IconButton
            sx={{ color: '#000000DE' }}
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              handleClickListItem(event);
            }}>
            <MoreHorizIcon />
          </IconButton>
        </TableCell>
        <Menu
          id={`workflow-menu-${index}`}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox'
          }}>
          {menus.map((menu, i) => (
            <MenuItem
              key={`workflow_menu_item_${index}_${i}`}
              sx={{ color: menu.type === 'delete' ? 'red' : '' }}
              selected={i === selectedIndex}
              onClick={(event) => {
                event.stopPropagation();
                handleMenuItemClick(event, i, menu.type);
              }}>
              {menu.label}
            </MenuItem>
          ))}
        </Menu>
      </TableRow>
      <Snackbar
        open={openAlert.isOpen}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        key={'top' + 'right'}>
        <Alert
          onClose={handleCloseAlert}
          severity={openAlert.type === 'CopyClipboard' ? 'info' : 'success'}
          sx={{
            width: '366px',
            fontSize: '16px',
            fontWeight: '500'
          }}>
          {openAlert.type === 'CopyClipboard' ? '共有リンクをコピーしました' : ''}
        </Alert>
      </Snackbar>
    </>
  );
};

export default WorkFlowItem;
