import { dia, util } from '@clientio/rappid';
import {
  ADD_LANE_WIDTH,
  LANE_HEADER_HEIGHT,
  LANE_HEADER_WIDTH,
  PART_WIDTH,
  ROW_HEIGHT,
  SIDE_WIDTH,
  SPACING,
  STROKE_COLOR
} from './constant';
import Add from '../icons/Add';
import { getLaneWidth } from '../utils';

class WorkflowPool extends dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: 'WorkflowPool',
      attrs: {
        body: {
          fill: 'transparent',
          width: '100%',
          height: '100%'
        },
        divider: {
          stroke: STROKE_COLOR,
          strokeWidth: 1,
          d: `M 0 30 H calc(w)`
        },
        plus: {
          x: 0,
          fill: STROKE_COLOR,
          width: ADD_LANE_WIDTH,
          height: '100%'
        },
        plusIcon: {
          event: 'lane:plus:pointerclick',
          x: '100%',
          y: 4,
          'xlink:href': 'data:image/svg+xml;utf8,' + encodeURIComponent(Add)
        }
      }
    };
  }

  preinitialize(...args) {
    this.markup = util.svg`
            <rect @selector="body" />
            <path @selector="divider" />
            <rect @selector="plus" />
            <image @selector="plusIcon" /> 
        `;
  }

  layoutLanes(laneElements, isShowTicket) {
    // const offset = [30];
    const offset = [0];
    let x = offset[0];
    laneElements.forEach((element) => {
      element.resize(getLaneWidth(isShowTicket), LANE_HEADER_HEIGHT);
      element.position(x, 0, { deep: true, parentRelative: true });
      x += element.size().width;
      offset.push(x);
    });
    this.layoutAddLaneButton();
    return offset;
  }

  layoutAddLaneButton() {
    const width = this.attr('body/width');
    this.attr('plusIcon/x', width - ADD_LANE_WIDTH + 7);
    this.attr('plus/x', width - ADD_LANE_WIDTH);
  }

  layoutAddLaneComment(commentElement, dividers, isShowTicket, isHasGroups) {
    const row = commentElement.prop('row');
    const column = commentElement.prop('column');

    let x = isHasGroups ? SPACING + SIDE_WIDTH + LANE_HEADER_WIDTH : SPACING + LANE_HEADER_WIDTH;
    let y = LANE_HEADER_HEIGHT;

    if (row > 0) {
      const { position, size } = dividers[row - 1].attributes;
      y = position.y + size.height;
    }
    x += getLaneWidth(isShowTicket) * column;

    commentElement.position(x, y + SPACING, { parentRelative: true });
  }

  layoutDividers(dividers, rowTicketsMap) {
    let offset = [50];
    let y = offset[0];
    dividers.forEach((divider, index) => {
      divider.position(-SIDE_WIDTH, y, { deep: true, parentRelative: true });
      const height =
        rowTicketsMap[index] >= 1
          ? ROW_HEIGHT * (rowTicketsMap[index] + 1) - 20
          : divider.size().height;
      divider.resize(this.attr('body/width'), height);
      divider.attr('body/height', height);
      y += height;
      offset.push(y);
    });
    return offset;
  }

  layoutGroup(groups, offsetY) {
    groups.forEach((group, index) => {
      const rowIndexes = group.prop('rowIndexes');
      const start = rowIndexes[0];
      const end = rowIndexes[rowIndexes.length - 1];
      const startY = offsetY.current[start];
      const endY = offsetY.current[end + 1];
      group.position(0, startY, { deep: true, parentRelative: true });
      const height = endY - startY;
      group.resize(SIDE_WIDTH, height);
    });
  }

  layoutIndividualNode(nodeElement, dividers, isShowTicket, isHasGroups) {
    const row = nodeElement.prop('row');
    const column = nodeElement.prop('column');
    let x = isHasGroups ? SPACING + SIDE_WIDTH : SPACING;
    let y = LANE_HEADER_HEIGHT;

    if (row > 0) {
      const { position, size } = dividers[row - 1].attributes;
      y = position.y + size.height;
    }
    x += getLaneWidth(isShowTicket) * column;
    nodeElement.position(x, y + SPACING, { parentRelative: true });
  }

  layoutAddButtons(buttons, dividers, isShowTicket) {
    buttons.forEach((button) => {
      const row = button.prop('rowIndex');
      const column = button.prop('columnIndex');
      const { position } = dividers[row].attributes;
      const x = button.size().width * column;
      const y = position.y + SPACING;
      button.position(x + SPACING + (getLaneWidth(isShowTicket) - PART_WIDTH) * column, y, {
        parentRelative: true
      });
    });
  }
}

const createWorkflowPool = (config) => {
  return new WorkflowPool(config);
};
export { createWorkflowPool, WorkflowPool };
