import { GNodeProperty } from '@/__generated__/graphql';
import { TICKET_PROPERTY_KIND, TICKET_PROPERTY_KIND_COLOR, TicketItemType } from '@/types/tickets';
import { NodeTypeEnum } from '@/graphql';
import React from 'react';

export const getColorKind = (ticket: GNodeProperty) => {
  if (ticket.kinds && ticket.kinds.length > 0) {
    if (ticket.kinds[0].text === TICKET_PROPERTY_KIND.Confirm)
      return TICKET_PROPERTY_KIND_COLOR.Confirm;
    else if (ticket.kinds[0].text === TICKET_PROPERTY_KIND.Problem)
      return TICKET_PROPERTY_KIND_COLOR.Problem;
  }
  return '';
};

export const getIconByTicketType = (type: string) => {
  if (type === TICKET_PROPERTY_KIND.Confirm) return <img src="/icons/confirm.svg" alt="confirm" />;
  else if (type === TICKET_PROPERTY_KIND.Problem)
    return <img src="/icons/problem.svg" alt="problem" />;
};

export const getLabelKind = (ticket: GNodeProperty) => {
  if (ticket.kinds && ticket.kinds.length > 0) {
    return ticket.kinds[0].text;
  }
  return '';
};

export const getNameTask = (ticket: TicketItemType) => {
  if (ticket.tasks && ticket.tasks.length > 0) {
    return ticket.tasks[0].name;
  }
  return '';
};

export const getUserName = (ticket: GNodeProperty) => {
  if (ticket.users && ticket.users.length > 0) {
    return ticket.users[0].name;
  }
  return '';
};
