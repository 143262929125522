import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import { ApolloError, useMutation } from '@apollo/client';
import { GET_ALL_IN_WORKFLOW, GET_USERS, INVITE_TO_SPACE } from '@/api/graphql';
import { useParams } from 'react-router-dom';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

const InviteMemberDialog = (props: Props) => {
  let { spaceId } = useParams();
  const { isOpen, handleClose } = props;
  const [emails, setEmails] = useState<string[]>(['', '', '']);
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const [inviteToSpace] = useMutation(INVITE_TO_SPACE, { refetchQueries: [GET_USERS] });
  const handleInviteMember = async () => {
    const invitedEmail = emails.filter((email) => email !== '');
    if (spaceId && invitedEmail.length > 0) {
      await inviteToSpace({
        variables: {
          in: {
            emails: invitedEmail,
            spaceId: spaceId
          }
        },
        onCompleted: () => {
          setError(undefined);
          handleClose();
        },
        onError: (errors) => {
          setError(errors);
        }
      });
    }
  };

  const handleAddEmail = () => {
    setEmails((prev) => [...prev, '']);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth={true}>
      <DialogTitle className="font-size-20 font-weight-500">メンバーを招待</DialogTitle>
      <Divider />
      <DialogContent>
        <div className="font-size-14 font-weight-400">
          メールアドレスを入力して、メンバーを招待します。後からメンバーを招待することも可能です。
        </div>
        {error && <Box sx={{ color: 'red', mt: 1 }}>{error.message}</Box>}
        <Box sx={{ mt: 1 }}>
          {emails.map((item, index) => (
            <Box sx={{ pt: 1, pb: 1 }} key={`invite_email_${index}`}>
              <TextField
                id={`email_item_${index}`}
                value={item}
                onChange={(e) => {
                  setEmails((prev) => {
                    let newEmails = [...prev];
                    newEmails[index] = e.target.value;
                    return newEmails;
                  });
                }}
                placeholder="Email"
                className="w-full"
                type="email"
                size="medium"
                variant="outlined"
                label="Email"
              />
            </Box>
          ))}
        </Box>
        <Box>
          <Button
            type="button"
            onClick={handleAddEmail}
            variant="text"
            color="primary"
            size="large">
            <AddIcon />
            <span className="font-weight-700 font-size-15">招待枠を追加</span>
          </Button>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          type="button"
          onClick={handleClose}
          variant="text"
          color="primary"
          size="medium"
          className="w-88">
          <span className="font-weight-700 font-size-14">スキップ</span>
        </Button>
        <Button
          type="button"
          onClick={() => handleInviteMember()}
          className="w-162"
          variant="contained"
          color="primary"
          size="medium"
          sx={{ boxShadow: 'none' }}>
          <span className="font-weight-700 font-size-14">メンバーを招待</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InviteMemberDialog;
