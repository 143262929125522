import React from 'react';
import { Box, IconButton } from '@mui/material';
import { GNodeProperty } from '@/__generated__/graphql';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { NodePropertyStatusEnum } from '@/graphql';
import { SmsOutlined } from '@mui/icons-material';

interface Props {
  ticket: GNodeProperty;
  showTicketInfo: (ticketId: string) => void;
  isShowCheckBoxArea: boolean;
  onCheckComplete: (nodeId: string, status: NodePropertyStatusEnum) => void;
  maxWidth: number;
}

const NodeTicketItem = (props: Props) => {
  const { ticket, showTicketInfo, isShowCheckBoxArea, onCheckComplete, maxWidth = 0 } = props;
  // ToDo isShowCheckBoxArea TicketのTypeの確認（問題、確認)
  // 問題はTrue
  // 確認はFalse

  const statusCheckIcon = (ticket: GNodeProperty) => {
    return ticket.status === NodePropertyStatusEnum.Complete;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        justifyContent: 'space-between',
        height: 32,
        '&:hover': {
          backgroundColor: '#0000000A'
        },
        padding: '4px 2px',
        maxWidth: maxWidth
      }}>
      {isShowCheckBoxArea && (
        <>
          <IconButton
            sx={{ padding: 0 }}
            onClick={(e) => {
              e.stopPropagation();
              if (statusCheckIcon(ticket)) {
                onCheckComplete(ticket.nodeId, NodePropertyStatusEnum.InProgress);
              } else {
                onCheckComplete(ticket.nodeId, NodePropertyStatusEnum.Complete);
              }
            }}>
            {statusCheckIcon(ticket) ? (
              <CheckCircleIcon
                sx={{
                  color: '#4CAF50',
                  height: 24,
                  width: 24
                }}
              />
            ) : (
              <CheckCircleOutlineIcon
                sx={{
                  '&:hover': {
                    color: '#4CAF50'
                  },
                  height: 24,
                  width: 24
                }}
                fontSize="medium"
              />
            )}
          </IconButton>
        </>
      )}
      <Typography
        sx={{
          width: '75%',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          cursor: 'pointer',
          paddingTop: '2px',
          fontSize: 14,
          opacity: statusCheckIcon(ticket) ? '36%' : null
        }}
        onClick={() => showTicketInfo(ticket.nodeId)}>
        {ticket.name}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <SmsOutlined sx={{ width: 16, height: 16 }} />
        {ticket.comments?.length ?? 111}
      </Box>
      <ChevronRightIcon
        sx={{ cursor: 'pointer', width: 16, height: 16, marginTop: '4px' }}
        onClick={() => showTicketInfo(ticket.nodeId)}
      />
    </Box>
  );
};
export default NodeTicketItem;
