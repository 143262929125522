import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

interface Props {
  isOpen: boolean;
  handleRetry: () => void;
  handleSubmit: () => void;
  handleClose: () => void;
  count: number;
  previousCount: number;
}

const WorkflowAIModifyDialog = (props: Props) => {
  const { isOpen, handleSubmit, handleClose, count, previousCount } = props;

  const handleCloseDialog = () => {
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth={true}
      PaperProps={{
        sx: { width: 630, position: 'fixed', bottom: '0px' }
      }}>
      <DialogContent sx={{ alignSelf: 'center', padding: '8px 24px' }}>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ color: '#000000DE', fontSize: '14px', textAlign: 'center', whiteSpace: 'pre-line' }}>
          {`自動修正ファイルを作成しました。\nこの内容でよければ確定を押してください。`}
        </DialogContentText>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ color: '#00000099', fontSize: '14px' }}>
          {`プロセス（実行前）：${previousCount}　→ プロセス（実行後）：${count}`}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ alignSelf: 'center' }}>
        <Button
          startIcon={<CloseIcon />}
          type="button"
          onClick={handleCloseDialog}
          variant="text"
          size="medium"
          sx={{ color: '#00000099' }}
          className="w-88">
          <span className="font-weight-700 font-size-14">取り消し</span>
        </Button>

        <Button
          startIcon={<CheckIcon />}
          type="submit"
          className="w-162"
          variant="contained"
          color="primary"
          size="medium"
          onClick={handleSubmit}>
          <span className="font-weight-700 font-size-14">確定</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WorkflowAIModifyDialog;
