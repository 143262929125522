import { format as dateFnsFormat } from 'date-fns';
import { ja } from 'date-fns/locale';

export const format = (date: Date, format: string) => {
  const options = {
    locale: ja
  };
  return dateFnsFormat(date, format, options);
};

export const formatWithDay = (date: string) => {
  if (!date) return '';
  return format(new Date(date), 'yyyy/MM/dd');
};

export const formatWithDayHourMinute = (date: string) => {
  if (!date) return '';
  return format(new Date(date), 'yyyy/MM/dd HH:mm');
};

export const differenceTimes = (updatedAt: string) => {
  const milliDiff: number = new Date().getTime() - new Date(updatedAt).getTime();
  return Math.floor((milliDiff < 0 ? 0 : milliDiff) / 1000 / 60 / 60);
};

export const differenceTimeString = (date: string): string => {
  const hours = differenceTimes(date);
  const days: number = Math.floor(hours / 24);
  const months: number = Math.floor(days / 31);
  const years: number = Math.floor(months / 12);
  if (days > 0) {
    if (months > 0) {
      if (years > 0) {
        return `${years}年`;
      }
      return `${months}ヶ月`;
    }
    return `${days}日`;
  }
  if (hours) return `${hours}時間`;
  else return '0時間';
};
