import React, { SetStateAction } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Menu,
  MenuItem,
  TextField
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { TASK_PRIORITY, FilterValueType, TaskItemType } from '@/types/tasks';
import CheckIcon from '@mui/icons-material/Check';
import remove from 'lodash/remove';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface Props {
  tasks: TaskItemType[];
  excludeViews: string[];
  setExcludeViews: (excludeViews: SetStateAction<string[]>) => void;
  filterValue: FilterValueType;
  setFilterValue: (excludeViews: SetStateAction<FilterValueType>) => void;
  selectedTask: (taskId: string) => void;
  isSidebarShowing: boolean;
}

const views = [
  {
    label: '説明',
    name: 'description'
  },
  {
    label: '工数',
    name: 'timeValue'
  },
  {
    label: '利用ツール',
    name: 'media'
  },
  {
    label: 'レーン',
    name: 'lane'
  }
];

const TaskListHeader = (props: Props) => {
  const {
    tasks,
    excludeViews,
    setExcludeViews,
    filterValue,
    setFilterValue,
    selectedTask,
    isSidebarShowing
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [displayAnchorEl, setDisplayAnchorEl] = React.useState<null | HTMLElement>(null);
  const [searchString, setSearchString] = React.useState('');
  const [focus, setFocus] = React.useState(false);
  const open = Boolean(anchorEl);
  const openDisplay = Boolean(displayAnchorEl);

  const handleMenuItemClick = (id: string) => {
    selectedTask(id);
    setFocus(false);
  };

  const getTasksByString = (text: string): TaskItemType[] => {
    return tasks.filter((task) => task.name.includes(text));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDisplayMenu = () => {
    setDisplayAnchorEl(null);
  };

  const handleChange = (name: string) => {
    setExcludeViews((prevState) => {
      const newState = [...prevState];
      if (newState.includes(name)) {
        remove(newState, (view) => view === name);
      } else {
        newState.push(name);
      }
      return newState;
    });
    handleCloseDisplayMenu();
  };

  return (
    <Box className="canvas-header" style={{ left: !isSidebarShowing ? 0 : 160 }}>
      <Box>
        <TextField
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            )
          }}
          placeholder="プロセスを検索"
          value={searchString}
          sx={{
            '& .MuiOutlinedInput-root': {
              height: 40
            }
          }}
          onFocus={(e) => {
            if (e.currentTarget === e.target) {
              setFocus(true);
            }
          }}
          onBlur={(e) => {
            e.preventDefault();
            if (e.currentTarget === e.target) {
              if (!e.relatedTarget?.classList.contains('MuiMenuItem-root')) {
                setFocus(false);
              }
            }
          }}
          onChange={(e) => {
            setSearchString(e.target.value);
          }}
        />
        {focus && (
          <Box
            sx={{
              position: 'absolute',
              backgroundColor: 'white',
              zIndex: 1,
              boxShadow:
                '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
            }}>
            {getTasksByString(searchString).map((task, i) => (
              <MenuItem
                key={`project_menu_item_${i}`}
                onClick={(e) => {
                  e.preventDefault();
                  handleMenuItemClick(task.id);
                }}
                sx={{ minWidth: 300 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <Box
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      width: 200,
                      textOverflow: 'ellipsis'
                    }}>
                    {task.name}
                  </Box>
                  <div>{task.type}</div>
                </Box>
              </MenuItem>
            ))}
            {getTasksByString(searchString).length === 0 && (
              <MenuItem sx={{ minWidth: 300 }}>プロセスが見つかりません。</MenuItem>
            )}
          </Box>
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
        <FormControl>
          <Button
            id="customized-button"
            sx={{
              height: 40,
              borderColor: '#0000003B',
              color: '#00000099',
              fontSize: '14px',
              padding: '9px 12px'
            }}
            size="small"
            aria-controls={open ? 'customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            disableElevation
            onClick={(e) => setAnchorEl(e.currentTarget)}
            variant="outlined"
            endIcon={<KeyboardArrowDownIcon />}>
            表示項目
          </Button>
          <Menu
            id="project-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'lock-button',
              role: 'listbox'
            }}>
            <Box sx={{ p: 1 }}>
              <Box sx={{ m: 1 }}>
                <label className="font-size-14 font-weight-400">種類</label>
                {/*「表示項目」のフィルタの種類の始点・終点の削除関連で修正（後で確認）*/}
                {/*<Box sx={{ display: 'flex', ml: 1 }}>*/}
                {/*  <FormControlLabel*/}
                {/*    control={*/}
                {/*      <Checkbox*/}
                {/*        checked={filterValue.start}*/}
                {/*        onClick={() => {*/}
                {/*          setFilterValue((prev) => ({*/}
                {/*            ...prev,*/}
                {/*            start: !filterValue.start*/}
                {/*          }));*/}
                {/*        }}*/}
                {/*      />*/}
                {/*    }*/}
                {/*    labelPlacement="end"*/}
                {/*    label="始点"*/}
                {/*  />*/}
                {/*  <FormControlLabel*/}
                {/*    control={*/}
                {/*      <Checkbox*/}
                {/*        checked={filterValue.end}*/}
                {/*        onClick={() => {*/}
                {/*          setFilterValue((prev) => ({*/}
                {/*            ...prev,*/}
                {/*            end: !filterValue.end*/}
                {/*          }));*/}
                {/*        }}*/}
                {/*      />*/}
                {/*    }*/}
                {/*    labelPlacement="end"*/}
                {/*    label="終点"*/}
                {/*  />*/}
                {/*</Box>*/}
                <Box sx={{ display: 'flex', ml: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterValue.task}
                        onClick={() => {
                          setFilterValue((prev) => ({
                            ...prev,
                            task: !filterValue.task
                          }));
                        }}
                      />
                    }
                    labelPlacement="end"
                    label="プロセス"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterValue.condition}
                        onClick={() => {
                          setFilterValue((prev) => ({
                            ...prev,
                            condition: !filterValue.condition
                          }));
                        }}
                      />
                    }
                    labelPlacement="end"
                    label="条件分岐"
                  />
                </Box>
              </Box>
              <Box sx={{ m: 1 }}>
                <label className="font-size-14 font-weight-400">作成者</label>
                <Box sx={{ display: 'flex', ml: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterValue.isAuthor}
                        onClick={() => {
                          setFilterValue((prev) => ({
                            ...prev,
                            isAuthor: !filterValue.isAuthor
                          }));
                        }}
                      />
                    }
                    labelPlacement="end"
                    label="自分"
                  />
                </Box>
              </Box>
              <Box sx={{ m: 1 }}>
                <label className="font-size-14 font-weight-400">優先順位</label>
                <Box sx={{ display: 'flex', ml: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterValue.low}
                        onClick={() => {
                          setFilterValue((prev) => ({
                            ...prev,
                            low: !filterValue.low
                          }));
                        }}
                      />
                    }
                    labelPlacement="end"
                    label={TASK_PRIORITY.LOW}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterValue.medium}
                        onClick={() => {
                          setFilterValue((prev) => ({
                            ...prev,
                            medium: !filterValue.medium
                          }));
                        }}
                      />
                    }
                    labelPlacement="end"
                    label={TASK_PRIORITY.MEDIUM}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterValue.high}
                        onClick={() => {
                          setFilterValue((prev) => ({
                            ...prev,
                            high: !filterValue.high
                          }));
                        }}
                      />
                    }
                    labelPlacement="end"
                    label={TASK_PRIORITY.HIGH}
                  />
                </Box>
              </Box>
            </Box>
          </Menu>
        </FormControl>
        <FormControl sx={{ minWidth: 120 }}>
          <Button
            id="customized-button"
            sx={{
              height: 40,
              borderColor: '#0000003B',
              color: '#00000099',
              fontSize: '14px',
              padding: '9px 12px'
            }}
            size="small"
            aria-controls={openDisplay ? 'customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openDisplay ? 'true' : undefined}
            disableElevation
            onClick={(e) => setDisplayAnchorEl(e.currentTarget)}
            variant="outlined"
            endIcon={<KeyboardArrowDownIcon />}>
            テーブル表示項目
          </Button>

          <Menu
            id="project-menu"
            anchorEl={displayAnchorEl}
            open={openDisplay}
            onClose={handleCloseDisplayMenu}
            MenuListProps={{
              'aria-labelledby': 'lock-button',
              role: 'listbox'
            }}>
            {views.map((view) => {
              return (
                <MenuItem
                  key={view.name}
                  onClick={() => handleChange(view.name)}
                  disableRipple
                  sx={{ width: 200, gap: 2 }}>
                  {!excludeViews.includes(view.name) ? (
                    <CheckIcon />
                  ) : (
                    <Box style={{ width: 24, height: 24 }}></Box>
                  )}
                  <label>{view.label}</label>
                </MenuItem>
              );
            })}
          </Menu>
        </FormControl>
      </Box>
    </Box>
  );
};
export default TaskListHeader;
