import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import TicketListHeader from '../../../molecules/workflows/TicketListHeader';
import TicketTable from '../../../molecules/workflows/TicketTable';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  ASSIGN_KIND_TO_NODE_PROPERTY,
  CREATE_NODE,
  GET_ALL_IN_WORKFLOW,
  GET_NODE_PROPERTY,
  UPDATE_NODE_PROPERTY
} from '@/api/graphql';
import { formatRaw } from '@/components/workflow/Canvas/utils/init';
import { TicketPartEditor } from '@/components/TicketPartEditor';
import { Modal } from '@mui/material';
import {
  NodePropertyPriorityEnum,
  NodePropertyStatusEnum,
  NodeTypeEnum,
  User
} from '@/__generated__/graphql';
import { TICKET_PROPERTY_STATUS, TicketItemType } from '@/types/tickets';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '90%',
  bgcolor: 'background.paper',
  border: '2px solid #FFFFFF',
  borderRadius: 1
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />;
});

export type FilterValueType = {
  confirm: boolean;
  ticket: boolean;
  isAuthor: boolean;
  isAssignee: boolean;
  low: boolean;
  middle: boolean;
  high: boolean;
  status: NodePropertyStatusEnum | 'all';
  kind: string[] | undefined;
  selectedUser: User | undefined;
};

const WorkflowTicketListPage = ({ isSidebarShowing = true }) => {
  let { workflowId } = useParams();
  const fileId = workflowId as string;
  const { loading, error, data, refetch } = useQuery(GET_ALL_IN_WORKFLOW, {
    variables: {
      nodeFilter: { fileId, types: [NodeTypeEnum.Ticket] },
      columnFilter: { fileId },
      groupFilter: { fileId },
      rowFilter: { fileId },
      kindFilter: { fileId },
      usersFilter: { fileId }
    },
    fetchPolicy: 'network-only'
  });
  const { refetch: refetchNodeProperty } = useQuery(GET_NODE_PROPERTY, { skip: true });
  const [updateProperty] = useMutation(UPDATE_NODE_PROPERTY, {
    refetchQueries: [GET_ALL_IN_WORKFLOW]
  });
  const [createNode] = useMutation(CREATE_NODE);
  const [assignKindToNodeProperty] = useMutation(ASSIGN_KIND_TO_NODE_PROPERTY);
  const [excludeViews, setExcludeViews] = useState<string[]>([]);
  const [addedTicketId, setAddedTicketId] = useState('');
  const [showZoomEditor, setShowZoomEditor] = useState(false);
  const [isSidePickerInput, setIsSidePickerInput] = useState(false);
  const [filterValue, setFilterValue] = useState<FilterValueType>({
    confirm: true,
    ticket: true,
    isAuthor: true,
    isAssignee: true,
    low: true,
    middle: true,
    high: true,
    status: 'all',
    kind: undefined,
    selectedUser: undefined
  });
  const [activeTicket, setActiveTicket] = useState<TicketItemType | null>(null);
  const [openAlert, setOpenAlert] = useState({
    type: '',
    isOpen: false,
    nodeId: ''
  });
  const filterUsers: User[] = [];

  useEffect(() => {
    if (loading) return;
    if (activeTicket) {
      const { nodes: node_tickets } = formatRaw(data);
      const tickets = node_tickets.map((node_ticket) => {
        const node_ticket_property = node_ticket.property;
        let customTicket = { ...node_ticket_property };
        // customTicket.id = node_ticket.id;
        customTicket.parentNodeId = node_ticket.id;
        customTicket.name = node_ticket_property.name || '';
        customTicket.color = node_ticket_property.color || '#FFF59D';
        customTicket.status = node_ticket_property.status;
        customTicket.tasks = node_ticket.tasks;
        return customTicket;
      });
      setActiveTicket(tickets?.find((ticket) => ticket.id === activeTicket.id));
    }
    if (addedTicketId !== '') {
      setActiveTicket(tickets?.find((ticket) => ticket.nodeId === addedTicketId));
      setAddedTicketId('');
    }
    if (filterValue.kind === undefined) {
      const kinds = data?.kinds.map((kind) => kind.id);
      setFilterValue((prev) => ({
        ...prev,
        kind: kinds
      }));
    }
  }, [data]);

  if (loading)
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '95px' }}>
        <CircularProgress size={24} />
      </Box>
    );

  const { nodes: node_tickets, kinds, users } = formatRaw(data);
  const tickets = node_tickets.map((node_ticket) => {
    if (
      node_ticket.property.users &&
      node_ticket.property.users.length > 0 &&
      node_ticket.property.users[0].email
    ) {
      const filterUser = users.filter((user) => user.email === node_ticket.property.users[0].email);
      if (filterUser.length > 0) {
        const isUserAlreadyIncluded = filterUsers.some(
          (existingUser) => existingUser.email === filterUser[0].email
        );
        if (!isUserAlreadyIncluded) {
          filterUsers.push(filterUser[0]);
        }
      }
    }
    const node_ticket_property = node_ticket.property;
    let customTicket = { ...node_ticket_property };
    customTicket.parentNodeId = node_ticket.id;
    customTicket.name = node_ticket_property.name || '';
    customTicket.color = node_ticket_property.color || '#FFF59D';
    customTicket.status = node_ticket_property.status;
    customTicket.tasks = node_ticket.tasks;
    customTicket.createdAt = node_ticket.createdAt;
    customTicket.updatedAt = node_ticket.updatedAt;
    return customTicket;
  });

  const filteredTicket = (): TicketItemType[] => {
    return tickets.filter((ticket) => {
      //　表示項目の優先順位
      if (!filterValue.low && ticket.priority === NodePropertyPriorityEnum.Low) return false;
      if (!filterValue.middle && ticket.priority === NodePropertyPriorityEnum.Medium) return false;
      if (!filterValue.high && ticket.priority === NodePropertyPriorityEnum.High) return false;
      //　全てのチケットのstatus
      if (filterValue.status !== 'all') {
        if (
          filterValue.status === NodePropertyStatusEnum.Complete &&
          ticket.status !== TICKET_PROPERTY_STATUS.Complete
        )
          return false;
        if (
          filterValue.status === NodePropertyStatusEnum.InProgress &&
          ticket.status !== TICKET_PROPERTY_STATUS.InProgress
        )
          return false;
      }

      // 種類のfilter
      if (ticket.kinds && ticket.kinds.length > 0) {
        if (filterValue.kind && filterValue.kind.indexOf(ticket.kinds[0].id) < 0) return false;
      }

      if (filterValue.selectedUser) {
        const creater = ticket.users.find((user: User) => filterValue.selectedUser?.id === user.id);
        return !!creater;
      }
      // ToDo 担当者,作成者, 種類のfilterを追加

      return true;
    });
  };

  const handleAddTicket = () => {
    createNode({
      variables: {
        in: {
          fileId,
          rowIndex: 0,
          columnIndex: 0,
          type: NodeTypeEnum.Ticket
        }
      },
      onCompleted: async (data) => {
        setAddedTicketId(data.createNode);
        const ticketNode = await refetchNodeProperty({
          nodeId: data.createNode
        });

        if (ticketNode && ticketNode.data.nodeProperty) {
          assignKindToNodeProperty({
            variables: {
              in: { kindId: kinds[0].id, nodePropertyId: ticketNode.data.nodeProperty.id }
            },
            onCompleted: () => {
              refetch();
            }
          });
        }
      }
    });
  };

  const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
    const isOutsideTicketList = (event.target as HTMLElement).classList.contains('h-full');
    if (isOutsideTicketList && activeTicket) {
      setActiveTicket(null);
    }
  };

  const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert({ type: '', isOpen: false, nodeId: '' });
  };

  const handleOpenZoomEditor = (node: any) => {
    // setActiveTicket(node);
    setShowZoomEditor(true);
  };

  return (
    <Box className="h-full text-left" onClick={handleClickOutside}>
      <TicketListHeader
        tickets={tickets}
        excludeViews={excludeViews}
        setExcludeViews={setExcludeViews}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        handleAddTicket={handleAddTicket}
        selectedTicket={(ticketId) =>
          setActiveTicket(tickets?.find((ticket) => ticket.id === ticketId))
        }
        kinds={kinds}
        users={filterUsers}
        isSidebarShowing={isSidebarShowing}
      />
      <TicketTable
        tickets={filteredTicket()}
        selectedTicket={activeTicket}
        excludeViews={excludeViews}
        setSelectedTicket={(ticket) => {
          setActiveTicket(ticket);
          setShowZoomEditor(true);
        }}
        onCheckComplete={async (nodeId: string, status: NodePropertyStatusEnum) => {
          return updateProperty({
            variables: {
              in: {
                nodeId,
                status
              }
            },
            onCompleted: () => {
              setOpenAlert({ type: status, isOpen: true, nodeId: nodeId });
            }
          });
        }}
      />

      <Modal open={showZoomEditor && activeTicket !== null}>
        {showZoomEditor && activeTicket ? (
          <Box sx={style}>
            <TicketPartEditor
              detailPage={true}
              nodes={tickets}
              node={activeTicket}
              users={users}
              setActiveNode={() => {}}
              setTickets={() => {}}
              kinds={kinds}
              setLastActiveNodeName={() => {}}
              setLastActiveNodeDesc={() => {}}
              isSidePickerInput={isSidePickerInput}
              setIsSidePickerInput={setIsSidePickerInput}
              refetchFlag={true}
              refetchAllNodes={() => {
                refetch();
              }}
              setOpenAlert={setOpenAlert}
              handleCloseEditor={() => setShowZoomEditor(false)}
            />
          </Box>
        ) : (
          <></>
        )}
      </Modal>
      <Snackbar
        open={openAlert.isOpen}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        key={'top' + 'right'}>
        <Alert
          onClose={handleCloseAlert}
          severity={openAlert.type === 'CopyClipboard' ? 'info' : 'success'}
          sx={{
            width: '366px',
            fontSize: '16px',
            fontWeight: '500'
          }}>
          {openAlert.type === 'CopyClipboard'
            ? '共有リンクをコピーしました'
            : openAlert.type === NodePropertyStatusEnum.Complete
            ? `${openAlert.nodeId}を完了にしました`
            : `${openAlert.nodeId}を未完了に戻しました`}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default WorkflowTicketListPage;
