import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Sidebar from './components/sidebar';
import DefaultLayout from './components/template/DefaultLayout';
import React, { useEffect } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from './firebase';
import { Space } from '@/__generated__/graphql';
import { useQuery } from '@apollo/client';
import { GET_SPACES } from './api/graphql';
import Box from '@mui/material/Box';
import SpaceTitle from './components/molecules/spaces/SpaceTitle';
import { RootProvider } from '@/context/RootProvider';
import TagManager from 'react-gtm-module';

const theme = createTheme({
  typography: {
    htmlFontSize: 14,
    fontSize: 12
  },
  palette: {
    secondary: {
      main: '#1C1F21',
      light: '#424242'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '12px'
        }
      }
    }
  }
});

const SPACE_CREATE_URL = '/spaces/create';

function Root() {
  const navigate = useNavigate();
  const location = useLocation();
  const { workflowId, spaceId, projectId } = useParams();
  const [spaces, setSpaces] = React.useState<Space[]>([]);

  const { loading, data } = useQuery(GET_SPACES, {
    variables: { filter: { page: 1, limit: 10 } }
  });

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user === null) navigate('/login');
      if (user) {
        const tagManagerArgs = {
          dataLayer: {
            user_id: user.uid,
            js: new Date(),
            config: process.env.REACT_APP_GA_ID || '',
          }
        };
        TagManager.dataLayer(tagManagerArgs);
      }
    });
  }, []);

  useEffect(() => {
    if (loading) return;
    if (data && data.spaces && data.spaces.nodes) {
      setSpaces(data.spaces.nodes);
      if (!spaceId && !projectId && !workflowId) {
        if (location.pathname !== SPACE_CREATE_URL && data.spaces.nodes.length > 0) {
          navigate(`/spaces/${data.spaces.nodes[0].id}`);
        }
      }
    }
  }, [data, loading]);

  const handleSignOut = async () => {
    await signOut(auth);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RootProvider>
        <div id="sidebar">{workflowId ? <Sidebar /> : <DefaultLayout spaces={spaces} />}</div>
        <div id={workflowId ? 'container' : 'container_default'}>
          {!loading && spaces.length === 0 && location.pathname === '/' && (
            <Box className="h-full">
              <Box sx={{ p: 4 }}>
                <SpaceTitle
                  title="スペースに所属していません"
                  subTitle="左のサイドバーからスペースを作成しましょう"
                  handleSignOut={handleSignOut}
                />
              </Box>
            </Box>
          )}
          <Outlet />
        </div>
      </RootProvider>
    </ThemeProvider>
  );
}

export default Root;
