import React from 'react';
import InputEmailField from '../../molecules/form/InputEmailField';
import InputPasswordField from '../../molecules/form/InputPasswordField';
import { Link } from '@mui/material';
import { useFormik } from 'formik';
import { postLogin } from '@/foundations/validationSchemas/auth';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export interface postLoginInput {
  email: string;
  password: string;
}

interface Props {
  error?: string | undefined;
  handleLogin: (input: postLoginInput) => void;
}

const LoginForm = ({ error, handleLogin }: Props) => {
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    } as postLoginInput,
    validationSchema: postLogin,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      await handleSubmit(values);
    }
  });

  const handleSubmit = (values: postLoginInput) => {
    return handleLogin({ email: values.email, password: values.password });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box sx={{ p: 2, mt: 9 }}>
        <p className="text-center font-weight-700 font-size-24">ログイン</p>
        {error && (
          <p className="text-center font-weight-500 font-size-14" style={{ color: '#D32F2F' }}>
            {error}
          </p>
        )}

        <Box sx={{ mt: 2 }}>
          <InputEmailField
            id="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            placeholder="メールアドレス"
            className="w-full"
            errors={formik.errors.email ? [formik.errors.email] : []}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <InputPasswordField
            id="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            placeholder="パスワード"
            className="w-full"
            errors={formik.errors.password ? [formik.errors.password] : []}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Button
            type="submit"
            className="w-full"
            variant="contained"
            color="primary"
            size="large"
            sx={{ boxShadow: 'none' }}>
            ログイン
          </Button>
        </Box>
        <div>
          <p>
            {/*「新規アカウント作成」リンクを削除関連で修正（後で確認）*/}
            {/*<Link href={'/new-register'} color="primary">*/}
            {/*  新規アカウント作成*/}
            {/*</Link>{' '}*/}
            <Link href={'/forgot-password'} color="primary">
              パスワードを忘れた方はこちら
            </Link>
          </p>
        </div>
      </Box>
    </form>
  );
};

export default LoginForm;
