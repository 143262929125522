import React, { ChangeEvent, useEffect, useState } from 'react';
import { Avatar, Grid, Divider, Button, Box } from '@mui/material';
import InputTextFieldWithLabel from '../../../atoms/InputTextFieldWithLabel';
import { useMutation } from '@apollo/client';
import { DELETE_SPACE, GET_SPACES, UPDATE_SPACE, GET_SPACE } from '@/api/graphql';
import { useNavigate } from 'react-router-dom';
import { Space } from '@/__generated__/graphql';
import DeleteConfirmDialog from 'src/components/organisms/modal/deleteConfirmDialog';

interface Props {
  space: Space;
}

const SpaceSetting = (props: Props) => {
  const { space } = props;
  const navigate = useNavigate();
  const [deleteSpace] = useMutation(DELETE_SPACE, {
    refetchQueries: [GET_SPACES]
  });
  const [updateSpace] = useMutation(UPDATE_SPACE, {
    refetchQueries: [GET_SPACES, GET_SPACE]
  });

  const [spaceName, setSpaceName] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (space) {
      setSpaceName(space.name);
    }
  }, [space]);

  const handleDelete = async () => {
    await deleteSpace({
      variables: {
        id: space.id
      },
      onCompleted: () => {
        navigate(`/`);
        setShowModal(false);
      }
    });
  };

  const handleChangeSpaceName = async () => {
    await updateSpace({
      variables: {
        in: {
          name: spaceName,
          id: space.id
        }
      },
      onCompleted: () => {}
    });
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      updateSpace({
        variables: {
          in: {
            name: space.name,
            id: space.id,
            icon: e.target.files[0]
          }
        },
        onCompleted: () => {}
      });
    }
  };
  return (
    <Box sx={{ p: 4 }} className="h-full text-left bg-color-white">
      <span className="font-size-20 font-weight-500">設定</span>
      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid xs={6} item className="text-left">
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'end' }}>
            <Box>
              <InputTextFieldWithLabel
                label="スペース名"
                size="small"
                value={spaceName}
                onChange={(e) => setSpaceName(e.target.value)}
              />
            </Box>
            <Button
              variant="outlined"
              color="error"
              size="medium"
              disabled={spaceName === ''}
              onClick={handleChangeSpaceName}
              sx={{ height: 40 }}>
              <span className="font-size-14 font-weight-700">変更</span>
            </Button>
          </Box>
          <Divider variant="middle" flexItem sx={{ mt: 3, mb: 3, ml: 0, mr: 0 }} />
          <Box sx={{ display: 'flex', mt: 1, gap: 3 }}>
            <Button variant="outlined" color="error" size="medium">
              <span className="font-size-14 font-weight-700" onClick={() => setShowModal(true)}>
                スペースを削除
              </span>
            </Button>
          </Box>
        </Grid>
        <Grid xs={6} item className="text-left">
          <div className="font-size-14 font-weight-400">画像</div>
          <Avatar
            alt="Avatar"
            variant="rounded"
            sx={{ mt: 2, width: 120, height: 120 }}
            src={
              space.icon ? `${space.icon}?timestamp=${new Date().getTime()}` : '/images/grey.png'
            }
          />
          <Button
            variant="outlined"
            color="inherit"
            component="label"
            size="medium"
            sx={{ mt: 2, width: 120 }}>
            アップロード
            <input
              type="file"
              id="upload-project-image"
              accept="image/*"
              hidden
              onChange={handleFileUpload}
            />
          </Button>
        </Grid>
      </Grid>
      {showModal && (
        <DeleteConfirmDialog
          isOpen={true}
          title="スペースを削除"
          content={
            <Box sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
              スペースを削除すると、プロジェクトやファイルなど、全ての情報が削除され、アクセスできなくなります。
              <p>確認の上、削除してください。</p>
            </Box>
          }
          handleDelete={handleDelete}
          handleClose={() => setShowModal(false)}
        />
      )}
    </Box>
  );
};

export default SpaceSetting;
