import React, { ChangeEvent, useEffect, useState } from 'react';
import InputEmailField from '@/components/molecules/form/InputEmailField';
import InputPasswordField from '@/components/molecules/form/InputPasswordField';
import { Avatar } from '@mui/material';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { UpdateUserInput, UserWithSettingQuery } from '@/__generated__/graphql';
import {
  getAuth,
  updatePassword,
  updateEmail,
  EmailAuthProvider,
  reauthenticateWithCredential
} from 'firebase/auth';
import {
  postChangeEmail,
  postChangePassword,
  postUser
} from '@/foundations/validationSchemas/auth';
import InputTextField from '@/components/molecules/form/InputTextField';
import ImageCropperDialog from '@/components/organisms/ImageCropperDialog';
import remove from 'lodash/remove';

interface Props {
  open: boolean;
  handleClose: () => void;
  user?: UserWithSettingQuery;
  handleUpdateUser: (user: UpdateUserInput) => Promise<void>;
}

const UpdateUserProfileDialog = (props: Props) => {
  const { open, user: currentUser, handleClose, handleUpdateUser } = props;
  const [isOpenCropper, setIsOpenCropper] = useState(false);
  const [uploadFile, setUploadFile] = useState('');
  const [profileImage, setProfileImage] = useState<string | null>(null);

  useEffect(() => {
    if (currentUser && currentUser.user && currentUser.user.icon) {
      setProfileImage(currentUser.user.icon);
    }
  }, [currentUser]);

  const reauthenticateUser = async (currentPassword: string, type: string) => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user && user.email) {
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      try {
        await reauthenticateWithCredential(user, credential);
        return true;
      } catch (error) {
        if (type === 'password')
          formikPassword.setErrors({ password: '現在入力したパスワードが正しくありません。' });
        else if (type === 'mail')
          formikEmail.setErrors({ password: '現在入力したパスワードが正しくありません。' });
        return false;
      }
    }
    return false;
  };

  const handleUploadUserIcon = async (url: string) => {
    if (url) {
      let response = await fetch(url);
      let data = await response.blob();
      let metadata = {
        type: 'image/jpeg'
      };
      let icon = new File([data], 'icon.jpg', metadata);
      const user: UpdateUserInput = {
        icon: icon
      };
      await handleUpdateUser(user);
    }
  };

  const handleDeleteUserIcon = async () => {
    const user: UpdateUserInput = {
      icon: null
    };
    await handleUpdateUser(user);
  };
  const formikUser = useFormik({
    initialValues: {
      first_name: currentUser?.user.firstName ?? '',
      last_name: currentUser?.user.lastName ?? ''
    },
    validationSchema: postUser,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const user: UpdateUserInput = {
        firstName: values.first_name,
        lastName: values.last_name
      };
      await handleUpdateUser(user);
    }
  });

  const formikEmail = useFormik({
    initialValues: {
      email: '',
      email_confirm: '',
      password: ''
    },
    validationSchema: postChangeEmail,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const user: UpdateUserInput = {
        email: values.email
      };
      try {
        const response = await handleUpdateUser(user);
        console.log('Update Email successful', response);
      } catch (error) {
        console.error('Update Email failed', error);
      }
    }
  });

  const formikPassword = useFormik({
    initialValues: {
      password: '',
      new_password: '',
      new_password_confirm: ''
    },
    validationSchema: postChangePassword,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const user = getAuth().currentUser;
      const reauthenticated = await reauthenticateUser(values.password, 'password');
      if (reauthenticated && user) {
        updatePassword(user, values.new_password)
          .then(() => {
            console.log('Updated password');
          })
          .catch((error) => {
            console.log('Error updating password:', error);
          });
      }
    }
  });

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const imageSrc = e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : '';
      setUploadFile(imageSrc);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: { width: 653 }
        }}>
        <DialogTitle id="form-dialog-title">
          アカウント情報の変更
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingBottom: '8px'
              }}>
              <Avatar src={profileImage ?? ''} style={{ width: 80, height: 80 }} />
              <Button
                onClick={() => {
                  setUploadFile('');
                  setIsOpenCropper(true);
                }}
                variant="outlined"
                color="inherit"
                component="label"
                size="small"
                sx={{ mt: 2, width: 101, color: '#00000080', fontWeight: 700, fontSize: '13px' }}>
                アップロード
                <input
                  type="file"
                  id="upload-project-image"
                  accept="image/*"
                  hidden
                  onChange={handleFileUpload}
                />
              </Button>
              {currentUser?.user.icon && <Button onClick={handleDeleteUserIcon}>画像を削除</Button>}
            </Box>
            <form onSubmit={formikUser.handleSubmit}>
              <InputTextField
                id="first_name"
                value={formikUser.values.first_name}
                onChange={formikUser.handleChange}
                margin="dense"
                placeholder="姓"
                label="姓"
                className="w-full"
                type="text"
                errors={formikUser.errors.first_name ? [formikUser.errors.first_name] : []}
              />
              <InputTextField
                id="last_name"
                value={formikUser.values.last_name}
                onChange={formikUser.handleChange}
                margin="dense"
                label="名"
                placeholder="名"
                className="w-full"
                type="text"
                errors={formikUser.errors.last_name ? [formikUser.errors.last_name] : []}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="medium"
                sx={{
                  mt: 2,
                  padding: '6px 16px',
                  fontSize: '14px',
                  fontWeight: '700',
                  justifyContent: 'start',
                  backgroundColor: '#2196F3',
                  boxShadow: 'none'
                }}>
                変更を保存
              </Button>
            </form>
          </Box>
          <Divider sx={{ mt: 3, mb: 2 }} />
          <form onSubmit={formikEmail.handleSubmit}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              メールアドレスの変更
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              現在のメールアドレスは「{currentUser?.user.email}」です
            </Typography>
            <Box sx={{ mt: 2 }}>
              <InputEmailField
                id="email"
                value={formikEmail.values.email}
                onChange={formikEmail.handleChange}
                placeholder="新しいメールアドレス"
                className="w-full"
                errors={formikEmail.errors.email ? [formikEmail.errors.email] : []}
              />
            </Box>
            <Box sx={{ mt: 1.6 }}>
              <InputEmailField
                id="email_confirm"
                value={formikEmail.values.email_confirm}
                onChange={formikEmail.handleChange}
                placeholder="新しいメールアドレス（確認用）"
                className="w-full"
                errors={formikEmail.errors.email_confirm ? [formikEmail.errors.email_confirm] : []}
              />
            </Box>
            <Typography variant="body2" sx={{ mt: 2, color: '#00000099' }}>
              パスワード
            </Typography>
            <Box sx={{ mt: 2 }}>
              <InputPasswordField
                id="password"
                value={formikEmail.values.password}
                onChange={formikEmail.handleChange}
                placeholder="パスワードを入力"
                className="w-full"
                errors={formikEmail.errors.password ? [formikEmail.errors.password] : []}
              />
            </Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="medium"
              sx={{
                mt: 2,
                padding: '6px 16px',
                fontSize: '14px',
                fontWeight: '700',
                justifyContent: 'start',
                backgroundColor: '#2196F3',
                boxShadow: 'none'
              }}>
              メールアドレス変更
            </Button>
          </form>
          <Divider sx={{ mt: 3, mb: 2 }} />
          <form onSubmit={formikPassword.handleSubmit}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              パスワードの変更
            </Typography>
            <Box sx={{ mt: 2 }}>
              <InputPasswordField
                id="password"
                value={formikPassword.values.password}
                onChange={formikPassword.handleChange}
                placeholder="現在のパスワード"
                className="w-full"
                errors={formikPassword.errors.password ? [formikPassword.errors.password] : []}
              />
            </Box>
            <Typography variant="body2" sx={{ mt: 2, color: '#00000099' }}>
              新しいパスワード
            </Typography>
            <Box sx={{ mt: 1.6 }}>
              <InputPasswordField
                id="new_password"
                value={formikPassword.values.new_password}
                onChange={formikPassword.handleChange}
                placeholder="新しいパスワード"
                className="w-full"
                errors={
                  formikPassword.errors.new_password ? [formikPassword.errors.new_password] : []
                }
              />
            </Box>

            <Box sx={{ mt: 2 }}>
              <InputPasswordField
                id="new_password_confirm"
                value={formikPassword.values.new_password_confirm}
                onChange={formikPassword.handleChange}
                placeholder="新しいパスワード（確認用）"
                className="w-full"
                errors={
                  formikPassword.errors.new_password_confirm
                    ? [formikPassword.errors.new_password_confirm]
                    : []
                }
              />
            </Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="medium"
              sx={{
                mt: 2,
                padding: '6px 16px',
                fontSize: '14px',
                fontWeight: '700',
                justifyContent: 'start',
                backgroundColor: '#2196F3',
                boxShadow: 'none'
              }}>
              パスワードを変更
            </Button>
          </form>
          <Divider sx={{ mt: 3, mb: 2 }} />
          <Typography variant="body1" sx={{ mb: 1 }}>
            メール通知
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={currentUser?.user.settingNotification?.isMention || false}
                onChange={async (_, checked) => {
                  const user: UpdateUserInput = {
                    userSettingNotification: {
                      isMention: checked
                    }
                  };
                  try {
                    const response = await handleUpdateUser(user);
                    console.log('Update Email successful', response);
                  } catch (error) {
                    console.error('Update Email failed', error);
                  }
                }}
              />
            }
            label="コメントでメンションされた時"
          />
        </DialogContent>
        <DialogActions></DialogActions>
        {isOpenCropper && uploadFile && (
          <ImageCropperDialog
            open={isOpenCropper}
            uploadFileURL={uploadFile}
            setUploadFileURL={(url) => {
              setProfileImage(url);
              handleUploadUserIcon(url);
            }}
            handleClose={() => {
              setUploadFile('');
              setIsOpenCropper(false);
            }}
          />
        )}
      </Dialog>
    </>
  );
};

export default UpdateUserProfileDialog;
