export const STROKE_COLOR = '#FFF';
export const STROKE_GREY_COLOR = '#0000001F';
export const TEXT_COLOR = '#000000DE';
export const TEXT_COLOR_LIGHT = '#0000008F';
export const ADD_BUTTON_COLOR = '#0000008A';
export const TICKET_DEFAULT_COLOR = '#FFF59D';
export const SELECTED_ROW_COLOR = '#2196F3';
export const PART_HEIGHT = 74;
export const PART_WIDTH = 225;
export const SPACING = 20;
export const SIDE_WIDTH = 40;
export const ROW_HEIGHT = PART_HEIGHT + SPACING * 2;

export const LANE_HEADER_HEIGHT = 50;
export const LANE_HEADER_WIDTH = 388;
export const LANE_HEADER_SLIM_WIDTH = PART_WIDTH + SPACING * 2;
export const ADD_LANE_WIDTH = 40;

export const TICKET_HEIGHT = 90;
export const TICKET_WIDTH = 90;
export const COMMENT_WIDTH = LANE_HEADER_WIDTH - SPACING * 2;
export const BIG_WIDTH = 5000;
export const BIG_HEIGHT = 5000;

export const SCROLL_DEFAULT_HEIGHT = 193;
export const SCROLL_HEIGHT = 160;
export const APPBAR_HEIGHT = 55;
export const BAR_ITEM_HEIGHT = 36;
export const MINIMUM_ROW = 10;

export const ALERT_TYPE = {
  REMOVE_PART: 'remove-part',
  REMOVE_COLUMN: 'remove-lane',
  REMOVE_ROW: 'remove-row',
  ADD_ROW: 'add-row'
};

export const HIDE_ADD_ROW_LOCALSTORAGE_KEY = 'dashboard:insert:column:alert';
export const LANE_FILTER_LOCALSTORAGE_KEY = 'dashboard:insert:lane:filter';
export const HIDE = 'hide';
