import * as React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useEffect, useState } from 'react';

type DebounceProps = {
  handleDebounce: (value: string) => void;
  debounceTimeout: number;
  initialValue: string;
};

const DebounceTextField = (props: TextFieldProps & DebounceProps) => {
  const { handleDebounce, debounceTimeout, initialValue, ...rest } = props;
  const [inputValue, setInputValue] = useState(initialValue);

  const timerRef = React.useRef<number>();

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = window.setTimeout(() => {
      handleDebounce(event.target.value);
    }, debounceTimeout);
  };

  return <TextField {...rest} onChange={handleChange} value={inputValue} />;
};

export default DebounceTextField;
