import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
// import AddIcon from '@mui/icons-material/Add';
import AddSpaceDialog from '../../organisms/modal/AddSpaceDialog';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { Avatar } from '@mui/material';
import { Space } from '@/__generated__/graphql';

const drawerWidth = 64;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden',
  backgroundColor: '#000000',
  color: theme.palette.secondary.light,
  top: '0'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  },
  backgroundColor: '#000000',
  color: theme.palette.secondary.light,
  top: '0'
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
);

// ToDo 色の変更部分
const sidebarColors = [
  '#1af6ed',
  '#e54129',
  '#8621ca',
  '#1af6ed',
  '#e54129',
  '#8621ca',
  '#1af6ed',
  '#e54129',
  '#8621ca',
  '#e54129'
];

interface Props {
  spaces: Space[];
}

const DefaultLayout = (props: Props) => {
  const { spaces } = props;
  const navigate = useNavigate();
  const [showModal, setShowModal] = React.useState<boolean>(false);

  const goToSpaceCreatePage = () => {
    navigate('/spaces/create');
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer variant="permanent" open={true}>
        <Divider />
        <List>
          {spaces.map((space, index) => (
            <ListItem
              key={`space_list_${index}`}
              to={`spaces/${space.id}`}
              component={RouterLink}
              sx={{ p: 1.5 }}>
              <Avatar src={space.icon as string} alt={space.name} variant="rounded">
                {space.name.slice(0, 1)}
              </Avatar>
            </ListItem>
          ))}

          {/*spaceの追加*/}
          {/*<ListItem key="space" sx={{ p: 1.5 }}>*/}
          {/*  <Avatar sx={{ bgcolor: '#111827' }} alt="Remy Sharp" variant="rounded">*/}
          {/*    <AddIcon onClick={() => goToSpaceCreatePage()} />*/}
          {/*  </Avatar>*/}
          {/*</ListItem>*/}
        </List>
      </Drawer>
      {showModal && <AddSpaceDialog isOpen={true} handleClose={() => setShowModal(false)} />}
    </Box>
  );
};

export default DefaultLayout;
