import { dia, util } from '@clientio/rappid';
import { COMMENT_WIDTH, PART_HEIGHT, TEXT_COLOR } from './constant';
import MoreVert from '../icons/MoreVert';

class WorkflowComment extends dia.Element {
  constructor(...args) {
    super(...args);
    this.resize(COMMENT_WIDTH, PART_HEIGHT);
  }

  defaults() {
    return {
      ...super.defaults,
      type: 'WorkflowComment',
      attrs: {
        label: {
          event: 'element:label:edit',
          fill: TEXT_COLOR,
          fontSize: 14,
          x: 3,
          y: 17
        },
        foreignObject: {
          width: COMMENT_WIDTH,
          height: PART_HEIGHT,
          x: 0,
          y: 0
        }
      },
      pid: ''
    };
  }

  preinitialize(...args) {
    this.markup = util.svg`
      <text @selector="label" />
    `;
  }
}

const createWorkflowComment = (config, graph, parent) => {
  const comment = new WorkflowComment(config);
  parent.embed(comment);
  comment.addTo(graph);
  return comment;
};
export { createWorkflowComment, WorkflowComment };
