import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckIcon from '@mui/icons-material/Check';
import { Drawer as MuiDrawer } from '@mui/material';
import { dia, g, shapes, ui, util } from '@clientio/rappid';
import { useMutation, useQuery } from '@apollo/client';
import { createWorkflowPool, WorkflowPool } from './elements/pool';
import { createWorkflowLane, WorkflowLane } from './elements/lane';
import { createWorkflowPart, WorkflowPart } from './elements/part';
import { createWorkflowTicket, WorkflowTicket } from './elements/ticket';
import { createWorkflowDivider, WorkflowDivider } from './elements/divider';
import { createWorkflowLink, WorkflowLink } from './elements/link';
import { createWorkflowAddButton, WorkflowAddButton } from './elements/addButton';
import { WorkflowPopup } from './elements/popup';
import { createWorkflowGroup } from './elements/group';
import {
  DELETE_FILE,
  DELETE_NODE_RELATION,
  GET_FILES,
  GET_NODE_COMMENTS,
  GET_ROWS,
  UPDATE_COLUMN,
  UPDATE_GROUP,
  UPDATE_NODE_PROPERTY,
  UPDATE_NODE_RELATION_PROPERTY,
  UPDATE_ROW
} from '@/api/graphql';
import {
  ADD_BUTTON_COLOR,
  ADD_LANE_WIDTH,
  ALERT_TYPE,
  APPBAR_HEIGHT,
  COMMENT_WIDTH,
  HIDE,
  HIDE_ADD_ROW_LOCALSTORAGE_KEY,
  LANE_FILTER_LOCALSTORAGE_KEY,
  LANE_HEADER_HEIGHT,
  SELECTED_ROW_COLOR,
  SIDE_WIDTH,
  SPACING,
  TEXT_COLOR
} from './elements/constant';
import difference from 'lodash/difference';
import remove from 'lodash/remove';
import {
  downloadBase64File,
  getLaneWidth,
  getMaxRow,
  getMaxRowInData,
  isCommentElement,
  isCommentLane,
  isGroupElement,
  isLaneElement,
  isNodeElement,
  isTicketElement,
  sliceText
} from './utils';
import { formatRaw } from './utils/init';
import { useListenerShowMenu } from './hooks/useListenerShowMenu';
import { useElementPointerClick } from './hooks/useElementPointerClick';
import { useElementPointerUpDown } from './hooks/useElementPointerUpDown';
import { useElementMousemove } from './hooks/useElementMousemove';
import { useLinkChange } from './hooks/useLinkChange';
import AddLaneDialog from '../../../components/organisms/modal/AddLaneDialog';
import AlertDialog from '../../../components/organisms/modal/AlertDialog';
import './index.scss';
import { TicketPartEditor } from '@/components/TicketPartEditor';
import NodeSearchBar from '@/components/molecules/workflows/NodeSearchBar';
import { createWorkflowComment } from '@/components/workflow/Canvas/elements/comment';
import { NodePropertyStatusEnum } from '@/__generated__/graphql';
import Typography from '@mui/material/Typography';
import EditGroupDialog from '@/components/organisms/modal/EditGroupDialog';
import WorkflowAIDialog from '@/components/organisms/modal/WorkflowAIDialog';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { PartEditor } from '@/components/PartEditor';
import SummarizeDialog from '@/components/organisms/modal/SummarizeDialog';
import WorkflowAISummaryDialog from '@/components/organisms/modal/WorkflowAISummaryDialog';
import AutoCorrectDialog from '@/components/organisms/modal/AutoCorrectDialog';
import WorkflowAIModifyDialog from '@/components/organisms/modal/WorkflowAIModifyDialog';
import { auth } from 'src/firebase';
import { downloadFileCsv } from '@/api/apis';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />;
});

const Drawer = styled(MuiDrawer)({
  '& .MuiModal-backdrop': {
    background: 'transparent'
  },
  '& .MuiDrawer-paper': {
    top: APPBAR_HEIGHT
  }
});

function hexToRGBA(hex, opacity) {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

const Canvas = ({
  rootRaw,
  fileId,
  file,
  projectId,
  ticketItems,
  refetch,
  refetchNodeProperty,
  onTicketAdded,
  isSidebarShowing = true,
  handleOpenZoomEditor
}) => {
  const navigate = useNavigate();
  const { workflowId } = useParams();
  const [queryParameters] = useSearchParams();
  const [showAIModal, setShowAIModal] = useState(queryParameters.get('isAI'));
  const [showAISummaryModal, setShowAISummaryModal] = useState(queryParameters.get('isSummary'));
  const [showAIAutoCorrectModal, setShowAIAutoCorrectModal] = useState(
    queryParameters.get('isModify')
  );
  const [isAddLaneDialogOpen, setIsAddLaneDialogOpen] = useState(false);
  const [alertType, setAlertType] = useState({
    type: '',
    callback: () => {}
  });
  const [activeLane, setActiveLane] = useState(null);
  const [activeSummary, setActiveSummary] = useState(false);
  const [activeAutoCorrect, setActiveAutoCorrect] = useState(false);
  const [activeGroup, setActiveGroup] = useState(null);
  const [activeNode, setActiveNode] = useState(null);
  const [addTicket, setAddTicket] = useState(null);
  const [activeTicket, setActiveTicketNode] = useState(null);
  const [openAlert, setOpenAlert] = React.useState({
    type: '',
    isOpen: false,
    nodeId: ''
  });
  const [deleteNodeRelation] = useMutation(DELETE_NODE_RELATION);
  const [updateNodeProperty] = useMutation(UPDATE_NODE_PROPERTY);
  const [updateNodeRelationProperty] = useMutation(UPDATE_NODE_RELATION_PROPERTY);
  const [updateColumn] = useMutation(UPDATE_COLUMN);
  const [deleteFile] = useMutation(DELETE_FILE, {
    refetchQueries: [GET_FILES]
  });
  const [updateRow] = useMutation(UPDATE_ROW, {
    refetchQueries: [GET_ROWS]
  });
  const [updateGroup] = useMutation(UPDATE_GROUP);

  // When node or ticket editor is active, poll the newest comments list
  const activeNodeOrTicketId = activeNode?.id || activeTicket?.nodeId;
  const { data: _ } = useQuery(GET_NODE_COMMENTS, {
    variables: {
      nodeId: activeNodeOrTicketId
    },
    skip: !activeNodeOrTicketId,
    pollInterval: activeNodeOrTicketId ? 5000 : 0
  });

  const [excludeViews, setExcludeViews] = useState(['ticket', 'comment']);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorAIEl, setAnchorAIEl] = useState(null);
  const openAI = Boolean(anchorAIEl);
  const [anchorDownloadEl, setAnchorDownloadEl] = useState(null);
  const openDownloadMenu = Boolean(anchorDownloadEl);
  const canvas = useRef(null);
  const [lanes, setLanes] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [relations, setRelations] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [groups, setGroups] = useState([]);
  const [rows, setRows] = useState([]);
  const [graph, setGraph] = useState(null);
  const [paper, setPaper] = useState(null);
  const [isSelectedItemSearchBar, setSelectedItemSearchBar] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [lastActiveNodeName, setLastActiveNodeName] = useState(null);
  const [lastActiveNodeMediaLabel, setLastActiveNodeMediaLabel] = useState(null);
  const [lastActiveNodeDesc, setLastActiveNodeDesc] = useState(null);
  // Record the position of each lane and row to calculate the part position
  const offsetX = useRef([]);
  const offsetY = useRef([]);
  const [isSidePickerInput, setIsSidePickerInput] = useState(false);
  const isHideAddRowAlert = JSON.parse(window.localStorage.getItem(HIDE_ADD_ROW_LOCALSTORAGE_KEY));

  const cellNamespace = {
    ...shapes,
    WorkflowPool,
    WorkflowLane,
    WorkflowPart,
    WorkflowTicket,
    WorkflowDivider,
    WorkflowLink,
    WorkflowAddButton,
    WorkflowPopup
  };

  const { kinds, users } = formatRaw(rootRaw);
  const setActiveTicket = (node) => {
    if (node) {
      const ticketItem = ticketItems.find((ticketItem) => ticketItem.nodeId === node.nodeId);
      if (ticketItem) {
        setActiveTicketNode({
          ...node,
          tasks: ticketItem.tasks
        });
        return;
      }
    }

    setActiveTicketNode(node);
  };

  const dep = {
    fileId,
    graph,
    paper,
    lanes,
    setLanes,
    nodes,
    setNodes,
    relations,
    setRelations,
    tickets,
    setTickets,
    groups,
    setGroups,
    selectedRows,
    setSelectedRows,
    setActiveNode,
    setActiveTicket,
    offsetX,
    offsetY,
    isAddLaneDialogOpen,
    setIsAddLaneDialogOpen,
    activeLane,
    setActiveLane,
    activeGroup,
    setActiveGroup,
    setAlertType,
    isHideAddRowAlert,
    refetch,
    activeNode,
    activeTicket,
    setAddTicket,
    lastActiveNodeName,
    setLastActiveNodeName,
    lastActiveNodeDesc,
    setLastActiveNodeDesc,
    lastActiveNodeMediaLabel,
    setLastActiveNodeMediaLabel,
    kinds,
    refetchNodeProperty
  };

  useListenerShowMenu(dep);
  useElementPointerClick(dep);
  useElementPointerUpDown(dep);
  useElementMousemove(dep);
  useLinkChange(dep);

  // Scroll a node to the center of the screen
  useEffect(() => {
    if (activeNode && activeNode.id && isSelectedItemSearchBar && !isSidePickerInput) {
      setSelectedItemSearchBar(false);
      const selector = `#label-${activeNode.id}`;
      const svgElement = document.querySelector(selector);
      if (svgElement) {
        const rect = svgElement.getBoundingClientRect();
        window.scrollBy({
          top: Math.floor(rect.y) - window.innerHeight / 2,
          left: Math.floor(rect.x) - window.innerWidth / 2,
          behavior: 'smooth'
        });
      }
    }
  }, [activeNode]);

  useEffect(() => {
    const savedExcludeViewsString = localStorage.getItem(LANE_FILTER_LOCALSTORAGE_KEY);
    if (savedExcludeViewsString) {
      try {
        let savedExcludeViews = JSON.parse(savedExcludeViewsString);
        if (Array.isArray(savedExcludeViews)) {
          if (!savedExcludeViews.includes('comment')) {
            savedExcludeViews = [...savedExcludeViews, 'comment'];
          }
          if (!savedExcludeViews.includes('ticket')) {
            savedExcludeViews.push('ticket');
          }
          setExcludeViews(savedExcludeViews);
        }
      } catch (error) {
        console.error('Parsing error in useEffect:', error);
      }
    }
  }, [fileId]);

  useEffect(() => {
    localStorage.setItem(LANE_FILTER_LOCALSTORAGE_KEY, JSON.stringify(excludeViews));
  }, [excludeViews]);

  useEffect(() => {
    if (activeTicket || addTicket) {
      // activeTicketを最新の情報で変更する
      if (addTicket) {
        const addTicketItem = ticketItems.find((ticket) => ticket.nodeId === addTicket);
        if (addTicketItem) {
          setActiveTicketNode(addTicketItem);
          setAddTicket(null);
        }
      } else {
        const ticketItem = ticketItems.find(
          (ticketItem) => ticketItem.nodeId === activeTicket.nodeId
        );
        if (ticketItem) {
          setActiveTicketNode({
            ...ticketItem,
            tasks: ticketItem.tasks
          });
          setActiveNode(null);
        }
      }
    }
  }, [ticketItems]);

  useEffect(() => {
    const { nodes, tickets, relations } = formatRaw(rootRaw);
    if (activeNode) {
      setActiveNode(nodes?.find((node) => node.id === activeNode.id));
      setActiveTicketNode(null);
    }
    setNodes(nodes);
    setTickets(tickets);
    setRelations(relations);
    if (addTicket) {
      const addTicketItem = tickets.find((ticket) => ticket.nodeId === addTicket);
      if (addTicketItem) {
        setActiveTicketNode(addTicketItem);
        setAddTicket(null);
      }
    }
  }, [rootRaw]);

  useEffect(() => {
    const initGraph = new dia.Graph();
    const initPaper = new dia.Paper({
      model: initGraph,
      height: '100%',
      width: '100%',
      gridSize: 10,
      frozen: true,
      async: true,
      sorting: dia.Paper.sorting.APPROX,
      cellViewNamespace: cellNamespace,
      highlighting: {
        connecting: {
          name: 'addClass',
          options: {
            className: 'highlight-connecting'
          }
        }
      },
      restrictTranslate: (elementView) => {
        // Find the pool at the top and set to parent
        let parent = elementView.model.getParentCell();
        if (!parent) return null; // No restriction
        while (parent.attributes.type !== 'WorkflowPool') {
          parent = parent.getParentCell();
        }

        // Activity movement is constrained by the parent area
        const { x, y, width, height } = parent.getBBox({ deep: true });
        return new g.Rect(
          x + 30,
          y + LANE_HEADER_HEIGHT,
          width - 20,
          height - LANE_HEADER_HEIGHT
        ).inflate(-20);
      },
      interactive: (cellView) => {
        const blacklist = [
          'WorkflowPool',
          'WorkflowLane',
          'WorkflowDivider',
          'WorkflowAddButton',
          'WorkflowTicket',
          'WorkflowComment'
        ];
        return !blacklist.includes(cellView.model.attributes.type);
      },
      defaultLink: () =>
        new shapes.standard.Link({
          attrs: {
            line: {
              stroke: '#757575',
              strokeWidth: 2
            }
          },
          router: { name: 'manhattan' },
          connector: { name: 'rounded' }
        }),
      // defaultConnectionPoint: { name: 'anchor' },
      // defaultAnchor: (view, magnet, ...rest) => {
      //   return anchors.bottom(view, magnet, ...rest);
      // },
      connectionStrategy: (end, view, _magnet, coords) => {
        end.anchor = {
          name: view.model.getBBox().sideNearestToPoint(coords)
        };
      }
    });

    new ui.PaperScroller({
      paper: initPaper,
      autoResizePaper: true,
      cursor: 'grab',
      padding: {
        top: 0,
        left: 0
      }
    });

    canvas.current.appendChild(initPaper.el);
    setGraph(initGraph);
    setPaper(initPaper);

    const raw = formatRaw(rootRaw);
    console.log(raw);
    setLanes([...raw.lanes]);
    setRelations([...raw.relations]);
    setGroups([...raw.groups]);
    setRows([...raw.rows]);

    initPaper.on('divider:pointerdown', (elementView, evt) => {});

    initPaper.on('element:label:edit', (elementView, evt) => {
      evt.stopPropagation();
      let callback = () => {};
      const isNode = isNodeElement(elementView.model);
      const isTicket = isTicketElement(elementView.model);
      const isLane = isLaneElement(elementView.model);
      const isGroup = isGroupElement(elementView.model);
      if (isNode || isTicket) {
        const nodeId = elementView.model.prop('pid');
        callback = (name) => {
          updateNodeProperty({
            variables: {
              in: {
                nodeId,
                name
              }
            }
          });
          if (isNode) {
            setNodes((prevState) => {
              const newState = [...prevState];
              newState.forEach((node) => {
                if (node.id === nodeId) {
                  node.name = name;
                  node.property.name = name;
                }
              });
              return newState;
            });
          }
          if (isTicket) {
            setTickets((prevState) => {
              const newState = [...prevState];
              newState.forEach((ticket) => {
                if (ticket.nodeId === nodeId) {
                  ticket.name = name;
                }
              });
              return newState;
            });
          }
        };
        // No direct edit now
        // editor(elementView, evt, callback);
      }
      if (isLane) {
        const index = elementView.model.prop('column');
        callback = (name) => {
          updateColumn({
            variables: {
              in: {
                fileId,
                index,
                name
              }
            },
            onCompleted: () => {
              setLanes((prevState) => {
                const newState = [...prevState];
                newState[index].name = name;
                return newState;
              });
            }
          });
        };
        // No direct edit now
        // editor(elementView, evt, callback);
      }
      if (isCommentElement(elementView.model)) {
        const index = elementView.model.prop('row');
        callback = (description) => {
          updateRow({
            variables: {
              in: {
                fileId,
                index,
                description
              }
            },
            onCompleted: () => {
              setRows((prevState) => {
                const newState = [...prevState];
                newState[index].description = description;
                return newState;
              });
            }
          });
        };
        // No direct edit now
        // editor(elementView, evt, callback);
      }
      if (isGroup) {
        const id = elementView.model.prop('gid');
        const rowIndexes = elementView.model.prop('rowIndexes');
        callback = (name) => {
          updateGroup({
            variables: {
              in: {
                id,
                name,
                rowIndexes
              }
            },
            onCompleted: () => {
              setGroups((prevState) => {
                const newState = [...prevState];
                newState.find((group) => group.id === id).name = name;
                return newState;
              });
            }
          });
        };
        // No direct edit now
        // editor(elementView, evt, callback);
      }
    });

    initPaper.unfreeze();

    return () => {
      // initScroller.remove();
      initPaper.remove();
    };
  }, [workflowId]);

  useEffect(() => {
    if (!graph || !paper || !lanes.length || !nodes.length) {
      return;
    }
    graph.off('change:vertices');
    graph.clear();
    const isShowTicket = !excludeViews.includes('ticket');
    const isShowComment = !excludeViews.includes('comment');
    const isShowCompleteTicket = !excludeViews.includes('completeTicket');
    const maxRow = getMaxRow(nodes);
    const pool = createWorkflowPool(
      {
        attrs: {
          body: {
            width:
              (isShowComment ? lanes.length : lanes.length - 1) * getLaneWidth(isShowTicket) +
              ADD_LANE_WIDTH
          },
          divider: {
            strokeWidth: 1,
            d: `M 0 ${LANE_HEADER_HEIGHT} H 200`
          }
        }
      },
      paper
    );
    pool.addTo(graph);

    const partsMap = {};
    const partElementsMap = {};
    const addButtons = [];
    const rowTicketsMap = {};
    const ticketWithGhost = [...tickets];
    const allRowIndexes = [...Array(getMaxRowInData(nodes) + 2).keys()];
    const existRowIndexes = nodes.map((node) => node.rowIndex);
    const emptyRowIndexes = difference(allRowIndexes, existRowIndexes);

    if (isShowTicket) {
      const nodeTicketsCount = {};
      tickets.forEach((ticket) => {
        const { parentNodeId } = ticket;
        if (!isShowCompleteTicket && ticket.status === 'complete') {
          return;
        }
        nodeTicketsCount[parentNodeId] = (nodeTicketsCount[parentNodeId] || 0) + 1;
      });
      Object.keys(nodeTicketsCount).forEach((parentNodeId) => {
        const node = nodes.find((node) => String(node.id) === String(parentNodeId));
        if (node) {
          const { rowIndex } = node;
          rowTicketsMap[rowIndex] = Math.max(
            rowTicketsMap[rowIndex] || 0,
            nodeTicketsCount[parentNodeId]
          );
        }
      });
    }

    const laneElements = lanes
      .filter((lane) => {
        // commentLane hide
        return lane.type !== 'comment';
      })
      .map((lane, index) => {
        const laneElement = createWorkflowLane(
          {
            attrs: {
              header: {
                fill: lane.color || '#FEEBEE'
              },
              body: {
                fill: lane.color || '#FEEBEE'
              },
              label: {
                x: isCommentLane(lane) ? 10 : 30,
                html: lane.name
              },
              dragIcon: {
                visibility: isCommentLane(lane) ? 'hidden' : 'visible'
              },
              moreIcon: {
                visibility: isCommentLane(lane) ? 'hidden' : 'visible'
              },
              divider: {
                visibility: isCommentLane(lane) ? 'hidden' : 'visible'
              }
            },
            column: index
          },
          graph
        );
        pool.embed(laneElement);

        if (!isCommentLane(lane)) {
          emptyRowIndexes.forEach((rowIndex) => {
            const addButtonElement = createWorkflowAddButton(
              {
                columnIndex: index,
                rowIndex: rowIndex
              },
              graph
            );
            addButtons.push(addButtonElement);
            if (rowIndex < getMaxRowInData(nodes)) {
              addButtonElement.attr('body/strokeWidth', 0);
              addButtonElement.attr('label/fill', 'transparent');
            } else {
              addButtonElement.prop('isLastRow', true);
            }
            pool.embed(addButtonElement);
          });
        } else {
          if (excludeViews.includes('comment')) {
            laneElement.attr('./visibility', 'hidden');
          }
        }

        return laneElement;
      });

    const columnOffset = pool.layoutLanes(laneElements, isShowTicket);

    offsetX.current = [...columnOffset];

    const dividers = [...Array(maxRow).keys()].map((i) => {
      const isSelected = selectedRows.includes(i);
      const dividerElement = createWorkflowDivider({
        attrs: {
          body: {
            fill: isSelected ? SELECTED_ROW_COLOR : 'transparent'
          },
          label: {
            text: i + 1,
            fill: isSelected ? 'white' : TEXT_COLOR,
            y: 'calc(h / 2)'
          }
        },
        row: i,
        rowIndex: rows[i]
      });
      dividerElement.addTo(graph);
      return dividerElement;
    });
    const rowOffset = pool.layoutDividers(dividers, rowTicketsMap);
    offsetY.current = [...rowOffset];
    pool.layoutAddButtons(addButtons, dividers, isShowTicket);

    if (groups.length) {
      const groupElements = groups.map((group) => {
        const groupElement = createWorkflowGroup(
          {
            attrs: {
              label: {
                text: group.name || 'グループ'
              }
            },
            gid: group.id,
            rowIndexes: group.rowIndexes
          },
          graph,
          pool
        );
        groupElement.addTo(graph);
        return groupElement;
      });
      pool.layoutGroup(groupElements, offsetY);
      pool.fitEmbeds({
        padding: {
          left: SIDE_WIDTH
        },
        deep: true
      });
    }

    const nodeElements = nodes.map((node) => {
      const { property = {}, id, columnIndex, rowIndex, type } = node;
      ticketWithGhost.push({
        parentNodeId: id
      });
      const nodeElement = createWorkflowPart(
        {
          attrs: {
            body: {
              stroke: activeNode?.id === node.id ? '#2196F380' : '',
              strokeWidth: activeNode?.id === node.id ? 2 : 0
            },
            label: {
              html: node.name || 'プロセス名',
              id: `label-${node.id}`
            },
            metadata: {
              html: `
                  <span style="display: flex; justify-content: center; height: 18px;">
                       ${
                         property.media
                           ? "<span class='metadata-icon icon-" + property.media + "'></span>"
                           : ''
                       }
                       ${
                         property.mediaLabel
                           ? '<span ' +
                             "style='font-size: 12px; padding-top: 2px; height: 18px; color: rgba(0,0,0, 0.6)' >" +
                             property.mediaLabel +
                             '</span>'
                           : ''
                       }
                  </span>
                  <span>
                      ${
                        property.activities?.length
                          ? "<span class='metadata-icon icon-error'>" +
                            property.activities.length +
                            '</span>'
                          : ''
                      }
                      ${
                        property.comments?.length
                          ? '<span style="display: flex; padding-top: 2px;">' +
                            "<span class='metadata-icon icon-comment'></span>" +
                            '<span>' +
                            property.comments.length +
                            '</span>' +
                            '</span>'
                          : ''
                      }
                  </span>
                `
            }
          },
          column: columnIndex,
          row: rowIndex,
          pid: id,
          nodeType: type
        },
        graph,
        pool
      );
      partsMap[nodeElement.id] = node;
      partElementsMap[node.id] = nodeElement;
      pool.layoutIndividualNode(nodeElement, dividers, isShowTicket, groups.length > 0);
      return nodeElement;
    });

    const relationLinks = relations.map((relation) => {
      const source = partElementsMap[relation.fromId];
      const target = partElementsMap[relation.toId];
      const linkElement = createWorkflowLink(
        {
          actions: {
            remove: (linkId) => {
              deleteNodeRelation({
                variables: {
                  id: linkId
                },
                onCompleted: (data) => {
                  setRelations((prevState) => {
                    const newState = [...prevState];
                    remove(newState, (relation) => relation.id === linkId);
                    return newState;
                  });
                }
              });
            }
          }
        },
        graph,
        source,
        target,
        relation.id
      );

      linkElement.addTools(paper, partsMap);

      const isConditionRelation = source?.prop('nodeType') === 'condition';

      const { property } = relation;
      if (property?.positions?.vertices) {
        linkElement.vertices(property.positions.vertices);
      }
      if (property?.description || property?.media) {
        linkElement.appendLabel({
          markup: util.svg`
                <foreignObject @selector="foreignObject">
                <div
                  class="link-main"
                  xmlns="http://www.w3.org/1999/xhtml"
                >
                  <span class="link-titles ${
                    isConditionRelation ? 'condition-link' : ''
                  }" @selector="labels"></span>
                </div>
              </foreignObject>
              `,
          attrs: {
            foreignObject: {
              x: -100,
              y: -15,
              height: 30,
              width: 200
            },
            labels: {
              html: getRelationType(relation)
            }
          }
        });
      }
      return linkElement;
    });

    if (isShowTicket) {
      let ticketTempArr = ticketWithGhost;
      if (!isShowCompleteTicket) {
        ticketTempArr = ticketWithGhost.filter(
          (ticket) => ticket.status !== NodePropertyStatusEnum.Complete
        );
      }
      const ticketElements = ticketTempArr.map((ticket) => {
        const { nodeId, parentNodeId, name = '', color = 'transparent', comments } = ticket;
        let fillColor = color;
        if (ticket.status === NodePropertyStatusEnum.Complete) {
          if (color.startsWith('#')) {
            fillColor = hexToRGBA(color, 0.3);
          } else if (!color.includes('rgba') && !color.includes('hsla')) {
          }
        }

        const nodeElement = partElementsMap[parentNodeId];
        let attrs = {
          body: {
            fill: fillColor,
            stroke: activeTicket?.id === ticket.id ? '#2196F380' : '',
            strokeWidth: activeTicket?.id === ticket.id ? 2 : 0
          },
          label: {
            html: sliceText(name) || 'チケット名'
          },
          metadata: {
            html: `
                    <span>
                      ${
                        comments?.length
                          ? "<span class='metadata-icon icon-comment'>" +
                            comments.length +
                            '</span>'
                          : ''
                      }
                  </span>
                  `
          }
        };
        // If nodeId is not existing that means this is an adding ticket button
        if (!nodeId) {
          attrs = {
            body: {
              event: 'ticket:plus:pointerdown',
              fill: 'transparent',
              stroke: ADD_BUTTON_COLOR,
              strokeWidth: 0,
              strokeDasharray: '5,5',
              width: 'calc(w)',
              height: 'calc(h)',
              rx: 8
            },
            label: {
              html: `<span class="ticket-add"> + </span>`
            }
          };
        }

        if (!nodeElement) return;
        const ticketElement = createWorkflowTicket(
          {
            attrs,
            parentNodeId,
            pid: nodeId,
            isGhost: !nodeId
          },
          graph,
          nodeElement
        );
      });
    }
    if (isShowComment && rows) {
      const commentElements = rows.map((row, index) => {
        if (dividers[index]) {
          const height = dividers[index].attr('body/height') - SPACING * 2;
          const commentElement = createWorkflowComment(
            {
              attrs: {
                body: {
                  fill: '#fff',
                  width: COMMENT_WIDTH,
                  height: height
                },
                label: {
                  width: COMMENT_WIDTH,
                  height: height,
                  lineHeight: '20px',
                  text: row.description,
                  pointerEvents: 'auto',
                  cursor: 'text',
                  displayEmpty: true,
                  textWrap: {
                    width: '100%',
                    height: height,
                    ellipsis: false
                  }
                },
                metadata: {
                  html: ``
                }
              },
              row: index,
              column: lanes.length - 2
            },
            graph,
            pool
          );
          pool.layoutAddLaneComment(commentElement, dividers, isShowTicket, groups.length > 0);
        }
      });
    }

    // Add this certain event listener after all the links' vertices have been generated, to avoid the re-trigger
    graph.on(
      'change:vertices',
      util.debounce((link) => {
        const linkId = link.prop('linkId');
        if (!linkId) {
          return;
        }
        updateNodeRelationProperty({
          variables: {
            in: {
              nodeRelationId: linkId,
              positions: JSON.stringify({
                vertices: link.vertices()
              })
            }
          }
        });
        setRelations((prevState) => {
          const newState = [...prevState];
          const currentRelation = newState.find((relation) => relation.id === link.prop('linkId'));
          if (currentRelation?.property?.positions) {
            currentRelation.property.positions.vertices = [...link.vertices()];
          }
          return newState;
        });
      }, 1000)
    );
  }, [
    excludeViews,
    nodes,
    lanes,
    groups,
    tickets,
    relations,
    selectedRows,
    paper,
    graph,
    activeNode,
    activeTicket
  ]);

  const views = [
    {
      key: 'lane',
      label: 'レーン',
      options: [
        {
          key: 'ticket',
          label: 'チケットレーン'
        }
        // ,
        // {
        //   key: 'comment',
        //   label: '説明レーン'
        // }
      ]
    },
    {
      key: 'ticket',
      label: 'チケット',
      options: [
        {
          key: 'completeTicket',
          label: '完了したチケット'
        }
      ]
    }
  ];

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      boxShadow:
        '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
      color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      '& .MuiMenu-list': {
        padding: '8px 0'
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5)
        },
        '&:active': {
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
        }
      }
    }
  }));

  const TopActionButtons = styled('div')(({ theme }) => ({
    position: 'fixed',
    zIndex: theme.zIndex.appBar + 1,
    top: '16px',
    right: '98px',
    display: 'flex'
  }));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAIClick = (event) => {
    setAnchorAIEl(event.currentTarget);
  };
  const handleDownloadMenuClick = (event) => {
    setAnchorDownloadEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleAIClose = () => {
    setAnchorAIEl(null);
  };
  const handleDownloadMenuClose = () => {
    setAnchorDownloadEl(null);
  };
  const handleAI = (key) => {
    switch (key) {
      case 'summary':
        setActiveSummary(true);
        break;
      case 'auto-correct':
        setActiveAutoCorrect(true);
        break;
      default:
        break;
    }

    handleAIClose();
  };

  const handleChange = (name) => {
    setExcludeViews((prevState) => {
      const newState = [...prevState];
      if (newState.includes(name)) {
        remove(newState, (view) => view === name);
      } else {
        newState.push(name);
      }
      return newState;
    });
    handleClose();
  };

  const getRelationType = (relation) => {
    return `
                  <span style="display: flex; justify-content: center; height: 18px;">
                       ${
                         relation.property?.media
                           ? "<span class='metadata-icon icon-" +
                             relation.property?.media +
                             "'></span>"
                           : ''
                       }
                       ${
                         relation.property.description
                           ? '<span ' +
                             "style='font-size: 14px; font-weight: 500; padding-bottom: 2px; height: 18px;'>" +
                             relation.property.description +
                             '</span>'
                           : ''
                       }
                  </span>
                  `;
  };

  const handleDownloadCsv = async () => {
    const token = await auth.currentUser?.getIdToken();
    if (token) {
      const res = await downloadFileCsv(token, fileId);
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.csv');
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleDownload = (key) => {
    switch (key) {
      case 'download-png': {
        const box = paper.getContentBBox();
        const height = offsetY.current[offsetY.current.length - 1];
        paper.toPNG(
          (imageData) => {
            downloadBase64File(imageData, 'workflow.png');
          },
          {
            area: {
              x: 0,
              y: 0,
              width: box.width,
              height
            },
            width: box.width + 100,
            height
          }
        );
        break;
      }
      case 'download-csv':
        handleDownloadCsv();
        break;
      default:
        break;
    }

    handleDownloadMenuClose();
  };

  const handleRetryAIGenerate = () => {
    //TODO
  };

  const handleConfirmAIGenerate = () => {
    setShowAIModal(false);
    navigate(`/workflows/${fileId}/diagram/view`);
  };

  const handleConfirmAISummaryGenerate = () => {
    setShowAISummaryModal(false);
    navigate(`/workflows/${fileId}/diagram/view`);
  };

  const handleConfirmAIAutoCorrectGenerate = () => {
    setShowAIAutoCorrectModal(false);
    navigate(`/workflows/${fileId}/diagram/view`);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason && reason === 'clickaway') {
      return;
    }
    setOpenAlert({ type: '', isOpen: false, nodeId: '' });
  };

  const handleSelectItemSearchBar = (type, node) => {
    setSelectedItemSearchBar(true);
    if (node.type === 'ticket') {
      setActiveTicket(node);
      setActiveNode(null);
    } else {
      setActiveNode(node);
      setActiveTicketNode(null);
    }
  };

  return (
    <>
      <TopActionButtons>
        <Button
          id="download-file-button"
          variant="contained"
          sx={{
            ml: 1,
            fontWeight: '700',
            fontSize: '14px',
            backgroundColor: '#2196F3',
            position: 'fixed',
            right: '20px',
            top: '9px'
          }}
          aria-controls={openDownloadMenu ? 'download-file-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openDownloadMenu ? 'true' : undefined}
          disableElevation
          onClick={handleDownloadMenuClick}>
          ダウンロード
        </Button>
      </TopActionButtons>
      <Menu
        id="download-file-menu"
        MenuListProps={{
          'aria-labelledby': 'download-file-button'
        }}
        sx={{
          '& .MuiPaper-root': {
            top: '49px !important',
            right: '20px',
            left: 'unset !important',
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        anchorEl={anchorDownloadEl}
        open={openDownloadMenu}
        onClose={handleDownloadMenuClose}>
        {[
          {
            label: '画像（PNG）でダウンロード',
            key: 'download-png'
          },
          {
            label: 'CSVでダウンロード',
            key: 'download-csv'
          }
        ].map((item) => (
          <MenuItem key={item.key} onClick={() => handleDownload(item.key)} disableRipple>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
      <Box className="canvas-header" flex style={{ left: !isSidebarShowing ? 0 : 160 }}>
        <NodeSearchBar
          nodes={nodes}
          tickets={tickets}
          selectAction={(node) => {
            handleSelectItemSearchBar(node.type, node);
          }}
        />
        <div>
          <Button
            id="customized-button"
            sx={{
              marginRight: '18px',
              height: 40,
              borderColor: '#0000003B',
              color: '#00000099',
              fontSize: '14px',
              padding: '9px 12px'
            }}
            aria-controls={open ? 'customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            disableElevation
            onClick={handleClick}
            variant="outlined"
            endIcon={<KeyboardArrowDownIcon />}>
            表示項目
          </Button>
          <Button
            id="ai-button"
            sx={{
              height: 40,
              border: 'none',
              bgcolor: '#9C27B0',
              fontSize: '16px',
              color: '#fff',
              padding: '6px 16px',
              fontWeight: '700',
              ':hover': {
                bgcolor: '#9C27B0',
                border: 'none',
                opacity: 0.8
              }
            }}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            disableElevation
            onClick={handleAIClick}
            variant="outlined"
            startIcon={<img src="/icons/sparkles.svg" alt="condition" />}>
            AI機能
          </Button>
        </div>
        <StyledMenu
          id="customized-menu"
          MenuListProps={{
            'aria-labelledby': 'customized-button'
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}>
          {views.map((view) => (
            <Box
              className="font-size-12 font-weight-400"
              key={view.key}
              sx={{
                width: '100%',
                display: 'block'
              }}>
              <Typography variant="body2" sx={{ padding: '6px 12px', background: '#F5F5F5' }}>
                {view.label}
              </Typography>
              {view.options &&
                view.options.map((item) => (
                  <MenuItem key={item.key} onClick={() => handleChange(item.key)} disableRipple>
                    {!excludeViews.includes(item.key) ? (
                      <CheckIcon />
                    ) : (
                      <Box style={{ width: 24, height: 24 }}></Box>
                    )}
                    {item.label}
                  </MenuItem>
                ))}
            </Box>
          ))}
        </StyledMenu>
        <StyledMenu
          id="ai-menu"
          MenuListProps={{
            'aria-labelledby': 'ai-button'
          }}
          anchorEl={anchorAIEl}
          open={openAI}
          onClose={handleAIClose}>
          {[
            {
              label: 'サマリファイルを作成',
              key: 'summary'
            },
            {
              label: '自動修正',
              key: 'auto-correct'
            }
          ].map((item) => (
            <MenuItem key={item.key} onClick={() => handleAI(item.key)} disableRipple>
              {item.label}
            </MenuItem>
          ))}
        </StyledMenu>
      </Box>

      <div className="canvas" ref={canvas} />

      <Drawer
        anchor="right"
        hideBackdrop={true}
        variant="persistent"
        ModalProps={{
          hideBackdrop: true,
          sx: { position: 'static' }
        }}
        PaperProps={{
          sx: {
            boxShadow:
              '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)'
          }
        }}
        open={Boolean(activeNode)}
        onClose={() => setActiveNode(null)}>
        {activeNode ? (
          <PartEditor
            users={users}
            lanes={lanes}
            nodes={nodes}
            node={activeNode}
            kinds={kinds}
            relations={relations}
            property={activeNode.property}
            setActiveNode={setActiveNode}
            setNodes={setNodes}
            setRelations={setRelations}
            setLastActiveNodeName={setLastActiveNodeName}
            setLastActiveNodeDesc={setLastActiveNodeDesc}
            setLastActiveNodeMediaLabel={setLastActiveNodeMediaLabel}
            isSidePickerInput={isSidePickerInput}
            setIsSidePickerInput={setIsSidePickerInput}
            refetchAllNodes={() => {
              refetch();
            }}
            onTicketAdded={onTicketAdded}
            handleOpenZoomEditor={handleOpenZoomEditor}
          />
        ) : null}
      </Drawer>

      <Drawer
        anchor="right"
        hideBackdrop={true}
        variant="persistent"
        ModalProps={{
          hideBackdrop: true,
          sx: { position: 'static' }
        }}
        PaperProps={{
          sx: {
            boxShadow:
              '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)'
          }
        }}
        open={Boolean(activeTicket)}
        onClose={() => setActiveTicket(null)}>
        {activeTicket ? (
          <TicketPartEditor
            nodes={tickets}
            node={activeTicket}
            users={users}
            isSidePickerInput={isSidePickerInput}
            setIsSidePickerInput={setIsSidePickerInput}
            setActiveNode={setActiveTicket}
            setLastActiveNodeName={setLastActiveNodeName}
            setLastActiveNodeDesc={setLastActiveNodeDesc}
            setTickets={setTickets}
            kinds={kinds}
            refetchAllNodes={() => {
              refetch();
            }}
            setOpenAlert={setOpenAlert}
            handleOpenZoomEditor={(node) => handleOpenZoomEditor(node, 'ticket')}
          />
        ) : null}
      </Drawer>

      <AddLaneDialog
        fileId={fileId}
        index={activeLane?.index !== undefined ? activeLane.index : lanes.length - 1}
        lane={activeLane}
        refetch={refetch}
        updateState={setLanes}
        handleClose={() => {
          setActiveLane(null);
        }}
      />

      {activeSummary && (
        <SummarizeDialog
          fileId={fileId}
          file={file}
          projectId={projectId}
          nodesCount={nodes.length}
          handleClose={() => {
            setActiveSummary(false);
          }}
        />
      )}

      {activeAutoCorrect && (
        <AutoCorrectDialog
          fileId={fileId}
          file={file}
          projectId={projectId}
          nodesCount={nodes.length}
          handleClose={() => {
            setActiveAutoCorrect(false);
          }}
        />
      )}

      <EditGroupDialog
        group={activeGroup}
        updateState={setGroups}
        handleClose={() => {
          setActiveGroup(null);
        }}
      />

      {alertType.type && (alertType.type !== ALERT_TYPE.ADD_ROW || isHideAddRowAlert !== HIDE) && (
        <AlertDialog
          isOpen={true}
          type={alertType.type}
          callback={alertType.callback}
          handleClose={() => {
            setAlertType({
              type: '',
              callback: () => {}
            });
          }}
        />
      )}
      {showAIModal && nodes.length > 0 && (
        <WorkflowAIDialog
          isOpen={true}
          count={nodes.length}
          handleRetry={handleRetryAIGenerate}
          handleSubmit={handleConfirmAIGenerate}
          handleClose={() => {
            deleteFile({
              variables: {
                id: workflowId
              },
              onCompleted: () => {
                navigate(`/projects/${projectId}`);
              }
            });
          }}
        />
      )}
      {showAISummaryModal && nodes.length > 0 && (
        <WorkflowAISummaryDialog
          isOpen={true}
          count={nodes.length}
          previousCount={queryParameters.get('nodeCount')}
          handleRetry={() => {}}
          handleSubmit={handleConfirmAISummaryGenerate}
          handleClose={() => {
            deleteFile({
              variables: {
                id: workflowId
              },
              onCompleted: () => {
                navigate(`/projects/${projectId}`);
              }
            });
          }}
        />
      )}
      {showAIAutoCorrectModal && nodes.length > 0 && (
        <WorkflowAIModifyDialog
          isOpen={true}
          count={nodes.length}
          previousCount={queryParameters.get('nodeCount')}
          handleRetry={() => {}}
          handleSubmit={handleConfirmAIAutoCorrectGenerate}
          handleClose={() => {
            deleteFile({
              variables: {
                id: workflowId
              },
              onCompleted: () => {
                navigate(`/projects/${projectId}`);
              }
            });
          }}
        />
      )}
      <Snackbar
        open={openAlert.isOpen}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        key={'top' + 'right'}>
        <Alert
          onClose={handleCloseAlert}
          severity={openAlert.type === 'CopyClipboard' ? 'info' : 'success'}
          sx={{
            width: '366px',
            fontSize: '16px',
            fontWeight: '500'
          }}>
          {openAlert.type === 'CopyClipboard'
            ? '共有リンクをコピーしました'
            : openAlert.type === NodePropertyStatusEnum.Complete
            ? `${openAlert.nodeId}を完了にしました`
            : `${openAlert.nodeId}を未完了に戻しました`}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Canvas;
