import { NodeTypeEnum } from '@/graphql';
import React from 'react';

export const getIconByTaskType = (type: string) => {
  if (type === NodeTypeEnum.Task || type === NodeTypeEnum.Start || type === NodeTypeEnum.End)
    return <img src="/icons/task.svg" alt="task" />;
  else if (type === NodeTypeEnum.Condition)
    return <img src="/icons/condition.svg" alt="condition" />;
  else return null;
};
