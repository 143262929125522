import { v4 as uuidv4 } from 'uuid';
import { getElementType, isTargetRowLabel } from '../../../../components/workflow/Canvas/utils';
import { orderBy, remove } from 'lodash';
import { useRefCallback } from '../../../../hooks/useRefCallback';
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  CREAT_GROUP,
  CREATE_NODE,
  CREATE_NODE_RELATION,
  DELETE_COLUMN,
  DELETE_GROUP,
  DELETE_NODE,
  DELETE_NODE_RELATION,
  DELETE_ROW,
  INSERT_ROW,
  UPDATE_NODE_PROPERTY,
  UPDATE_NODE_RELATION_PROPERTY
} from '../../../../api/graphql';
import { ui } from '@clientio/rappid';
import { ALERT_TYPE, HIDE, LANE_HEADER_SLIM_WIDTH, PART_HEIGHT } from '../elements/constant';

export const useListenerShowMenu = ({
  fileId,
  paper,
  lanes,
  nodes,
  groups,
  selectedRows,
  setSelectedRows,
  setNodes,
  setRelations,
  setLanes,
  setGroups,
  setTickets,
  setAlertType,
  setActiveLane,
  setActiveGroup,
  setActiveNode,
  isHideAddRowAlert,
  refetch
}) => {
  const [deleteNode] = useMutation(DELETE_NODE);
  const [createNode] = useMutation(CREATE_NODE);
  const [insertRow] = useMutation(INSERT_ROW);
  const [deleteRow] = useMutation(DELETE_ROW);
  const [deleteColumn] = useMutation(DELETE_COLUMN);
  const [createGroup] = useMutation(CREAT_GROUP);
  const [deleteGroup] = useMutation(DELETE_GROUP);
  const [createNodeRelation] = useMutation(CREATE_NODE_RELATION);
  const [deleteNodeRelation] = useMutation(DELETE_NODE_RELATION);
  const [updateNodeProperty] = useMutation(UPDATE_NODE_PROPERTY);
  const [updateNodeRelationProperty] = useMutation(UPDATE_NODE_RELATION_PROPERTY);
  const [menu, setMenu] = useState(null);

  const onAddButtonClick = async (currentElement) => {
    const columnIndex = currentElement.prop('columnIndex');
    const rowIndex = currentElement.prop('rowIndex');
    const lane = lanes[columnIndex];
    const taskColumnIndex = lanes.findIndex((lane) => lane.type === 'task');
    let newNodeId = uuidv4();
    let relationNode;
    const row = rowIndex;
    let nodeBasic = {};
    let relationBasic = {};
    switch (lane.type) {
      case 'task':
        nodeBasic = {
          name: 'プロセス名',
          type: 'task'
        };
        // Only connect with the node above the new node (other column is ok)
        const relationNodes = nodes.filter(
          (node) => node.rowIndex === row - 1
        );
        // If there are multiple nodes above this new one, choose the closest one from left
        relationNode = orderBy(relationNodes, 'columnIndex', 'desc')[0];
        if (relationNode) {
          relationBasic = {
            out: 'top',
            in: 'bottom',
            fromId: relationNode.id,
            toId: newNodeId
          };
        }
        break;
      case 'system':
        nodeBasic = {
          name: 'システム'
        };
        break;
      default:
        nodeBasic = {
          title: '他の'
        };
    }

    const nodeResponse = await createNode({
      variables: {
        in: {
          fileId,
          type: nodeBasic.type || 'task',
          rowIndex: row,
          columnIndex: columnIndex
        }
      }
    });
    newNodeId = nodeResponse.data.createNode;

    setNodes((prevData) => {
      const newData = [...prevData];
      const newNode = {
        ...nodeBasic,
        id: newNodeId,
        rowIndex: row,
        columnIndex: columnIndex,
        property: {
          name: '',
          nodeId: newNodeId,
          priority: '',
          type: '',
          media: '',
          description: ''
        }
      };
      newData.push(newNode);
      setActiveNode(newNode);
      if (lane.type === 'system') {
        relationNode = orderBy(
          newData.filter((node) => node.columnIndex === taskColumnIndex),
          ['rowIndex'],
          ['desc']
        )[0];
        relationBasic = {
          out: 'left',
          in: 'right',
          fromId: relationNode.id,
          toId: newNodeId
        };
      }
      return newData;
    });

    if (relationBasic.fromId && newNodeId) {
      const relationResponse = await createNodeRelation({
        variables: {
          in: {
            fileId,
            fromId: relationBasic.fromId,
            toId: newNodeId
          }
        },
        onCompleted: () => {
          setRelations((prevData) => {
            const newData = [...prevData];
            newData.push({
              ...relationBasic,
              id: relationResponse.data.createNodeRelation,
              toId: newNodeId
            });
            return newData;
          });
          refetch();
        }
      });
    }
  };

  const onAddConditionClick = async (currentElement) => {
    const columnIndex = currentElement.prop('columnIndex');
    const rowIndex = currentElement.prop('rowIndex');
    const { x, y } = currentElement.position();
    const conditionNodes = [
      {
        name: '',
        type: 'condition',
        columnIndex,
        rowIndex: rowIndex,
        property: {
          name: ''
        }
      },
      {
        name: '（条件１）プロセス名',
        type: 'task',
        columnIndex,
        rowIndex: rowIndex + 1,
        property: {
          name: '（条件１）プロセス名'
        }
      },
      {
        name: '（条件２）プロセス名',
        type: 'task',
        columnIndex,
        rowIndex: rowIndex + 2,
        property: {
          name: '（条件２）プロセス名'
        }
      }
    ];

    // Insert 2 new rows for placeholder
    await insertNewRow(rowIndex);
    await insertNewRow(rowIndex + 1);
    // TODO: Change to a new mutation that will be created by backend
    // Since there are six mutations to create a condition, and there is no error catch now.
    const [parentNodeResponse, child1NodeResponse, child2NodeResponse] = await Promise.all(
      conditionNodes.map((node) => {
        return createNode({
          variables: {
            in: {
              fileId,
              columnIndex,
              rowIndex: node.rowIndex,
              type: node.type
            }
          }
        });
      })
    );

    const parentNodeId = parentNodeResponse.data.createNode;
    const child1NodeId = child1NodeResponse.data.createNode;
    const child2NodeId = child2NodeResponse.data.createNode;

    const conditionRelations = [
      {
        fromId: parentNodeId,
        toId: child1NodeId,
        property: {
          description: '条件１',
          positions: {
            output: 'bottom',
            input: 'top'
          }
        }
      },
      {
        fromId: parentNodeId,
        toId: child2NodeId,
        property: {
          description: '条件２',
          positions: {
            output: 'right',
            input: 'right',
            vertices: [
              {
                x: x + LANE_HEADER_SLIM_WIDTH,
                y: y + PART_HEIGHT / 2
              }
            ]
          }
        }
      }
    ];

    const [relation1Response, relation2Response] = await Promise.all(
      conditionRelations.map((relation) => {
        return createNodeRelation({
          variables: {
            in: {
              fileId,
              fromId: relation.fromId,
              toId: relation.toId
            }
          }
        });
      })
    );

    conditionNodes.map((node, index) => {
      node.id = node.property.nodeId = [parentNodeId, child1NodeId, child2NodeId][index];
      updateNodeProperty({
        variables: {
          in: {
            nodeId: node.id,
            name: node.name
          }
        }
      });
    });

    const relation1Id = relation1Response.data.createNodeRelation;
    const relation2Id = relation2Response.data.createNodeRelation;

    conditionRelations.map((relation, index) => {
      relation.id = [relation1Id, relation2Id][index];
      return updateNodeRelationProperty({
        variables: {
          in: {
            nodeRelationId: relation.id,
            description: relation.property.description,
            positions: JSON.stringify(relation.property.positions)
          }
        },
        onCompleted: (data) => {
          relation.property = {
            ...relation.property,
            id: data.updateNodeRelationProperty,
            nodeRelationId: relation.id
          };
        }
      });
    });

    let relationNode;
    const relationNodes = nodes.filter(
      (node) => node.rowIndex === rowIndex - 1 && node.columnIndex <= columnIndex
    );
    // If there are multiple nodes above this new one, choose the closest one from left
    relationNode = orderBy(relationNodes, 'columnIndex', 'desc')[0];
    let relationBasic = {};
    if (relationNode) {
      relationBasic = {
        out: 'top',
        in: 'bottom',
        fromId: relationNode.id,
        toId: parentNodeId
      };
    }

    let relationBasicResponse = null;
    if (relationBasic.fromId && parentNodeId) {
      await createNodeRelation({
        variables: {
          in: {
            fileId,
            fromId: relationBasic.fromId,
            toId: parentNodeId
          }
        },
        onCompleted: (data) => {
          relationBasicResponse = data.createNodeRelation;
        }
      });
    }
    setNodes((prevState) => {
      let newState = [...prevState];
      newState = [...newState.concat(conditionNodes)];
      setActiveNode(conditionNodes[0]);
      return newState;
    });

    setRelations((prevState) => {
      let newState = [...prevState];
      newState = [...newState.concat(conditionRelations)];
      if (relationBasic.fromId && parentNodeId && relationBasicResponse) {
        newState.push({
          ...relationBasic,
          id: relationBasicResponse,
          toId: parentNodeId
        });
      }
      return newState;
    });
    refetch();
  };

  const elementActions = (target, elementView, tools) => {
    const element = elementView.model;
    const ctxToolbar = new ui.ContextToolbar({
      target,
      position: 'center',
      padding: 5,
      vertical: true,
      tools: tools
    });
    setMenu(ctxToolbar);
    ctxToolbar.render();
    ctxToolbar.on({
      'action:remove': () => {
        ctxToolbar.remove();
        setAlertType({
          type: ALERT_TYPE.REMOVE_PART,
          callback: () => {
            const id = element.prop('pid');
            deleteNode({
              variables: { id },
              onCompleted: () => {
                setRelations((prevData) => {
                  const newData = [...prevData];
                  remove(newData, (relation) => relation.fromId === id || relation.toId === id);
                  return newData;
                });
                setNodes((prevData) => {
                  const newData = [...prevData];
                  remove(newData, (node) => node.id === id);
                  return newData;
                });
              }
            });
          }
        });
      },
      'action:remove-lane': () => {
        ctxToolbar.remove();
        setAlertType({
          type: ALERT_TYPE.REMOVE_COLUMN,
          callback: () => {
            const column = element.prop('column');
            deleteColumn({
              variables: {
                in: { fileId, index: column }
              },
              onCompleted: () => {
                setLanes((prevData) => {
                  const newData = [...prevData];
                  newData.splice(column, 1);
                  return newData;
                });
                refetch();
              }
            });
          }
        });
      },
      'action:edit-lane': () => {
        ctxToolbar.remove();
        const columnIndex = element.prop('column');
        const lane = lanes[columnIndex];
        setActiveLane(lane);
      },
      'action:add-normal': () => {
        ctxToolbar.remove();
        onAddButtonClick(element);
      },
      'action:add-condition': () => {
        ctxToolbar.remove();
        onAddConditionClick(element);
      },
      'action:add-start': () => {
        ctxToolbar.remove();
        onAddButtonClick(element);
      },
      'action:add-end': () => {
        ctxToolbar.remove();
        onAddButtonClick(element);
      },
      'action:remove-row': () => {
        ctxToolbar.remove();
        setAlertType({
          type: ALERT_TYPE.REMOVE_ROW,
          callback: () => {
            const row = element.prop('row');
            let removedNodeIds = nodes
              .filter((node) => node.rowIndex === row)
              .map((node) => node.id);
            deleteRow({
              variables: { in: { fileId, index: row } },
              onCompleted: () => {
                setRelations((prevState) => {
                  const newState = [...prevState];
                  remove(
                    newState,
                    (relation) =>
                      removedNodeIds.includes(relation.fromId) ||
                      removedNodeIds.includes(relation.toId)
                  );
                  return newState;
                });
                setTickets((prevState) => {
                  const newState = [...prevState];
                  remove(newState, (ticket) => removedNodeIds.includes(ticket.nodeId));
                  return newState;
                });
                setNodes((prevData) => {
                  const newData = [...prevData];
                  remove(newData, (node) => node.rowIndex === row);
                  newData.forEach((node) => {
                    if (node.rowIndex > row) {
                      node.rowIndex--;
                    }
                  });
                  return newData;
                });
                setGroups((prevData) => {
                  const newData = prevData.map((node) => {
                    const updatedRowIndexes = node.rowIndexes.map((index) => {
                      if (index > row) {
                        return index - 1;
                      }
                      return index;
                    });
                    return {
                      ...node,
                      rowIndexes: updatedRowIndexes
                    };
                  });
                  return newData;
                });
                setSelectedRows([]);
              }
            });
          }
        });
      },
      'action:add-row': async () => {
        ctxToolbar.remove();
        if (isHideAddRowAlert === HIDE) addRowHandler(element);
        setAlertType({
          type: ALERT_TYPE.ADD_ROW,
          callback: () => {
            addRowHandler(element);
          }
        });
      },
      'action:edit-group': () => {
        ctxToolbar.remove();
        const groupId = element.prop('gid');
        const group = groups.find((group) => group.id === groupId);
        setActiveGroup(group);
      },
      'action:remove-group': () => {
        ctxToolbar.remove();
        const gid = element.prop('gid');
        deleteGroup({
          variables: {
            id: gid
          },
          onCompleted: () => {
            setGroups((prevData) => {
              const newData = [...prevData];
              remove(newData, (group) => group.id === gid);
              return newData;
            });
          }
        });
      },
      'action:grouping': () => {
        ctxToolbar.remove();
        const name = `グループ${groups.length + 1}`;
        createGroup({
          variables: {
            in: {
              fileId,
              name,
              rowIndexes: selectedRows
            }
          },
          onCompleted: (data) => {
            console.log(groups);
            setGroups((prevData) => {
              const newData = [...prevData];
              newData.push({
                id: data.createGroup,
                name,
                rowIndexes: selectedRows
              });
              return newData;
            });
            setSelectedRows([]);
          }
        });
      }
    });
  };

  const menuHandlerRef = useRefCallback((elementView, evt) => {
    // ContextToolbar is triggered at mousedown, so need to remove it at the dbclick event
    if (evt.type === 'dblclick' && menu) {
      menu.remove();
      return;
    }
    const type = getElementType(elementView);
    if (evt.type === 'contextmenu' && !isTargetRowLabel(evt)) {
      return;
    }
    const model = elementView.model;
    let tools = model.getActions();
    if (tools) {
      // 「行をクリックしたら（右クリックではなく左クリックで）すぐに「下に行を挿入」「行を削除」のメニューを出す」関連で修正（後で確認）
      //evt.stopPropagation();
      let target = null;
      switch (type) {
        case 'WorkflowPart':
        case 'WorkflowLane':
        case 'WorkflowGroup':
          target = elementView.findBySelector('moreIcon');
          break;
        case 'WorkflowDivider':
          if (selectedRows.length < 2) {
            remove(tools, (tool) => tool.action === 'grouping');
          }
          target = elementView.findBySelector('body');
          break;
        default:
          target = elementView.el;
          break;
      }
      elementActions(target, elementView, tools);
    }
  });

  const insertNewRow = async (row) => {
    await insertRow({
      variables: {
        in: {
          fileId,
          index: row,
          insertType: 'down'
        }
      }
    });
    setNodes((prevData) => {
      const newData = [...prevData];
      newData.forEach((node) => {
        if (node.rowIndex > row) {
          node.rowIndex++;
        }
      });
      return newData;
    });
  };

  const addRowHandler = (element) => {
    const row = element.prop('row');
    insertNewRow(row);
    setRelations((prevState) => {
      const newState = [...prevState];
      const removedRelations = remove(newState, (relation) => {
        const { fromId, toId } = relation;
        const fromNode = nodes.find((node) => node.id === fromId);
        const toNode = nodes.find((node) => node.id === toId);
        // Remove the arrows which are:
        // - In same column
        // - Start node and end node are just before/after the insert row
        // - Note: row is the row user clicks, actually the added row is row + 1
        return (
          fromNode.columnIndex === toNode.columnIndex &&
          ((fromNode.rowIndex === row && toNode.rowIndex === row + 2) ||
            (fromNode.rowIndex === row + 2 && toNode.rowIndex === row))
        );
      });
      if (removedRelations.length) {
        removedRelations.forEach((relation) => {
          deleteNodeRelation({
            variables: { id: relation.id }
          });
        });
      }
      return newState;
    });
    setGroups((prevData) => {
      const newData = prevData.map((node) => {
        const updatedRowIndexes = node.rowIndexes.map((index) => {
          if (index > row) {
            return index + 1;
          }
          return index;
        });
        return {
          ...node,
          rowIndexes: updatedRowIndexes
        };
      });
      return newData;
    });
    setSelectedRows([]);
  };

  useEffect(() => {
    if (paper) {
      paper.on(
        'element:show:menu element:contextmenu element:pointerdblclick',
        (elementView, evt) => menuHandlerRef.current(elementView, evt)
      );
    }
  }, [paper]);
};
