import React from 'react';
import { Avatar, Menu, MenuItem } from '@mui/material';
import { UserQuery } from '@/__generated__/graphql';

interface Props {
  title: string;
  subTitle: string;
  user?: UserQuery;
  handleSignOut: () => void;
  handleOpenUserProfileModal?: (open: boolean) => void;
}

const SpaceTitle = (props: Props) => {
  const { title, user, subTitle, handleSignOut, handleOpenUserProfileModal } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="text-left flex justify-content-between align-content-center">
      <div>
        <div className="font-size-24 font-weight-700">{title}</div>
        <div className="font-size-14 font-weight-500">{subTitle}</div>
      </div>
      <Avatar
        variant="circular"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={{ cursor: 'pointer' }}
        src={
          user?.user.icon
            ? `${user.user.icon}?timestamp=${new Date().getTime()}`
            : '/images/grey.png'
        }></Avatar>
      <Menu
        id="space-top-menu"
        anchorEl={anchorEl}
        open={open}
        sx={{ mt: 1 }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox'
        }}>
        <MenuItem
          key="space-top-menu-1"
          onClick={() => handleOpenUserProfileModal && handleOpenUserProfileModal(true)}
          sx={{ color: '#000000DE' }}>
          プロフィールを編集
        </MenuItem>
        <MenuItem key="space-top-menu-2" onClick={handleSignOut} sx={{ color: 'red' }}>
          ログアウト
        </MenuItem>
      </Menu>
    </div>
  );
};

export default SpaceTitle;
