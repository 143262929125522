import React from 'react';

import Box from '@mui/material/Box';
import WorkflowTicketListPage from './../../../components/organisms/workflows/TicketList';
import { RootContext } from '@/context/RootProvider';

const WorkflowTicketPage = () => {
  const rootContext = React.useContext(RootContext);
  return (
    <div
      className={`workflow-main ${rootContext?.isSidebarShowing ? '' : 'workflow-hide-sidebar'}`}>
      <Box className="h-full">
        <WorkflowTicketListPage isSidebarShowing={rootContext?.isSidebarShowing} />
      </Box>
    </div>
  );
};

export default WorkflowTicketPage;
