import React from 'react';

import Box from '@mui/material/Box';
import WorkflowTaskListPage from './../../../components/organisms/workflows/TaskList';
import { RootContext } from '@/context/RootProvider';

const WorkflowTaskPage = () => {
  const rootContext = React.useContext(RootContext);
  return (
    <div
      className={`workflow-main ${rootContext?.isSidebarShowing ? '' : 'workflow-hide-sidebar'}`}>
      <Box className="h-full">
        <WorkflowTaskListPage isSidebarShowing={rootContext?.isSidebarShowing} />
      </Box>
    </div>
  );
};

export default WorkflowTaskPage;
