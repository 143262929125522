import React, { useEffect, useRef, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableSortLabel } from '@mui/material';
import { getIcon } from '@/components/PartEditor';
import { TaskItemType } from '@/types/tasks';
import Box from '@mui/material/Box';
import { getIconByTaskType } from '@/utils/tasks';

interface Props {
  tasks: TaskItemType[];
  excludeViews: string[];
  setSelectedTask: (task: TaskItemType) => void;
  selectedTask: TaskItemType;
}

type Order = 'asc' | 'desc';
type TaskRefs = {
  [key: string]: React.RefObject<HTMLDivElement>;
};
const TaskTable = (props: Props) => {
  const { tasks, excludeViews, setSelectedTask, selectedTask } = props;
  const taskRefs = useRef<TaskRefs>({});
  tasks.forEach((task) => {
    if (!taskRefs.current[task.id]) {
      taskRefs.current[task.id] = React.createRef();
    }
  });
  const [sortedTasks, setSortedTasks] = useState<TaskItemType[]>(
    tasks.sort((a, b) => {
      return Number(a.rowIndex) - Number(b.rowIndex);
    })
  );
  const [direction, setDirection] = useState({
    column: 'asc' as Order,
    name: 'asc' as Order,
    description: 'asc' as Order,
    lane: 'asc' as Order,
    media: 'asc' as Order,
    timeValue: 'asc' as Order
  });

  useEffect(() => {
    if (sortedTasks) {
      let newTasks = [] as TaskItemType[];
      let existTaskNodeId: string[] = [];
      sortedTasks.map((sortedTask) => {
        const existTask = tasks.find((item) => item.id === sortedTask.id);
        if (existTask) {
          newTasks.push(existTask);
          existTaskNodeId.push(sortedTask.id);
        }
        return true;
      });
      tasks.map((task) => {
        if (!existTaskNodeId.includes(task.id)) {
          newTasks.push(task);
        }
        return true;
      });
      setSortedTasks(newTasks);
    } else {
      setSortedTasks(tasks);
    }
  }, [tasks]);

  useEffect(() => {
    if (selectedTask && taskRefs.current[selectedTask.id]) {
      const ref = taskRefs.current[selectedTask.id].current;
      if (ref) {
        ref.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        });
      }
    }
  }, [selectedTask]);

  const isShowDescriptionArea = !excludeViews.includes('description');
  const isShowTimeValueArea = !excludeViews.includes('timeValue');
  const isShowMediaArea = !excludeViews.includes('media');
  const isShowLaneArea = !excludeViews.includes('lane');

  const sortByItem = (type: string) => {
    if (!sortedTasks) return;
    setSortedTasks(
      sortedTasks.slice().sort((a, b) => {
        switch (type) {
          case 'name':
            setDirection({ ...direction, name: direction.name === 'asc' ? 'desc' : 'asc' });
            return direction.name === 'asc'
              ? b.name.localeCompare(a.name)
              : a.name.localeCompare(b.name);
          case 'description':
            setDirection({
              ...direction,
              description: direction.description === 'asc' ? 'desc' : 'asc'
            });
            return direction.description === 'asc'
              ? b.property.description.localeCompare(a.property.description)
              : a.property.description.localeCompare(b.property.description);
          case 'media':
            setDirection({ ...direction, media: direction.media === 'asc' ? 'desc' : 'asc' });
            return direction.media === 'asc'
              ? (b.property.mediaLabel ?? '').localeCompare(a.property.mediaLabel ?? '')
              : (a.property.mediaLabel ?? '').localeCompare(b.property.mediaLabel ?? '');
          case 'lane':
            setDirection({ ...direction, lane: direction.lane === 'asc' ? 'desc' : 'asc' });
            return direction.lane === 'asc'
              ? b.lane.localeCompare(a.lane)
              : a.lane.localeCompare(b.lane);
          case 'timeValue':
            setDirection({
              ...direction,
              timeValue: direction.timeValue === 'asc' ? 'desc' : 'asc'
            });
            return direction.timeValue === 'asc'
              ? Number(b.property.timeValue) - Number(a.property.timeValue)
              : Number(a.property.timeValue) - Number(b.property.timeValue);
          case 'column':
          default:
            setDirection({ ...direction, column: direction.column === 'asc' ? 'desc' : 'asc' });
            return direction.column === 'asc'
              ? Number(b.rowIndex) - Number(a.rowIndex)
              : Number(a.rowIndex) - Number(b.rowIndex);
        }
      })
    );
  };

  const getLaneColor = (row: TaskItemType) => {
    if (row.laneColor === '#FEEBEE') {
      return '#E57373';
    } else if (row.laneColor === '#E8F5E9') {
      return '#81C784';
    } else if (row.laneColor === '#E3F2FD') {
      return '#64B5F6';
    }
    return row.laneColor;
  };

  return (
    <TableContainer component={Paper} sx={{ paddingTop: '64px' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ backgroundColor: '#F5F5F5' }}>
            <TableCell sx={{ cursor: 'pointer', padding: '6px 16px' }}>
              <TableSortLabel
                active={true}
                direction={direction.column}
                sx={{
                  '&.Mui-active .MuiTableSortLabel-icon': { opacity: 0 },
                  '&:hover .MuiTableSortLabel-icon': { opacity: 1 }
                }}
                onClick={() => sortByItem('column')}>
                行
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ cursor: 'pointer', padding: '6px 16px' }}>
              <TableSortLabel
                active={true}
                direction={direction.name}
                sx={{
                  '&.Mui-active .MuiTableSortLabel-icon': { opacity: 0 },
                  '&:hover .MuiTableSortLabel-icon': { opacity: 1 }
                }}
                onClick={() => sortByItem('name')}>
                プロセス名
              </TableSortLabel>
            </TableCell>
            {isShowDescriptionArea && (
              <TableCell sx={{ cursor: 'pointer', padding: '6px 16px' }}>
                <TableSortLabel
                  active={true}
                  direction={direction.description}
                  sx={{
                    '&.Mui-active .MuiTableSortLabel-icon': { opacity: 0 },
                    '&:hover .MuiTableSortLabel-icon': { opacity: 1 }
                  }}
                  onClick={() => sortByItem('description')}>
                  説明
                </TableSortLabel>
              </TableCell>
            )}
            {isShowTimeValueArea && (
              <TableCell sx={{ cursor: 'pointer', padding: '6px 16px' }}>
                <TableSortLabel
                  active={true}
                  direction={direction.timeValue}
                  sx={{
                    '&.Mui-active .MuiTableSortLabel-icon': { opacity: 0 },
                    '&:hover .MuiTableSortLabel-icon': { opacity: 1 }
                  }}
                  onClick={() => sortByItem('timeValue')}>
                  工数
                </TableSortLabel>
              </TableCell>
            )}
            {isShowMediaArea && (
              <TableCell sx={{ cursor: 'pointer', padding: '6px 16px' }}>
                <TableSortLabel
                  active={true}
                  direction={direction.media}
                  sx={{
                    '&.Mui-active .MuiTableSortLabel-icon': { opacity: 0 },
                    '&:hover .MuiTableSortLabel-icon': { opacity: 1 }
                  }}
                  onClick={() => sortByItem('media')}>
                  利用ツール
                </TableSortLabel>
              </TableCell>
            )}
            {isShowLaneArea && (
              <TableCell sx={{ cursor: 'pointer', padding: '6px 16px' }}>
                <TableSortLabel
                  active={true}
                  direction={direction.lane}
                  sx={{
                    '&.Mui-active .MuiTableSortLabel-icon': { opacity: 0 },
                    '&:hover .MuiTableSortLabel-icon': { opacity: 1 }
                  }}
                  onClick={() => sortByItem('lane')}>
                  レーン
                </TableSortLabel>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedTasks.map((row, index) => (
            <TableRow
              key={`task_item_${index}`}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                cursor: 'pointer',
                '&.Mui-selected, &.Mui-selected:hover': {
                  backgroundColor: '#2196F314'
                },
                '&.MuiTableRow-root:hover': {
                  backgroundColor: '#F5F5F5'
                }
              }}
              onClick={() => setSelectedTask(row)}
              selected={selectedTask?.id === row.id}>
              <TableCell
                ref={taskRefs.current[row.id]}
                component="th"
                scope="row"
                sx={{
                  width: '4%',
                  textAlign: 'center'
                }}>
                {row.rowIndex + 1}
              </TableCell>
              <TableCell sx={{ width: '28%' }}>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  {getIconByTaskType(row.type)}
                  {row.name}
                </Box>
              </TableCell>
              {isShowDescriptionArea && (
                <TableCell sx={{ width: '32%' }}>{row.property.description}</TableCell>
              )}
              {isShowTimeValueArea && <TableCell>{row.property.timeValue}</TableCell>}
              {isShowMediaArea && (
                <TableCell
                  sx={{
                    fontSize: 12,
                    color: '#00000099'
                  }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {row.property.media && getIcon(row.property.media)}
                    <span style={{ marginLeft: row.property.media ? 0 : 25 }}>
                      {row.property.mediaLabel ? row.property.mediaLabel : ''}
                    </span>
                  </Box>
                </TableCell>
              )}
              {isShowLaneArea && (
                <TableCell>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <span
                      style={{
                        display: 'inline-flex',
                        backgroundColor: getLaneColor(row),
                        width: '16px',
                        height: '16px'
                      }}
                    />
                    {row.lane}
                  </Box>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TaskTable;
