import Box from '@mui/material/Box';
import {
  NodeTypeEnum,
  useAssignNodeMutation,
  useCreateNodeMutation,
  useCreateNodePropertyCommentMutation,
  useDeleteNodeMutation,
  useDetachNodeMutation,
  useNodesQuery,
  useUpdateNodePropertyMutation
} from '@/graphql';
import { useParams } from 'react-router-dom';
import WorkflowTicketListPage from '@/components/templates/WorkflowsTicketList';

const WorkflowTicketV2Page = () => {
  let { workflowId } = useParams();
  const fileId = workflowId as string;

  const { data, loading, refetch } = useNodesQuery({
    variables: {
      filter: {
        fileId,
        types: [NodeTypeEnum.Ticket]
      }
    }
  });

  const [updateProperty] = useUpdateNodePropertyMutation({
    onCompleted: async () => refetch()
  });
  const [createCommentForProperty] = useCreateNodePropertyCommentMutation({
    onCompleted: async () => refetch()
  });
  const [createNode] = useCreateNodeMutation({
    onCompleted: async () => refetch()
  });
  const [deleteNode] = useDeleteNodeMutation({
    onCompleted: async () => refetch()
  });
  const [assignNode] = useAssignNodeMutation({
    onCompleted: async () => refetch()
  });
  const [detachNode] = useDetachNodeMutation({
    onCompleted: async () => refetch()
  });

  if (loading || !data || !data.nodes || !data.nodes.nodes) {
    return <></>;
  }

  return (
    <Box className="h-full">
      <WorkflowTicketListPage
        fileId={fileId}
        tickets={data.nodes.nodes}
        createNode={createNode}
        deleteNode={deleteNode}
        assignNode={assignNode}
        detachNode={detachNode}
        updateProperty={updateProperty}
        createCommentForProperty={createCommentForProperty}
      />
    </Box>
  );
};

export default WorkflowTicketV2Page;
