import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import TaskListHeader from '../../../molecules/workflows/TaskListHeader';
import TaskTable from '../../../molecules/workflows/TaskTable';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_ALL_IN_WORKFLOW } from '@/api/graphql';
import { NodePropertyPriorityEnum, NodeTypeEnum } from '@/__generated__/graphql';
import { formatRaw } from '@/components/workflow/Canvas/utils/init';
import { PartEditor } from '@/components/PartEditor';
import { Drawer as MuiDrawer, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FilterValueType, TaskItemType } from '@/types/tasks';
import { APPBAR_HEIGHT } from '@/components/workflow/Canvas/elements/constant';
import CircularProgress from '@mui/material/CircularProgress';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '90%',
  bgcolor: 'background.paper',
  border: '2px solid #FFFFFF',
  borderRadius: 1
};

const Drawer = styled(MuiDrawer)({
  '& .MuiModal-backdrop': {
    background: 'transparent'
  },
  '& .MuiDrawer-paper': {
    top: APPBAR_HEIGHT
  }
});

const WorkflowTaskListPage = ({ isSidebarShowing = true }) => {
  let { workflowId } = useParams();
  const fileId = workflowId as string;
  const { loading, error, data, refetch } = useQuery(GET_ALL_IN_WORKFLOW, {
    variables: {
      nodeFilter: { fileId },
      columnFilter: { fileId },
      groupFilter: { fileId },
      rowFilter: { fileId },
      kindFilter: { fileId },
      usersFilter: { fileId }
    },
    fetchPolicy: 'network-only'
  });

  const [excludeViews, setExcludeViews] = useState<string[]>(['timeValue', 'media']);
  const [activeNode, setActiveNode] = useState<any>(null);
  const [showZoomEditor, setShowZoomEditor] = useState(false);
  const [isSidePickerInput, setIsSidePickerInput] = useState(false);
  const [filterValue, setFilterValue] = useState<FilterValueType>({
    start: true,
    end: true,
    task: true,
    condition: true,
    isAuthor: true,
    low: true,
    medium: true,
    high: true
  });

  useEffect(() => {
    if (loading) return;
    if (activeNode) {
      const { nodes } = formatRaw(data);
      setActiveNode(nodes?.find((node) => node.id === activeNode.id));
    }
  }, [data]);

  if (loading)
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '95px' }}>
        <CircularProgress size={24} />
      </Box>
    );

  const { nodes, lanes, kinds, relations, users } = formatRaw(data);
  const tasks = nodes.map((node) => {
    return {
      ...node,
      lane: lanes[node.columnIndex].name,
      laneColor: lanes[node.columnIndex].color,
      priority: node.property.priority
    };
  }) as TaskItemType[];

  const filteredTask = (): TaskItemType[] => {
    return tasks.filter((task) => {
      if (!filterValue.start && task.type === NodeTypeEnum.Start) return false;
      if (!filterValue.end && task.type === NodeTypeEnum.End) return false;
      if (!filterValue.task && task.type === NodeTypeEnum.Task) return false;
      if (!filterValue.condition && task.type === NodeTypeEnum.Condition) return false;
      if (!filterValue.low && task.priority === NodePropertyPriorityEnum.Low) return false;
      if (!filterValue.medium && task.priority === NodePropertyPriorityEnum.Medium) return false;
      if (!filterValue.high && task.priority === NodePropertyPriorityEnum.High) return false;
      // ToDo 担当者,作成者, 種類のfilterを追加
      return true;
    });
  };
  const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
    const isOutsideTicketList = (event.target as HTMLElement).classList.contains('h-full');
    if (isOutsideTicketList && activeNode) {
      setActiveNode(null);
    }
  };

  const handleOpenZoomEditor = (node: any) => {
    setActiveNode(node);
    setShowZoomEditor(true);
  };

  const handleChangeActiveNode = (node: any) => {
    setActiveNode(node);
  };

  return (
    <>
      <Box className="h-full text-left" onClick={handleClickOutside}>
        <TaskListHeader
          tasks={filteredTask()}
          excludeViews={excludeViews}
          setExcludeViews={setExcludeViews}
          filterValue={filterValue}
          setFilterValue={setFilterValue}
          selectedTask={(taskId) => setActiveNode(nodes?.find((node) => node.id === taskId))}
          isSidebarShowing={isSidebarShowing}
        />
        <TaskTable
          tasks={filteredTask()}
          selectedTask={activeNode}
          excludeViews={excludeViews}
          setSelectedTask={(task) => {
            setActiveNode(nodes?.find((node) => node.id === task.id));
            setShowZoomEditor(true);
          }}
        />
      </Box>

      <Modal open={showZoomEditor && activeNode !== null}>
        {showZoomEditor && activeNode ? (
          <Box sx={style}>
            <PartEditor
              users={users}
              detailPage={true}
              lanes={lanes}
              nodes={nodes}
              node={activeNode}
              kinds={kinds}
              relations={relations}
              refetchFlag={true}
              property={activeNode.property}
              setActiveNode={() => {}}
              setNodes={() => {}}
              setRelations={() => {}}
              setLastActiveNodeName={() => {}}
              setLastActiveNodeDesc={() => {}}
              isSidePickerInput={isSidePickerInput}
              setIsSidePickerInput={setIsSidePickerInput}
              refetchAllNodes={() => {
                refetch();
              }}
              handleOpenZoomEditor={handleChangeActiveNode}
              handleCloseEditor={() => setShowZoomEditor(false)}
            />
          </Box>
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
};

export default WorkflowTaskListPage;
