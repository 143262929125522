import React, { useEffect, useState } from 'react';
import SpaceLayout from './SpaceLayout';
//import SpaceProjectList from '../../../molecules/spaces/SpaceProjectList';
import SpaceProjectList from '@/components/organisms/spaces/SpaceProjectList';
import AddProjectDialog from '../../modal/AddProjectDialog';
import Box from '@mui/material/Box';
import SpaceMemberList from '../SpaceMemberList';
import SpaceSetting from '../../../molecules/spaces/SpaceSetting';
import { Project, Space, UserQuery } from '@/__generated__/graphql';
import { useMutation, useQuery } from '@apollo/client';
import { DELETE_PROJECT, GET_PROJECTS, GET_USERS, UPDATE_PROJECT } from './../../../../api/graphql';
import DeleteConfirmDialog from '@/components/organisms/modal/deleteConfirmDialog';
import { ProjectOrder, SortType } from '@/graphql';

export type MenuType = 'project' | 'member' | 'settings';

interface Props {
  space: Space;
  user?: UserQuery;
  handleOpenUserProfileModal: (open: boolean) => void;
}

const SpaceDetail = (props: Props) => {
  const { space, user, handleOpenUserProfileModal } = props;
  const [projectList, setProjectList] = useState<Project[]>([]);
  const [showModal, setShowModal] = useState<string>('');
  const [type, setType] = useState<MenuType>('project');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProject, setSelectedProject] = useState<Project>();
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);

  const { data } = useQuery(GET_PROJECTS, {
    variables: { filter: { spaceId: space.id, page: currentPage, limit: 20, orderBy: ProjectOrder.UpdatedAt, sortType: SortType.Desc } }
  });
  const { data: userData } = useQuery(GET_USERS, {
    variables: { filter: { spaceId: space.id, page: 1, limit: 10 } }
  });

  const [deleteProject] = useMutation(DELETE_PROJECT, {
    refetchQueries: [GET_PROJECTS]
  });
  const [updateProject] = useMutation(UPDATE_PROJECT, {
    refetchQueries: [GET_PROJECTS]
  });
  const handleDeleteProject = async () => {
    if (!selectedProject) return;
    await deleteProject({
      variables: {
        id: selectedProject.id
      },
      onCompleted: () => {
        //window.location.href = `/spaces/${selectedProject.spaceId}`;
        setShowDeleteProjectModal(false);
      }
    });
  };
  const handleUpdateProject = async (name: string, description: string) => {
    if (!selectedProject) return;
    await updateProject({
      variables: {
        in: {
          name: name,
          id: selectedProject.id,
          description: description
        }
      },
      onCompleted: () => {
        setShowModal('');
      }
    });
  };

  useEffect(() => {
    if (data && data.projects && data.projects.nodes) {
      setProjectList(data.projects.nodes);
    }
  }, [data]);

  useEffect(() => {
    setCurrentPage(1);
  }, [space]);

  return (
    <Box sx={{ p: 5 }} className="h-full">
      <SpaceLayout
        title={space.name}
        subTitle=""
        type={type}
        user={user}
        handleClickMenu={(type) => setType(type)}
        handleOpenUserProfileModal={handleOpenUserProfileModal}>
        {type === 'project' && (
          <SpaceProjectList
            projectList={projectList}
            setShowModal={(type) => setShowModal(type)}
            key="space_project_list"
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setSelectedProject={setSelectedProject}
            handleDeleteProject={() => setShowDeleteProjectModal(true)}
            showAddProjectDialog={() => setShowModal('modify')}
            totalCount={space.projectsCount}
          />
        )}
        {type === 'member' && <SpaceMemberList users={userData} spaceId={space.id} />}
        {type === 'settings' && <SpaceSetting space={space} />}
      </SpaceLayout>
      {showModal === 'AddProjectModal' && (
        <AddProjectDialog isOpen={true} handleClose={() => setShowModal('')} />
      )}
      {selectedProject && (
        <>
          {showModal === 'modify' && (
            <AddProjectDialog
              isOpen={true}
              project={{
                ...selectedProject,
                name: selectedProject?.name,
                description: selectedProject?.description
              }}
              handleUpdateProject={handleUpdateProject}
              handleClose={() => setShowModal('')}
            />
          )}
          {showDeleteProjectModal && (
            <DeleteConfirmDialog
              isOpen={true}
              title="プロジェクトを削除"
              content={
                <Box sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                  プロジェクトを削除します。プロジェクト内のファイルは全て削除されます。
                </Box>
              }
              handleDelete={handleDeleteProject}
              handleClose={() => setShowDeleteProjectModal(false)}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default SpaceDetail;
