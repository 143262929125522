import { useRefCallback } from '../../../../hooks/useRefCallback';
import { useEffect } from 'react';
import { TEXT_COLOR } from '../elements/constant';
import {
  isAddButtonElement,
  isAddButtonInLastRowElement,
  isAddTicketElement,
  isNodeElement
} from '@/components/workflow/Canvas/utils';

export const useElementMousemove = ({ paper }) => {
  const onMouseEnterRef = useRefCallback((elementView, evt) => {
    const element = elementView.model;
    if (isNodeElement(element)) {
      element.addTools(paper);
    }

    if (
      isAddTicketElement(element) ||
      (isAddButtonElement(element) && !isAddButtonInLastRowElement(element))
    ) {
      element.attr('body/strokeWidth', 2);
      element.attr('label/fill', TEXT_COLOR);
    }
  });

  const onMouseLeaveRef = useRefCallback((elementView, evt) => {
    const element = elementView.model;
    if (isNodeElement(element)) {
      elementView.removeTools();
    }
    if (
      isAddTicketElement(element) ||
      (isAddButtonElement(element) && !isAddButtonInLastRowElement(element))
    ) {
      element.attr('body/strokeWidth', 0);
      element.attr('label/fill', 'transparent');
    }
  });

  useEffect(() => {
    if (paper) {
      paper.on('element:mouseenter', (elementView, evt) =>
        onMouseEnterRef.current(elementView, evt)
      );
      paper.on('element:mouseleave', (elementView, evt) =>
        onMouseLeaveRef.current(elementView, evt)
      );
    }
  }, [paper]);
};
