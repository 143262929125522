import { LANE_HEADER_SLIM_WIDTH, LANE_HEADER_WIDTH, MINIMUM_ROW } from '../elements/constant';
import some from 'lodash/some';

export const getMaxRowInData = (nodes) => {
  let max = 0;
  nodes.forEach((node) => {
    max = Math.max(max, node.rowIndex);
  });
  return max;
};
export const getMaxRow = (nodes) => {
  return Math.max(getMaxRowInData(nodes) + 2, MINIMUM_ROW);
};

export const sliceText = (string, count = 20) => {
  return string.substring(0, count) + (string.length > count ? '...' : '');
};

export const isCommentLane = (lane) => lane.type === 'comment';

export const getElementType = (elementView) => {
  return elementView.model.attributes.type;
};

export const isNodeElement = (element) => {
  return element?.attributes?.type === 'WorkflowPart';
};

export const isLaneElement = (element) => {
  return element?.attributes?.type === 'WorkflowLane';
};

export const isTicketElement = (element) => {
  return element?.attributes?.type === 'WorkflowTicket' && !element?.prop('isGhost');
};

export const isAddTicketElement = (element) => {
  return element?.attributes?.type === 'WorkflowTicket' && element?.prop('isGhost');
};

export const isCommentElement = (element) => {
  return element?.attributes?.type === 'WorkflowComment';
};

export const isGroupElement = (element) => {
  return element?.attributes?.type === 'WorkflowGroup';
};

export const isAddButtonElement = (element) => {
  return element?.attributes?.type === 'WorkflowAddButton';
};

export const isAddButtonInLastRowElement = (element) => {
  return isAddButtonElement(element) && element?.prop('isLastRow');
};

export const getLaneWidth = (isShowTicket) => {
  return isShowTicket ? LANE_HEADER_WIDTH : LANE_HEADER_SLIM_WIDTH;
};

export const isTargetRowLabel = (evt) => {
  return evt.target.classList.contains('divider-body') || evt.target.parentNode.nodeName === 'text';
};

export const isNumberConsecutive = (values) => {
  values.sort();
  return values.every((num, i) => i === values.length - 1 || num === values[i + 1] - 1);
};

export const isInGroup = (row, groups) => {
  return some(groups, (group) => {
    return group.rowIndexes.includes(row);
  });
};

export const downloadBase64File = (base64Data, fileName) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = base64Data;
  downloadLink.download = fileName;
  downloadLink.click();
};
