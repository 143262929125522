import React, { ChangeEvent, useEffect, useReducer, useRef, useState } from 'react';
import {
  Autocomplete,
  Avatar,
  Box,
  Chip,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  ListItemAvatar,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';
import Button from '@mui/material/Button';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LinkIcon from '@mui/icons-material/Link';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CheckIcon from '@mui/icons-material/Check';
import { grey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import {
  GNode,
  GNodeProperty,
  GNodePropertyFile,
  Kind,
  NodePropertyPriorityEnum,
  NodePropertyStatusEnum,
  UpdateGNodePropertyInput,
  User
} from 'src/__generated__/graphql';
import { styled } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { useMutation, useQuery } from '@apollo/client';
import {
  ASSIGN_KIND_TO_NODE_PROPERTY,
  ASSIGN_NODE,
  ASSIGN_USER_TO_NODE_PROPERTY,
  CREATE_NODE_PROPERTY_COMMENT,
  CREATE_NODE_PROPERTY_FILE,
  DELETE_KIND_TO_NODE_PROPERTY,
  DELETE_NODE,
  DELETE_NODE_PROPERTY_COMMENT,
  DELETE_NODE_PROPERTY_FILE,
  DELETE_USER_TO_NODE_PROPERTY,
  DETACH_NODE,
  GET_ALL_IN_WORKFLOW,
  GET_NODES,
  GET_USER,
  UPDATE_NODE_PROPERTY,
  UPDATE_NODE_PROPERTY_COMMENT
} from '@/api/graphql';
import { formatWithDayHourMinute } from '@/utils/dates';
import { useNavigate, useParams } from 'react-router-dom';
import {
  TICKET_PRIORITY_COLOR,
  TICKET_PROPERTY_KIND,
  TICKET_PROPERTY_KIND_COLOR,
  TicketItemType
} from '@/types/tickets';
import NodePropertyFileList from '@/components/molecules/workflows/detail/NodePropertyFileList';
import DeleteConfirmDialog from '@/components/organisms/modal/deleteConfirmDialog';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getIconByTaskType } from '@/utils/tasks';
import { clipboardToCopy } from '@/utils/functions';
import { ZoomOutMapOutlined } from '@mui/icons-material';
import FullScreenPreview from '@/components/organisms/FullScreenPreview';
import MentionEditor from '@/components/molecules/workflows/MentionEditor';
import { APPBAR_HEIGHT } from '@/components/workflow/Canvas/elements/constant';

type Props = {
  nodes: GNodeProperty[];
  node: TicketItemType;
  kinds: Kind[];
  setActiveNode: Function;
  setTickets: Function;
  users: User[];
  refetchAllNodes: Function;
  setLastActiveNodeName: Function;
  setLastActiveNodeDesc: Function;
  refetchFlag?: boolean;
  detailPage?: boolean;
  setOpenAlert?: Function;
  handleOpenZoomEditor?: Function;
  handleCloseEditor?: Function;
  isSidePickerInput?: boolean;
  setIsSidePickerInput?: Function;
};

type AttributeState = Pick<
  GNodeProperty,
  'name' | 'nodeId' | 'priority' | 'type' | 'users' | 'description' | 'media'
>;

type AttributeConfig = {
  key: keyof AttributeState;
  label: string;
  options?: {
    key: string;
    label: string;
    icon?: string;
    contact?: string;
  }[];
}[];

const attributeConfig: AttributeConfig = [
  {
    key: 'priority',
    label: '優先順位',
    options: [
      {
        key: NodePropertyPriorityEnum.High,
        label: '高'
      },
      {
        key: NodePropertyPriorityEnum.Medium,
        label: '中'
      },
      {
        key: NodePropertyPriorityEnum.Low,
        label: '低'
      }
    ]
  }
];

const attributeReducer = (state: AttributeState, value: Partial<AttributeState>) => {
  return {
    ...state,
    ...value
  };
};

const TextFieldTitle = styled(TextField)({
  '.MuiInputBase-formControl:before, .MuiInputBase-formControl:after, .MuiInputBase-formControl:hover:before':
    {
      border: 'none !important'
    },
  '& input': {
    color: '#202123',
    fontWeight: 'bold',
    fontSize: '16px'
  }
});

export const TicketPartEditor = ({
  nodes,
  node,
  setActiveNode,
  setTickets,
  kinds,
  users,
  refetchAllNodes,
  setLastActiveNodeName,
  setLastActiveNodeDesc,
  refetchFlag = false,
  detailPage = false,
  setOpenAlert,
  handleOpenZoomEditor,
  handleCloseEditor,
  isSidePickerInput,
  setIsSidePickerInput
}: Props) => {
  let { workflowId } = useParams();
  const navigate = useNavigate();
  const elementRef = useRef<HTMLDivElement>(null);
  const fileId = workflowId as string;
  const [comment, setComment] = useState('');
  const [maxWidth, setMaxWidth] = useState<number>(0);
  const [updateComment, setUpdateComment] = useState('');
  const [selectedComment, setSelectedComment] = useState('');
  // File中のすべてのタスクNodeを取得
  const { loading, data } = useQuery(GET_NODES, {
    variables: {
      filter: { fileId }
    },
    fetchPolicy: 'network-only'
  });

  const { data: userData } = useQuery(GET_USER);

  const [createNodePropertyComment] = useMutation(CREATE_NODE_PROPERTY_COMMENT);
  const [updateNodePropertyComment] = useMutation(UPDATE_NODE_PROPERTY_COMMENT);
  const [deleteNodePropertyComment] = useMutation(DELETE_NODE_PROPERTY_COMMENT);

  const informationConfig = [
    {
      key: 'nodeId',
      label: 'ID',
      value: node.nodeId
    }
  ];
  const [updateNodeProperty] = useMutation(UPDATE_NODE_PROPERTY);
  let nodeIndex = nodes.findIndex((item) => item.nodeId === node.nodeId);
  const [state, attributeUpdate] = useReducer(attributeReducer, node);
  const [deleteNode] = useMutation(DELETE_NODE, { refetchQueries: [GET_ALL_IN_WORKFLOW] });
  const [assignNode] = useMutation(ASSIGN_NODE, { refetchQueries: [GET_ALL_IN_WORKFLOW] });
  const [detachNode] = useMutation(DETACH_NODE, { refetchQueries: [GET_ALL_IN_WORKFLOW] });
  const [assignKindToNodeProperty] = useMutation(ASSIGN_KIND_TO_NODE_PROPERTY, {
    refetchQueries: [GET_ALL_IN_WORKFLOW]
  });
  const [deleteKindToNodeProperty] = useMutation(DELETE_KIND_TO_NODE_PROPERTY, {
    refetchQueries: [GET_ALL_IN_WORKFLOW]
  });
  const [deleteUserToNodeProperty] = useMutation(DELETE_USER_TO_NODE_PROPERTY, {
    refetchQueries: [GET_ALL_IN_WORKFLOW]
  });
  const [assignUserToNodeProperty] = useMutation(ASSIGN_USER_TO_NODE_PROPERTY, {
    refetchQueries: [GET_ALL_IN_WORKFLOW]
  });
  const [createNodePropertyFile] = useMutation(CREATE_NODE_PROPERTY_FILE);
  const [deleteNodePropertyFile] = useMutation(DELETE_NODE_PROPERTY_FILE);

  const [searchText, setSearchText] = useState<string>('');
  const [kindType, setKindType] = useState('');
  // 全ての紐づくプロセスのNodes
  const [allNodes, setAllNodes] = useState<GNode[]>([]);
  // ticketの紐づくプロセスを除くすべてのNodes
  const [fetchedTaskNodes, setFetchedTaskNodes] = useState<GNode[]>([]);
  // 紐づくプロセス検索周り
  const [searchTaskNodes, setSearchTaskNodes] = useState<GNode[]>([]);
  const [searchEnable, setSearchEnable] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorCommentEl, setAnchorCommentEl] = React.useState<null | HTMLElement>(null);

  const [isAutoCompleteFocus, setIsAutoCompleteFocus] = useState(false);
  const open = Boolean(anchorEl);
  const openComment = Boolean(anchorCommentEl);
  const [removableFileId, setShowModal] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editRows, setEditRows] = useState<number>(71);
  const editorRef = useRef<HTMLDivElement>(null);
  const [isClickSendComment, setIsClickSendComment] = useState(false);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<GNodePropertyFile | null>(null);
  const mentionRef = useRef<HTMLDivElement>(null);
  const [mentionEditorHeight, setMentionEditorHeight] = useState<number>(0);
  useEffect(() => {
    if (loading) return;
    if (data && data.nodes) {
      setAllNodes(data.nodes.nodes);
      filterTasks(data.nodes.nodes);
    }
  }, [data]);

  useEffect(() => {
    if (elementRef.current) {
      const object = elementRef.current.getBoundingClientRect();
      console.log('Width:', object.width, elementRef.current);
      setMaxWidth(object.width - 32);
    }
    const element = mentionRef.current;

    if (element) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const height = entry.target.scrollHeight;
          setMentionEditorHeight(height);
        }
      });

      resizeObserver.observe(element);

      return () => resizeObserver.unobserve(element);
    }
  }, []);

  useEffect(() => {
    if (fetchedTaskNodes) {
      filterTasks(allNodes);
    }
  }, [node.tasks]);

  useEffect(() => {
    if (node.name)
      attributeUpdate({
        name: node.name
      });
  }, [node.name]);

  useEffect(() => {
    if (node.description)
      attributeUpdate({
        description: node.description
      });
  }, [node.description]);

  useEffect(() => {
    let kindType = '';
    if (node.kinds && node.kinds.length > 0) {
      kindType = node.kinds[0].id;
    }
    setKindType(kindType);
  }, [node.kinds]);

  useEffect(() => {
    if (isSidePickerInput) return;
    attributeUpdate(node);
  }, [node]);

  useEffect(() => {
    if (isClickSendComment) scrollToBottom();
  }, [node.comments]);

  useEffect(() => {
    if (isSidePickerInput) {
      return;
    }
    const variables: {
      in: UpdateGNodePropertyInput;
    } = {
      in: {
        nodeId: state.nodeId
      }
    };
    if (state.name) {
      variables.in.name = state.name;
    }
    if (state.priority) {
      variables.in.priority = state.priority;
    }
    if (state.description) {
      variables.in.description = state.description;
    }
    console.log('==============_+_+_+__+', isSidePickerInput, state.priority);
    updateNodeProperty({
      variables,
      onCompleted: () => {
        if (refetchFlag) {
          refetchAllNodes();
        }
      }
    });
    // TODO: fix any type
    setTickets((prevState: any[]) => {
      const newState = [...prevState];
      const currentNode = newState[nodeIndex];
      if (state.name && currentNode) {
        currentNode.name = state.name;
      }
      newState[nodeIndex] = {
        ...newState[nodeIndex],
        ...state
      };
      return newState;
    });
  }, [isSidePickerInput, state.priority]);

  const nextNode = () => {
    if (nodeIndex === nodes.length - 1) {
      return;
    }
    nodeIndex++;
    return nodes[nodeIndex];
  };

  const previewNode = () => {
    if (nodeIndex === 0) {
      return;
    }
    nodeIndex--;
    return nodes[nodeIndex];
  };

  const getChipTypeColor = (type: string) => {
    switch (type) {
      case TICKET_PROPERTY_KIND.Confirm:
        return TICKET_PROPERTY_KIND_COLOR.Confirm;
      case TICKET_PROPERTY_KIND.Problem:
        return TICKET_PROPERTY_KIND_COLOR.Problem;
      case NodePropertyPriorityEnum.High:
        return TICKET_PRIORITY_COLOR.High;
      case NodePropertyPriorityEnum.Medium:
        return TICKET_PRIORITY_COLOR.Medium;
      case NodePropertyPriorityEnum.Low:
        return TICKET_PRIORITY_COLOR.Low;
      default:
        break;
    }
  };

  const filterTasks = (nodes: GNode[]) => {
    const existTaskIds: string[] = node.tasks ? node.tasks.map((task) => task.nodeId) : [];
    const allTasks = nodes.filter((task) => {
      return !existTaskIds.includes(task.id);
    });
    setFetchedTaskNodes(allTasks);
    setSearchTaskNodes(allTasks);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickComment =
    (commentId: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectedComment(commentId);
      setAnchorCommentEl(event.currentTarget);
    };

  const handleCommentClose = () => {
    setAnchorCommentEl(null);
  };

  const handleTicketComplete = (node: GNodeProperty) => {
    let status = NodePropertyStatusEnum.Complete;
    if (node.status === NodePropertyStatusEnum.Complete) {
      status = NodePropertyStatusEnum.InProgress;
    }

    updateNodeProperty({
      variables: {
        in: {
          nodeId: state.nodeId,
          status: status
        }
      },
      onCompleted: () => {
        setOpenAlert && setOpenAlert({ type: status, isOpen: true, nodeId: state.nodeId });
        refetchAllNodes();
      }
    });
  };

  const handleAddComment = async (comment: string, ids: string[]) => {
    await createNodePropertyComment({
      variables: {
        in: {
          nodeId: state.nodeId,
          text: comment,
          ids: ids
        }
      },
      onCompleted: () => {
        setIsClickSendComment(true);
        setComment('');
        refetchAllNodes();
      }
    });
  };

  const handleUpdateComment = async (comment: string, id: string) => {
    await updateNodePropertyComment({
      variables: {
        in: {
          id: id,
          text: comment
        }
      },
      onCompleted: () => {
        setUpdateComment('');
        setIsEditing(false);
        setSelectedComment('');
        refetchAllNodes();
      }
    });
  };

  const handleCommentRemove = async (id: string) => {
    await deleteNodePropertyComment({
      variables: {
        in: {
          id: id
        }
      },
      onCompleted: () => {
        setUpdateComment('');
        setIsEditing(false);
        setSelectedComment('');
        refetchAllNodes();
      }
    });
  };
  const handleRemove = async () => {
    await deleteNode({
      variables: {
        id: state.nodeId
      },
      onCompleted: () => {
        if (detailPage) {
          navigate(`/workflows/${workflowId}/diagram/view`);
          return;
        }
        setActiveNode(null);
        handleClose();
        setTickets((prevState: any[]) => {
          const newState = [...prevState];
          newState.splice(nodeIndex, 1);
          return newState;
        });
      }
    });
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearchTaskNodes(fetchedTaskNodes.filter((task) => task.name.toLowerCase().includes(query)));
    setSearchText(query);
  };
  const handleItemClick = (taskNode: GNode) => {
    assignNode({
      variables: {
        in: { parentNodeId: taskNode.id, childNodeId: node.nodeId }
      },
      onCompleted: () => {
        setSearchEnable(false);
      }
    });
  };

  const handleTaskDelete = (taskProperty: GNodeProperty) => {
    detachNode({
      variables: {
        in: { parentNodeId: taskProperty.nodeId, childNodeId: node.nodeId }
      },
      onCompleted: () => {
        setSearchEnable(false);
      }
    });
  };

  const handleShowTaskDetail = (taskNodeId: string) => {
    window.open(`/workflows/${workflowId}/process/${taskNodeId}`, '_blank');
  };

  const handleCopyTicketDetailURL = (ticketNodeId: string) => {
    const domainName = window.location.host;
    const url = `${domainName}/workflows/${workflowId}/ticket/${ticketNodeId}`;
    clipboardToCopy(url).then((r) => {
      console.log(r);
      setOpenAlert && setOpenAlert({ type: 'CopyClipboard', isOpen: true, nodeId: '' });
    });
  };

  const handleChangeKind = (kindId: string, kinds: Kind[], node: GNodeProperty) => {
    setKindType(kindId);
    kinds.forEach((kind) => {
      if (kind.id === kindId) {
        assignKindToNodeProperty({
          variables: {
            in: { kindId: kind.id, nodePropertyId: node.id }
          }
        });
      } else {
        // kindId以外は削除する
        deleteKindToNodeProperty({
          variables: {
            in: { nodePropertyId: node.id, kindId: kind.id }
          }
        });
      }
    });
  };

  const handleFocus = () => {
    setIsAutoCompleteFocus(true);
  };

  const handleBlur = () => {
    setIsAutoCompleteFocus(false);
  };

  const handleSelectedUser = async (selectedUsers: User[]) => {
    let newUserIds: string[] = [];
    let deletedUserIds: string[] = [];
    let arrLength = selectedUsers.length;
    if (node.users && node.users.length > 0) {
      let user = node.users[0];
      if (!selectedUsers.some((selectedUser) => user.id === selectedUser.id)) {
        deletedUserIds.push(user.id);
      } else if (user.id !== selectedUsers[arrLength - 1].id) {
        deletedUserIds.push(user.id);
        newUserIds.push(selectedUsers[arrLength - 1].id);
      }
    } else {
      newUserIds.push(selectedUsers[arrLength - 1].id);
    }

    newUserIds.map(async (user) => {
      await assignUserToNodeProperty({
        variables: {
          in: { nodePropertyId: node.id, userId: user }
        }
      });
    });

    deletedUserIds.map(async (user) => {
      await deleteUserToNodeProperty({
        variables: {
          in: { nodePropertyId: node.id, userId: user }
        }
      });
    });
    setIsAutoCompleteFocus(false);
  };

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      await createNodePropertyFile({
        variables: {
          in: {
            nodeId: node.nodeId,
            file: e.target.files[0]
          }
        },
        onCompleted: () => {
          refetchAllNodes();
        }
      });
    }
  };

  const handleFileDelete = async () => {
    await deleteNodePropertyFile({
      variables: {
        in: {
          id: removableFileId
        }
      },
      onCompleted: () => {
        setShowModal('');
        refetchAllNodes();
      }
    });
  };

  const scrollToBottom = () => {
    const element = editorRef.current;
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  };

  const handleOpenFullScreenPreview = (file: GNodePropertyFile) => {
    setShowFullScreen(true);
    setSelectedItem(file);
  };

  const parseAndRenderText = (text: string) => {
    const mentionRegex = /@\{name:(.*?)\}\{id:(\d+)\}/g;

    let updatedText = text.replace(mentionRegex, (_, name) => {
      return `<a href="#" class="m6zwb4v" spellCheck="false" data-testid="mentionLink">${name}</a>`;
    });

    return updatedText;
  };

  console.log('============', state);
  return (
    <>
      <Box
        ref={editorRef}
        sx={{
          width: detailPage ? '100%' : 518,
          fontSize: 12,
          height: `calc(100% - ${
            detailPage ? mentionEditorHeight : mentionEditorHeight + APPBAR_HEIGHT
          }px)`,
          overflowY: detailPage ? null : 'auto',
          textAlign: 'left'
        }}>
        <Toolbar
          sx={{
            backgroundColor: grey[100],
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            minHeight: 52,
            padding: '8px 16px 7px !important',
            gap: 2
          }}
          variant="dense">
          <Button
            startIcon={<CheckIcon />}
            variant="outlined"
            color={node.status === NodePropertyStatusEnum.Complete ? 'success' : 'inherit'}
            size="medium"
            sx={{
              fontSize: 14,
              fontWeight: 700,
              borderColor:
                node.status === NodePropertyStatusEnum.Complete ? '#2E7D3280' : '#00000080',
              color: node.status === NodePropertyStatusEnum.Complete ? '#2E7D32' : '#00000099',
              background: node.status === NodePropertyStatusEnum.Complete ? '#2E7D3214' : '',
              lineHeight: '2',
              padding: '3px 15px'
            }}
            onClick={() => handleTicketComplete(node)}>
            {node.status === NodePropertyStatusEnum.Complete ? '完了済み' : '完了にする'}
          </Button>
          {!detailPage && (
            <>
              <IconButton size="small" color="inherit" onClick={() => setActiveNode(nextNode())}>
                <KeyboardArrowDownIcon />
              </IconButton>
              <IconButton size="small" color="inherit" onClick={() => setActiveNode(previewNode())}>
                <KeyboardArrowUpIcon />
              </IconButton>
            </>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { md: 'flex', gap: '16px' } }}>
            {!detailPage && (
              <IconButton
                size="small"
                color="inherit"
                onClick={() => handleOpenZoomEditor && handleOpenZoomEditor(node)}>
                <ZoomOutMapOutlined />
              </IconButton>
            )}
            <IconButton
              size="small"
              color="inherit"
              onClick={() => handleCopyTicketDetailURL(node.nodeId)}>
              <LinkIcon />
            </IconButton>
            <IconButton id="remove-button" size="small" color="inherit" onClick={handleClick}>
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="remove-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              MenuListProps={{
                'aria-labelledby': 'remove-button'
              }}>
              <MenuItem
                sx={{
                  width: 145,
                  height: 36,
                  color: 'red',
                  fontWeight: 300,
                  fontSize: 16
                }}
                onClick={handleRemove}>
                削除
              </MenuItem>
            </Menu>
            <IconButton
              size="small"
              color="inherit"
              onClick={() => {
                if (detailPage) {
                  handleCloseEditor && handleCloseEditor();
                }
                setActiveNode(null);
              }}>
              <CloseIcon />{' '}
            </IconButton>
          </Box>
        </Toolbar>
        <Box
          sx={{
            height: detailPage ? 'calc(100% - 16px)' : null,
            p: detailPage ? 2 : null
          }}>
          <Grid container spacing={2} sx={{ height: '100%' }}>
            <Grid
              item
              xs={detailPage ? 6 : 12}
              sx={{ height: detailPage ? '100%' : null, overflowY: detailPage ? 'auto' : null }}>
              <Stack
                spacing={2}
                sx={{ p: 2, color: '#00000099', pb: 0 }}
                divider={
                  <Divider
                    orientation="horizontal"
                    sx={{ borderStyle: 'dashed', borderColor: '#0000001F' }}
                    flexItem
                  />
                }
                ref={elementRef}>
                <Grid>
                  <Grid item xs={12}>
                    <TextFieldTitle
                      fullWidth
                      autoFocus
                      variant="standard"
                      placeholder="チケット名"
                      value={state.name}
                      InputProps={{
                        onBlur: ({ target: { value } }) => {
                          setIsSidePickerInput && setIsSidePickerInput(false);
                        },
                        onKeyDown: (event) => {
                          if (event.key === 'Enter') {
                            setIsSidePickerInput && setIsSidePickerInput(false);
                          }
                        }
                      }}
                      onChange={({ target: { value } }) => {
                        setIsSidePickerInput && setIsSidePickerInput(true);
                        setLastActiveNodeName(value);
                        attributeUpdate({
                          name: value
                        });
                      }}
                    />
                  </Grid>
                  {informationConfig.map((item) => (
                    <Grid container spacing={1} alignItems="center" key={item.key} sx={{ mt: 1 }}>
                      <Grid item xs={2}>
                        <Typography variant="body2">{item.label}</Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="body1" sx={{ color: '#202123', fontWeight: 'normal' }}>
                          {item.value}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                  <Grid container spacing={1} alignItems="center" key="kinds" sx={{ mt: 1 }}>
                    <Grid item xs={2}>
                      <Typography variant="body2">種別</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {kinds && kinds.length > 0 ? (
                        <FormControl variant="standard" size="small">
                          <Select
                            sx={{
                              border: 'none',
                              width: '140px',
                              height: '28px',
                              padding: '4px 8px'
                            }}
                            value={kindType}
                            onChange={({ target: { value } }) => {
                              handleChangeKind(value, kinds, node);
                            }}>
                            {kinds.map((kind) => (
                              <MenuItem value={kind.id} key={`kind_item_${kind.id}`}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1
                                  }}>
                                  <Chip
                                    label={kind.text}
                                    size="small"
                                    variant="filled"
                                    sx={{
                                      fontSize: 13,
                                      fontWeight: 400,
                                      padding: '3px 4px',
                                      backgroundColor: getChipTypeColor(kind.text)
                                    }}
                                  />
                                </Box>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <Typography
                          variant="body1"
                          sx={{ color: '#202123', fontWeight: 'bold' }}></Typography>
                      )}
                    </Grid>
                  </Grid>
                  {attributeConfig.map((item) => (
                    <Grid container spacing={1} alignItems="center" key={item.key} sx={{ mt: 1 }}>
                      <Grid item xs={2}>
                        <Typography variant="body2">{item.label}</Typography>
                      </Grid>
                      <Grid item xs={10}>
                        {item.options ? (
                          <FormControl variant="standard" size="small">
                            <Select
                              sx={{
                                border: 'none',
                                width: '140px',
                                height: '28px',
                                padding: '4px 8px'
                              }}
                              value={state[item.key]}
                              onChange={({ target: { value } }) => {
                                attributeUpdate({
                                  [item.key]: value
                                });
                              }}>
                              {item.options.map((optionItem) => (
                                <MenuItem value={optionItem.key} key={optionItem.key}>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 1
                                    }}>
                                    <Chip
                                      label={optionItem.label}
                                      size="small"
                                      variant="filled"
                                      sx={{
                                        color: 'white',
                                        fontSize: 13,
                                        fontWeight: 400,
                                        padding: '3px 4px',
                                        backgroundColor: getChipTypeColor(optionItem.key)
                                      }}
                                    />
                                  </Box>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ) : (
                          <Typography
                            variant="body1"
                            sx={{ color: '#202123', fontWeight: 'bold' }}></Typography>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                  <Grid container spacing={1} alignItems="center" sx={{ mt: 1 }}>
                    <Grid item xs={2}>
                      <Typography variant="body2">担当者</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <FormControl variant="standard" size="small">
                        <Autocomplete
                          multiple
                          id="tags-standard"
                          sx={{
                            border: 'none',
                            width: '200px',
                            height: '29px'
                          }}
                          options={users ?? []}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          value={node.users ? (node.users as User[] | undefined) : []}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, selectedUsers) => {
                            handleSelectedUser(selectedUsers);
                          }}
                          componentsProps={{
                            paper: {
                              sx: {
                                width: '321px'
                              }
                            }
                          }}
                          renderOption={(props, option, { selected }) => (
                            <Box
                              component="li"
                              {...props}
                              key={`user_key_${option.id}`}
                              sx={{
                                '& > img': { mr: 2, flexShrink: 0 },
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                maxWidth: '100%',
                                maxLines: 1,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                              }}>
                              <Avatar
                                sx={{ width: 24, height: 24 }}
                                variant="circular"
                                src={option?.icon as string}
                              />
                              {option?.firstName + ' ' + option?.lastName} {option?.email}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              multiline={false}
                              variant="standard"
                              InputProps={{
                                ...params.InputProps,
                                style: {
                                  overflowX: 'hidden',
                                  paddingRight: '10px',
                                  flexFlow: 'nowrap'
                                },
                                startAdornment: (
                                  <Box
                                    sx={{
                                      display: 'contents',
                                      gap: '4px',
                                      flexWrap: 'nowrap',
                                      overflowX: 'auto'
                                    }}>
                                    {node.users &&
                                      node.users.map((user, index) => (
                                        <Avatar
                                          sx={{
                                            width: 24,
                                            height: 24,
                                            marginRight: '3px'
                                          }}
                                          key={`selected_user_avatar_${index}`}
                                          variant="circular"
                                          src={user?.icon as string}
                                        />
                                      ))}
                                    {!isAutoCompleteFocus
                                      ? node.users && node.users.length > 0 && node.users[0]?.name
                                      : ''}
                                  </Box>
                                )
                              }}
                              value=""
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Stack spacing={2}>
                  <Typography variant="body2">説明</Typography>
                  <TextField
                    multiline
                    fullWidth
                    variant="standard"
                    minRows={3}
                    placeholder="このチケットの内容を記載します"
                    value={state.description}
                    InputProps={{
                      onBlur: ({ target: { value } }) => {
                        setIsSidePickerInput && setIsSidePickerInput(false);
                      },
                    }}
                    onChange={({ target: { value } }) => {
                      setIsSidePickerInput && setIsSidePickerInput(true);
                      setLastActiveNodeDesc(value);
                      attributeUpdate({
                        description: value
                      });
                    }}
                  />
                </Stack>
                <Stack spacing={2} sx={{ mt: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="body2">ファイル</Typography>
                    <Button variant="text" color="inherit" component="label" size="small">
                      <AddIcon sx={{ cursor: 'pointer' }} />
                      <input
                        type="file"
                        id="upload-task-file"
                        accept="*"
                        hidden
                        onChange={handleFileUpload}
                      />
                    </Button>
                  </Box>
                  {node.files && node.files.length > 0 && (
                    <NodePropertyFileList
                      propertyFileList={node.files}
                      setShowModal={setShowModal}
                      setShowFullScreen={handleOpenFullScreenPreview}
                    />
                  )}
                </Stack>
                <Stack spacing={1}>
                  <Box>
                    <Typography variant="body2">関連プロセス</Typography>
                  </Box>
                  <List sx={{ width: '100%' }} dense={true}>
                    {node.tasks &&
                      node.tasks.map((task, index) => (
                        <ListItem
                          key={`task_list_item_${index}`}
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleTaskDelete(task)}>
                              <ClearOutlinedIcon />
                            </IconButton>
                          }>
                          <ListItemAvatar sx={{ paddingTop: '5px', minWidth: 26 }}>
                            {getIconByTaskType(task.type)}
                          </ListItemAvatar>
                          <ListItemText
                            primary={task.name}
                            secondary={null}
                            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => handleShowTaskDetail(task.nodeId)}
                          />
                        </ListItem>
                      ))}
                  </List>
                  <Box>
                    {searchEnable && (
                      <>
                        <TextField
                          sx={{
                            width: '100%',
                            maxHeight: '40px'
                          }}
                          variant="outlined"
                          size="small"
                          InputProps={{
                            startAdornment: <InputAdornment position="start"></InputAdornment>
                          }}
                          placeholder="チケット名を入力"
                          value={searchText}
                          onChange={handleSearch}
                          onFocus={(e) => {
                            if (e.currentTarget === e.target) {
                              setFocus(true);
                            }
                          }}
                          onBlur={(e) => {
                            e.preventDefault();
                            if (e.currentTarget === e.target) {
                              if (!e.relatedTarget?.classList.contains('MuiMenuItem-root')) {
                                setFocus(false);
                              }
                            }
                          }}
                        />
                        {focus && (
                          <Box sx={{ position: 'relative' }}>
                            <Box
                              sx={{
                                backgroundColor: 'white',
                                position: 'absolute',
                                zIndex: 1,
                                width: '100%',
                                boxShadow:
                                  '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
                              }}>
                              {searchTaskNodes.map((task, index) => (
                                <MenuItem
                                  key={index}
                                  sx={{
                                    width: '100%'
                                  }}
                                  onClick={() => handleItemClick(task)}>
                                  <Box sx={{ width: '100%' }}>
                                    <Typography>{task.name || 'プロセス名'}</Typography>
                                  </Box>
                                </MenuItem>
                              ))}
                              {searchTaskNodes.length === 0 && (
                                <MenuItem sx={{ minWidth: 300 }}>
                                  プロセスが見つかりません。
                                </MenuItem>
                              )}
                            </Box>
                          </Box>
                        )}
                      </>
                    )}
                  </Box>
                  <Box sx={{ marginBottom: '16px !important' }}>
                    <Button
                      startIcon={<AddIcon />}
                      variant="outlined"
                      color="inherit"
                      size="small"
                      onClick={() => setSearchEnable(true)}
                      sx={{ width: '40%' }}>
                      紐づけるプロセスを追加
                    </Button>
                  </Box>
                </Stack>
              </Stack>
            </Grid>
            <Grid
              item
              xs={detailPage ? 6 : 12}
              sx={{
                height: detailPage ? '100%' : null,
                overflowY: detailPage ? 'auto' : null,
                paddingLeft: detailPage ? '0px' : null,
                backgroundColor: '#F9F8F8'
              }}>
              <Stack
                sx={{ backgroundColor: '#F9F8F8', p: 2, pb: 4, minHeight: 300, pt: 0 }}
                spacing={3}
                divider={
                  <Divider
                    orientation="horizontal"
                    sx={{ borderStyle: 'dashed', borderColor: '#0000001F', pt: 0 }}
                    flexItem
                  />
                }>
                {node.activities && node.activities.length > 0 && (
                  <Stack spacing={2}>
                    {/*<Typography variant="body2">アクティビティ</Typography>*/}
                    {node.activities?.map((activity) => (
                      <Stack spacing={2} direction="row" alignItems="center">
                        <Avatar sx={{ width: 28, height: 28 }}>K</Avatar>
                        <Typography variant="body2">
                          {activity.text}
                          {/*<strong>水野 直</strong> がこのタスクを作成 2023/07/18 13:89*/}
                        </Typography>
                      </Stack>
                    ))}
                  </Stack>
                )}
                <Stack spacing={2}>
                  <Typography variant="body2">コメント</Typography>
                  {node.comments?.map((item, index) => (
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="start"
                      sx={{ width: '100%' }}
                      key={`${item.id}_${index}`}>
                      <Box>
                        <Avatar
                          sx={{ width: 28, height: 28 }}
                          src={item.creator?.icon || '/images/grey.png'}
                        />
                      </Box>
                      <Box sx={{ width: '100%' }}>
                        <Typography variant="body2">
                          {item.creator && <span>{item.creator.name}</span>}
                          <span style={{ marginLeft: '8px' }}>
                            {formatWithDayHourMinute(item.createdAt)}
                          </span>
                        </Typography>
                        {isEditing && selectedComment === item.id ? (
                          <Box style={{ maxWidth: maxWidth - 38 }}>
                            <MentionEditor
                              users={users}
                              isUpdateComment={isEditing}
                              comment={updateComment === '' ? item.text : updateComment}
                              handleAddComment={async (comment: string) => {
                                setUpdateComment(comment);
                                await handleUpdateComment(comment ?? item.text, item.id);
                              }}
                              handleCloseComment={() => setIsEditing(false)}
                            />
                          </Box>
                        ) : (
                          <div
                            style={{
                              maxWidth: maxWidth - 92,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                            dangerouslySetInnerHTML={{ __html: parseAndRenderText(item.text) }}
                          />
                        )}
                      </Box>
                      <Box>
                        {!isEditing && (
                          <IconButton
                            id={`comment-menu-${item.id}`}
                            size="small"
                            color="inherit"
                            onClick={handleClickComment(item.id)}>
                            <ExpandMoreIcon />
                          </IconButton>
                        )}
                      </Box>
                    </Stack>
                  ))}
                  <Box>
                    <Menu
                      id="comment-menu"
                      anchorEl={anchorCommentEl}
                      open={openComment}
                      onClose={handleCommentClose}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      MenuListProps={{
                        'aria-labelledby': 'comment-edit-button'
                      }}
                      PaperProps={{
                        sx: {
                          boxShadow:
                            '0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)'
                        }
                      }}>
                      <MenuItem
                        sx={{
                          width: 145,
                          height: 36,
                          fontWeight: 400,
                          fontSize: 16
                        }}
                        onClick={() => {
                          handleCommentClose();
                          setIsEditing(true);
                        }}>
                        コメントを編集
                      </MenuItem>
                      <MenuItem
                        sx={{
                          width: 145,
                          height: 36,
                          color: 'red',
                          fontWeight: 400,
                          fontSize: 16
                        }}
                        onClick={() => {
                          handleCommentClose();
                          setIsEditing(false);
                          handleCommentRemove(selectedComment);
                        }}>
                        コメントを削除
                      </MenuItem>
                    </Menu>
                  </Box>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        {removableFileId !== '' && (
          <DeleteConfirmDialog
            isOpen={true}
            title="ファイルを削除します"
            content={
              <Box sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                「ファイル名」を削除します。よろしいですか？この操作は取り消せません。
              </Box>
            }
            handleDelete={handleFileDelete}
            handleClose={() => setShowModal('')}
          />
        )}
      </Box>
      <Box
        ref={mentionRef}
        sx={{
          display: 'flex',
          width: detailPage ? '100%' : 518,
          gap: '10px',
          padding: '15px',
          alignItems: 'center',
          position: 'fixed',
          bottom: 0,
          backgroundColor: '#F9F8F8',
          borderTop: '1px solid #0000001F',
          boxShadow:
            '0px 2px 1px -1px #00000033, 0px 1px 1px 0px #00000024, 0px 1px 3px 0px #0000001F'
        }}>
        <Box sx={{ width: 28 }}>
          <Avatar sx={{ width: 28, height: 28 }} src={userData?.user.icon ?? '/images/grey.png'} />
        </Box>
        <Box sx={{ width: 'calc(100% - 38px)' }}>
          <MentionEditor
            users={users}
            setEditRows={setEditRows}
            handleAddComment={handleAddComment}
            comment={comment}
            isTicketComment={true}
          />
        </Box>
      </Box>
      {showFullScreen && (
        <FullScreenPreview
          src={`${selectedItem?.file}?w=161&fit=crop&auto=format`}
          alt="Fullscreen Image"
          showFullScreen={showFullScreen}
          setShowFullScreen={setShowFullScreen}
        />
      )}
    </>
  );
};
