import { dia, util } from '@clientio/rappid';
import { TEXT_COLOR, TICKET_HEIGHT, TICKET_WIDTH } from './constant';
import MoreVert from '../icons/MoreVert';

class WorkflowTicket extends dia.Element {
  constructor(...args) {
    super(...args);
    this.resize(TICKET_WIDTH, TICKET_HEIGHT);
  }

  defaults() {
    return {
      ...super.defaults,
      type: 'WorkflowTicket',
      attrs: {
        body: {
          width: TICKET_WIDTH,
          height: TICKET_HEIGHT,
          rx: 8
        },
        label: {
          event: 'element:label:edit',
          fill: TEXT_COLOR,
          fontSize: 11,
          x: 8,
          y: 18
        },
        moreIcon: {
          cursor: 'pointer',
          x: 'calc(w-20)',
          y: 8,
          'xlink:href': 'data:image/svg+xml;utf8,' + encodeURIComponent(MoreVert)
        },
        foreignObject: {
          width: TICKET_WIDTH - 10,
          height: TICKET_HEIGHT - 10,
          x: 5,
          y: 5
        }
      },
      pid: ''
    };
  }

  preinitialize(...args) {
    this.markup = util.svg`
      <rect @selector="body" />
      <foreignObject class="ticket-object" @selector="foreignObject">
        <div
          class="ticket-main"
          xmlns="http://www.w3.org/1999/xhtml"
        >
          <p class="ticket-name" @selector="label"></p><div class="ticket-metadata" @selector="metadata"></div>
        </div>
      </foreignObject>
    `;
  }
}

const createWorkflowTicket = (config, graph, parent) => {
  if (!parent) {
    console.error('Parent is undefined');
    return null;
  }
  const ticket = new WorkflowTicket(config);
  parent.embed(ticket);
  ticket.addTo(graph);
  parent.layoutTickets(ticket);
  return ticket;
};
export { createWorkflowTicket, WorkflowTicket };
