import React from 'react';
import Box from '@mui/material/Box';
import SpaceCreate from '../../components/organisms/spaces/SpaceCreate';

const SpaceCreatePage = () => {
  return (
    <Box className="h-full">
      <SpaceCreate />
    </Box>
  );
};

export default SpaceCreatePage;
