import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { File } from '@/__generated__/graphql';
import Box from '@mui/material/Box';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  file?: File;
  handleFileNameChange: (name: string, description: string) => void;
}

const FileNameChangeDialog = (props: Props) => {
  const { isOpen, handleClose, file, handleFileNameChange } = props;
  const [fileName, setFileName] = useState(file ? file.name : '');
  const [fileDescription, setFileDescription] = useState(file ? file.description : '');

  const handleChange = () => {
    // ToDo changeFileName APIを呼び出す
    if (fileName === '') return;
    handleFileNameChange(fileName, fileDescription);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth={true}>
      <DialogTitle className="font-size-20 font-weight-500">
        {file ? 'ファイル名前を編集' : 'ファイルを新規作成'}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          required
          id="outlined-required"
          label="ファイル名"
          value={fileName}
          className="w-full"
          onChange={(e) => setFileName(e.target.value)}
        />
        <Box sx={{ mt: 1 }}>
          <TextField
            id="project_description"
            value={fileDescription}
            onChange={(e) => setFileDescription(e.target.value)}
            placeholder="説明（任意）"
            className="w-full"
            type="text"
            size="medium"
            multiline
            rows={4}
          />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          type="button"
          onClick={() => handleClose()}
          variant="text"
          color="primary"
          size="medium"
          className="w-88">
          <span className="font-weight-700 font-size-14">キャンセル</span>
        </Button>
        <Button
          type="submit"
          onClick={() => handleChange()}
          className="w-162"
          variant="contained"
          color="primary"
          disabled={fileName === ''}
          size="medium"
          sx={{ boxShadow: 'none' }}>
          <span className="font-weight-700 font-size-14">{file ? '変更' : '作成'}</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileNameChangeDialog;
