import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { File } from '@/__generated__/graphql';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { formatWithDay } from '@/utils/dates';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  file?: File;
  handleFileCloneChange: (name: string, description: string) => void;
  handleRemove: (id: string) => void;
}

const FileCloneDialog = (props: Props) => {
  const { isOpen, handleClose, file, handleFileCloneChange, handleRemove } = props;
  const [fileName, setFileName] = useState(file ? file.name : '');
  const [fileDescription, setFileDescription] = useState(file ? file.description : '');

  const handleChange = () => {
    // ToDo changeFileName APIを呼び出す
    if (fileName === '') return;
    handleFileCloneChange(fileName, fileDescription);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth={true}>
      <DialogTitle
        className="font-size-20 font-weight-500"
        sx={{ display: 'flex', justifyContent: 'space-between' }}>
        ファイル情報
        <IconButton size="small" color="inherit" onClick={() => handleClose()}>
          <CloseIcon />{' '}
        </IconButton>
      </DialogTitle>

      <Divider />
      <DialogContent>
        <TextField
          required
          id="outlined-required"
          label="ファイル名"
          value={fileName}
          className="w-full"
          onChange={(e) => {
            setFileName(e.target.value);
          }}
          disabled
        />
        <Box sx={{ mt: 1 }}>
          <TextField
            id="project_description"
            value={fileDescription}
            onChange={(e) => {
              setFileDescription(e.target.value);
            }}
            placeholder="ワークフローの説明"
            className="w-full"
            type="text"
            size="medium"
            multiline
            rows={4}
            disabled
          />
        </Box>
        <Box
          sx={{
            mt: 2.5,
            backgroundColor: '#FAFAFA',
            padding: '20px',
            gap: '20px',
            display: 'flex',
            fontSize: 14,
            fontWeight: 400
          }}>
          <div>
            <span style={{ color: '#00000099' }}>作成者</span>
            <span style={{ color: '#000000DE', marginLeft: 10 }}>{file?.creator.name}</span>
          </div>
          <div>
            <span style={{ color: '#00000099' }}>作成日</span>
            <span style={{ color: '#000000DE', marginLeft: 10 }}>
              {formatWithDay(file?.createdAt as string)}
            </span>
          </div>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{ display: 'flex', justifyContent: 'space-between', padding: '12px 24px' }}>
        <Button
          onClick={() => handleRemove(file?.id as string)}
          variant="text"
          color="warning"
          size="large">
          <span className="font-weight-700 font-size-14">削除</span>
        </Button>
        <Button
          onClick={handleChange}
          variant="outlined"
          color="inherit"
          size="large"
          sx={{ color: '#00000080', borderColor: '#00000080' }}>
          <span className="font-weight-700 font-size-14">複製</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileCloneDialog;
