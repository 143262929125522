import React from 'react';
import { Box } from '@mui/material';
import { GNodePropertyFile, Kind } from '@/__generated__/graphql';
import NodePropertyFileItem from '@/components/molecules/workflows/detail/NodePropertyFileItem';

interface Props {
  propertyFileList: GNodePropertyFile[];
  setShowModal: (fileId: string) => void;
  setShowFullScreen: Function;
}

const NodePropertyFileList = (props: Props) => {
  const { propertyFileList, setShowModal, setShowFullScreen } = props;
  return (
    <Box sx={{ py: 0, display: 'flex', gap: 1, overflowY: 'auto' }}>
      {propertyFileList.map((item, index) => (
        <NodePropertyFileItem
          propertyFile={item}
          setShowModal={setShowModal}
          setShowFullScreen={() => setShowFullScreen(item)}
          key={`node_kind_item_${index}`}
        />
      ))}
    </Box>
  );
};
export default NodePropertyFileList;
