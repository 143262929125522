import { dia, util } from '@clientio/rappid';
import { BIG_WIDTH, LANE_HEADER_HEIGHT, PART_WIDTH, SPACING, STROKE_GREY_COLOR } from './constant';
import DragIndicator from '../icons/DragIndicator';
import MoreHoriz from '../icons/MoreHoriz';

class WorkflowLane extends dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: 'WorkflowLane',
      attrs: {
        header: {
          stroke: STROKE_GREY_COLOR,
          height: LANE_HEADER_HEIGHT,
          width: 'calc(w)'
        },
        body: {
          fill: 'transparent',
          stroke: STROKE_GREY_COLOR,
          strokeWidth: 1,
          width: 'calc(w)',
          height: '100%'
        },
        divider: {
          stroke: STROKE_GREY_COLOR,
          strokeWidth: 1,
          strokeDasharray: '5,5',
          d: `M ${PART_WIDTH + SPACING * 2} ${LANE_HEADER_HEIGHT} V ${BIG_WIDTH}`
        },
        dragIcon: {
          x: 5,
          y: 15,
          'xlink:href': 'data:image/svg+xml;utf8,' + encodeURIComponent(DragIndicator)
        },
        moreIcon: {
          event: 'element:show:menu',
          x: 'calc(w-30)',
          y: 15,
          'xlink:href': 'data:image/svg+xml;utf8,' + encodeURIComponent(MoreHoriz)
        },
        foreignObject: {
          fontSize: 14,
          textVerticalAnchor: 'middle',
          width: 'calc(w-60)',
          height: 21,
          x: 30,
          y: 15
        }
      },
      column: 0
    };
  }

  preinitialize(...args) {
    this.markup = util.svg`
            <rect @selector="body" />
            <rect @selector="header" />
            <image @selector="dragIcon"/>
            <foreignObject @selector="foreignObject">
              <div
                class="lane-main"
                xmlns="http://www.w3.org/1999/xhtml"
                style="height: 100%;"
              >
                <text class="lane-name" @selector="label"></text>
              </div>
            </foreignObject>
            <image @selector="moreIcon"/>
            <path @selector="divider" />
        `;
  }

  getActions() {
    return [
      {
        action: 'edit-lane',
        content: '編集'
      },
      {
        action: 'remove-lane',
        content: '削除'
      }
    ];
  }
}

const createWorkflowLane = (config, graph) => {
  const lane = new WorkflowLane(config);
  lane.addTo(graph);
  return lane;
};
export { createWorkflowLane, WorkflowLane };
