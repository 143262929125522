import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { File } from '@/__generated__/graphql';
import Box from '@mui/material/Box';
import { format } from '@/utils/dates';

interface Props {
  handleClose: () => void;
  file: File;
}

const ShowFileInfoDialog = (props: Props) => {
  const { handleClose, file } = props;
  return (
    <Dialog open={true} onClose={handleClose} fullWidth={true}>
      <DialogTitle className="font-size-20 font-weight-500">ワークフロー情報</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}>
        <CloseIcon />
      </IconButton>
      <Divider />
      <DialogContent>
        <Box>
          <div className="font-size-14 font-weight-500">ファイル名</div>
          <div className="font-size-16 font-weight-500">{file.name}</div>
        </Box>
        <Box sx={{ mt: 2 }}>
          <div className="font-size-14 font-weight-500">概要</div>
          <div className="font-size-16 font-weight-500">{file.description}</div>
        </Box>
        <Box
          sx={{ mt: 2, backgroundColor: '#FAFAFA', p: 2 }}
          className="font-size-14 font-weight-400 flex justify-content-between">
          <div>
            <span style={{ color: '#00000099' }}>作成者</span>
            <span style={{ color: '#000000DE', marginLeft: 10 }}>{file.creator.name}</span>
          </div>
          <div>
            <span style={{ color: '#00000099' }}>作成日</span>
            <span style={{ color: '#000000DE', marginLeft: 10 }}>
              {format(new Date(file.createdAt), 'yyyy/MM/dd')}
            </span>
          </div>
          <div>
            <span style={{ color: '#00000099' }}>最終変更日時</span>
            <span style={{ color: '#000000DE', marginLeft: 10 }}>
              {format(new Date(file.updatedAt), 'yyyy/MM/dd')}
            </span>
          </div>
        </Box>
      </DialogContent>
      <Divider />
    </Dialog>
  );
};

export default ShowFileInfoDialog;
