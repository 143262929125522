import React, { ChangeEvent, useState } from 'react';
import InputEmailField from '../../molecules/form/InputEmailField';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const NewRegisterForm = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    // ToDo 仮登録APIを呼び出す
    navigate('/');
  };

  return (
    <form>
      <Box sx={{ p: 2, mt: 2 }}>
        <p className="text-center font-weight-700 font-size-24">新規登録</p>
        <Box sx={{ mt: 2 }}>
          <InputEmailField
            id="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="メールアドレス"
            className="w-full"
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Button
            type="button"
            onClick={() => handleSubmit()}
            className="w-full"
            variant="contained"
            color="primary"
            size="large"
            sx={{ boxShadow: 'none' }}>
            登録する
          </Button>
        </Box>
        <div>
          <p>
            <Link href={'/login'} color="primary">
              アカウントをお持ちの方はログイン
            </Link>
          </p>
        </div>
      </Box>
    </form>
  );
};

export default NewRegisterForm;
