import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"


type Props = {
  handleClose: () => void
  handleBack: () => void
}

const ErrorAIGenerateDialog = ({ handleClose, handleBack }: Props) => {
  return (
    <Dialog open onClose={handleClose} hideBackdrop={false} fullWidth={true}>
      <DialogTitle className="font-size-20 font-weight-500">エラーが発生しました。</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Typography sx={{ fontSize: 14, margin: 0, mb: '20px' }} component="p">
            実行中に、エラーが発生しました。再度実行してください。
          </Typography>
        </Box>
        <Box sx={{ mt: '20px' }}>
          <Typography sx={{ fontSize: 14, margin: 0, mb: 1 }} component="p">
          （※エラーが何度も表示される場合は、
          <a target='_blank' rel='noreferrer' href='https://acatise.com/gekikaru/contact'>問い合わせ</a>
          よりご連絡ください。）
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          onClick={handleBack}
          variant="text"
          color="primary"
          size="medium"
          className="w-88">
          <span className="font-weight-700 font-size-14">戻る</span>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ErrorAIGenerateDialog
