import React from 'react';
import { Project } from '@/__generated__/graphql';
import { Menu, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useNavigate } from 'react-router-dom';
import { differenceTimeString } from '@/utils/dates';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

type ProjectMenuType = 'change' | 'delete';

interface ProjectMenu {
  value: number;
  label: string;
  type: ProjectMenuType;
}

const menus: ProjectMenu[] = [
  {
    value: 1,
    label: '編集',
    type: 'change'
  },
  {
    value: 2,
    label: '削除',
    type: 'delete'
  }
];

interface Props {
  project: Project;
  index: number;
  handleDeleteProject: () => void;
  showAddProjectDialog: () => void;
  setSelectedProject: (type: Project) => void;
}

const ProjectItem = (props: Props) => {
  const navigate = useNavigate();
  const { project, index, handleDeleteProject, showAddProjectDialog, setSelectedProject } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const open = Boolean(anchorEl);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    menuIndex: number,
    type: ProjectMenuType
  ) => {
    setSelectedIndex(menuIndex);
    setSelectedProject(project);
    setAnchorEl(null);
    switch (type) {
      case 'change':
        showAddProjectDialog();
        break;
      case 'delete':
        handleDeleteProject();
        break;
      default:
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = () => {
    navigate(`/projects/${project.id}/`);
  };

  return (
    <TableRow
      key={`file_item_${index}`}
      sx={{ '&:last-child td, &:last-child th': { border: 0 }, padding: '0px', cursor: 'pointer' }}
      onClick={open ? handleClose : handleClick}>
      <TableCell component="th" scope="row" sx={{ paddingLeft: '3px' }}></TableCell>
      <TableCell
        sx={{
          fontSize: '14px',
          fontWeight: 400,
          paddingLeft: '0px'
        }}>
        {project.name}
      </TableCell>
      <TableCell
        sx={{
          fontSize: '14px',
          fontWeight: 400
        }}>
        {differenceTimeString(project.updatedAt)}前
      </TableCell>
      <TableCell
        sx={{
          fontSize: '14px',
          fontWeight: 400
        }}>
        {project.filesCount}
      </TableCell>
      <TableCell component="th" scope="row">
        <IconButton
          sx={{ color: '#000000DE' }}
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            handleClickListItem(event);
          }}>
          <MoreHorizIcon />
        </IconButton>
      </TableCell>
      <Menu
        id={`project-menu-${index}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox'
        }}>
        {menus.map((menu, i) => (
          <MenuItem
            key={`project_menu_item_${index}_${i}`}
            sx={{
              color: menu.type === 'delete' ? 'red' : '',
              fontSize: '14px',
              fontWeight: '400',
              paddingLeft: '20px',
              paddingRight: '20px'
            }}
            selected={i === selectedIndex}
            onClick={(event) => {
              event.stopPropagation();
              handleMenuItemClick(event, i, menu.type);
            }}>
            {menu.label}
          </MenuItem>
        ))}
      </Menu>
    </TableRow>
  );
};

export default ProjectItem;
