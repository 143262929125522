import { gql } from 'src/__generated__';
import { parse } from 'graphql';

export const GET_FILE = gql(/* GraphQL */ `
  query File($id: ID!) {
    file(id: $id) {
      id
      name
      projectId
      description
      creatorId
      creator {
        id
        email
        firstName
        lastName
        name
        icon
      }
      createdAt
      updatedAt
      thumbnailUrl
    }
  }
`);

export const GET_NODES = parse(/* GraphQL */ `
  query Nodes($filter: GNodesFilter!) {
    nodes(filter: $filter) {
      nodes {
        id
        fileId
        rowIndex
        columnIndex
        type
        name
      }
      relations {
        id
        fromId
        toId
      }
    }
  }
`);

export const GET_TICKETS = gql(`
  query Tickets($filter: GNodesFilter!) {
    nodes(filter: $filter) {
      nodes {
        id
        name
        type
        rowIndex
        columnIndex
        property {
          name
          nodeId
          priority
          type
          media
          status
          description
          users {
            name
          }
          activities {
            text
          }
          comments {
            text
          }
        }
      }
    }
  }
`);

export const GET_ALL_IN_WORKFLOW = gql(/* GraphQL */ `
  query All(
    $columnFilter: ColumnsFilter
    $nodeFilter: GNodesFilter!
    $groupFilter: GroupFilter
    $rowFilter: RowsFilter!
    $kindFilter: KindFilter!
    $usersFilter: UsersFilter!
  ) {
    columns(filter: $columnFilter) {
      columns {
        id
        index
        name
        color
      }
    }
    rows(filter: $rowFilter) {
      id
      fileId
      index
      description
    }
    nodes(filter: $nodeFilter) {
      nodes {
        id
        name
        type
        rowIndex
        columnIndex
        createdAt
        updatedAt
        property {
          id
          name
          nodeId
          priority
          type
          media
          mediaLabel
          status
          description
          timeValue
          timeUnit
          users {
            id
            email
            firstName
            lastName
            name
            icon
          }
          activities {
            text
          }
          comments {
            id
            nodePropertyId
            text
            createdAt
            creatorId
            creator {
              id
              email
              firstName
              lastName
              name
              icon
            }
          }
          files {
            id
            nodePropertyId
            fileName
            fileType
            file
          }
          kinds {
            id
            text
          }
        }
        tickets {
          id
          nodeId
          type
          name
          status
          priority
          description
          users {
            id
            email
            firstName
            lastName
            name
            icon
          }
          activities {
            text
          }
          comments {
            id
            nodePropertyId
            text
            createdAt
            creatorId
            creator {
              id
              email
              firstName
              lastName
              name
              icon
            }
          }
          kinds {
            id
            text
          }
          files {
            id
            nodePropertyId
            fileName
            fileType
            file
          }
        }
        tasks {
          id
          nodeId
          type
          name
        }
      }
      relations {
        id
        fromId
        toId
        type
        property {
          id
          nodeRelationId
          type
          media
          description
          positions
          type
          media
          description
        }
      }
    }
    groups(filter: $groupFilter) {
      id
      name
      rowIndexes
    }
    kinds(filter: $kindFilter) {
      id
      fileId
      text
    }
    users(filter: $usersFilter) {
      id
      email
      firstName
      lastName
      name
      icon
    }
  }
`);

export const GET_NODE_PROPERTY = gql(/* GraphQL */ `
  query NodeProperty($nodeId: ID!) {
    nodeProperty(nodeId: $nodeId) {
      id
      nodeId
      type
      name
      description
      priority
      media
      users {
        id
        email
        firstName
        lastName
        name
        icon
      }
      activities {
        id
        nodePropertyId
        text
      }
      comments {
        id
        nodePropertyId
        text
      }
    }
  }
`);

export const GET_NODE_COMMENTS = gql(/* GraphQL */ `
  query NodeComments($nodeId: ID!) {
    nodeProperty(nodeId: $nodeId) {
      id
      nodeId
      type
      comments {
        id
        nodePropertyId
        text
      }
    }
  }
`);

export const CREATE_NODE = gql(/* GraphQL */ `
  mutation CreateNode($in: GNodeInput!) {
    createNode(in: $in)
  }
`);

export const ASSIGN_NODE = gql(/* GraphQL */ `
  mutation AssignNode($in: GNodeFromToInput!) {
    assignNode(in: $in)
  }
`);

export const DETACH_NODE = parse(/* GraphQL */ `
  mutation DetachNode($in: GNodeFromToInput!) {
    detachNode(in: $in)
  }
`);

export const UPDATE_NODE = gql(/* GraphQL */ `
  mutation UpdateNode($in: UpdateGNodeInput!) {
    updateNode(in: $in)
  }
`);

export const UPDATE_NODE_PROPERTY = gql(/* GraphQL */ `
  mutation UpdateNodeProperty($in: UpdateGNodePropertyInput!) {
    updateNodeProperty(in: $in)
  }
`);

export const CREATE_NODE_PROPERTY_FILE = gql(/* GraphQL */ `
  mutation CreateNodePropertyFile($in: GNodePropertyFileInput!) {
    createNodePropertyFile(in: $in)
  }
`);
export const DELETE_NODE_PROPERTY_FILE = gql(/* GraphQL */ `
  mutation DeleteNodePropertyFile($in: DeleteGNodePropertyFileInput!) {
    deleteNodePropertyFile(in: $in)
  }
`);

export const DELETE_NODE = gql(/* GraphQL */ `
  mutation DeleteNode($id: ID!) {
    deleteNode(id: $id)
  }
`);

export const CREATE_NODE_RELATION = gql(/* GraphQL */ `
  mutation CreateNodeRelation($in: GNodeRelationInput!) {
    createNodeRelation(in: $in)
  }
`);
export const DELETE_NODE_RELATION = gql(/* GraphQL */ `
  mutation DeleteNodeRelation($id: ID!) {
    deleteNodeRelation(id: $id)
  }
`);

export const INSERT_COLUMN = gql(/* GraphQL */ `
  mutation InsertColumn($in: ColumnInput!) {
    insertColumn(in: $in)
  }
`);

export const UPDATE_COLUMN = gql(/* GraphQL */ `
  mutation UpdateColumn($in: UpdateColumnInput!) {
    updateColumn(in: $in)
  }
`);

export const DELETE_COLUMN = gql(/* GraphQL */ `
  mutation DeleteColumn($in: DeleteColumnInput!) {
    deleteColumn(in: $in)
  }
`);

export const INSERT_ROW = gql(/* GraphQL */ `
  mutation InsertRow($in: RowInput!) {
    insertRow(in: $in)
  }
`);

export const DELETE_ROW = gql(/* GraphQL */ `
  mutation DeleteRow($in: DeleteRowInput!) {
    deleteRow(in: $in)
  }
`);

export const GET_ROWS = gql(/* GraphQL */ `
  query Rows($filter: RowsFilter!) {
    rows(filter: $filter) {
      id
      fileId
      index
      description
    }
  }
`);

export const UPDATE_ROW = gql(/* GraphQL */ `
  mutation UpdateRow($in: UpdateRowInput!) {
    updateRow(in: $in)
  }
`);

export const CREAT_GROUP = gql(/* GraphQL */ `
  mutation CreateGroup($in: GroupInput!) {
    createGroup(in: $in)
  }
`);

export const DELETE_GROUP = gql(/* GraphQL */ `
  mutation DeleteGroup($id: ID!) {
    deleteGroup(id: $id)
  }
`);

export const UPDATE_GROUP = gql(/* GraphQL */ `
  mutation UpdateGroup($in: UpdateGroupInput!) {
    updateGroup(in: $in)
  }
`);

export const UPDATE_NODE_RELATION_PROPERTY = gql(/* GraphQL */ `
  mutation UpdateNodeRelationProperty($in: UpdateGNodeRelationPropertyInput!) {
    updateNodeRelationProperty(in: $in)
  }
`);

// export const UPDATE_NODES_AND_RELATIONS = gql(/* GraphQL */ `
//   mutation UpdateNode($inN: UpdateGNodeInput!, $inR: UpdateGNodeRelationInput) {
//     updateNode(in: $in)
//     updateNodeRelation(in: $inR) @include(if: $inR)
//   }
// `);

// Spaces

export const GET_SPACES = parse(/* GraphQL */ `
  query Spaces($filter: SpacesFilter) {
    spaces(filter: $filter) {
      nodes {
        id
        name
        icon
      }
      pageInfo {
        count
        hasNext
        hasPrevious
        totalPage
      }
    }
  }
`);

export const GET_SPACE = parse(/* GraphQL */ `
  query Space($id: ID!) {
    space(id: $id) {
      id
      name
      icon
      projectsCount
    }
  }
`);

export const CREATE_SPACE = parse(/* GraphQL */ `
  mutation CreateSpace($in: SpaceInput!) {
    createSpace(in: $in)
  }
`);

export const DELETE_SPACE = parse(/* GraphQL */ `
  mutation DeleteSpace($id: ID!) {
    deleteSpace(id: $id)
  }
`);

export const UPDATE_SPACE = parse(/* GraphQL */ `
  mutation UpdateSpace($in: UpdateSpaceInput!) {
    updateSpace(in: $in)
  }
`);

export const INVITE_TO_SPACE = parse(/* GraphQL */ `
  mutation InviteToSpace($in: InviteSpaceInput!) {
    inviteToSpace(in: $in)
  }
`);

export const EXIT_FROM_SPACE = parse(/* GraphQL */ `
  mutation ExitFromSpace($in: ExitSpaceInput!) {
    exitFromSpace(in: $in)
  }
`);

// Project

export const GET_PROJECTS = parse(/* GraphQL */ `
  query Projects($filter: ProjectsFilter) {
    projects(filter: $filter) {
      nodes {
        id
        name
        description
        spaceId
        filesCount
        createdAt
        updatedAt
      }
      pageInfo {
        count
        hasNext
        hasPrevious
        totalPage
      }
    }
  }
`);

export const CREATE_PROJECT = parse(/* GraphQL */ `
  mutation CreateProject($in: ProjectInput!) {
    createProject(in: $in)
  }
`);

export const GET_PROJECT = parse(/* GraphQL */ `
  query Project($id: ID!) {
    project(id: $id) {
      id
      name
      description
      spaceId
      filesCount
      createdAt
      updatedAt
    }
  }
`);

export const UPDATE_PROJECT = parse(/* GraphQL */ `
  mutation UpdateProject($in: UpdateProjectInput!) {
    updateProject(in: $in)
  }
`);

export const DELETE_PROJECT = parse(/* GraphQL */ `
  mutation DeleteProject($id: ID!) {
    deleteProject(id: $id)
  }
`);

// NodePropertyComment
export const CREATE_NODE_PROPERTY_COMMENT = parse(/* GraphQL */ `
  mutation CreateNodePropertyComment($in: GNodePropertyCommentInput!) {
    createNodePropertyComment(in: $in)
  }
`);
export const UPDATE_NODE_PROPERTY_COMMENT = parse(/* GraphQL */ `
  mutation UpdateNodePropertyComment($in: UpdateGNodePropertyCommentInput!) {
    updateNodePropertyComment(in: $in)
  }
`);
export const DELETE_NODE_PROPERTY_COMMENT = parse(/* GraphQL */ `
  mutation DeleteNodePropertyComment($in: DeleteGNodePropertyCommentInput!) {
    deleteNodePropertyComment(in: $in)
  }
`);
// Files

export const GET_FILES = parse(/* GraphQL */ `
  query Files($filter: FilesFilter) {
    files(filter: $filter) {
      nodes {
        id
        name
        projectId
        description
        creatorId
        creator {
          id
          email
          firstName
          lastName
          name
          icon
        }
        createdAt
        updatedAt
        thumbnailUrl
      }
      pageInfo {
        count
        hasNext
        hasPrevious
        totalPage
      }
    }
  }
`);

export const CREATE_FILE = parse(/* GraphQL */ `
  mutation CreateFile($in: FileInput!) {
    createFile(in: $in)
  }
`);

export const CREATE_FILE_AI_SUMMARIZE = parse(/* GraphQL */ `
  mutation CreateFileAISummarize($in: AISummarizeFileInput!) {
    createFileAISummarize(in: $in)
  }
`);

export const CREATE_FILE_AI_AUTO_CORRECT = parse(/* GraphQL */ `
  mutation CreateFileAIAutoCorrect($in: AIAutoCorrectFileInput!) {
    createFileAIAutoCorrect(in: $in)
  }
`);

export const CREATE_FILE_AI_GENERATE = parse(/* GraphQL */ `
  mutation CreateFileAIGenerate($in: AIGenerateFileInput!) {
    createFileAIGenerate(in: $in)
  }
`);

export const CREATE_FILE_AI_WITH_IMAGE_GENERATE = parse(/* GraphQL */ `
  mutation CreateFileAIWithImageGenerate($in: AIWithImageGenerateFileInput!) {
    createFileAIWithImageGenerate(in: $in)
  }
`);

export const UPDATE_FILE = parse(/* GraphQL */ `
  mutation UpdateFile($in: UpdateFileInput!) {
    updateFile(in: $in)
  }
`);

export const DELETE_FILE = parse(/* GraphQL */ `
  mutation DeleteFile($id: ID!) {
    deleteFile(id: $id)
  }
`);

export const COPY_FILE = parse(/* GraphQL */ `
  mutation CopyFile($id: ID!) {
    copyFile(id: $id)
  }
`);

// Kind

export const ASSIGN_KIND_TO_NODE_PROPERTY = gql(/* GraphQL */ `
  mutation AssignKindToNodeProperty($in: NodePropertyKindInput!) {
    assignKindToNodeProperty(in: $in)
  }
`);

export const DELETE_KIND_TO_NODE_PROPERTY = gql(/* GraphQL */ `
  mutation DeleteKindToNodeProperty($in: NodePropertyKindInput!) {
    deleteKindToNodeProperty(in: $in)
  }
`);
export const DELETE_USER_TO_NODE_PROPERTY = gql(/* GraphQL */ `
  mutation DeleteUserToNodeProperty($in: NodePropertyUserInput!) {
    deleteUserToNodeProperty(in: $in)
  }
`);
export const ASSIGN_USER_TO_NODE_PROPERTY = gql(/* GraphQL */ `
  mutation AssignUserToNodeProperty($in: NodePropertyUserInput!) {
    assignUserToNodeProperty(in: $in)
  }
`);

export const GET_NODE_RELATION_PROPERTY = gql(/* GraphQL */ `
  query NodeRelationProperty($nodeRelationId: ID!) {
    nodeRelationProperty(nodeRelationId: $nodeRelationId) {
      id
      nodeRelationId
      type
      media
      description
      positions
    }
  }
`);
export const GET_USERS = gql(/* GraphQL */ `
  query Users($filter: UsersFilter!) {
    users(filter: $filter) {
      id
      email
      firstName
      lastName
      name
      icon
    }
  }
`);

export const GET_USER = gql(/* GraphQL */ `
  query User {
    user {
      id
      email
      firstName
      lastName
      name
      icon
    }
  }
`);

export const GET_USER_WITH_SETTING = gql(/* GraphQL */ `
  query UserWithSetting {
    user {
      id
      email
      firstName
      lastName
      name
      icon
      settingNotification {
        isMention
      }
    }
  }
`);

export const UPDATE_USER = parse(/* GraphQL */ `
  mutation UpdateUser($in: UpdateUserInput) {
    updateUser(in: $in)
  }
`);

export const AI_GENERATE_NODES = parse(/* GraphQL */ `
  mutation AIGenerateNodes($in: AIGenerateInput!) {
    AIGenerateNodes(in: $in)
  }
`);

export const JSON_GENERATE_NODES = parse(/* GraphQL */ `
  mutation CreateFileJSONGenerate($in: JSONGenerateFileInput!) {
    createFileJSONGenerate(in: $in)
  }
`);

export const JSON_V2_GENERATE_NODES = parse(/* GraphQL */ `
  mutation CreateFileV2JSONGenerate($in: JSONGenerateV2FileInput!) {
    createFileV2JSONGenerate(in: $in)
  }
`);
