import { useEffect, useMemo, useState } from 'react';
import { Drawer, Box } from '@mui/material';
import {
  NodePropertyStatusEnum,
  NodeTypeEnum,
  AssignNodeMutationFn,
  CreateNodeMutationFn,
  CreateNodePropertyCommentMutationFn,
  DeleteNodeMutationFn,
  DetachNodeMutationFn,
  NodePropertyPriorityEnum,
  TicketItemFragment,
  UpdateNodePropertyMutationFn,
  useNodesLazyQuery
} from '@/graphql';
import TicketListHeader from '@/components/organisms/TicketListHeader';
import TicketTable from '@/components/organisms/TicketTable';
import { TicketSideEditor } from '@/components/organisms/TicketSideEditor';

export type FilterValueType = {
  confirm: boolean;
  ticket: boolean;
  isAuthor: boolean;
  isAssignee: boolean;
  low: boolean;
  middle: boolean;
  high: boolean;
  status?: NodePropertyStatusEnum;
};

type Props = {
  fileId: string;
  tickets: TicketItemFragment[];
  searchTasks?: TicketItemFragment[];
  updateProperty: UpdateNodePropertyMutationFn;
  createNode: CreateNodeMutationFn;
  deleteNode: DeleteNodeMutationFn;
  assignNode: AssignNodeMutationFn;
  detachNode: DetachNodeMutationFn;
  createCommentForProperty: CreateNodePropertyCommentMutationFn;
};

const WorkflowTicketListPage = ({
  fileId,
  tickets,
  updateProperty,
  createNode,
  deleteNode,
  assignNode,
  detachNode,
  createCommentForProperty
}: Props) => {
  const [excludeViews, setExcludeViews] = useState<string[]>([]);

  const [filterValue, setFilterValue] = useState<FilterValueType>({
    confirm: true,
    ticket: true,
    isAuthor: true,
    isAssignee: true,
    low: true,
    middle: true,
    high: true,
    status: undefined
  });
  const [selectedTicketId, setSelectedTicketId] = useState<string | null>(null);
  const selectedTicket = useMemo(
    () => tickets.find((t) => t.id === selectedTicketId),
    [tickets, selectedTicketId]
  );
  const [searchNodes, { data }] = useNodesLazyQuery();

  const filteredTicket = (tickets: TicketItemFragment[]): TicketItemFragment[] => {
    return tickets.filter((ticket) => {
      //　表示項目の優先順位
      if (!filterValue.low && ticket.property.priority === NodePropertyPriorityEnum.Low)
        return false;
      if (!filterValue.middle && ticket.property.priority === NodePropertyPriorityEnum.Medium)
        return false;
      if (!filterValue.high && ticket.property.priority === NodePropertyPriorityEnum.High)
        return false;
      //　全てのチケットのstatus
      if (filterValue.status !== undefined) {
        if (
          filterValue.status === NodePropertyStatusEnum.Complete &&
          ticket.property.status !== NodePropertyStatusEnum.Complete
        )
          return false;
        if (
          filterValue.status === NodePropertyStatusEnum.InProgress &&
          ticket.property.status !== NodePropertyStatusEnum.InProgress
        )
          return false;
      }

      // ToDo 担当者,作成者, 種類のfilterを追加
      return true;
    });
  };

  const handleSearch = async (query: string) => {
    await searchNodes({
      variables: {
        filter: {
          fileId,
          types: [NodeTypeEnum.Task, NodeTypeEnum.Start, NodeTypeEnum.End],
          query
        }
      }
    });
  };

  const handleAddTicket = () => {
    createNode({
      variables: {
        in: {
          fileId,
          rowIndex: 0,
          columnIndex: 0,
          type: NodeTypeEnum.Ticket
        }
      }
    });
  };

  useEffect(() => {
    handleSearch('');
  }, []);

  return (
    <Box className="h-full text-left">
      <TicketListHeader
        tickets={tickets}
        excludeViews={excludeViews}
        setExcludeViews={setExcludeViews}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        handleAddTicket={handleAddTicket}
      />
      <TicketTable
        tickets={filteredTicket(tickets)}
        excludeViews={excludeViews}
        setSelectedTicketId={(id) => setSelectedTicketId(id)}
        onCheckComplete={async (nodeId, status) => {
          await updateProperty({
            variables: {
              in: {
                nodeId,
                status
              }
            }
          });
        }}
      />
      <Drawer
        anchor="right"
        open={Boolean(selectedTicket)}
        onClose={() => setSelectedTicketId(null)}>
        {selectedTicket ? (
          <TicketSideEditor
            nodes={data?.nodes.nodes || []}
            node={selectedTicket}
            setTickets={() => {}}
            searchNodes={handleSearch}
            nextNode={() => {
              let nodeIndex = tickets.findIndex(
                (item) => item.property.nodeId === selectedTicketId
              );
              if (nodeIndex === tickets.length - 1) {
                return;
              }
              nodeIndex++;
              setSelectedTicketId(tickets[nodeIndex].id);
            }}
            prevNode={() => {
              let nodeIndex = tickets.findIndex(
                (item) => item.property.nodeId === selectedTicketId
              );
              if (nodeIndex === 0) {
                return;
              }
              nodeIndex--;
              setSelectedTicketId(tickets[nodeIndex].id);
            }}
            updateNodeProperty={async (property) => {
              await updateProperty({
                variables: {
                  in: {
                    ...property
                  }
                }
              });
            }}
            assignNode={async (parentNodeId, childNodeId) => {
              assignNode({
                variables: {
                  in: {
                    parentNodeId,
                    childNodeId
                  }
                }
              });
            }}
            detachNode={async (parentNodeId, childNodeId) => {
              detachNode({
                variables: {
                  in: {
                    parentNodeId,
                    childNodeId
                  }
                }
              });
            }}
            deleteNode={async (nodeId) => {
              deleteNode({
                variables: {
                  id: nodeId
                }
              });
            }}
            createCommentForProperty={async (nodeId, text) => {
              await createCommentForProperty({
                variables: {
                  in: {
                    nodeId,
                    text
                  }
                }
              });
            }}
          />
        ) : null}
      </Drawer>
    </Box>
  );
};

export default WorkflowTicketListPage;
