import { boolean, object, string } from 'yup';
import * as Yup from 'yup';

export const postLogin = object({
  email: string().label('メールアドレス').email().required('メールアドレスは必須入力です。'),
  password: string().label('パスワード').required('パスワードは必須入力です。')
});

export const postSignUp = object({
  first_name: string().label('姓').required('姓は必須入力です。'),
  last_name: string().label('名').required('名は必須入力です。'),
  password: string()
    .label('パスワード')
    .min(8, 'パスワードは8桁以上で入力してください。')
    .required('パスワードは必須入力です。'),
  password_confirm: string()
    .label('パスワード確認用')
    .required('パスワード確認用は必須入力です。')
    .oneOf([Yup.ref('password'), ''], '同じパスワードを入力してください'),
  is_agree: boolean()
    .required()
    .test('is_agree', '利用規約に同意してください', (value) => value)
});

export const postUser = object({
  first_name: string().label('姓').required('姓は必須入力です。'),
  last_name: string().label('名').required('名は必須入力です。')
});

export const postChangePassword = object({
  password: string()
    .label('パスワード')
    .min(8, 'パスワードは8桁以上で入力してください。')
    .required('パスワードは必須入力です。'),
  new_password: string()
    .label('新しいパスワード')
    .min(8, '新しいパスワードは8桁以上で入力してください。')
    .required('新しいパスワードは必須入力です。'),
  new_password_confirm: string()
    .label('新しいパスワード確認用')
    .required('新しいパスワード確認用は必須入力です。')
    .oneOf([Yup.ref('new_password'), ''], '同じパスワードを入力してください')
});

export const postChangeEmail = object({
  email: string().label('メールアドレス').required('メールアドレスは必須入力です。'),
  email_confirm: string()
    .label('メールアドレス確認用')
    .required('メールアドレス確認用は必須入力です。')
    .oneOf([Yup.ref('email'), ''], '同じメールアドレスを入力してください'),
  password: string()
    .label('パスワード')
    .min(8, 'パスワードは8桁以上で入力してください。')
    .required('パスワードは必須入力です。')
});
