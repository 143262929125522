import React, { ReactNode } from 'react';
import Divider from '@mui/material/Divider';
import SpaceTitle from '../../../../molecules/spaces/SpaceTitle';
import SpaceSidebar from '../../../../molecules/spaces/SpaceSidebar';
import Box from '@mui/material/Box';
import { MenuType } from '../index';
import { signOut } from 'firebase/auth';
import { auth } from 'src/firebase';
import { UserQuery } from '@/__generated__/graphql';

interface Props {
  title: string;
  subTitle: string;
  user?: UserQuery;
  children: ReactNode;
  type: MenuType;
  handleClickMenu: (menuType: MenuType) => void;
  handleOpenUserProfileModal: (open: boolean) => void;
}

const SpaceLayout = (props: Props) => {
  const { title, user, subTitle, children, handleClickMenu, type, handleOpenUserProfileModal } =
    props;

  const handleSignOut = async () => {
    await signOut(auth);
  };

  return (
    <>
      <SpaceTitle
        title={title}
        user={user}
        subTitle={subTitle}
        handleSignOut={handleSignOut}
        handleOpenUserProfileModal={handleOpenUserProfileModal}
      />
      <Box sx={{ mt: 2, height: '80%' }} className="flex">
        <SpaceSidebar type={type} handleClickMenu={handleClickMenu} />
        <Divider orientation="vertical" variant="middle" flexItem className="p-10" />
        <Box className="w-full h-full" sx={{ pl: 3 }}>
          {children}
        </Box>
      </Box>
    </>
  );
};

export default SpaceLayout;
