import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import './index.scss';
import Canvas from '../../components/workflow/Canvas';
import { GET_ALL_IN_WORKFLOW, GET_FILE, GET_NODE_PROPERTY } from '@/api/graphql';
import { NodePropertyStatusEnum, NodeTypeEnum } from '@/__generated__/graphql';
import { formatRaw, formatRawTicketData } from '@/components/workflow/Canvas/utils/init';
import { TicketItemType } from '@/types/tickets';
import { RootContext } from '@/context/RootProvider';
import { Alert, Modal } from '@mui/material';
import { PartEditor } from '@/components/PartEditor';
import Box from '@mui/material/Box';
import { TicketPartEditor } from '@/components/TicketPartEditor';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '90%',
  bgcolor: 'background.paper',
  border: '2px solid #FFFFFF',
  borderRadius: 1
};

const WorkflowPage = () => {
  const navigate = useNavigate();
  const rootContext = useContext(RootContext);
  const [activeNode, setActiveNode] = useState<any>(null);
  const [activeTicket, setActiveTicket] = useState<any>(null);
  const [showZoomEditor, setShowZoomEditor] = useState(false);
  const [isSidePickerInput, setIsSidePickerInput] = useState(false);
  const [openAlert, setOpenAlert] = useState({
    type: '',
    isOpen: false,
    nodeId: ''
  });
  let { workflowId, processId, ticketId } = useParams();
  const fileId = workflowId as string;
  // ticketのGNodePropertyと紐づくプロセスのGNodeProperty
  const [ticketItems, setTicketItems] = useState<TicketItemType[]>([]);

  const { loading, error, data, refetch } = useQuery(GET_ALL_IN_WORKFLOW, {
    variables: {
      nodeFilter: { fileId },
      columnFilter: { fileId },
      groupFilter: { fileId },
      rowFilter: { fileId },
      kindFilter: { fileId },
      usersFilter: { fileId }
    },
    fetchPolicy: 'network-only'
  });

  // ticketのNodeを取得
  const {
    loading: ticketDataLoading,
    data: ticketData,
    refetch: refetchTicket
  } = useQuery(GET_ALL_IN_WORKFLOW, {
    variables: {
      nodeFilter: { fileId, types: [NodeTypeEnum.Ticket] },
      columnFilter: { fileId },
      groupFilter: { fileId },
      rowFilter: { fileId },
      kindFilter: { fileId },
      usersFilter: { fileId }
    },
    fetchPolicy: 'network-only'
  });

  const { data: fileData } = useQuery(GET_FILE, {
    variables: { id: fileId }
  });

  const { refetch: refetchNodeProperty } = useQuery(GET_NODE_PROPERTY, { skip: true });

  useEffect(() => {
    document.title = 'ゲキカル_ワークフロー';
  }, []);

  // Utilize the default initial function to generate tickets after re-fetching
  useEffect(() => {
    if (loading) return;
    const { tickets } = formatRaw(data);
    setTicketItems(tickets);

    if (ticketId) {
      setActiveTicket(tickets?.find((ticket) => ticket.nodeId === Number(ticketId)));
    }
  }, [data, loading, ticketId]);

  useEffect(() => {
    if (loading) return;
    const { nodes } = formatRaw(data);
    if (processId) {
      setActiveNode(nodes?.find((node) => node.id === Number(processId)));
    }
    if (activeNode) {
      setActiveNode(nodes?.find((node) => node.id === Number(activeNode.id)));
    }
  }, [data]);

  if (loading || ticketDataLoading)
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '95px' }}>
        <CircularProgress size={24} />
      </Box>
    );

  const { nodes, lanes, kinds, relations } = formatRaw(data);
  const { nodes: node_tickets, kinds: ticketKinds, users } = formatRaw(ticketData);
  const tickets = node_tickets.map((node_ticket) => {
    const node_ticket_property = node_ticket.property;
    let customTicket = { ...node_ticket_property };
    // customTicket.id = node_ticket.id;
    customTicket.parentNodeId = node_ticket.id;
    customTicket.name = node_ticket_property.name || '';
    customTicket.color = node_ticket_property.color || '#FFF59D';
    customTicket.status = node_ticket_property.status;
    customTicket.tasks = node_ticket.tasks;
    customTicket.createdAt = node_ticket.createdAt;
    customTicket.updatedAt = node_ticket.updatedAt;
    return customTicket;
  });

  const goToWorkFlowPage = () => {
    if (processId || ticketId) {
      navigate(`/workflows/${workflowId}/diagram/view`);
    } else {
      setShowZoomEditor(false);
      setActiveTicket(null);
      setActiveNode(null);
    }
  };
  const handleChangeActiveNode = (node: any) => {
    setActiveNode(node);
  };

  const handleOpenZoomEditor = (node: any, type = 'process') => {
    if (type === 'process') {
      if (processId) return;
      setActiveNode(node);
    } else {
      if (ticketId) return;
      setActiveTicket(node);
    }
    setShowZoomEditor(true);
  };

  const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert({ type: '', isOpen: false, nodeId: '' });
  };

  return (
    <div
      className={`workflow-main ${rootContext?.isSidebarShowing ? '' : 'workflow-hide-sidebar'}`}>
      <Canvas
        fileId={fileId}
        file={fileData?.file}
        projectId={fileData?.file.projectId}
        rootRaw={data}
        ticketItems={ticketItems}
        refetch={(type = 'node') => {
          if (type === 'ticket') {
            refetchTicket();
          }
          refetch();
        }}
        refetchNodeProperty={refetchNodeProperty}
        isSidebarShowing={rootContext?.isSidebarShowing}
        handleOpenZoomEditor={handleOpenZoomEditor}
        onTicketAdded={async (ticket: any) => {
          const { data: _ticketData } = await refetchTicket();
          await refetch()
          const { tickets: _tickets } = formatRawTicketData(_ticketData);
          setActiveTicket(_tickets?.find((t) => t.nodeId === ticket.nodeId));
          setShowZoomEditor(true)
        }}
      />
      <Modal open={!!((processId || showZoomEditor) && activeNode !== null)}>
        {(processId || showZoomEditor) && activeNode ? (
          <Box sx={style}>
            <PartEditor
              users={users}
              detailPage={true}
              lanes={lanes}
              nodes={nodes}
              node={activeNode}
              kinds={kinds}
              relations={relations}
              refetchFlag={true}
              property={activeNode.property}
              setActiveNode={() => {}}
              setNodes={() => {}}
              setRelations={() => {}}
              setLastActiveNodeName={() => {}}
              setLastActiveNodeDesc={() => {}}
              isSidePickerInput={isSidePickerInput}
              setIsSidePickerInput={setIsSidePickerInput}
              refetchAllNodes={() => {
                refetch();
              }}
              handleOpenZoomEditor={handleChangeActiveNode}
              onTicketAdded={async (ticket: any) => {
                const { data: _ticketData } = await refetchTicket();
                await refetch()
                const { tickets: _tickets } = formatRawTicketData(_ticketData);
                setActiveTicket(_tickets?.find((t) => t.nodeId === ticket.nodeId));
                setShowZoomEditor(true)
              }}
              handleCloseEditor={() => goToWorkFlowPage()}
            />
          </Box>
        ) : (
          <></>
        )}
      </Modal>
      <Modal open={!!((ticketId || showZoomEditor) && activeTicket !== null)}>
        {(ticketId || showZoomEditor) && activeTicket ? (
          <Box sx={style}>
            <TicketPartEditor
              detailPage={true}
              nodes={tickets}
              node={activeTicket}
              users={users}
              setActiveNode={() => {}}
              setTickets={() => {}}
              kinds={ticketKinds}
              setLastActiveNodeName={() => {}}
              setLastActiveNodeDesc={() => {}}
              isSidePickerInput={isSidePickerInput}
              setIsSidePickerInput={setIsSidePickerInput}
              refetchFlag={true}
              refetchAllNodes={() => {
                refetch();
              }}
              setOpenAlert={setOpenAlert}
              handleCloseEditor={() => goToWorkFlowPage()}
            />
          </Box>
        ) : (
          <></>
        )}
      </Modal>
      <Snackbar
        open={openAlert.isOpen}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        key={'top' + 'right'}>
        <Alert
          onClose={handleCloseAlert}
          severity={openAlert.type === 'CopyClipboard' ? 'info' : 'success'}
          sx={{
            width: '366px',
            fontSize: '16px',
            fontWeight: '500'
          }}>
          {openAlert.type === 'CopyClipboard'
            ? '共有リンクをコピーしました'
            : openAlert.type === NodePropertyStatusEnum.Complete
            ? `${openAlert.nodeId}を完了にしました`
            : `${openAlert.nodeId}を未完了に戻しました`}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default WorkflowPage;
