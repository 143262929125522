import React, { useEffect, useState } from 'react';
import SpaceDetail from '../../components/organisms/spaces/SpaceDetail';
import Box from '@mui/material/Box';
import { Space } from '@/__generated__/graphql';
import { useMutation, useQuery } from '@apollo/client';
import { GET_SPACE, GET_USER_WITH_SETTING, UPDATE_USER } from '../../api/graphql';
import { useNavigate, useParams } from 'react-router-dom';
import UpdateUserProfileDialog from '@/components/organisms/UpdateUserProfileDialog';

const SpacePage = () => {
  const { spaceId } = useParams();
  const navigate = useNavigate();
  const [space, setSpace] = React.useState<Space | undefined>(undefined);
  const [openUserProfile, setOpenUserProfile] = useState(false);

  const { loading, data } = useQuery(GET_SPACE, {
    variables: { id: spaceId }
  });

  const { data: userData } = useQuery(GET_USER_WITH_SETTING);

  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: [GET_USER_WITH_SETTING]
  });

  useEffect(() => {
    document.title = 'ゲキカル_スペース';
  }, []);

  useEffect(() => {
    if (data && data.space) {
      setSpace(data.space);
    }

    if (!loading && data === undefined) {
      navigate('/404');
    }
  }, [data, loading]);

  return (
    <Box className="h-full">
      {space && (
        <SpaceDetail
          space={space}
          user={userData}
          handleOpenUserProfileModal={(open: boolean) => setOpenUserProfile(open)}
        />
      )}
      {openUserProfile && userData && (
        <UpdateUserProfileDialog
          open={openUserProfile}
          user={userData}
          handleClose={() => setOpenUserProfile(false)}
          handleUpdateUser={async (user) => {
            await updateUser({
              variables: {
                in: user
              },
              onCompleted: () => {
                setOpenUserProfile(false);
              }
            });
          }}
        />
      )}
    </Box>
  );
};

export default SpacePage;
