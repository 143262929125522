import { dia, util } from '@clientio/rappid';
import { SIDE_WIDTH, STROKE_COLOR, TEXT_COLOR } from './constant';
import MoreVert from '../icons/MoreVert';

class WorkflowGroup extends dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: 'WorkflowGroup',
      attrs: {
        body: {
          fill: '#F5F5F5',
          stroke: STROKE_COLOR,
          strokeWidth: 1,
          x: -SIDE_WIDTH * 2,
          y: 0,
          width: 'calc(w)',
          height: 'calc(h)'
        },
        label: {
          // event: 'element:label:edit',
          fill: TEXT_COLOR,
          fontSize: 10,
          class: 'label-rotation',
          textAnchor: 'middle',
          x: -SIDE_WIDTH * 1.5,
          y: 'calc(h / 2)'
        },
        moreIcon: {
          event: 'element:show:menu',
          cursor: 'pointer',
          x: `calc(w/2 - ${SIDE_WIDTH * 2 + 8})`,
          y: 10,
          'xlink:href': 'data:image/svg+xml;utf8,' + encodeURIComponent(MoreVert)
        }
      },
      gid: null,
      rowIndexes: []
    };
  }

  preinitialize(...args) {
    this.markup = util.svg`
        <rect @selector="body" />
        <text @selector="label" />
        <image @selector="moreIcon" />
    `;
  }

  getActions() {
    return [
      {
        action: 'edit-group',
        content: '編集'
      },
      {
        action: 'remove-group',
        content: '削除'
      }
    ];
  }
}

const createWorkflowGroup = (config, graph, parent) => {
  const group = new WorkflowGroup(config);
  parent.embed(group);
  group.addTo(graph);
  return group;
};
export { createWorkflowGroup, WorkflowGroup };
