import React, { ChangeEvent, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  TextField
} from '@mui/material';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useMutation } from '@apollo/client';
import { CREATE_FILE_AI_SUMMARIZE, GET_FILES } from '@/api/graphql';
import GenerateWorkflowAIDialog from '../GenerateWorkflowAIDialog';
import { useNavigate } from 'react-router-dom';
import ErrorAIGenerateDialog from '../ErrorDialog';
import { FileQuery } from '@/__generated__/graphql';

interface Props {
  fileId: number;
  file: FileQuery['file'] | undefined;
  nodesCount: number;
  handleClose: () => void;
}

const SummarizeDialog = ({ fileId, file, nodesCount, handleClose }: Props) => {
  const [fileName, setFileName] = useState(file ? `${file.name} (サマリー)`: '');
  const [fileNameError, setFileNameError] = useState<null | string>();
  const [numberOfNodes, setNumberOfNodes] = useState(nodesCount);
  const [numberOfNodesError, setNumberOfNodesError] = useState<null | string>();
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [progress, setProgress] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [createAISummarize] = useMutation(CREATE_FILE_AI_SUMMARIZE);
  const navigate = useNavigate()

  const handleSubmit = async () => {
    if (!fileName) {
      setFileNameError("ファイル名が必須です")
      return
    }

    if (numberOfNodes < 1) {
      setNumberOfNodesError("サマリノード数は0以上が必要です")
      return
    }

    setProgress(0);
    setIsSubmitted(true);

    const progressIncrement = 25;
    const interval = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress + progressIncrement >= 75 ? 75 : prevProgress + progressIncrement
      );
    }, 3000);

    try {
      var progress = new Promise(function (resolve, reject) {
        setTimeout(function () {
          createAISummarize({
            variables: {
              in: {
                name: fileName,
                fileId,
                summarizeNumber: numberOfNodes
              }
            },
            refetchQueries: [GET_FILES],
            onCompleted: async (data) => {
              resolve(data.createFileAISummarize);
            },
            onError: async (err) => {
              reject(err)
            }
          });
        }, 1000);
      });

      await progress.then(
        function (generateFileId) {
          clearInterval(interval);
          setProgress(100);
          navigate(`/workflows/${generateFileId}/diagram/view?isSummary=${true}&nodeCount=${nodesCount}`);
        },
        function () {
          clearInterval(interval);
          setErrorMessage(true)
        }
      );
    } catch (error) {
      clearInterval(interval);
    } finally {
      setIsSubmitted(false);
    }
  };

  if (errorMessage) {
    return (
      <ErrorAIGenerateDialog
        handleClose={handleClose}
        handleBack={() => setErrorMessage(false)}
      />
    )
  }

  return (
    <>
      {!isSubmitted ? (
        <Dialog open onClose={handleClose} hideBackdrop={false} fullWidth={true}>
          <DialogTitle className="font-size-20 font-weight-500">サマリファイルを作成</DialogTitle>
          <Divider />
          <DialogContent>
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ margin: 0, mb: '20px' }} component="p">
                現状のプロセスを要約（サマリー）して、新しいワークフローファイルを作成します。
              </Typography>
              <Box>
                <Typography
                  component="p"
                  sx={{ p: 2, bgcolor: '#F5F5F5' }}>{`現状のプロセス数：${nodesCount}`}</Typography>
              </Box>
            </Box>
            <Box sx={{ mt: '20px' }}>
              <Typography sx={{ margin: 0, mb: 1 }} component="p">
                ファイル名
              </Typography>
              <TextField
                required
                error={!!fileNameError}
                id="outlined-required"
                label="ファイル名"
                value={fileName}
                className="w-full"
                onChange={(e) => setFileName(e.target.value)}
                onBlur={() => {
                  if (!fileName) {
                    setFileNameError("ファイル名が必須です")
                  } else {
                    setFileNameError(null)
                  }
                }}
              />
            </Box>
            <Box sx={{ mt: '20px' }}>
              <Typography sx={{ margin: 0, mb: 1 }} component="p">
                サマリ後のプロセス数
              </Typography>
              <TextField
                required
                error={!!numberOfNodesError}
                id="outlined-required"
                label="プロセス数"
                type="number"
                value={numberOfNodes}
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10);
                  if (!isNaN(value)) {
                    setNumberOfNodes(value);
                  } else {
                    setNumberOfNodes(0);
                  }
                }}
                onBlur={() => {
                  if (numberOfNodes < 1) {
                    setNumberOfNodesError("サマリノード数は0以上が必要です")
                  } else {
                    setNumberOfNodesError(null)
                  }
                }}
              />
            </Box>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              type="button"
              onClick={() => handleClose()}
              variant="text"
              color="primary"
              size="medium"
              className="w-88">
              <span className="font-weight-700 font-size-14">キャンセル</span>
            </Button>
            <Button
              type="submit"
              onClick={() => handleSubmit()}
              className="w-162"
              variant="contained"
              color="primary"
              size="medium">
              <span className="font-weight-700 font-size-14">実行</span>
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <GenerateWorkflowAIDialog isOpen progress={progress} handleClose={() => {}} />
      )}
    </>
  );
};

export default SummarizeDialog;
