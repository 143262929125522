import React from 'react';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface Props {
  handleAddProject: () => void;
}

const SpaceProjectListHeader = (props: Props) => {
  const { handleAddProject } = props;
  return (
    <div
      className="text-left flex justify-content-between align-content-center"
      style={{ height: 36, marginBottom: 20 }}>
      <Box>
        <Box className="flex font-size-20 font-weight-700" sx={{ alignItems: 'center' }}>
          プロジェクト一覧
        </Box>
      </Box>
      <div>
        <Button
          type="button"
          onClick={handleAddProject}
          variant="contained"
          color="primary"
          size="large"
          sx={{ boxShadow: 'none' }}>
          <AddIcon />
          <span className="font-weight-700 font-size-15">新規プロジェクト</span>
        </Button>
      </div>
    </div>
  );
};
export default SpaceProjectListHeader;
