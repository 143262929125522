import React, { ChangeEvent } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Project } from '@/__generated__/graphql';
import ProjectItem from '@/components/organisms/spaces/SpaceProjectListTable/ProjectItem';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';

interface Props {
  projects: Project[];
  currentPage: number;
  setCurrentPage: (type: number) => void;
  handleDeleteProject: () => void;
  showAddProjectDialog: () => void;
  setSelectedProject: (type: Project) => void;
  totalCount: number;
}

const SpaceProjectListTable = (props: Props) => {
  const {
    projects,
    currentPage,
    setCurrentPage,
    handleDeleteProject,
    showAddProjectDialog,
    setSelectedProject,
    totalCount
  } = props;

  const itemsPerPage = 20;
  const totalPages = Math.ceil(totalCount / itemsPerPage);

  const handleChangePage = (event: ChangeEvent<unknown>, newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ backgroundColor: '#F5F5F5' }}>
            <TableCell sx={{ padding: '3px', width: 20 }}></TableCell>
            <TableCell sx={{ paddingLeft: '0px' }}>プロジェクト名</TableCell>
            <TableCell sx={{ paddingLeft: '16px' }}>更新日</TableCell>
            <TableCell sx={{ paddingLeft: '16px' }}>ファイル数</TableCell>
            <TableCell sx={{ padding: '3px', width: 50 }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects?.map((project, index) => (
            <ProjectItem
              project={project}
              index={index}
              key={`space_project_list_item_${index}`}
              setSelectedProject={setSelectedProject}
              showAddProjectDialog={showAddProjectDialog}
              handleDeleteProject={handleDeleteProject}
            />
          ))}
        </TableBody>
      </Table>
      <Stack spacing={2} sx={{ alignItems: 'center', mt: 2, mb: 2 }}>
        <Pagination
          color="primary"
          count={totalPages}
          page={currentPage}
          onChange={handleChangePage}
        />
      </Stack>
    </TableContainer>
  );
};

export default SpaceProjectListTable;
