import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useMutation } from '@apollo/client';
import { CREATE_SPACE, GET_SPACES } from '../../../../api/graphql';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

const AddSpaceDialog = (props: Props) => {
  const { isOpen, handleClose } = props;
  const [spaceName, setSpaceName] = useState('');

  const [createSpace] = useMutation(CREATE_SPACE, {
    refetchQueries: [GET_SPACES]
  });

  const handleAddSpace = async () => {
    await createSpace({
      variables: {
        in: {
          name: spaceName ?? ''
        }
      },
      onCompleted: (data) => {
        window.location.href = `/spaces/${data.createSpace}`;
      }
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth={true}>
      <DialogTitle className="font-size-20 font-weight-500">スペースを新規追加</DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          required
          id="outlined-required"
          label="ファイル名"
          value={spaceName}
          className="w-full"
          onChange={(e) => setSpaceName(e.target.value)}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          type="button"
          onClick={() => handleClose()}
          variant="text"
          color="primary"
          size="medium"
          className="w-88">
          <span className="font-weight-700 font-size-14">キャンセル</span>
        </Button>
        <Button
          type="submit"
          onClick={() => handleAddSpace()}
          className="w-162"
          variant="contained"
          color="primary"
          disabled={spaceName === ''}
          size="medium"
          sx={{ boxShadow: 'none' }}>
          <span className="font-weight-700 font-size-14">スペースを作成</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSpaceDialog;
