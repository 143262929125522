import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box sx={{ width: 280, mr: 1 }}>
        <LinearProgress variant="determinate" color="primary" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="#000000DE">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

interface Props {
  isOpen: boolean;
  progress: number;
  handleClose: () => void;
}

const GenerateWorkflowAIDialog = (props: Props) => {
  const { isOpen, progress, handleClose } = props;

  const handleCloseDialog = () => {
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseDialog}
      fullWidth={true}
      hideBackdrop={false}
      PaperProps={{
        sx: { width: 500, height: 250 }
      }}>
      <DialogTitle className="font-size-20 font-weight-500" sx={{ alignSelf: 'center' }}>
        ワークフローを生成しています...
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%', padding: '32px 0px' }}>
          <LinearProgressWithLabel value={progress} />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography>ワークフローファイルを生成しています。</Typography>
          <Typography>この作業は１分ほどかかります。</Typography>
          <Typography>完了まで、しばらくお待ちください</Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default GenerateWorkflowAIDialog;
