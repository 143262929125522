import { GNode, NodePropertyPriorityEnum } from '@/__generated__/graphql';

export interface TaskItemType extends GNode {
  lane: string;
  laneColor: string;
  priority?: NodePropertyPriorityEnum;
}

export type FilterValueType = {
  start: boolean;
  end: boolean;
  task: boolean;
  condition: boolean;
  isAuthor: boolean;
  low: boolean;
  medium: boolean;
  high: boolean;
};

export const TASK_MEDIA = {
  TEL: '電話',
  EMAIL: 'メール',
  FAX: 'FAX',
  DOCUMENT: 'ドキュメント',
  EXCEL: 'エクセル',
  SYSTEM: 'システム'
};

export const TASK_PRIORITY = {
  HIGH: '高',
  MEDIUM: '中',
  LOW: '低'
};

export const TASK_TYPE_STATUS = {
  START: '始点',
  END: '終点',
  TASK: 'タスク',
  PAIR: '条件分岐'
};
