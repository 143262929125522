import { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import ToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';

export default function ButtonSwitch() {
  const { workflowId, url } = useParams();
  const search = useLocation();
  const [alignment, setAlignment] = useState('diagram');
  const theme = useTheme();

  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setAlignment(newAlignment);
  };

  useEffect(() => {
    if (!workflowId || !search) return;
    const params = search.pathname.split('/');
    if (params.length >= 4) {
      setAlignment(params[3]);
    }
  }, [search]);

  const Switch = styled(ToggleButton)<ToggleButtonProps>(({ theme }) => ({
    width: 100,
    padding: 8,
    border: 'none',
    borderRadius: '20px !important',
    color: '#fff',
    '&.Mui-selected': {
      background: '#2196F3',
      color: '#fff',
      '&:hover': {
        background: theme.palette.primary.main
      }
    }
  }));

  const Link = styled(RouterLink)(() => ({
    color: '#fff',
    width: '100%',
    display: 'block',
    textDecoration: 'none',
    '&.Mui-selected': {
      color: '#fff'
    }
  }));

  return (
    <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      sx={{
        backgroundColor: theme.palette.secondary.light,
        p: 0.5,
        height: 39,
        borderRadius: 20,
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, 0)'
      }}>
      <Switch
        value="diagram"
        size="small"
        sx={{
          fontWeight: '600',
          fontSize: '13px',
          width: 107
        }}>
        <Link to={`/workflows/${workflowId}/diagram/view`} sx={{ paddingTop: '2px' }}>
          ワークフロー
        </Link>
      </Switch>

      <Switch
        value="task"
        size="small"
        sx={{
          fontWeight: '600',
          fontSize: '13px',
          width: 100
        }}>
        <Link to={`/workflows/${workflowId}/task`} sx={{ paddingTop: '2px' }}>
          {' '}
          プロセス
        </Link>
      </Switch>

      <Switch
        value="ticket"
        size="small"
        sx={{
          fontWeight: '600',
          fontSize: '13px',
          width: 100
        }}>
        <Link to={`/workflows/${workflowId}/ticket`} sx={{ paddingTop: '2px' }}>
          チケット
        </Link>
      </Switch>
    </ToggleButtonGroup>
  );
}
