import React, { ChangeEvent, useEffect, useReducer, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Divider,
  FormControl,
  Grid,
  ListItemAvatar,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LinkIcon from '@mui/icons-material/Link';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { grey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import {
  ASSIGN_KIND_TO_NODE_PROPERTY,
  ASSIGN_NODE,
  CREATE_NODE,
  CREATE_NODE_PROPERTY_COMMENT,
  CREATE_NODE_PROPERTY_FILE,
  DELETE_NODE,
  DELETE_NODE_PROPERTY_COMMENT,
  DELETE_NODE_PROPERTY_FILE,
  GET_NODE_PROPERTY,
  GET_USER,
  UPDATE_NODE_PROPERTY,
  UPDATE_NODE_PROPERTY_COMMENT,
  UPDATE_NODE_RELATION_PROPERTY
} from '@/api/graphql';
import {
  GNode,
  GNodeProperty,
  GNodePropertyFile,
  GNodeRelation,
  Kind,
  NodePropertyMediaEnum,
  NodeRelationPropertyMediaEnum,
  NodeTypeEnum,
  UpdateGNodePropertyInput,
  User
} from 'src/__generated__/graphql';
import { useMutation, useQuery } from '@apollo/client';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import FaxOutlinedIcon from '@mui/icons-material/FaxOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import NodeTicketItem from '@/components/molecules/workflows/detail/NodeTicketItem';
import Button from '@mui/material/Button';
import remove from 'lodash/remove';
import { TASK_MEDIA } from '@/types/tasks';
import NodePropertyFileList from '@/components/molecules/workflows/detail/NodePropertyFileList';
import { useNavigate, useParams } from 'react-router-dom';
import { formatWithDayHourMinute } from '@/utils/dates';
import { TICKET_PROPERTY_KIND, TICKET_PROPERTY_KIND_COLOR } from '@/types/tickets';
import DeleteConfirmDialog from '@/components/organisms/modal/deleteConfirmDialog';
import ListItem from '@mui/material/ListItem';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import DebounceTextField from '@/components/atoms/DebounceTextField';
import { NodePropertyStatusEnum } from '@/graphql';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { clipboardToCopy } from '@/utils/functions';
import { getIconByTaskType } from '@/utils/tasks';
import { ZoomOutMapOutlined } from '@mui/icons-material';
import FullScreenPreview from '@/components/organisms/FullScreenPreview';
import { getIconByTicketType } from '@/utils/tickets';
import MentionEditor from '@/components/molecules/workflows/MentionEditor';
import { APPBAR_HEIGHT } from '@/components/workflow/Canvas/elements/constant';

export const getIcon = (media: NodePropertyMediaEnum | NodeRelationPropertyMediaEnum) => {
  switch (media) {
    case NodePropertyMediaEnum.Tel:
    case NodeRelationPropertyMediaEnum.Tel:
      return <PhoneInTalkOutlinedIcon fontSize="small" />;
    case NodePropertyMediaEnum.Fax:
    case NodeRelationPropertyMediaEnum.Fax:
      return <FaxOutlinedIcon fontSize="small" />;
    case NodePropertyMediaEnum.Email:
    case NodeRelationPropertyMediaEnum.Email:
      return <EmailOutlinedIcon fontSize="small" />;
    case NodeRelationPropertyMediaEnum.System:
      return <DnsOutlinedIcon fontSize="small" />;
    case NodeRelationPropertyMediaEnum.Document:
      return <ArticleOutlinedIcon fontSize="small" />;
    case NodeRelationPropertyMediaEnum.Excel:
      return <TableChartOutlinedIcon fontSize="small" />;
  }
  return <></>;
};

export const getIconLabel = (media: NodePropertyMediaEnum | NodeRelationPropertyMediaEnum) => {
  return media ? TASK_MEDIA[media.toUpperCase() as keyof typeof TASK_MEDIA] : '';
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />;
});

type Props = {
  lanes: any;
  nodes: GNode[];
  node: GNode;
  kinds: Kind[];
  relations: GNodeRelation[];
  property: GNodeProperty;
  setActiveNode: Function;
  setNodes: Function;
  setRelations: Function;
  refetchAllNodes: Function;
  setLastActiveNodeName: Function;
  setLastActiveNodeDesc: Function;
  setLastActiveNodeMediaLabel?: Function;
  refetchFlag?: boolean;
  detailPage?: boolean;
  handleCloseEditor?: Function;
  handleOpenZoomEditor?: Function;
  onTicketAdded?: (ticket: any) => Promise<void>;
  isSidePickerInput?: boolean;
  setIsSidePickerInput?: Function;
  users: User[];
};

type AttributeState = Pick<
  GNodeProperty,
  'name' | 'nodeId' | 'priority' | 'media' | 'mediaLabel' | 'description' | 'timeUnit' | 'timeValue'
>;

type AttributeConfig = {
  key: keyof AttributeState;
  label: string;
  options?: {
    key: string;
    icon?: NodePropertyMediaEnum;
    label: string;
  }[];
}[];

const attributeConfig: AttributeConfig = [
  {
    key: 'media',
    label: '利用ツール',
    options: [
      {
        key: NodePropertyMediaEnum.Tel,
        label: getIconLabel(NodePropertyMediaEnum.Tel),
        icon: NodePropertyMediaEnum.Tel
      },
      {
        key: NodePropertyMediaEnum.Email,
        label: getIconLabel(NodePropertyMediaEnum.Email),
        icon: NodePropertyMediaEnum.Email
      },
      {
        key: NodePropertyMediaEnum.Fax,
        label: getIconLabel(NodePropertyMediaEnum.Fax),
        icon: NodePropertyMediaEnum.Fax
      },
      {
        key: NodePropertyMediaEnum.System,
        label: getIconLabel(NodePropertyMediaEnum.System),
        icon: NodePropertyMediaEnum.System
      },
      {
        key: NodePropertyMediaEnum.Document,
        label: getIconLabel(NodePropertyMediaEnum.Document),
        icon: NodePropertyMediaEnum.Document
      },
      {
        key: NodePropertyMediaEnum.Excel,
        label: getIconLabel(NodePropertyMediaEnum.Excel),
        icon: NodePropertyMediaEnum.Excel
      }
    ]
  },
  {
    key: 'timeUnit',
    label: '工数'
  }
];

type RelationConfig = {
  key: string;
  icon?: NodeRelationPropertyMediaEnum;
  label: string;
}[];

const relationIconConfig: RelationConfig = [
  {
    key: NodeRelationPropertyMediaEnum.Tel,
    label: getIconLabel(NodeRelationPropertyMediaEnum.Tel),
    icon: NodeRelationPropertyMediaEnum.Tel
  },
  {
    key: NodeRelationPropertyMediaEnum.Email,
    label: getIconLabel(NodeRelationPropertyMediaEnum.Email),
    icon: NodeRelationPropertyMediaEnum.Email
  },
  {
    key: NodeRelationPropertyMediaEnum.Fax,
    label: getIconLabel(NodeRelationPropertyMediaEnum.Fax),
    icon: NodeRelationPropertyMediaEnum.Fax
  },
  {
    key: NodeRelationPropertyMediaEnum.System,
    label: getIconLabel(NodeRelationPropertyMediaEnum.System),
    icon: NodeRelationPropertyMediaEnum.System
  },
  {
    key: NodeRelationPropertyMediaEnum.Document,
    label: getIconLabel(NodeRelationPropertyMediaEnum.Document),
    icon: NodeRelationPropertyMediaEnum.Document
  },
  {
    key: NodeRelationPropertyMediaEnum.Excel,
    label: getIconLabel(NodeRelationPropertyMediaEnum.Excel),
    icon: NodeRelationPropertyMediaEnum.Excel
  }
];
const attributeReducer = (state: AttributeState, value: Partial<AttributeState>) => {
  return {
    ...state,
    ...value
  };
};

const TextFieldTitle = styled(TextField)({
  '.MuiInputBase-formControl:before, .MuiInputBase-formControl:after, .MuiInputBase-formControl:hover:before':
    {
      border: 'none !important'
    },
  '& input': {
    color: '#202123',
    fontWeight: 'bold',
    fontSize: '16px'
  }
});

export const PartEditor = ({
  property,
  node,
  nodes,
  lanes,
  kinds,
  relations,
  setActiveNode,
  setNodes,
  setRelations,
  refetchAllNodes,
  setLastActiveNodeName,
  setLastActiveNodeDesc,
  setLastActiveNodeMediaLabel,
  refetchFlag = false,
  detailPage = false,
  handleCloseEditor,
  handleOpenZoomEditor,
  onTicketAdded,
  isSidePickerInput,
  setIsSidePickerInput,
  users
}: Props) => {
  let { workflowId } = useParams();
  const navigate = useNavigate();
  const elementRef = useRef<HTMLDivElement>(null);
  const fileId = workflowId as string;
  let nodeIndex = nodes.findIndex((item) => item.id === node.id);
  const { refetch } = useQuery(GET_NODE_PROPERTY, { skip: true });
  const { data: userData } = useQuery(GET_USER);
  const isConditionNode = node.type === NodeTypeEnum.Condition;

  const informationConfig = [
    // {
    //   key: 'nodeId',
    //   label: 'ID',
    //   value: node.id
    // },
    // {
    //   key: 'nodeIndex',
    //   label: '番号',
    //   value: `${nodeIndex + 1} / ${nodes.length}`
    // },
    {
      key: 'columnIndex',
      label: 'レーン',
      value: lanes[node.columnIndex].name
    }
  ];

  const nextNode = () => {
    const _nodes = nodes.sort((a, b) => a.rowIndex - b.rowIndex)
    const index = _nodes.findIndex(n => n.rowIndex === node.rowIndex)

    if (_nodes.length - 1 === index) return nodes[index]

    return nodes[index + 1];
  };

  const previewNode = () => {
    const _nodes = nodes.sort((a, b) => a.rowIndex - b.rowIndex)
    const index = _nodes.findIndex(n => n.rowIndex === node.rowIndex)

    if (index === 0) return nodes[index]

    return nodes[index - 1];
  };

  const [updateNodeProperty] = useMutation(UPDATE_NODE_PROPERTY);
  const [createNodePropertyFile] = useMutation(CREATE_NODE_PROPERTY_FILE);
  const [deleteNodePropertyFile] = useMutation(DELETE_NODE_PROPERTY_FILE);
  const [assignKindToNodeProperty] = useMutation(ASSIGN_KIND_TO_NODE_PROPERTY);
  const [createNodePropertyComment] = useMutation(CREATE_NODE_PROPERTY_COMMENT);
  const [updateNodePropertyComment] = useMutation(UPDATE_NODE_PROPERTY_COMMENT);
  const [deleteNodePropertyComment] = useMutation(DELETE_NODE_PROPERTY_COMMENT);
  const [updateNodeRelationProperty] = useMutation(UPDATE_NODE_RELATION_PROPERTY);
  const [deleteNode] = useMutation(DELETE_NODE);
  const [createNode] = useMutation(CREATE_NODE);
  const [assignNode] = useMutation(ASSIGN_NODE);
  const [state, attributeUpdate] = useReducer(attributeReducer, property);
  const [comment, setComment] = useState<string>('');
  const [updateComment, setUpdateComment] = useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorCommentEl, setAnchorCommentEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openComment = Boolean(anchorCommentEl);
  const [selectedComment, setSelectedComment] = useState('');
  const [removableFileId, setShowModal] = useState('');
  const [openAlert, setOpenAlert] = React.useState({
    type: '',
    isOpen: false,
    nodeId: ''
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editRows, setEditRows] = useState<number>(0);
  const [maxWidth, setMaxWidth] = useState<number>(0);
  const editorRef = useRef<HTMLDivElement>(null);
  const [isClickSendComment, setIsClickSendComment] = useState(false);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<GNodePropertyFile | null>(null);
  const mentionRef = useRef<HTMLDivElement>(null);
  const [mentionEditorHeight, setMentionEditorHeight] = useState<number>(0);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickComment =
    (commentId: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectedComment(commentId);
      setAnchorCommentEl(event.currentTarget);
    };
  const handleCommentClose = () => {
    setAnchorCommentEl(null);
  };

  useEffect(() => {
    attributeUpdate(property);
  }, [property]);

  useEffect(() => {
    if (isClickSendComment) scrollToBottom();
  }, [property.comments]);

  useEffect(() => {
    if (elementRef.current) {
      const object = elementRef.current.getBoundingClientRect();
      console.log('Width:', object.width, elementRef.current);
      setMaxWidth(object.width - 32);
    }
    const element = mentionRef.current;

    if (element) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const height = entry.target.scrollHeight;
          setMentionEditorHeight(height);
        }
      });

      resizeObserver.observe(element);

      return () => resizeObserver.unobserve(element);
    }
  }, []);

  useEffect(() => {
    // To avoid the frequent update, check the input flag before send API request
    if (isSidePickerInput) {
      return;
    }
    const variables: {
      in: UpdateGNodePropertyInput;
    } = {
      in: {
        nodeId: state.nodeId
      }
    };
    if (state.name) {
      variables.in.name = state.name;
    }
    if (state.priority) {
      variables.in.priority = state.priority;
    }
    if (state.description) {
      variables.in.description = state.description;
    } else {
      if (property.description !== state.description) {
        variables.in.description = '';
      }
    }
    if (state.media) {
      variables.in.media = state.media;
    }
    if (state.mediaLabel) {
      variables.in.mediaLabel = state.mediaLabel;
    } else {
      if (property.mediaLabel !== state.mediaLabel) {
        variables.in.mediaLabel = '';
      }
    }

    if (state.timeUnit) {
      variables.in.timeUnit = state.timeUnit;
    }
    if (state.timeValue >= 0) {
      variables.in.timeValue = state.timeValue;
    }
    updateNodeProperty({
      variables,
      onCompleted: () => {
        if (refetchFlag) {
          refetchAllNodes();
        }
      }
    });
    // TODO: fix any type
    setNodes((prevState: any[]) => {
      const newState = [...prevState];
      const currenNode = newState[nodeIndex];
      if (state.name) {
        currenNode.name = state.name;
      }
      newState[nodeIndex] = {
        ...newState[nodeIndex],
        property: {
          ...state
        }
      };
      return newState;
    });
  }, [
    isSidePickerInput,
    state.media,
    state.mediaLabel,
    state.priority,
    state.timeValue,
    state.timeUnit
  ]);

  const handleAddTicket = (kindId: string) => {
    createNode({
      variables: {
        in: {
          fileId: fileId,
          rowIndex: node.rowIndex,
          columnIndex: node.columnIndex,
          type: NodeTypeEnum.Ticket
        }
      },
      onCompleted: async (data) => {
        const ticketNode = await refetch({ nodeId: data.createNode });
        if (onTicketAdded) {
          await onTicketAdded(ticketNode.data.nodeProperty);
        }
        if (ticketNode && ticketNode.data.nodeProperty) {
          assignKindToNodeProperty({
            variables: {
              in: { kindId: kindId, nodePropertyId: ticketNode.data.nodeProperty.id }
            }
          });
        }
        assignNode({
          variables: {
            in: { parentNodeId: node.id, childNodeId: data.createNode }
          },
          onCompleted: () => {
            refetchAllNodes();
          }
        });
      }
    });
  };

  const handleShowTicketDetail = (ticketNodeId: string) => {
    window.open(`/workflows/${workflowId}/ticket/${ticketNodeId}`, '_blank');
  };

  const handleCopyTaskDetailURL = (taskNodeId: string) => {
    const domainName = window.location.host;
    const url = `${domainName}/workflows/${workflowId}/process/${taskNodeId}`;
    clipboardToCopy(url).then((r) => {
      console.log(r);
      setOpenAlert && setOpenAlert({ type: 'CopyClipboard', isOpen: true, nodeId: '' });
    });
  };

  const handleAddComment = async (comment: string, ids: string[]) => {
    await createNodePropertyComment({
      variables: {
        in: {
          nodeId: node.id,
          text: comment,
          ids: ids
        }
      },
      onCompleted: () => {
        setIsClickSendComment(true);
        setComment('');
        refetchAllNodes();
      }
    });
  };

  const handleUpdateComment = async (comment: string, id: string) => {
    await updateNodePropertyComment({
      variables: {
        in: {
          id: id,
          text: comment
        }
      },
      onCompleted: () => {
        setUpdateComment('');
        setIsEditing(false);
        setSelectedComment('');
        refetchAllNodes();
      }
    });
  };

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      await createNodePropertyFile({
        variables: {
          in: {
            nodeId: node.id,
            file: e.target.files[0]
          }
        },
        onCompleted: () => {
          refetchAllNodes();
        }
      });
    }
  };

  const handleFileDelete = async () => {
    await deleteNodePropertyFile({
      variables: {
        in: {
          id: removableFileId
        }
      },
      onCompleted: () => {
        setShowModal('');
        refetchAllNodes();
      }
    });
  };

  const handleRemove = async () => {
    await deleteNode({
      variables: {
        id: node.id
      },
      onCompleted: () => {
        if (detailPage) {
          refetchAllNodes();
          navigate(`/workflows/${workflowId}/diagram/view`);
          return;
        }
        setRelations((prevData: any[]) => {
          const newData = [...prevData];
          remove(newData, (relation) => relation.fromId === node.id || relation.toId === node.id);
          return newData;
        });
        setNodes((prevData: any[]) => {
          const newData = [...prevData];
          remove(newData, (data) => data.id === node.id);
          return newData;
        });
        setActiveNode(null);
        handleClose();
      }
    });
  };

  const handleCommentRemove = async (id: string) => {
    await deleteNodePropertyComment({
      variables: {
        in: {
          id: id
        }
      },
      onCompleted: () => {
        setUpdateComment('');
        setIsEditing(false);
        setSelectedComment('');
        refetchAllNodes();
      }
    });
  };

  const handleChangeArrowIcon = async (icon: string, nodeId: string) => {
    await updateNodeRelationProperty({
      variables: {
        in: {
          nodeRelationId: nodeId,
          media: getArrowIconType(icon) as NodeRelationPropertyMediaEnum
        }
      },
      onCompleted: () => {
        refetchAllNodes();
      }
    });
  };

  const handleChangeArrowText = async (
    text: string,
    nodeId: string,
    relation: GNodeRelation,
    isConditionNode: boolean
  ) => {
    await updateNodeRelationProperty({
      variables: {
        in: {
          nodeRelationId: nodeId,
          description: text,
          media: isConditionNode ? null : relation.property.media || null
        }
      },
      onCompleted: () => {
        refetchAllNodes();
      }
    });
  };

  const handleChangeStatusTicket = async (nodeId: string, status: NodePropertyStatusEnum) => {
    await updateNodeProperty({
      variables: {
        in: {
          nodeId,
          status
        }
      },
      onCompleted: () => {
        refetchAllNodes();
        setOpenAlert({ type: status, isOpen: true, nodeId: nodeId });
      }
    });
  };

  const getColorByKind = (kind: Kind) => {
    if (kind.text === TICKET_PROPERTY_KIND.Confirm) return TICKET_PROPERTY_KIND_COLOR.Confirm;
    else if (kind.text === TICKET_PROPERTY_KIND.Problem) return TICKET_PROPERTY_KIND_COLOR.Problem;

    return 'yellow';
  };

  const getTicketsByKindId = (tickets: GNodeProperty[], kindId: string): GNodeProperty[] => {
    return tickets.filter((ticket) => {
      if (ticket.kinds && ticket.kinds.filter((kind) => kind.id === kindId).length > 0) return true;
      return false;
    });
  };

  const getRelations = (): GNodeRelation[] => {
    if (relations.length > 0 && nodes.length > 0) {
      return relations.filter((relation) => relation.fromId === node.id);
    }
    return [];
  };
  const getRelationNode = (nodeId: string): GNode | undefined => {
    if (nodeId && nodes.length > 0) {
      return nodes.find((node) => node.id === nodeId);
    }
  };

  const getArrowIconType = (icon: string) => {
    if (icon === 'document') return NodeRelationPropertyMediaEnum.Document;
    else if (icon === 'email') return NodeRelationPropertyMediaEnum.Email;
    else if (icon === 'excel') return NodeRelationPropertyMediaEnum.Excel;
    else if (icon === 'fax') return NodeRelationPropertyMediaEnum.Fax;
    else if (icon === 'system') return NodeRelationPropertyMediaEnum.System;
    else if (icon === 'tel') return NodeRelationPropertyMediaEnum.Tel;
    else return null;
  };

  const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert({ type: '', isOpen: false, nodeId: '' });
  };

  const scrollToBottom = () => {
    const element = editorRef.current;
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  };

  const handleOpenFullScreenPreview = (file: GNodePropertyFile) => {
    setShowFullScreen(true);
    setSelectedItem(file);
  };

  // Handle the default placeholder for condition node
  const getNamePlaceholder = (name: string) => {
    if (!name) {
      return 'プロセス名';
    }
    if (['（条件１）プロセス名', '（条件２）プロセス名'].includes(name)) {
      return name;
    }
  };

  // Handle the default label for condition relations
  const getArrowDesc = (desc: string) => {
    if (['条件１', '条件２'].includes(desc)) {
      return '';
    }
    return desc;
  };

  const parseAndRenderText = (text: string) => {
    const mentionRegex = /@\{name:(.*?)\}\{id:(\d+)\}/g;

    let updatedText = text.replace(mentionRegex, (_, name) => {
      return `<a href="#" class="m6zwb4v" spellCheck="false" data-testid="mentionLink">${name}</a>`;
    });

    return updatedText;
  };

  return (
    <>
      <Box
        ref={editorRef}
        sx={{
          width: detailPage ? '100%' : 518,
          fontSize: 12,
          height: `calc(100% - ${
            detailPage ? mentionEditorHeight : mentionEditorHeight + APPBAR_HEIGHT
          }px)`,
          overflowY: detailPage ? null : 'auto',
          textAlign: 'left'
        }}>
        <Toolbar
          sx={{
            backgroundColor: grey[100],
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            minHeight: 46,
            padding: '8px 16px 7px !important',
            gap: 2
          }}
          variant="dense">
          {!detailPage && (
            <>
              <IconButton size="small" color="inherit" onClick={() => setActiveNode(nextNode())}>
                <KeyboardArrowDownIcon sx={{ height: 20 }} />
              </IconButton>
              <IconButton size="small" color="inherit" onClick={() => setActiveNode(previewNode())}>
                <KeyboardArrowUpIcon sx={{ height: 20 }} />
              </IconButton>
            </>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { md: 'flex', gap: '16px' } }}>
            {!detailPage && (
              <IconButton
                size="small"
                color="inherit"
                onClick={() => handleOpenZoomEditor && handleOpenZoomEditor(node)}>
                <ZoomOutMapOutlined sx={{ height: 20 }} />
              </IconButton>
            )}
            <IconButton
              size="small"
              color="inherit"
              onClick={() => handleCopyTaskDetailURL(node.id)}>
              <LinkIcon sx={{ height: 20 }} />
            </IconButton>
            <IconButton id="remove-button" size="small" color="inherit" onClick={handleClick}>
              <MoreHorizIcon sx={{ height: 20 }} />
            </IconButton>
            <Menu
              id="remove-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              MenuListProps={{
                'aria-labelledby': 'remove-button'
              }}>
              <MenuItem
                sx={{
                  width: 145,
                  height: 36,
                  color: 'red',
                  fontWeight: 400,
                  fontSize: 16
                }}
                onClick={handleRemove}>
                削除
              </MenuItem>
            </Menu>
            <IconButton
              size="small"
              color="inherit"
              onClick={() => {
                if (detailPage) {
                  handleCloseEditor && handleCloseEditor();
                }
                setActiveNode(null);
              }}>
              <CloseIcon sx={{ height: 20 }} />
            </IconButton>
          </Box>
        </Toolbar>
        <Box
          sx={{
            height: detailPage ? 'calc(100% - 16px)' : null,
            p: detailPage ? 2 : null
          }}>
          <Grid container spacing={2} sx={{ height: '100%' }}>
            <Grid
              item
              xs={detailPage ? 6 : 12}
              sx={{ height: detailPage ? '100%' : null, overflowY: detailPage ? 'auto' : null }}>
              <Stack
                spacing={2}
                sx={{ p: 2, color: '#00000099', pb: 0 }}
                divider={
                  <Divider
                    orientation="horizontal"
                    sx={{ borderStyle: 'dashed', borderColor: '#0000001F' }}
                    flexItem
                  />
                }
                ref={elementRef}>
                <Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      {getIconByTaskType(node.type)}
                      <TextFieldTitle
                        fullWidth
                        variant="standard"
                        autoFocus
                        placeholder={getNamePlaceholder(state.name)}
                        value={state.name}
                        InputProps={{
                          onBlur: ({ target: { value } }) => {
                            setIsSidePickerInput && setIsSidePickerInput(false);
                          },
                          onKeyDown: (event) => {
                            if (event.key === 'Enter') {
                              setIsSidePickerInput && setIsSidePickerInput(false);
                            }
                          }
                        }}
                        onChange={({ target: { value } }) => {
                          setIsSidePickerInput && setIsSidePickerInput(true);
                          setLastActiveNodeName(value);
                          attributeUpdate({
                            name: value
                          });
                        }}
                      />
                    </Box>
                  </Grid>
                  {informationConfig.map((item, index) => (
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      key={`info_${item.key}_${index}`}
                      sx={{ mt: 1 }}>
                      <Grid item xs={2}>
                        <Typography variant="body2">{item.label}</Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="body1" sx={{ color: '#202123', fontWeight: 'normal' }}>
                          {item.value}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                  {attributeConfig.map((item, index) => (
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      key={`attr_${item.key}_${index}`}
                      sx={{ mt: 1 }}>
                      <Grid item xs={2}>
                        <Typography variant="body2">{item.label}</Typography>
                      </Grid>
                      <Grid item xs={10}>
                        {item.key === 'timeUnit' ? (
                          <input
                            style={{
                              backgroundColor: '#0000000A',
                              border: 'none',
                              width: '70px',
                              height: '28px',
                              padding: '4px 8px'
                            }}
                            type="text"
                            pattern="[0-9]*"
                            value={state['timeValue']}
                            onChange={({ target: { value } }) =>
                              attributeUpdate({
                                timeValue: Number(value)
                              })
                            }
                          />
                        ) : (
                          <></>
                        )}
                        {item.options ? (
                          <FormControl variant="standard" size="small" sx={{}}>
                            <Select
                              sx={{
                                backgroundColor: '#0000000A',
                                border: 'none',
                                width: '70px',
                                height: '28px',
                                padding: '4px 8px',
                                '&& .MuiSelect-select': {
                                  paddingTop: '3px'
                                }
                              }}
                              value={state[item.key]}
                              onChange={({ target: { value } }) =>
                                attributeUpdate({
                                  [item.key]: value
                                })
                              }>
                              <MenuItem value="" sx={{ height: 33 }}>
                                <em></em>
                              </MenuItem>
                              {item.options.map((optionItem) => (
                                <MenuItem
                                  value={optionItem.key}
                                  key={optionItem.key}
                                  sx={{
                                    gap: 1,
                                    paddingLeft: '10px'
                                  }}>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      margin: '4px 0 0 8px',
                                      gap: 1
                                    }}>
                                    {optionItem.icon && getIcon(optionItem.icon)}
                                    {item.key !== 'media' ? optionItem.label : ''}
                                  </Box>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ) : (
                          <></>
                        )}
                        {item.key === 'media' ? (
                          <TextField
                            variant="standard"
                            sx={{
                              marginLeft: '8px',
                              width: '115px',
                              '& .MuiInputBase-input': {
                                padding: '3.5px 0px 5px'
                              }
                            }}
                            value={state.mediaLabel ?? ''}
                            placeholder={getIconLabel(state[item.key] as NodePropertyMediaEnum)}
                            InputProps={{
                              onBlur: ({ target: { value } }) => {
                                setIsSidePickerInput && setIsSidePickerInput(false);
                              },
                              onKeyDown: (event) => {
                                if (event.key === 'Enter') {
                                  setIsSidePickerInput && setIsSidePickerInput(false);
                                }
                              }
                            }}
                            onChange={({ target: { value } }) => {
                              setIsSidePickerInput && setIsSidePickerInput(true);
                              setLastActiveNodeMediaLabel && setLastActiveNodeMediaLabel(value);
                              attributeUpdate({
                                mediaLabel: value
                              });
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Stack spacing={2}>
                  <Typography variant="body2">説明</Typography>
                  <TextField
                    multiline
                    fullWidth
                    variant="standard"
                    minRows={3}
                    value={state.description}
                    placeholder="このプロセスの内容を記載します"
                    InputProps={{
                      onBlur: ({ target: { value } }) => {
                        setIsSidePickerInput && setIsSidePickerInput(false);
                      },
                    }}
                    onChange={({ target: { value } }) => {
                      setIsSidePickerInput && setIsSidePickerInput(true);
                      setLastActiveNodeDesc(value);
                      attributeUpdate({
                        description: value
                      });
                    }}
                  />
                </Stack>
                <Stack spacing={2} sx={{ mt: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="body2">ファイル</Typography>
                    <Button variant="text" color="inherit" component="label" size="small">
                      <AddIcon sx={{ cursor: 'pointer' }} />
                      <input
                        type="file"
                        id="upload-task-file"
                        accept="*"
                        hidden
                        onChange={handleFileUpload}
                      />
                    </Button>
                  </Box>
                  {property.files && property.files.length > 0 && (
                    <NodePropertyFileList
                      propertyFileList={property.files}
                      setShowModal={setShowModal}
                      setShowFullScreen={handleOpenFullScreenPreview}
                    />
                  )}
                </Stack>
              </Stack>
            </Grid>
            <Grid
              item
              xs={detailPage ? 6 : 12}
              sx={{
                height: detailPage ? '100%' : null,
                overflowY: detailPage ? 'auto' : null,
                paddingLeft: detailPage ? '0px' : null,
                display: 'grid'
              }}>
              <Stack spacing={2} sx={{ p: 2, color: '#00000099', pt: 0 }}>
                <Divider
                  orientation="horizontal"
                  sx={{ borderStyle: 'dashed', borderColor: '#0000001F' }}
                />
                <Stack spacing={2}>
                  <Typography variant="body2">{isConditionNode ? '分岐条件' : ''}アロー</Typography>
                  <Box sx={{ padding: '4px 8px', backgroundColor: '#F5F5F5' }}>
                    <Grid container spacing={0}>
                      {!isConditionNode ? (
                        <Grid item xs={2}>
                          <Typography variant="body2">アイコン</Typography>
                        </Grid>
                      ) : null}
                      <Grid item xs={5}>
                        <Typography variant="body2">
                          {isConditionNode ? '分岐条件' : 'アロー文言'}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography variant="body2">後のタスク</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  {getRelations().map((relation, index) => (
                    <Box key={`relation_item_${index}`}>
                      <Grid container spacing={0} sx={{ height: 40 }}>
                        {!isConditionNode ? (
                          <Grid item xs={1.8}>
                            <Select
                              sx={{ height: 37, width: '90%' }}
                              value={relation.property?.media}
                              onChange={({ target: { value } }) => {
                                handleChangeArrowIcon(value, relation.property?.nodeRelationId);
                              }}>
                              <MenuItem value="" sx={{ height: 33 }}>
                                <em></em>
                              </MenuItem>
                              {relationIconConfig.map((icon) => (
                                <MenuItem
                                  value={icon.key}
                                  key={icon.key}
                                  sx={{
                                    gap: 1
                                  }}>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      margin: '4px 0 0 8px',
                                      gap: 1
                                    }}>
                                    {icon.icon && getIcon(icon.icon)}
                                  </Box>
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                        ) : null}
                        <Grid item xs={4.2}>
                          <DebounceTextField
                            handleDebounce={(value) =>
                              handleChangeArrowText(
                                value,
                                relation.property.nodeRelationId,
                                relation,
                                isConditionNode
                              )
                            }
                            debounceTimeout={300}
                            initialValue={getArrowDesc(relation.property?.description)}
                            sx={{
                              height: 37,
                              width: '95%',
                              WebkitFlexDirection: 'initial'
                            }}>
                            アロー文言
                          </DebounceTextField>
                        </Grid>
                        <Grid item xs={0.5} sx={{ paddingTop: '8px' }}>
                          <EastOutlinedIcon></EastOutlinedIcon>
                        </Grid>
                        <Grid item xs={5.2} sx={{ paddingTop: '5px', paddingLeft: '10px' }}>
                          <List sx={{ height: 37, width: '100%', paddingTop: '3px' }} dense={true}>
                            <ListItem
                              sx={{ padding: 0, cursor: 'pointer' }}
                              key={`task_list_item`}
                              onClick={() =>
                                handleOpenZoomEditor &&
                                handleOpenZoomEditor(getRelationNode(relation.toId))
                              }>
                              <ListItemAvatar sx={{ minWidth: 20, paddingRight: '5px' }}>
                                {getIconByTaskType(getRelationNode(relation.toId)?.type ?? '')}
                              </ListItemAvatar>
                              <ListItemText
                                sx={{ marginTop: 0 }}
                                primary={
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      fontSize: '14px',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      '&:hover': {
                                        textDecoration: 'underline'
                                      }
                                    }}>
                                    {relation.toId} - {getRelationNode(relation.toId)?.name}
                                  </Typography>
                                }
                                secondary={null}
                              />
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                </Stack>
                <Divider
                  orientation="horizontal"
                  sx={{ borderStyle: 'dashed', borderColor: '#0000001F' }}
                />
                {kinds.map((kind, index) => (
                  <Stack spacing={2} key={`kind_item_${index}`}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography
                        variant="body2"
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <span style={{ paddingTop: '2.5px' }}>
                          {getIconByTicketType(kind.text)}
                        </span>
                        {kind.text}チケット
                      </Typography>
                      {/*課題/確認チケット追加ボタン（＋）削除関連で修正（後で確認）*/}
                      <AddIcon
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleAddTicket(kind.id)}
                      />
                    </Box>
                    <Box>
                      {node.tickets &&
                        getTicketsByKindId(node.tickets, kind.id).map((ticket) => (
                          <NodeTicketItem
                            maxWidth={maxWidth}
                            isShowCheckBoxArea={true}
                            ticket={ticket}
                            key={ticket.nodeId}
                            onCheckComplete={handleChangeStatusTicket}
                            showTicketInfo={(ticketNodeId) => {
                              handleShowTicketDetail(ticketNodeId);
                            }}
                          />
                        ))}
                    </Box>
                  </Stack>
                ))}
              </Stack>
              <Stack
                sx={{ backgroundColor: '#F9F8F8', p: 2, pb: 4 }}
                spacing={3}
                divider={
                  <Divider
                    orientation="horizontal"
                    sx={{ borderStyle: 'dashed', borderColor: '#0000001F' }}
                    flexItem
                  />
                }>
                {property.activities && property.activities.length > 0 && (
                  <Stack spacing={2}>
                    {/*<Typography variant="body2">アクティビティ</Typography>*/}
                    {property.activities?.map((activity, index) => (
                      <Stack
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        key={`${activity.nodePropertyId}_${index}`}>
                        <Avatar sx={{ width: 28, height: 28 }}>K</Avatar>
                        <Typography variant="body2">
                          {activity.text}
                          {/*<strong>水野 直</strong> がこのタスクを作成 2023/07/18 13:89*/}
                        </Typography>
                      </Stack>
                    ))}
                  </Stack>
                )}
                <Stack spacing={2}>
                  <Typography variant="body2">コメント</Typography>
                  {property.comments?.map((item, index) => (
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="start"
                      sx={{ width: '100%' }}
                      key={`${item.id}_${index}`}>
                      <Box>
                        <Avatar
                          sx={{ width: 28, height: 28 }}
                          src={item.creator?.icon || '/images/grey.png'}
                        />
                      </Box>
                      <Box sx={{ width: '100%' }}>
                        <Typography variant="body2">
                          {item.creator && <span>{item.creator.name}</span>}
                          <span style={{ marginLeft: '8px' }}>
                            {formatWithDayHourMinute(item.createdAt)}
                          </span>
                        </Typography>
                        {isEditing && selectedComment === item.id ? (
                          <Box style={{ maxWidth: maxWidth - 38 }}>
                            <MentionEditor
                              users={users}
                              isUpdateComment={isEditing}
                              comment={updateComment === '' ? item.text : updateComment}
                              handleAddComment={async (comment: string) => {
                                setUpdateComment(comment);
                                await handleUpdateComment(comment ?? item.text, item.id);
                              }}
                              handleCloseComment={() => setIsEditing(false)}
                            />
                          </Box>
                        ) : (
                          <div
                            style={{
                              maxWidth: maxWidth - 92,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                            dangerouslySetInnerHTML={{ __html: parseAndRenderText(item.text) }}
                          />
                        )}
                      </Box>
                      <Box>
                        {!isEditing && (
                          <IconButton
                            id={`comment-menu-${item.id}`}
                            size="small"
                            color="inherit"
                            onClick={handleClickComment(item.id)}>
                            <ExpandMoreIcon />
                          </IconButton>
                        )}
                      </Box>
                    </Stack>
                  ))}
                  <Box>
                    <Menu
                      id="comment-menu"
                      anchorEl={anchorCommentEl}
                      open={openComment}
                      onClose={handleCommentClose}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      MenuListProps={{
                        'aria-labelledby': 'comment-edit-button'
                      }}
                      PaperProps={{
                        sx: {
                          boxShadow:
                            '0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)'
                        }
                      }}>
                      <MenuItem
                        sx={{
                          width: 145,
                          height: 36,
                          fontWeight: 400,
                          fontSize: 16
                        }}
                        onClick={() => {
                          handleCommentClose();
                          setIsEditing(true);
                        }}>
                        コメントを編集
                      </MenuItem>
                      <MenuItem
                        sx={{
                          width: 145,
                          height: 36,
                          color: 'red',
                          fontWeight: 400,
                          fontSize: 16
                        }}
                        onClick={() => {
                          handleCommentClose();
                          setIsEditing(false);
                          handleCommentRemove(selectedComment);
                        }}>
                        コメントを削除
                      </MenuItem>
                    </Menu>
                  </Box>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        {removableFileId !== '' && (
          <DeleteConfirmDialog
            isOpen={true}
            title="ファイルを削除します"
            content={
              <Box sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                「ファイル名」を削除します。よろしいですか？この操作は取り消せません。
              </Box>
            }
            handleDelete={handleFileDelete}
            handleClose={() => setShowModal('')}
          />
        )}
        <Snackbar
          open={openAlert.isOpen}
          autoHideDuration={3000}
          onClose={handleCloseAlert}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          key={'top' + 'right'}>
          <Alert
            onClose={handleCloseAlert}
            severity={openAlert.type === 'CopyClipboard' ? 'info' : 'success'}
            sx={{
              width: '366px',
              fontSize: '16px',
              fontWeight: '500'
            }}>
            {openAlert.type === 'CopyClipboard'
              ? '共有リンクをコピーしました'
              : openAlert.type === NodePropertyStatusEnum.Complete
              ? `${openAlert.nodeId}を完了にしました`
              : `${openAlert.nodeId}を未完了に戻しました`}
          </Alert>
        </Snackbar>
      </Box>
      <Box
        ref={mentionRef}
        sx={{
          display: 'flex',
          width: detailPage ? '100%' : 518,
          gap: '10px',
          padding: '15px',
          alignItems: 'center',
          position: 'fixed',
          bottom: 0,
          backgroundColor: '#F9F8F8',
          borderTop: '1px solid #0000001F',
          boxShadow:
            '0px 2px 1px -1px #00000033, 0px 1px 1px 0px #00000024, 0px 1px 3px 0px #0000001F'
        }}>
        <Box sx={{ width: 28 }}>
          <Avatar sx={{ width: 28, height: 28 }} src={userData?.user.icon ?? '/images/grey.png'} />
        </Box>
        <Box sx={{ width: 'calc(100% - 38px)' }}>
          <MentionEditor
            users={users}
            setEditRows={setEditRows}
            handleAddComment={handleAddComment}
            comment={comment}
            isTicketComment={false}
          />
        </Box>
      </Box>

      {showFullScreen && (
        <FullScreenPreview
          src={`${selectedItem?.file}?w=161&fit=crop&auto=format`}
          alt="Fullscreen Image"
          showFullScreen={showFullScreen}
          setShowFullScreen={setShowFullScreen}
        />
      )}
    </>
  );
};
