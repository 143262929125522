import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { getFileTypeFromURL } from '@/utils/files';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { DialogTitle } from '@mui/material';
import Box from '@mui/material/Box';

interface FullScreenImageProps {
  src: string;
  alt?: string;
  showFullScreen: boolean;
  setShowFullScreen: Function;
}

const FullScreenPreview: React.FC<FullScreenImageProps> = ({
  src,
  alt,
  showFullScreen,
  setShowFullScreen
}) => {
  const handleClose = () => {
    setShowFullScreen(false);
  };

  const renderContent = () => {
    const fileType = getFileTypeFromURL(src);

    switch (fileType) {
      case 'video':
        return <video src={src} controls style={{ width: '100%', height: '100%' }} />;
      case 'image':
        return (
          <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
            <img src={src} alt="" style={{ maxWidth: '100%', height: 'auto' }} />
          </Box>
        );
      case 'document':
        return (
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={[
              {
                uri: src,
                fileType: 'docx'
              }
            ]}
            style={{ width: '100%', height: '100%' }}
          />
        );
      case 'pdf':
        return <iframe src={src} style={{ width: '100%', height: '100%' }} />;
      default:
        return <p>Unsupported file type</p>;
    }
  };

  return (
    <>
      <Dialog
        open={showFullScreen}
        onClose={handleClose}
        aria-labelledby="full-screen-image"
        aria-describedby="full-screen-image-description"
        maxWidth="xl"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: '#ffffff',
            boxShadow: 'none',
            overflow: 'hidden',
            height: '90%'
          }
        }}>
        <DialogTitle sx={{ height: 50 }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{renderContent()}</DialogContent>
      </Dialog>
    </>
  );
};

export default FullScreenPreview;
