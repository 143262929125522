import { GNodeProperty } from '@/__generated__/graphql';

export interface TicketItemType extends GNodeProperty {
  createdAt: string;
  tasks: GNodeProperty[];
}

export const TICKET_PROPERTY_PRIORITY = {
  HIGH: '高',
  MEDIUM: '中',
  LOW: '低'
};

export const TICKET_PROPERTY_STATUS = {
  Complete: 'complete',
  InProgress: 'in_progress'
};

export const TICKET_PROPERTY_KIND = {
  Confirm: '確認',
  Problem: '課題'
};

export const TICKET_PROPERTY_KIND_COLOR = {
  Confirm: '#FFF59D',
  Problem: '#F8BBD0'
};

export const TICKET_PRIORITY_COLOR = {
  High: '#C62828',
  Medium: '#F9A825',
  Low: '#1E88E5'
};
