import { ChangeEvent, useState } from 'react';
import { Button, Box } from '@mui/material';
import InputTextFieldWithLabel from '../../../atoms/InputTextFieldWithLabel';
import { useMutation } from '@apollo/client';
import { CREATE_SPACE, GET_SPACES } from '@/api/graphql';

const CreateSpace = () => {
  const [channel, setSpaceSetting] = useState<{
    name: string;
    imageURL: string;
    file: null | File;
  }>({
    name: '',
    imageURL: '',
    file: null
  });
  const [createSpace] = useMutation(CREATE_SPACE, {
    refetchQueries: [GET_SPACES]
  });

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSpaceSetting((prev) => {
        return {
          ...prev,
          imageURL: URL.createObjectURL(e.target.files![0]),
          file: e.target.files && e.target.files[0]
        };
      });
    }
  };

  const handleCreateSpace = async () => {
    if (channel.name === '') return;
    await createSpace({
      variables: {
        in: {
          name: channel.name,
          icon: channel.file
        }
      },
      onCompleted: (data) => {
        window.location.href = `/spaces/${data.createSpace}`;
      }
    });
  };

  return (
    <Box sx={{ p: 2 }} className="mx-auto max-w-522">
      <p className="text-center font-weight-700 font-size-24">スペースを作成</p>
      <Box sx={{ mt: 2, textAlign: 'left' }}>
        <InputTextFieldWithLabel
          label="スペース名"
          size="small"
          value={channel.name}
          onChange={(e) =>
            setSpaceSetting((prev) => {
              return {
                ...prev,
                name: e.target.value
              };
            })
          }
        />
        <Box sx={{ mt: 2, textAlign: 'left' }}>
          <div className="font-size-14 font-weight-400">スペース画像</div>
          {channel.imageURL && channel.file && (
            <Box mt={2}>
              <img src={channel.imageURL} alt={channel.file.name} height="100px" />
            </Box>
          )}
          <Button
            variant="outlined"
            color="inherit"
            component="label"
            size="medium"
            sx={{ mt: 2, width: 120 }}>
            アップロード
            <input
              type="file"
              id="upload-project-image"
              accept="image/*"
              hidden
              onChange={handleFileUpload}
            />
          </Button>
        </Box>
      </Box>
      <Button
        type="button"
        onClick={() => handleCreateSpace()}
        className="w-full"
        sx={{ mt: 2 }}
        variant="contained"
        disabled={channel.name === ''}
        color="primary"
        size="medium">
        <span className="font-weight-700 font-size-14">スペースを作成</span>
      </Button>
    </Box>
  );
};

export default CreateSpace;
