import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { UsersQuery } from '@/__generated__/graphql';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import SpaceMemberListHeader from 'src/components/organisms/spaces/SpaceMemberListHeader';
import SpaceMemberTable from 'src/components/organisms/spaces/SpaceMemberTable';
import InviteMemberDialog from '@/components/organisms/modal/InviteMemberDialog';
import { useMutation } from '@apollo/client';
import { EXIT_FROM_SPACE, GET_USERS, INVITE_TO_SPACE } from '@/api/graphql';

interface Props {
  users?: UsersQuery;
  spaceId: string;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const SpaceMemberList = (props: Props) => {
  const { users, spaceId } = props;
  const [openInviteAlert, setOpenInviteAlert] = React.useState(false);
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const [inviteToSpace] = useMutation(INVITE_TO_SPACE, { refetchQueries: [GET_USERS] });
  const [exitFromSpace] = useMutation(EXIT_FROM_SPACE, { refetchQueries: [GET_USERS] });
  const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenInviteAlert(false);
  };
  const handleInviteSentAgain = async (email: string) => {
    await inviteToSpace({
      variables: {
        in: {
          emails: [email],
          spaceId: spaceId
        }
      },
      onCompleted: () => {
        setOpenInviteAlert(true);
      }
    });
  };
  const handleDeleteUser = async (userId: string) => {
    await exitFromSpace({
      variables: {
        in: {
          userId: userId,
          spaceId: spaceId
        }
      }
    });
  };
  const handleShowInviteDialog = () => {
    setShowInviteDialog(true);
  };
  return (
    <Box className="h-full text-left">
      <SpaceMemberListHeader handleShowInviteDialog={handleShowInviteDialog} />
      <SpaceMemberTable
        users={users}
        handleInviteSentAgain={handleInviteSentAgain}
        handleDeleteUser={handleDeleteUser}
      />
      <Snackbar
        open={openInviteAlert}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        key={'top' + 'right'}>
        <Alert
          onClose={handleCloseAlert}
          severity="success"
          sx={{
            width: '100%',
            fontSize: '16px',
            fontWeight: '500',
            backgroundColor: '#EDF7ED',
            color: '#1E4620'
          }}>
          招待を再送しました
        </Alert>
      </Snackbar>
      {showInviteDialog && (
        <InviteMemberDialog isOpen={true} handleClose={() => setShowInviteDialog(false)} />
      )}
    </Box>
  );
};

export default SpaceMemberList;
