import { dia, elementTools, util } from '@clientio/rappid';
import { PART_HEIGHT, PART_WIDTH, SPACING, TEXT_COLOR } from './constant';
import MoreVert from '../icons/MoreVert';

const getMarkup = (angle = 0) => {
  return [
    {
      tagName: 'circle',
      selector: 'button',
      attributes: {
        r: 7,
        fill: 'rgba(70, 102, 229, .8)',
        stroke: '#FFFFFF',
        cursor: 'pointer'
      }
    },
    {
      tagName: 'path',
      selector: 'icon',
      attributes: {
        transform: `rotate(${angle})`,
        d: 'M -4 -1 L 0 -1 L 0 -4 L 4 0 L 0 4 0 1 -4 1 z',
        fill: '#FFFFFF',
        stroke: 'none',
        'stroke-width': 2,
        'pointer-events': 'none'
      }
    }
  ];
};

const createConnect = (x = '100%', y = '50%', angle = 0) => {
  return new elementTools.Connect({
    x,
    y,
    markup: getMarkup(angle)
  });
};

class WorkflowPart extends dia.Element {
  constructor(...args) {
    super(...args);
    this.resize(PART_WIDTH, PART_HEIGHT);
  }

  defaults() {
    return {
      ...super.defaults,
      type: 'WorkflowPart',
      attrs: {
        body: {
          fill: '#fff',
          width: 'calc(w)',
          height: 'calc(h)',
          rx: 8
        },
        label: {
          fill: TEXT_COLOR,
          fontSize: 14,
          textVerticalAnchor: 'middle',
          x: 10,
          y: 22
        },
        moreIcon: {
          event: 'element:show:menu',
          cursor: 'pointer',
          x: 'calc(w-24)',
          y: 12,
          'xlink:href': 'data:image/svg+xml;utf8,' + encodeURIComponent(MoreVert)
        },
        foreignObject: {
          width: 'calc(w-24)',
          height: 'calc(h-24)',
          x: 12,
          y: 12
        }
      },
      row: 0,
      column: 0,
      pid: null,
      ticketsCount: 0
    };
  }

  preinitialize(...args) {
    this.markup = util.svg`
      <rect @selector="body" />
      <foreignObject @selector="foreignObject">
        <div
          class="part-main"
          xmlns="http://www.w3.org/1999/xhtml"
          style="background-color: #fff; height: 100%;"
        >
          <p class="part-name" @selector="label"></p>
          <div class="part-metadata" @selector="metadata"></div>
        </div>
      </foreignObject>
      <image @selector="moreIcon" />
    `;
  }

  getActions() {
    return [
      {
        action: 'remove',
        content: '削除'
      }
    ];
  }

  addTools(paper) {
    const connectRight = createConnect();
    const connectBottom = createConnect('50%', '100%', 90);
    const connectTop = createConnect('50%', '0%', 270);
    const connectLeft = createConnect('0%', '50%', 180);
    const tools = new dia.ToolsView({
      tools: [connectRight, connectLeft, connectTop, connectBottom]
    });
    const elementView = this.findView(paper);
    if (!elementView) {
      return;
    }
    elementView.addTools(tools);
  }

  layoutTickets(ticket) {
    const x = PART_WIDTH + SPACING + 12;
    let y = -8;
    const ticketsCount = this.prop('ticketsCount');
    // if a node has more than 1 ticket, change the next ticket's position
    if (ticketsCount > 0) {
      y += (ticket.size().height + 12) * ticketsCount;
    }
    this.prop('ticketsCount', ticketsCount + 1);
    ticket.position(x, y, { deep: true, parentRelative: true });
  }
}

const createWorkflowPart = (config, graph, parent) => {
  const part = new WorkflowPart(config);
  parent.embed(part);
  part.addTo(graph);
  return part;
};
export { createWorkflowPart, WorkflowPart };
