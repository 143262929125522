import NewRegisterForm from '../../components/organisms/new-register/';
import React from 'react';

const NewRegisterPage = () => {
  return (
    <div className="mx-auto max-w-522">
      <NewRegisterForm />
    </div>
  );
};

export default NewRegisterPage;
