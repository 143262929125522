import React from 'react';
import Box from '@mui/material/Box';
import { File } from '@/__generated__/graphql';
import SpaceWorkflowListHeader from '@/components/organisms/spaces/SpaceWorkflowListHeader';
import SpaceWorkflowListTable from '@/components/organisms/spaces/SpaceWorkflowListTable';
import { WORKFLOW_CREATE_TYPE } from '@/types/workflows';

interface Props {
  files: File[];
  showFileNameChangeDialog: (file: File) => void;
  setSelectFileForTransfer: (file: File) => void;
  cronFile: (file: File) => void;
  showFileInfoDialog: (file: File) => void;
  deleteFile: (file: File) => void;
  handleDeleteFile: () => void;
  title: string;
  subTitle: string;
  showAddFileDialog: (type: WORKFLOW_CREATE_TYPE) => void;
  showAddProjectDialog: () => void;
  deleteProject: () => void;
  currentPage: number;
  setCurrentPage: (type: number) => void;
  totalCount: number;
}

const SpaceWorkflowList = (props: Props) => {
  const {
    title,
    subTitle,
    showAddFileDialog,
    showAddProjectDialog,
    deleteProject,
    files,
    showFileNameChangeDialog,
    setSelectFileForTransfer,
    cronFile,
    showFileInfoDialog,
    deleteFile,
    handleDeleteFile,
    currentPage,
    setCurrentPage,
    totalCount
  } = props;

  return (
    <Box className="h-full text-left">
      <SpaceWorkflowListHeader
        title={title}
        subTitle={subTitle}
        showAddFileDialog={showAddFileDialog}
        showAddProjectDialog={showAddProjectDialog}
        deleteProject={deleteProject}
      />
      <SpaceWorkflowListTable
        files={files}
        showFileNameChangeDialog={showFileNameChangeDialog}
        setSelectFileForTransfer={setSelectFileForTransfer}
        cronFile={cronFile}
        showFileInfoDialog={showFileInfoDialog}
        deleteFile={deleteFile}
        handleDeleteFile={handleDeleteFile}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalCount={totalCount}
      />
    </Box>
  );
};

export default SpaceWorkflowList;
