export type WORKFLOW_CREATE_TYPE = '通常' | 'AI自動生成' | 'Jsonインポート';

export const WORKFLOW_CREATE_TYPES = {
  DEFAULT: '通常',
  AI: 'AI自動生成',
  JSON: 'Jsonインポート'
};

export const getWorkFlowCreateTypeValue = (type: WORKFLOW_CREATE_TYPE) => {
  return Object.values(WORKFLOW_CREATE_TYPES).findIndex((item) => {
    return item === type;
  });
};

export const getWorkFlowCreateTypeLabel = (type: string) => {
  const index = Object.keys(WORKFLOW_CREATE_TYPES).findIndex((item) => {
    return item === type;
  });
  if (index < 0) return '';
  return Object.values(WORKFLOW_CREATE_TYPES)[index];
};
