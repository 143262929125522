import React, { ReactNode } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';

interface Props {
  isOpen: boolean;
  title: string;
  content: ReactNode;
  handleClose: () => void;
  handleDelete: () => void;
}

const DeleteConfirmDialog = (props: Props) => {
  const { isOpen, handleClose, title, content, handleDelete } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth={true}
      PaperProps={{
        sx: { width: 430 }
      }}>
      <DialogTitle className="font-size-20 font-weight-500">{title}</DialogTitle>
      <DialogContent sx={{ padding: '0px 24px 20px 24px', height: '100%', gap: 20 }}>
        <DialogContentText id="alert-dialog-slide-description">{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          onClick={() => handleClose()}
          variant="text"
          color="primary"
          size="medium"
          className="w-88">
          <span className="font-weight-700 font-size-14">キャンセル</span>
        </Button>
        <Button
          type="submit"
          className="w-162"
          variant="contained"
          color="error"
          size="medium"
          onClick={handleDelete}
          sx={{ boxShadow: 'none' }}>
          <span className="font-weight-700 font-size-14">削除</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmDialog;
