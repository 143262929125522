import React from 'react';
import { useLocation } from 'react-router-dom';

interface RootInterface {
  isSidebarShowing: boolean;
  setIsSidebarShowing: Function;
}

export const RootContext = React.createContext<RootInterface | null>(null);

export const RootProvider: React.FC<React.PropsWithChildren> = (props) => {
  const { hash } = useLocation();
  const isSidebarClose = hash.slice(1) === 'nosidebar';
  const [isSidebarShowing, setIsSidebarShowing] = React.useState(!isSidebarClose);

  return (
    <RootContext.Provider
      value={{
        isSidebarShowing,
        setIsSidebarShowing
      }}>
      {props.children}
    </RootContext.Provider>
  );
};
