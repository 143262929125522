export function clipboardToCopy(text: string): Promise<boolean> {
  return new Promise((resolve, error) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(
        function () {
          resolve(true);
        },
        function () {
          error();
        }
      );
    } else {
      // ToDo navigator.clipboardが利用できない場合
      error();
    }
  });
}
