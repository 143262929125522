import Slider from '@mui/material/Slider';
import Cropper from 'react-easy-crop';
import { Point, Area } from 'react-easy-crop/types';
import React, { useCallback, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

interface Props {
  open: boolean;
  handleClose: () => void;
  uploadFileURL: string;
  setUploadFileURL: (value: string) => void;
}

const ImageCropperDialog = (props: Props) => {
  const { open, uploadFileURL, setUploadFileURL, handleClose } = props;
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const getCroppedImg = async (imageSrc: string, crop: Area): Promise<string> => {
    const createImage = (url: string): Promise<HTMLImageElement> =>
      new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.src = url;
        image.setAttribute('crossOrigin', 'anonymous');
      });

    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d')!;

    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.drawImage(image, crop.x, crop.y, crop.width, crop.height, 0, 0, crop.width, crop.height);

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          throw new Error('Canvas is empty');
        }
        resolve(URL.createObjectURL(blob));
      }, 'image/jpeg');
    });
  };

  const handleSave = async () => {
    if (!croppedAreaPixels || !uploadFileURL) return;
    handleClose();
    try {
      const croppedImage = await getCroppedImg(uploadFileURL, croppedAreaPixels);
      setUploadFileURL(croppedImage);
    } catch (error) {}
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      PaperProps={{
        sx: {
          width: 600,
          height: 534,
          borderRadius: '12px'
        }
      }}>
      <DialogTitle className="font-size-20 font-weight-500">アカウント画像を更新</DialogTitle>
      <Divider />
      <DialogContent sx={{ padding: 0 }}>
        <Box
          className="crop-container"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 330,
            alignItems: 'center',
            mb: 5.5
          }}>
          <Cropper
            style={{
              containerStyle: { marginTop: '60px', marginBottom: '115px' }
            }}
            cropShape="round"
            image={uploadFileURL}
            crop={crop}
            zoom={zoom}
            showGrid={false}
            aspect={1 / 1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </Box>
        <Box className="controls" sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ width: 350, display: 'flex' }}>
            <Typography variant="body2" sx={{ marginTop: '7px', marginRight: '25px' }}>
              ー
            </Typography>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => setZoom(Number(zoom))}
              classes={{ root: 'slider' }}
            />
            <Typography variant="body2" sx={{ marginTop: '7px', marginLeft: '20px' }}>
              ＋
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          type="button"
          onClick={() => handleClose()}
          variant="text"
          color="primary"
          size="medium"
          className="w-88">
          <span className="font-weight-700 font-size-14">キャンセル</span>
        </Button>
        <Button
          type="submit"
          onClick={handleSave}
          className="w-162"
          variant="contained"
          color="primary"
          size="medium"
          sx={{ boxShadow: 'none' }}>
          <span className="font-weight-700 font-size-14">保存する</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageCropperDialog;
