import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Avatar, Chip, MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';
import { UsersQuery } from '@/__generated__/graphql';
import CheckIcon from '@mui/icons-material/Check';
import Divider from '@mui/material/Divider';
import { getAuth } from 'firebase/auth';

interface Props {
  users?: UsersQuery;
  handleInviteSentAgain: (id: string) => void;
  handleDeleteUser: (id: string) => void;
}

interface FilterUser {
  email: string;
  firstName: string;
  icon: string;
  id: string;
  lastName: string;
  name: string;
  typeSelected: boolean;
  type: string;
}

const SpaceMemberTable = (props: Props) => {
  const { users, handleInviteSentAgain, handleDeleteUser } = props;
  const auth = getAuth();
  const FilterUsers = (usersQuery: UsersQuery | undefined): FilterUser[] => {
    if (!usersQuery?.users) {
      return [];
    }
    const userData = usersQuery.users.map((user) => ({
      email: user.email,
      firstName: user.firstName,
      icon: user.icon || '',
      id: user.id,
      lastName: user.lastName,
      name: user.name,
      typeSelected: true,
      type: 'admin'
    }));
    const currentUser = userData.find((user) => user.email === auth?.currentUser?.email);
    const remainedUsers = userData.filter((user) => user.email !== auth?.currentUser?.email);
    const availableUsers = remainedUsers.filter((user) => user.name !== ' ');
    const filteredUsers = currentUser ? [currentUser, ...availableUsers] : [...availableUsers];

    return filteredUsers;
  };
  const [rows, setRows] = useState<FilterUser[]>(FilterUsers(users));
  useEffect(() => {
    setRows(FilterUsers(users));
  }, [users]);
  const userType = [
    { key: 'general', label: '一般' },
    { key: 'admin', label: '管理者' }
  ];

  const handleToSelectAll = () => {
    //Todo handleToSelectAll
  };
  const handleToSelectItem = (index: number) => {
    //Todo handleToSelectItem
  };

  const handleChangeType = (type: string, id: string) => {
    const newRows = rows?.map((row) => {
      if (row.id === id && type) {
        return { ...row, type: type, typeSelected: true };
      }
      return row;
    });
    setRows(newRows);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ backgroundColor: '#F5F5F5' }}>
            <TableCell sx={{ padding: '3px', width: 20 }}>
              {/*<Checkbox defaultChecked={false} onChange={handleToSelectAll} />*/}
            </TableCell>
            <TableCell sx={{ paddingLeft: '0px' }}>ユーザー名</TableCell>
            <TableCell sx={{ paddingLeft: '16px' }}>メールアドレス</TableCell>
            <TableCell sx={{ paddingLeft: '16px' }}>権限</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, index) => (
            <TableRow
              key={`member_item_${index}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              onClick={() => {}}>
              <TableCell component="th" scope="row" sx={{ padding: '3px' }}>
                {/*<Checkbox defaultChecked={false} onChange={(e) => handleToSelectItem(index)} />*/}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                aria-disabled={true}
                sx={{ paddingLeft: '0px' }}>
                <Box
                  component="li"
                  sx={{
                    '& > img': { mr: 2, flexShrink: 0 },
                    ...(row.name === ' ' && {
                      color: 'grey'
                    }),
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    maxWidth: '100%',
                    maxLines: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: '14px',
                    fontWeight: 400
                  }}>
                  <Avatar
                    sx={{ width: 32, height: 32 }}
                    variant="circular"
                    src={row?.icon as string}
                  />
                  {row.name === ' ' ? row.email : row.name}
                  {row.email === auth.currentUser?.email ? (
                    <Chip
                      label="自分"
                      size="small"
                      variant="filled"
                      sx={{ backgroundColor: '#00000014' }}
                    />
                  ) : (
                    <></>
                  )}
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  fontSize: '14px',
                  fontWeight: 400
                }}>
                {row.name !== ' ' ? (
                  row.email
                ) : (
                  <Chip
                    label="招待中"
                    size="small"
                    variant="filled"
                    sx={{ backgroundColor: '#00000014' }}
                  />
                )}
              </TableCell>
              <TableCell sx={{ paddingLeft: 0 }}>
                <Select
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      border: 'none'
                    },
                    '.MuiSelect-select': {
                      color: '#000000DE'
                    },
                    border: 'none',
                    width: '120px',
                    height: '28px',
                    padding: '4px 0px'
                  }}
                  value={row.type}
                  renderValue={(selected) => {
                    if (selected !== '') {
                      return userType.find((item) => item.key === selected)?.label || '';
                    }
                  }}
                  onChange={({ target: { value } }) => {
                    handleChangeType(value, row.id as string);
                  }}>
                  {userType
                    .filter((item) => row.name === ' ' || item.key !== 'again')
                    .map((item) => {
                      const menuItems = [
                        <MenuItem
                          value={item.key}
                          key={`menu_item_${item.key}`}
                          sx={{
                            color: item.key === 'remove' ? 'red' : '#000000DE',
                            paddingLeft:
                              row.typeSelected === true && row.type === item.key ? 0 : '36px'
                          }}>
                          {row.typeSelected === true && row.type === item.key && (
                            <CheckIcon sx={{ width: 36, color: '#000000DE' }} />
                          )}
                          {item.label}
                        </MenuItem>
                      ];

                      if (item.key === 'admin') {
                        menuItems.push(<Divider key={`divider_${item.key}`} />);
                      }

                      return menuItems;
                    })}
                  <MenuItem
                    value=""
                    key={'menu_item_again'}
                    onClick={() => handleInviteSentAgain(row.email)}
                    sx={{
                      color: '#000000DE',
                      paddingLeft: '36px'
                    }}>
                    招待を再送
                  </MenuItem>
                  <MenuItem
                    value=""
                    key={'menu_item_remove'}
                    onClick={() => handleDeleteUser(row.id)}
                    sx={{
                      color: 'red',
                      paddingLeft: '36px'
                    }}>
                    スペースから削除
                  </MenuItem>
                </Select>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SpaceMemberTable;
