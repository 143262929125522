import React, { ChangeEvent, useState } from 'react';
import InputEmailField from '../../molecules/form/InputEmailField';
import { Link } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const ForgotPasswordForm = () => {
  const auth = getAuth();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [openAlert, setOpenAlert] = React.useState(false);
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (error !== '') setError('');
  };

  const handleSubmit = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setError('');
        setOpenAlert(true);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  return (
    <form>
      <Box sx={{ p: 2, mt: 12 }}>
        <p className="text-center font-weight-700 font-size-24">パスワード再設定</p>
        <Box sx={{ mt: 2 }}>
          <InputEmailField
            id="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="メールアドレス"
            className="w-full"
            errors={error !== '' ? [error] : []}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Button
            type="button"
            onClick={() => handleSubmit()}
            className="w-full"
            variant="contained"
            color="primary"
            size="large"
            sx={{ boxShadow: 'none' }}>
            メールを送信する
          </Button>
        </Box>
        <div>
          <p>
            <Link href={'/login'} color="primary">
              ログインへ戻る
            </Link>
          </p>
        </div>
      </Box>
      <Snackbar
        open={openAlert}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        key={'top' + 'right'}>
        <Alert
          onClose={handleCloseAlert}
          severity="success"
          sx={{
            width: '100%',
            fontSize: '16px',
            fontWeight: '500'
          }}>
          再設定用のメールを送信しました
        </Alert>
      </Snackbar>
    </form>
  );
};

export default ForgotPasswordForm;
