import React from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import { Menu, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { WORKFLOW_CREATE_TYPE, WORKFLOW_CREATE_TYPES } from '@/types/workflows';

type ProjectMenuType = 'change' | 'delete';

interface ProjectMenu {
  value: number;
  label: string;
  type: ProjectMenuType;
}

const menus: ProjectMenu[] = [
  {
    value: 1,
    label: 'プロジェクト詳細',
    type: 'change'
  },
  {
    value: 2,
    label: '削除',
    type: 'delete'
  }
];

interface Props {
  title: string;
  subTitle: string;
  showAddFileDialog: (type: WORKFLOW_CREATE_TYPE) => void;
  showAddProjectDialog: () => void;
  deleteProject: () => void;
}

const SpaceWorkflowListHeader = (props: Props) => {
  const { title, subTitle, showAddFileDialog, showAddProjectDialog, deleteProject } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    type: ProjectMenuType,
    index: number
  ) => {
    setSelectedIndex(index);
    if (type === 'delete') {
      deleteProject();
      handleClose();
    } else if (type === 'change') {
      showAddProjectDialog();
      handleClose();
    }
  };

  return (
    <div
      className="text-left flex justify-content-between align-content-center"
      style={{ height: 60, marginBottom: 20 }}>
      <Box>
        <Box className="flex font-size-24 font-weight-700" sx={{ alignItems: 'center' }}>
          {title}
          <IconButton
            color="secondary"
            size="small"
            onClick={(event) => setAnchorEl(event.currentTarget)}>
            <KeyboardArrowDownIcon />
          </IconButton>
        </Box>
        <div className="font-size-14 font-weight-500">{subTitle}</div>
      </Box>
      <div>
        <Button
          id="customized-ai-button"
          startIcon={<AutoAwesomeOutlinedIcon sx={{ rotate: '180deg' }} />}
          size="large"
          variant="contained"
          onClick={() => showAddFileDialog(WORKFLOW_CREATE_TYPES.AI as WORKFLOW_CREATE_TYPE)}
          sx={{ background: '#9C27B0', color: 'white', marginRight: '20px', boxShadow: 'none' }}>
          <span className="font-weight-700 font-size-15">AI自動生成</span>
        </Button>
        <Button
          type="button"
          onClick={() => showAddFileDialog(WORKFLOW_CREATE_TYPES.DEFAULT as WORKFLOW_CREATE_TYPE)}
          variant="contained"
          color="primary"
          size="large"
          sx={{ boxShadow: 'none' }}>
          <AddIcon />
          <span className="font-weight-700 font-size-15">新規ファイル</span>
        </Button>
      </div>
      <Menu
        id="project-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox'
        }}>
        {menus.map((menu, i) => (
          <MenuItem
            key={`project_menu_item_${i}`}
            sx={{ color: menu.type === 'delete' ? 'red' : '' }}
            selected={i === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, menu.type, i)}>
            {menu.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default SpaceWorkflowListHeader;
