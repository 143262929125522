import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Link } from '@mui/material';
import { Space, File as GraphQLFile, Project } from '@/__generated__/graphql';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import AddProjectDialog from '../../modal/AddProjectDialog';
import FileNameChangeDialog from '../../modal/FileNameChangeDialog';
import ShowFileInfoDialog from '../../modal/ShowFileInfoDialog';
import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_FILE,
  COPY_FILE,
  DELETE_FILE,
  DELETE_PROJECT,
  UPDATE_PROJECT,
  GET_FILES,
  GET_PROJECTS,
  GET_SPACE,
  UPDATE_FILE,
  JSON_GENERATE_NODES,
  CREATE_FILE_AI_GENERATE,
  JSON_V2_GENERATE_NODES,
  CREATE_FILE_AI_WITH_IMAGE_GENERATE
} from '@/api/graphql';
import DeleteConfirmDialog from '@/components/organisms/modal/deleteConfirmDialog';
import SpaceWorkflowList from '@/components/organisms/spaces/SpaceWorkflowList';
import { WORKFLOW_CREATE_TYPE, WORKFLOW_CREATE_TYPES } from '@/types/workflows';
import AddFileDialog from '@/components/organisms/modal/AddFileDialog';
import GenerateWorkflowAIDialog from '@/components/organisms/modal/GenerateWorkflowAIDialog';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import ErrorAIGenerateDialog from '../../modal/ErrorDialog';
import { FileOrder, SortType } from '@/graphql';
import FileTransferDialog from '../../modal/FileTransferDialog';

export type SpaceProjectTabType = 'workflow' | 'analysis';

export interface SpaceProjectTab {
  value: number;
  type: SpaceProjectTabType;
  label: string;
}

// const tabs: SpaceProjectTab[] = [
//   {
//     value: 0,
//     type: 'workflow',
//     label: 'ワークフロー'
//   },
//   {
//     value: 1,
//     type: 'analysis',
//     label: '分析'
//   }
// ];

interface Props {
  project: Project;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />;
});

const SpaceDetail = (props: Props) => {
  const { project } = props;
  const navigate = useNavigate();
  const [projectName, setProjectName] = useState(project.name);
  const [projectDescription, setProjectDescription] = useState(project.description);
  const [space, setSpace] = useState<Space | undefined>(undefined);
  const [files, setFiles] = useState<GraphQLFile[]>([]);
  const [showModal, setShowModal] = useState<string>('');
  const [showAddFileModal, setShowAddFileModal] = useState<WORKFLOW_CREATE_TYPE | null>(null);
  const [showGenerateWorkflowAIModal, setShowGenerateWorkflowAIModal] = useState(false);
  const [selectFileForTransfer, setSelectFileForTransfer] = useState<GraphQLFile>();
  const [selectedFile, setSelectedFile] = useState<GraphQLFile | undefined>(undefined);
  const [removeFile, setRemoveFile] = useState<GraphQLFile>();
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [progress, setProgress] = useState(0);
  const [openAlert, setOpenAlert] = React.useState<{
    type?: string;
    message?: string;
    alertType?: AlertColor;
    isOpen: boolean;
  }>({
    type: '',
    isOpen: false
  });

  const [createFile] = useMutation(CREATE_FILE, {
    refetchQueries: [GET_FILES]
  });
  const [updateFile] = useMutation(UPDATE_FILE, {
    refetchQueries: [GET_FILES]
  });
  const [deleteFile] = useMutation(DELETE_FILE, {
    refetchQueries: [GET_FILES]
  });
  const [copyFile] = useMutation(COPY_FILE, {
    refetchQueries: [GET_FILES]
  });
  // AI時はrefetchしない
  const [createFileAIGenerate, { error: AIError }] = useMutation(CREATE_FILE_AI_GENERATE, {
    refetchQueries: [GET_FILES]
  });
  const [createFileAIWithImageGenerate, { error: AIWithImageError }] = useMutation(CREATE_FILE_AI_WITH_IMAGE_GENERATE, {
    refetchQueries: [GET_FILES]
  });
  const [deleteProject] = useMutation(DELETE_PROJECT, {
    refetchQueries: [GET_PROJECTS]
  });
  const [updateProject] = useMutation(UPDATE_PROJECT, {
    refetchQueries: [GET_PROJECTS]
  });

  const [jsonGenerateNodes] = useMutation(JSON_GENERATE_NODES);
  const [jsonV2GenerateNodes] = useMutation(JSON_V2_GENERATE_NODES);

  const { data } = useQuery(GET_FILES, {
    variables: {
      filter: {
        projectId: project.id,
        page: currentPage,
        limit: 20,
        orderBy: FileOrder.UpdatedAt,
        sortType: SortType.Desc
      }
    }
  });

  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);
  const [showDeleteFileModal, setShowDeleteFileModal] = useState(false);

  useEffect(() => {
    if (data && data.files && data.files.nodes) {
      setFiles(data.files.nodes);
    }
  }, [data]);

  useEffect(() => {
    if (AIError) {
      setOpenAlert({ type: WORKFLOW_CREATE_TYPES.AI, isOpen: true });
    }
  }, [AIError]);

  const spaceRes = useQuery(GET_SPACE, {
    variables: { id: project.spaceId }
  });

  useEffect(() => {
    if (spaceRes && spaceRes.data && spaceRes.data.space) {
      setSpace(spaceRes.data.space);
    }
  }, [spaceRes.data]);

  const showFileNameChangeDialog = (file: GraphQLFile) => {
    setSelectedFile(file);
    setShowModal('change');
  };

  const handleCronFile = async (file: GraphQLFile) => {
    await copyFile({
      variables: {
        id: file.id
      }
    });
  };

  const handleDeleteFile = async () => {
    await deleteFile({
      variables: {
        id: removeFile?.id
      },
      onCompleted: () => {
        setShowDeleteFileModal(false);
      }
    });
  };

  const showFileInfoDialog = (file: GraphQLFile) => {
    setSelectedFile(file);
    setShowModal('info');
  };

  const handleClose = () => {
    setShowModal('');
    setShowAddFileModal(null);
    setSelectedFile(undefined);
  };

  const handleFileNameChange = async (name: string, description: string) => {
    if (selectedFile) {
      await updateFile({
        variables: {
          in: {
            name: name,
            id: selectedFile.id,
            description: description
          }
        },
        onCompleted: () => {
          const file = { ...selectedFile, name: name, description: description };
          setSelectedFile(file);
        }
      });
    } else {
      await createFile({
        variables: {
          in: {
            name: name,
            projectId: project.id,
            description: description
          }
        },
        onCompleted: () => {}
      });
    }
    handleClose();
  };

  const handleAddWithAIPrompt = async (name: string, description: string, prompt: string, file?: File) => {
    setShowAddFileModal(null);
    setShowGenerateWorkflowAIModal(true);
    setProgress(0);
    const progressIncrement = 25;
    const interval = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress + progressIncrement >= 75 ? 75 : prevProgress + progressIncrement
      );
    }, 3000);

    try {
      var progress = new Promise(function (resolve, reject) {
        setTimeout(function () {
          if (file) {
            createFileAIWithImageGenerate({
              variables: {
                in: {
                  name: name,
                  projectId: project.id,
                  description: description,
                  query: prompt,
                  image: file
                }
              },
              onCompleted: async (data) => {
                resolve(data.createFileAIWithImageGenerate);
              },
              onError: async (err) => {
                reject(err);
              }
            });
          } else {
            createFileAIGenerate({
              variables: {
                in: {
                  name: name,
                  projectId: project.id,
                  description: description,
                  query: prompt
                }
              },
              onCompleted: async (data) => {
                resolve(data.createFileAIGenerate);
              },
              onError: async (err) => {
                reject(err);
              }
            });
          }
        }, 1000);
      });

      await progress.then(
        function (fileId) {
          clearInterval(interval);
          setProgress(100);
          navigate(`/workflows/${fileId}/diagram/view?isAI=${true}&projectId=${project.id}`);
        },
        function () {
          clearInterval(interval);
          setOpenAlert({ type: WORKFLOW_CREATE_TYPES.AI, isOpen: true });
          setErrorMessage(true);
        }
      );
    } catch (error) {
      clearInterval(interval);
      setOpenAlert({ type: WORKFLOW_CREATE_TYPES.AI, isOpen: true });
    } finally {
      setShowGenerateWorkflowAIModal(false);
    }
  };

  const handleGenerateFileJson = async (name: string, description: string, jsonFile: File) => {
    setShowAddFileModal(null);
    const reader = new FileReader();
    reader.readAsText(jsonFile); // Read the file as text
    reader.onload = () => {
      try {
        if (reader) {
          if (typeof reader.result === 'string') {
            const jsonData = JSON.parse(reader.result);
            setProgress(0);
            const progressIncrement = 25;
            const interval = setInterval(() => {
              setProgress((prevProgress) =>
                prevProgress + progressIncrement >= 75 ? 75 : prevProgress + progressIncrement
              );
            }, 1000);

            var progress = new Promise(function (resolve, reject) {
              setTimeout(function () {
                if (jsonData['processes']) {
                  jsonV2GenerateNodes({
                    variables: {
                      in: {
                        name: name,
                        projectId: project.id,
                        description: description,
                        content: jsonData
                      }
                    },
                    onCompleted: async (data) => {
                      console.log(data)
                      resolve(data.createFileV2JSONGenerate);
                    }
                  });
                } else {
                  jsonGenerateNodes({
                    variables: {
                      in: {
                        name: name,
                        projectId: project.id,
                        description: description,
                        content: jsonData
                      }
                    },
                    onCompleted: async (data) => {
                      resolve(data.createFileJSONGenerate);
                    }
                  });
                }
              }, 1000);
            });

            progress.then(
              function (fileId) {
                clearInterval(interval);
                setProgress(100);
                navigate(`/workflows/${fileId}/diagram/view`);
              },
              function () {
                clearInterval(interval);
                setOpenAlert({ type: WORKFLOW_CREATE_TYPES.JSON, isOpen: true });
              }
            );
          }
        }
      } catch (error) {
        setOpenAlert({ type: WORKFLOW_CREATE_TYPES.JSON, isOpen: true });
      }
    };
  };

  const handleDeleteProject = async () => {
    await deleteProject({
      variables: {
        id: project.id
      },
      onCompleted: () => {
        window.location.href = `/spaces/${project.spaceId}`;
        setShowDeleteProjectModal(false);
      }
    });
  };

  const handleUpdateProject = async (name: string, description: string) => {
    await updateProject({
      variables: {
        in: {
          name: name,
          id: project.id,
          description: description
        }
      },
      onCompleted: () => {
        setProjectName(name);
        setProjectDescription(description);
        handleClose();
      }
    });
  };

  const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert({ type: '', isOpen: false });
  };

  return (
    <>
      <Box sx={{ p: 5 }} className="h-full">
        <Link href={`/spaces/${project.spaceId}`} sx={{ display: 'inline-flex' }}>
          <KeyboardArrowLeftIcon />
          <span>{space?.name}</span>
        </Link>
        <SpaceWorkflowList
          files={files}
          title={projectName}
          subTitle={projectDescription}
          showAddFileDialog={(type) => setShowAddFileModal(type)}
          showAddProjectDialog={() => setShowModal('project')}
          deleteProject={() => setShowDeleteProjectModal(true)}
          showFileNameChangeDialog={showFileNameChangeDialog}
          setSelectFileForTransfer={setSelectFileForTransfer}
          cronFile={handleCronFile}
          showFileInfoDialog={showFileInfoDialog}
          deleteFile={setRemoveFile}
          handleDeleteFile={() => setShowDeleteFileModal(true)}
          totalCount={project.filesCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        {selectedFile && (
          <>
            {showModal === 'info' && (
              <ShowFileInfoDialog handleClose={handleClose} file={selectedFile} />
            )}
            {showModal === 'change' && (
              <FileNameChangeDialog
                isOpen={true}
                handleClose={handleClose}
                file={selectedFile}
                handleFileNameChange={handleFileNameChange}
              />
            )}
          </>
        )}
        {showModal === 'add' && (
          <FileNameChangeDialog
            isOpen={true}
            handleClose={handleClose}
            file={selectedFile}
            handleFileNameChange={handleFileNameChange}
          />
        )}
        {showModal === 'project' && (
          <AddProjectDialog
            isOpen={true}
            project={{ ...project, name: projectName, description: projectDescription }}
            handleUpdateProject={handleUpdateProject}
            handleClose={() => setShowModal('')}
          />
        )}
        {showDeleteFileModal && (
          <DeleteConfirmDialog
            isOpen={true}
            title="ワークフローを削除"
            content={
              <Box sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                ワークフローを削除すると、他のユーザーもアクセスできなくなります。
              </Box>
            }
            handleDelete={handleDeleteFile}
            handleClose={() => setShowDeleteFileModal(false)}
          />
        )}
        {showDeleteProjectModal && (
          <DeleteConfirmDialog
            isOpen={true}
            title="プロジェクトを削除"
            content={
              <Box sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                プロジェクトを削除します。プロジェクト内のファイルは全て削除されます。
              </Box>
            }
            handleDelete={handleDeleteProject}
            handleClose={() => setShowDeleteProjectModal(false)}
          />
        )}
        {showAddFileModal && (
          <AddFileDialog
            isOpen={true}
            type={showAddFileModal}
            setOpenAlert={() => setOpenAlert({ type: WORKFLOW_CREATE_TYPES.JSON, isOpen: true })}
            handleClose={() => setShowAddFileModal(null)}
            handleAddDefaultFile={handleFileNameChange}
            handleAddWithAIPrompt={handleAddWithAIPrompt}
            handleAddWithJSONFile={handleGenerateFileJson}
          />
        )}
        {showGenerateWorkflowAIModal && (
          <GenerateWorkflowAIDialog
            isOpen={true}
            progress={progress}
            handleClose={() => setShowGenerateWorkflowAIModal(false)}
          />
        )}
        {selectFileForTransfer && (
          <FileTransferDialog
            transferFile={async (proj: Project) => {
              await updateFile({
                variables: {
                  in: {
                    id: selectFileForTransfer.id,
                    projectId: proj.id
                  }
                },
                onCompleted: () => {
                  setSelectFileForTransfer(undefined);
                  setOpenAlert({
                    message: `「${selectFileForTransfer.name}」を「${proj.name}」に移動しました`,
                    alertType: 'success',
                    isOpen: true
                  });
                }
              });
            }}
            handleClose={() => {
              setSelectFileForTransfer(undefined);
            }}
          />
        )}
      </Box>
      <Snackbar
        open={openAlert.isOpen}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        key={'top' + 'right'}>
        <Alert
          onClose={handleCloseAlert}
          severity={openAlert.alertType || "error"}
          sx={{
            width: '366px',
            fontSize: '16px',
            fontWeight: '500'
          }}>
          {openAlert.message ? (
            openAlert.message
          ) : (
            <>
              {openAlert.type === WORKFLOW_CREATE_TYPES.JSON
                ? 'アップロードするファイル形式は.jsonにしてください'
                : 'エラーが発生しました。お手数おかけしますが、もう一度実行するか、画面をリロードして再実行をお願いします。'}
            </>
          )}
        </Alert>
      </Snackbar>
      {errorMessage && (
        <ErrorAIGenerateDialog
          handleClose={handleClose}
          handleBack={() => {
            setShowAddFileModal(WORKFLOW_CREATE_TYPES.AI as WORKFLOW_CREATE_TYPE);
            setErrorMessage(false);
          }}
        />
      )}
    </>
  );
};

export default SpaceDetail;
