import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ButtonSwitch from './ButtonSwitch';
import HomeIcon from '@mui/icons-material/Home';
import { APPBAR_HEIGHT } from '@/components/workflow/Canvas/elements/constant';
import { RootContext } from '@/context/RootProvider';

interface Props {
  spaceId?: string;
  projectId?: string;
  name?: string;
  projectName?: string;
  setInfoShowModal: (type: boolean) => void;
}

const TopBar = (prop: Props) => {
  const { spaceId, projectId, setInfoShowModal } = prop;
  const navigate = useNavigate();
  const rootContext = React.useContext(RootContext);
  const handleClickHome = () => {
    navigate(`/spaces/${spaceId}`);
  };
  const handleClickProjectName = () => {
    navigate(`/projects/${projectId}`);
  };
  const handleClickMenu = () => {
    if (rootContext) {
      rootContext.setIsSidebarShowing(!rootContext.isSidebarShowing);
      navigate(rootContext.isSidebarShowing ? '#nosidebar' : '#');
    }
  };
  const handleClickFileInfo = () => {
    setInfoShowModal(true);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" color="secondary" sx={{ minHeight: APPBAR_HEIGHT, boxShadow: 0 }}>
        <Toolbar style={{ minHeight: APPBAR_HEIGHT }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="home"
            sx={{ mr: 0.5 }}
            onClick={handleClickHome}>
            <HomeIcon />
          </IconButton>
          <IconButton
            onClick={handleClickMenu}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 3 }}>
            {rootContext?.isSidebarShowing ? (
              <MenuIcon />
            ) : (
              <KeyboardDoubleArrowRightOutlinedIcon />
            )}
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', gap: '10px' }}>
            <span style={{ cursor: 'pointer', marginRight: 8 }} onClick={handleClickProjectName}>
              {prop.projectName}
            </span>
            {' / '}
            <span
              style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: '15%'
              }}>
              {prop.name}
            </span>
            <IconButton onClick={handleClickFileInfo} size="large" edge="start" color="inherit">
              <AssignmentIcon />
            </IconButton>
          </Typography>

          <ButtonSwitch />
          {/*<Button*/}
          {/*  variant="contained"*/}
          {/*  sx={{*/}
          {/*    mr: 1,*/}
          {/*    fontWeight: '700',*/}
          {/*    fontSize: '14px',*/}
          {/*    backgroundColor: '#2196F3'*/}
          {/*  }}>*/}
          {/*  ダウンロード*/}
          {/*</Button>*/}
          {/*<Button*/}
          {/*  variant="contained"*/}
          {/*  sx={{*/}
          {/*    mt: '3px',*/}
          {/*    fontWeight: '700',*/}
          {/*    fontSize: '14px',*/}
          {/*    height: 36,*/}
          {/*    backgroundColor: '#2196F3'*/}
          {/*  }}>*/}
          {/*  共有*/}
          {/*</Button>*/}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default TopBar;
