import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { useMutation } from '@apollo/client';
import { UPDATE_GROUP } from '../../../../api/graphql';
import { Group } from '@/__generated__/graphql';

interface Props {
  group: Group;
  handleClose: () => void;
  updateState: Function;
}

const EditGroupDialog = (props: Props) => {
  const { group, handleClose, updateState } = props;
  const [groupName, setGroupName] = useState('');
  const [updateGroup] = useMutation(UPDATE_GROUP);

  useEffect(() => {
    group?.name ? setGroupName(group.name) : setGroupName('');
  }, [group]);

  const handleAddLane = async () => {
    updateGroup({
      variables: {
        in: {
          id: group.id,
          name: groupName,
          rowIndexes: group.rowIndexes
        }
      },
      onCompleted: () => {
        updateState((prevData: any) => {
          const newData = [...prevData];
          const currentGroup = newData.find((item) => item.id === group.id);
          currentGroup.name = groupName;
          return newData;
        });
      }
    });
    handleClose();
  };

  return (
    <Dialog
      open={Boolean(group)}
      onClose={handleClose}
      PaperProps={{
        sx: { width: 500 }
      }}>
      <DialogTitle className="font-size-20 font-weight-500">グループを編集</DialogTitle>
      <Divider />
      <DialogContent>
        <FormControl sx={{ m: 1, minWidth: 400 }}>
          <TextField
            required
            fullWidth
            id="outlined-required"
            label="グループ名"
            value={groupName}
            className="w-full"
            onChange={(e) => setGroupName(e.target.value)}
          />
        </FormControl>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          type="button"
          onClick={() => handleClose()}
          variant="text"
          color="primary"
          size="medium"
          className="w-88">
          <span className="font-weight-700 font-size-14">キャンセル</span>
        </Button>
        <Button
          type="submit"
          onClick={() => handleAddLane()}
          className="w-162"
          variant="contained"
          color="primary"
          disabled={groupName === ''}
          size="medium"
          sx={{ boxShadow: 'none' }}>
          <span className="font-weight-700 font-size-14">グループを保存</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditGroupDialog;
