import React, { useEffect, useRef, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Avatar, Chip, IconButton, TableSortLabel } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Box from '@mui/material/Box';
import { formatWithDay } from '@/utils/dates';
import {
  GNodeProperty,
  NodePropertyPriorityEnum,
  NodePropertyStatusEnum
} from '@/__generated__/graphql';
import { TICKET_PRIORITY_COLOR, TICKET_PROPERTY_PRIORITY, TicketItemType } from '@/types/tickets';
import { getColorKind, getLabelKind, getNameTask, getUserName } from '@/utils/tickets';
import { getIconByTaskType } from '@/utils/tasks';
import { useParams } from 'react-router-dom';

interface Props {
  tickets: TicketItemType[];
  excludeViews: string[];
  setSelectedTicket: (ticket: TicketItemType) => void;
  onCheckComplete: (nodeId: string, status: NodePropertyStatusEnum) => void;
  selectedTicket?: TicketItemType | null;
}

type Order = 'asc' | 'desc';
type TicketRefs = {
  [key: string]: React.RefObject<HTMLDivElement>;
};
const TicketTable = (props: Props) => {
  let { workflowId } = useParams();
  const { tickets, excludeViews, setSelectedTicket, onCheckComplete, selectedTicket } = props;
  const ticketRefs = useRef<TicketRefs>({});
  tickets.forEach((ticket) => {
    if (!ticketRefs.current[ticket.id]) {
      ticketRefs.current[ticket.id] = React.createRef();
    }
  });
  const [sortedTickets, setSortedTickets] = useState<TicketItemType[]>(tickets);
  const [direction, setDirection] = useState({
    id: 'asc' as Order,
    name: 'asc' as Order,
    type: 'asc' as Order,
    task: 'asc' as Order,
    priority: 'asc' as Order,
    assignee: 'asc' as Order,
    createAt: 'asc' as Order
  });

  useEffect(() => {
    if (sortedTickets) {
      let newTickets = [] as TicketItemType[];
      let existTicketNodeId: string[] = [];
      sortedTickets.map((sortedTicket) => {
        const existTicket = tickets.find((item) => item.nodeId === sortedTicket.nodeId);
        if (existTicket) {
          newTickets.push(existTicket);
          existTicketNodeId.push(sortedTicket.nodeId);
        }
        return true;
      });
      tickets.map((ticket) => {
        if (!existTicketNodeId.includes(ticket.nodeId)) {
          newTickets.push(ticket);
        }
        return true;
      });
      setSortedTickets(newTickets);
    } else {
      setSortedTickets(tickets);
    }
  }, [tickets]);

  useEffect(() => {
    if (selectedTicket && ticketRefs.current[selectedTicket.id]) {
      const ref = ticketRefs.current[selectedTicket.id].current;
      if (ref) {
        ref.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        });
      }
    }
  }, [selectedTicket]);

  const isShowTypeArea = !excludeViews.includes('type');
  const isShowTaskArea = !excludeViews.includes('task');
  const isShowPriorityArea = !excludeViews.includes('priority');
  const isShowAssigneeArea = !excludeViews.includes('assignee');
  const isShowCreatedAtArea = !excludeViews.includes('created_at');

  const PRIORITY_RANKING = {
    high: 3,
    medium: 2,
    low: 1
  };

  const sortByItem = (type: string) => {
    if (!sortedTickets) return;
    setSortedTickets(
      sortedTickets.slice().sort((a, b) => {
        if (type === 'name') {
          setDirection({ ...direction, name: direction.name === 'asc' ? 'desc' : 'asc' });
          return direction.name === 'asc'
            ? b.name.localeCompare(a.name)
            : a.name.localeCompare(b.name);
        } else if (type === 'type') {
          setDirection({ ...direction, type: direction.type === 'asc' ? 'desc' : 'asc' });
          return direction.type === 'asc'
            ? getLabelKind(b).localeCompare(getLabelKind(a))
            : getLabelKind(a).localeCompare(getLabelKind(b));
        } else if (type === 'task') {
          setDirection({ ...direction, task: direction.task === 'asc' ? 'desc' : 'asc' });
          return direction.task === 'asc'
            ? getNameTask(b).localeCompare(getNameTask(a))
            : getNameTask(a).localeCompare(getNameTask(b));
        } else if (type === 'priority') {
          setDirection({ ...direction, priority: direction.priority === 'asc' ? 'desc' : 'asc' });

          const priorityA = getLabelPrioritySort(a);
          const priorityB = getLabelPrioritySort(b);

          return direction.priority === 'asc' ? priorityB - priorityA : priorityA - priorityB;
        } else if (type === 'assignee') {
          {
            setDirection({ ...direction, assignee: direction.assignee === 'asc' ? 'desc' : 'asc' });
            return direction.assignee === 'asc'
              ? getUserName(b).localeCompare(getUserName(a))
              : getUserName(a).localeCompare(getUserName(b));
          }
        } else if (type === 'createAt') {
          {
            setDirection({ ...direction, createAt: direction.createAt === 'asc' ? 'desc' : 'asc' });
            const date1 = new Date(a.createdAt).getTime();
            const date2 = new Date(b.createdAt).getTime();
            return direction.createAt === 'asc' ? date2 - date1 : date1 - date2;
          }
        } else {
          setDirection({ ...direction, id: direction.id === 'asc' ? 'desc' : 'asc' });
          return direction.id === 'asc' ? Number(b.id) - Number(a.id) : Number(a.id) - Number(b.id);
        }
      })
    );
  };

  const getChipColor = (ticket: TicketItemType) => {
    if (!ticket.priority) return undefined;
    if (ticket.priority === NodePropertyPriorityEnum.High) return TICKET_PRIORITY_COLOR.High;
    if (ticket.priority === NodePropertyPriorityEnum.Medium) return TICKET_PRIORITY_COLOR.Medium;
    if (ticket.priority === NodePropertyPriorityEnum.Low) return TICKET_PRIORITY_COLOR.Low;
    return 'primary';
  };

  const getLabelPriority = (ticket: GNodeProperty) => {
    if (ticket.priority === NodePropertyPriorityEnum.High) return TICKET_PROPERTY_PRIORITY.HIGH;
    if (ticket.priority === NodePropertyPriorityEnum.Medium) return TICKET_PROPERTY_PRIORITY.MEDIUM;
    if (ticket.priority === NodePropertyPriorityEnum.Low) return TICKET_PROPERTY_PRIORITY.LOW;
    return ticket.priority;
  };

  const getLabelPrioritySort = (ticket: GNodeProperty): number => {
    if (ticket && ticket.priority) {
      return PRIORITY_RANKING[ticket.priority] || 0;
    } else return 0;
  };

  const isShowCheckIcon = (ticket: GNodeProperty) => {
    return ticket.status === NodePropertyStatusEnum.Complete;
  };

  const handleShowTaskDetail = (taskNodeId: string) => {
    window.open(`/workflows/${workflowId}/process/${taskNodeId}`, '_blank');
  };

  return (
    <TableContainer component={Paper} sx={{ paddingTop: '64px' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ backgroundColor: '#F5F5F5', height: '36px' }}>
            <TableCell sx={{ padding: '6px 0px' }}></TableCell>
            <TableCell
              sx={{
                cursor: 'pointer',
                paddingLeft: '0px',
                padding: '6px 16px'
              }}>
              <TableSortLabel
                active={true}
                sx={{
                  '&.Mui-active .MuiTableSortLabel-icon': { opacity: 0 },
                  '&:hover .MuiTableSortLabel-icon': { opacity: 1 }
                }}
                direction={direction.id}
                onClick={() => sortByItem('id')}>
                ID
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ cursor: 'pointer', padding: '6px 0px' }}>
              <TableSortLabel
                active={true}
                sx={{
                  '&.Mui-active .MuiTableSortLabel-icon': { opacity: 0 },
                  '&:hover .MuiTableSortLabel-icon': { opacity: 1 }
                }}
                direction={direction.name}
                onClick={() => sortByItem('name')}>
                チケット内容
              </TableSortLabel>
            </TableCell>
            {isShowTypeArea && (
              <TableCell sx={{ cursor: 'pointer', padding: '6px 0px' }}>
                <TableSortLabel
                  active={true}
                  sx={{
                    '&.Mui-active .MuiTableSortLabel-icon': { opacity: 0 },
                    '&:hover .MuiTableSortLabel-icon': { opacity: 1 }
                  }}
                  direction={direction.type}
                  onClick={() => sortByItem('type')}>
                  種類
                </TableSortLabel>
              </TableCell>
            )}
            {isShowTaskArea && (
              <TableCell sx={{ cursor: 'pointer', padding: '6px 0px' }}>
                <TableSortLabel
                  active={true}
                  sx={{
                    '&.Mui-active .MuiTableSortLabel-icon': { opacity: 0 },
                    '&:hover .MuiTableSortLabel-icon': { opacity: 1 }
                  }}
                  direction={direction.task}
                  onClick={() => sortByItem('task')}>
                  関連プロセス
                </TableSortLabel>
              </TableCell>
            )}
            {isShowPriorityArea && (
              <TableCell sx={{ cursor: 'pointer', padding: '6px 0px' }}>
                <TableSortLabel
                  active={true}
                  sx={{
                    '&.Mui-active .MuiTableSortLabel-icon': { opacity: 0 },
                    '&:hover .MuiTableSortLabel-icon': { opacity: 1 }
                  }}
                  direction={direction.priority}
                  onClick={() => sortByItem('priority')}>
                  優先順位
                </TableSortLabel>
              </TableCell>
            )}
            {isShowAssigneeArea && (
              <TableCell sx={{ cursor: 'pointer', padding: '6px 0px' }}>
                <TableSortLabel
                  active={true}
                  sx={{
                    '&.Mui-active .MuiTableSortLabel-icon': { opacity: 0 },
                    '&:hover .MuiTableSortLabel-icon': { opacity: 1 }
                  }}
                  direction={direction.assignee}
                  onClick={() => sortByItem('assignee')}>
                  担当者
                </TableSortLabel>
              </TableCell>
            )}
            {isShowCreatedAtArea && (
              <TableCell sx={{ cursor: 'pointer', padding: '6px 0px' }}>
                <TableSortLabel
                  active={true}
                  sx={{
                    '&.Mui-active .MuiTableSortLabel-icon': { opacity: 0 },
                    '&:hover .MuiTableSortLabel-icon': { opacity: 1 }
                  }}
                  direction={direction.createAt}
                  onClick={() => sortByItem('createAt')}>
                  作成日
                </TableSortLabel>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedTickets.map((row, index) => (
            <TableRow
              key={`ticket_item_${index}`}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                cursor: 'pointer',
                '&.Mui-selected, &.Mui-selected:hover': {
                  backgroundColor: '#2196F314'
                },
                '&.MuiTableRow-root:hover': {
                  backgroundColor: '#F5F5F5'
                }
              }}
              onClick={() => setSelectedTicket(row)}
              selected={selectedTicket?.id === row.id}>
              <TableCell
                ref={ticketRefs.current[row.id]}
                component="th"
                scope="row"
                sx={{ padding: '5px' }}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isShowCheckIcon(row)) {
                      onCheckComplete(row.nodeId, NodePropertyStatusEnum.InProgress);
                    } else {
                      onCheckComplete(row.nodeId, NodePropertyStatusEnum.Complete);
                    }
                  }}>
                  {isShowCheckIcon(row) ? (
                    <CheckCircleIcon sx={{ color: '#4CAF50' }} />
                  ) : (
                    <CheckCircleOutlineIcon />
                  )}
                </IconButton>
              </TableCell>
              <TableCell sx={{ padding: '0px' }} component="th" scope="row">
                {row.nodeId}
              </TableCell>
              <TableCell sx={{ padding: '4px', maxWidth: '300px', wordBreak: 'break-word' }}>
                {row.name}
              </TableCell>
              {isShowTypeArea && (
                <TableCell sx={{ padding: '0px' }}>
                  <Chip
                    label={getLabelKind(row)}
                    size="small"
                    variant="filled"
                    sx={{
                      fontSize: 13,
                      fontWeight: 400,
                      padding: '3px 4px',
                      backgroundColor: getColorKind(row)
                    }}
                  />
                </TableCell>
              )}
              {isShowTaskArea && (
                <TableCell sx={{ padding: '4px', maxWidth: '300px', wordBreak: 'break-word' }}>
                  {row.tasks && row.tasks.length > 0 ? (
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', gap: 1, fontSize: '14px' }}
                      onClick={() => handleShowTaskDetail(row.tasks[0].nodeId)}>
                      {getIconByTaskType(row.tasks[0].type)}
                      {row.tasks.length > 1 ? (
                        <>
                          <span style={{ textDecoration: 'underline', fontSize: 12 }}>
                            {row.tasks[0].name}...
                          </span>
                          {` (+ ${row.tasks.length - 1})`}
                        </>
                      ) : (
                        <span
                          style={{
                            textDecoration: 'underline',
                            fontSize: 12
                          }}>
                          {row.tasks[0].name}
                        </span>
                      )}
                    </Box>
                  ) : (
                    ''
                  )}
                </TableCell>
              )}
              {isShowPriorityArea && (
                <TableCell sx={{ padding: '0px' }}>
                  {getChipColor(row) !== undefined && (
                    <Chip
                      label={getLabelPriority(row)}
                      size="small"
                      variant="filled"
                      sx={{
                        backgroundColor: getChipColor(row),
                        color: 'white',
                        fontSize: 13,
                        fontWeight: 400,
                        padding: '3px 4px'
                      }}
                    />
                  )}
                </TableCell>
              )}
              {isShowAssigneeArea && (
                <TableCell sx={{ padding: '0px' }}>
                  {row.users ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Avatar
                        sx={{ width: 32, height: 32 }}
                        key={`selected_user_avatar_${index}`}
                        variant="circular"
                        src={row.users[0]?.icon as string}
                      />
                      {row.users[0]?.name}
                    </Box>
                  ) : (
                    ''
                  )}
                </TableCell>
              )}
              {isShowCreatedAtArea && (
                <TableCell sx={{ padding: '0px' }}>{formatWithDay(row.createdAt)}</TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TicketTable;
