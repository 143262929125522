import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import SpaceProjectDetail from '../../../components/organisms/spaces/SpaceProjectDetail';
import { useNavigate, useParams } from 'react-router-dom';
import { Project } from '@/__generated__/graphql';
import { useQuery } from '@apollo/client';
import { GET_PROJECT } from './../../../api/graphql';
import PageNotFound from './../../../pages/PageNotFound';

const ProjectDetailPage = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [project, setProject] = React.useState<Project | undefined>(undefined);

  const { loading, data } = useQuery(GET_PROJECT, {
    variables: { id: projectId }
  });

  useEffect(() => {
    document.title = 'ゲキカル_プロジェクト';
  }, []);

  useEffect(() => {
    if (data && data.project) {
      setProject(data.project);
    }

    if (!loading && data === undefined) {
      navigate('/404');
    }
  }, [data, loading]);

  return (
    <Box className="h-full text-left">
      {project ? <SpaceProjectDetail project={project} /> : <PageNotFound />}
    </Box>
  );
};

export default ProjectDetailPage;
