import { dia, linkTools, shapes } from '@clientio/rappid';
import remove from 'lodash/remove';

class WorkflowLink extends shapes.standard.Link {
  defaults() {
    return {
      ...super.defaults,
      type: 'WorkflowLink'
    };
  }

  addTools(paper, partsMap) {
    paper.off('link:mouseenter');
    paper.off('link:mouseleave');
    const { actions } = this.attributes;
    const verticesTool = new linkTools.Vertices();
    const boundaryTool = new linkTools.Boundary();
    const removeButton = new linkTools.Remove({
      focusOpacity: 0.5,
      rotate: true,
      distance: -25,
      action(evt, linkView, toolView) {
        const { model } = linkView;
        const { linkId } = model.attributes;
        const { links } = partsMap[model.getSourceElement().id];
        remove(links, (link) => {
          return link.id === linkId;
        });
        model.remove({ ui: true, tool: toolView.cid });
        actions.remove(linkId);
      }
    });
    const toolsView = new dia.ToolsView({
      tools: [verticesTool, boundaryTool, removeButton]
    });
    const linkView = this.findView(paper);
    if (!linkView) {
      return;
    }
    paper.on('link:mouseenter', function (linkView) {
      linkView.addTools(toolsView);
    });
    paper.on('link:mouseleave', function (linkView) {
      linkView.removeTools();
    });
  }
}

const createWorkflowLink = (config, graph, source, target, id) => {
  const link = new WorkflowLink(config);
  link.attr({
    line: {
      stroke: '#757575',
      strokeWidth: 2
    }
  });
  link.prop('linkId', id);
  link.prop('sourceNodeId', source.prop('pid'));
  link.source(source);
  link.target(target);
  link.router('manhattan', {
    padding: 10
  });
  link.connector('rounded');
  link.addTo(graph);
  return link;
};
export { createWorkflowLink, WorkflowLink };
