import React from 'react';

export const getFileType = (src: string) => {
  const extension = src.split('.').pop()?.toLowerCase();
  if (['mp4', 'webm', 'ogg'].includes(extension!)) {
    return 'video';
  } else if (['jpg', 'jpeg', 'png', 'gif'].includes(extension!)) {
    return 'image';
  } else if (['doc', 'docx'].includes(extension!)) {
    return 'docx';
  } else if (['pdf'].includes(extension!)) {
    return 'pdf';
  } else if (['ppt', 'pptx'].includes(extension!)) {
    return 'ppt';
  } else if (['xlsx', 'csv'].includes(extension!)) {
    return 'xls';
  } else if (['txt'].includes(extension!)) {
    return 'txt';
  }
};

export const getFileTypeFromURL = (src: string) => {
  const path = src.split('?')[0];
  const extension = path.split('.').pop()?.toLowerCase();

  if (['mp4', 'webm', 'ogg'].includes(extension!)) {
    return 'video';
  } else if (['jpg', 'jpeg', 'png', 'gif'].includes(extension!)) {
    return 'image';
  } else if (['pdf'].includes(extension!)) {
    return 'pdf';
  } else {
    return 'document';
  }
};
